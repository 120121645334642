import React from "react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

import { ParticipantStatus, ppColorMap } from "models/participants";
import "./PpSummaryCell.scss";

const PpSummaryCell = ({ summary }) => {
  const { totalCapacity, quantity, sum } = summary;

  const afCount = quantity.find((q) => q.status === "af_summary")?.count || 0;
  const waitlistCount =
    quantity.find((q) => q.status === "wait_list")?.count || 0;
  const preBookingCount =
    quantity.find((q) => q.status === "pre_booking")?.count || 0;
  const partlyPaidCount =
    quantity.find((q) => q.status === "partly_paid")?.count || 0;
  const fullyPaidCount =
    quantity.find((q) => q.status === "fully_paid")?.count || 0;

  return (
    <div className="d-flex align-items-center pp-summary-cell">
      <span className="pp-summary-cell__bracket">(</span>
      <AngloTooltip stringId="af_summary">
        <span
          className="pp-summary-cell__val"
          style={{ color: ppColorMap.other }}
        >
          {afCount}
        </span>
      </AngloTooltip>
      <span className="pp-summary-cell__bracket"> / </span>
      <AngloTooltip stringId="wait_list">
        <span
          className="pp-summary-cell__val"
          style={{
            color: ppColorMap[ParticipantStatus.WAIT_LIST],
            fontWeight: 700,
          }}
        >
          {waitlistCount}
        </span>
      </AngloTooltip>
      <span className="pp-summary-cell__bracket">)</span>
      <span className="text-muted"> </span>
      <AngloTooltip stringId="pre_booking">
        <span
          className="pp-summary-cell__val"
          style={{ color: ppColorMap[ParticipantStatus.PRE_BOOKING] }}
        >
          {preBookingCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> + </span>
      <AngloTooltip stringId="partly_paid">
        <span
          className="pp-summary-cell__val"
          style={{ color: ppColorMap[ParticipantStatus.PARTLY_PAID] }}
        >
          {partlyPaidCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> + </span>
      <AngloTooltip stringId="fully_paid">
        <span
          className="pp-summary-cell__val"
          style={{ color: ppColorMap[ParticipantStatus.FULLY_PAID] }}
        >
          {fullyPaidCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> = </span>
      <AngloTooltip stringId="sum">
        <span className="pp-summary-cell__val pp-summary-cell__sum">{sum}</span>
      </AngloTooltip>
      <span className="text-muted"> / </span>
      <AngloTooltip stringId="total_capacity">
        <span className="pp-summary-cell__val pp-summary-cell__total">
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};

export default PpSummaryCell;
