import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getCustomersListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { format } from "date-fns";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";

const CustomersList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const maxResults = 10;

  const searchQuery = searchParams.get("searchTerm");

  const fetchCustomers = useCallback(
    debounce((page, searchValue) => {
      setLoading(true);
      getCustomersListPromise(page, maxResults, searchValue)
        .then((response) => {
          setCustomers(response.data);
          setCount(response.count);
          setCurrentPage(response.currentPage);
          setTotalPages(response.totalPages);
          setPageSize(response.pageSize);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_customers"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchCustomers(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchCustomers]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("customer_name"),
        accessor: (row) => (
          <div className="cell-customer-name">
            <Link to={`/customer/${row.id}`}>
              {row.firstName} {row.lastName}
            </Link>
          </div>
        ),
      },
      {
        Header: getString("phone_number"),
        accessor: (d) => (
          <div className="cell-phone-number">
            +{d.phonePrefix} {splitNumbers(d.phoneNumber)}
          </div>
        ),
      },
      {
        Header: getString("email"),
        accessor: (d) => (
          <div
            className="cell-email d-flex align-items-center"
            style={{ gap: "8px", color: "#6c757d" }}
          >
            <i
              className="ri-clipboard-line"
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(d.email)}
            ></i>
            <span
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(d.email)}
            >
              {d.email}
            </span>
          </div>
        ),
      },
      {
        Header: getString("ap_count"),
        accessor: (d) => (
          <div className="cell-ap-count">{d.applicationsCount}</div>
        ),
      },
      {
        Header: getString("pp_count"),
        accessor: (d) => (
          <div className="cell-pp-count">{d.participantsCount}</div>
        ),
      },
      {
        Header: getString("last_program"),
        accessor: (d) => (
          <div className="cell-last-program">
            <div>{d.lastProgramName}</div>
            {d.lastProgramStartDate && d.lastProgramEndDate ? (
              <div>
                {format(new Date(d.lastProgramStartDate), "yyyy-MM-dd")} -{" "}
                {format(new Date(d.lastProgramEndDate), "yyyy-MM-dd")}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
      },
      {
        Header: getString("note"),
        accessor: (d) => (
          <div className="cell-note">
            {d.customerNote ? d.customerNote : "-"}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Card>
      <CardHeader>
        <h5 style={{ marginBottom: 0 }}>
          <FormattedString id="customers" />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <TableContainerExternalPagination
            columns={columns || []}
            count={count}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            loading={loading}
            globalFilter={searchQuery}
            setGlobalFilter={() => {}}
            onPageChange={handlePageChange}
            data={customers || []}
            isBordered={false}
            isGlobalFilter={true}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            SearchPlaceholder={getString("customers_search_for")}
            error={null}
            onRetry={() => fetchCustomers(currentPage, searchQuery)}
            preventLoading={false}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default CustomersList;
