import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

import navdata from "../LayoutMenuData";

import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { FormattedString } from "Components/Common/FormattedString";
import { hasPermission } from "Components/Hooks/useHasPermission";

const VerticalLayout = (props) => {
  const navData = navdata();
  const location = useLocation();

  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
    }),
  );

  const { layoutType, leftsidbarSizeType, sidebarVisibilitytype } = useSelector(
    selectLayoutProperties,
  );

  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftsidbarSizeType,
        );
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftsidbarSizeType,
        );
      }
      var hamburgerIcon = document.querySelector(".hamburger-icon");
      if (
        (sidebarVisibilitytype === "show" ||
          layoutType === "vertical" ||
          layoutType === "twocolumn") &&
        document.querySelector(".hamburger-icon")
      ) {
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.remove("open");
        }
      } else {
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.add("open");
        }
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("twocolumn-panel");
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon").classList.add("open");
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (
        document.documentElement.getAttribute("data-layout") !== "horizontal"
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon").classList.add("open");
      }
    }
  }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
  }, [resizeSidebarMenu]);

  const initMenu = () => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    const ul = document.getElementById("navbar-nav");
    const items = ul.getElementsByTagName("a");
    let itemsArray = [...items];
    removeActivation(itemsArray);
    let matchingMenuItems = itemsArray.filter((x) => {
      return (
        x.pathname.includes(pathName.split("/")[1]) &&
        Math.abs(x.pathname.length - pathName.split("/")[1].length) <=
          0.3 * x.pathname.length
      );
    });

    matchingMenuItems.forEach((item) => {
      activateParentDropdown(item);
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    collapseAll();
    initMenu();
  }, [props.router.location.pathname, props.layoutType]);

  function toggleDropdown(baseItem, id) {
    setOpenItems((openItems) => [...openItems, id]);
    const item = baseItem.closest(".nav-link.menu-link");
    if (item.getAttribute("aria-expanded") === "true") {
      setOpenItems((openItems) => openItems.filter((itemId) => itemId !== id));
    } else {
      let parentCollapseDiv = item
        .closest(".nav-item")
        .querySelector(".menu-dropdown.collapse");

      if (parentCollapseDiv) {
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true",
        );
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show");
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          )
            parentCollapseDiv.parentElement.closest(".collapse");
          if (
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse")
          ) {
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse")
              .classList.add("show");
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse");
          }
        }
        return false;
      }
      return false;
    }
  }

  function toggleSubItemDropdown(baseItem) {
    const item = baseItem.closest(".nav-link");
    if (item.getAttribute("aria-expanded") === "true") {
      setOpenItems((openItems) =>
        openItems.filter((item) => item !== baseItem.getAttribute("itemID")),
      );
      if (item.classList.contains("nav-link"))
        item.setAttribute("aria-expanded", false);
    } else {
      setOpenItems((openItems) => [...openItems, item.getAttribute("itemID")]);
      let parentCollapseDiv = item
        .closest(".nav-item")
        .querySelector(".menu-dropdown.collapse");

      if (parentCollapseDiv) {
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true",
        );
        return false;
      }
      return false;
    }
  }

  function activateParentDropdown(item) {
    if (item.classList.contains("active")) {
      removeActivation([item]);
    } else {
      item.classList.add("active");
      let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

      if (parentCollapseDiv) {
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].classList.add("active");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true",
        );
        setOpenItems((openItems) => [
          ...openItems,
          parentCollapseDiv.parentElement.children[0]?.getAttribute("itemID"),
        ]);
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show");
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          ) {
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.classList.add("active");

            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.setAttribute("aria-expanded", "true");
            setOpenItems((openItems) => [
              ...openItems,
              parentCollapseDiv.parentElement
                .closest(".collapse")
                .previousElementSibling.getAttribute("itemID"),
            ]);
          }
          if (
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse")
          ) {
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse")
              .classList.add("show");
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.closest(".collapse")
              .previousElementSibling.classList.add("active");
          }
        }
        return false;
      }
      return false;
    }
  }

  const removeActivation = (items) => {
    let actiItems = items.filter(
      (x) =>
        x.getAttribute("aria-expanded") === "true" ||
        x.classList.contains("active"),
    );
    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
          setOpenItems((openItems) =>
            openItems.filter((item) => item !== item?.getAttribute("itemID")),
          );
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  const collapseAll = () => {
    setOpenItems([]);
  };

  const [openItems, setOpenItems] = useState([]);

  const roles = useSelector((state) => state.Profile.user.roles);

  const filterMenuItems = (menuItems) => {
    return menuItems
      .map((item) => {
        if (item.permission && !hasPermission(roles, item.permission)) {
          return null;
        }

        if (item.subItems) {
          item.subItems = filterMenuItems(item.subItems);
          if (item.subItems.length === 0) {
            return null;
          }
        }

        if (item.childItems) {
          item.childItems = filterMenuItems(item.childItems);
          if (item.childItems.length === 0) {
            return null;
          }
        }

        return item;
      })
      .filter((item) => item !== null);
  };

  const filteredNavData = filterMenuItems(navData);

  const handleLinkClick = (e, link) => {
    if (location.pathname === link) {
      e.preventDefault();
      window.location.reload();
    }
  };

  return (
    <React.Fragment>
      {(filteredNavData || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item["isHeader"] ? (
              <li className="menu-title">
                <span data-key="t-menu">
                  <FormattedString id={props.t(item.id)} />
                </span>
              </li>
            ) : item.subItems ? (
              <li className="nav-item">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown(e.target, item.id);
                  }}
                  className="nav-link menu-link"
                  to={item.link ? item.link : "/#"}
                  data-bs-toggle="collapse"
                  itemID={item.id}
                  aria-expanded={openItems.includes(item.id) ? "true" : "false"}
                >
                  <i className={item.icon}></i>{" "}
                  <span data-key="t-apps">
                    <FormattedString id={props.t(item.id)} />
                  </span>{" "}
                </Link>
                <Collapse
                  className="menu-dropdown"
                  isOpen={openItems.includes(item.id)}
                  id="sidebarApps"
                >
                  <ul className="nav nav-sm flex-column test">
                    {item.subItems &&
                      (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <li className="nav-item">
                              <Link
                                to={subItem.link ? subItem.link : "/#"}
                                onClick={(e) =>
                                  handleLinkClick(e, subItem.link)
                                }
                                className="nav-link"
                                itemID={subItem.id}
                                aria-expanded={
                                  openItems.includes(subItem.id)
                                    ? "true"
                                    : "false"
                                }
                              >
                                <FormattedString id={props.t(subItem.id)} />
                                {subItem.badgeName ? (
                                  <span
                                    className={
                                      "badge badge-pill bg-" +
                                      subItem.badgeColor
                                    }
                                    data-key="t-new"
                                  >
                                    {subItem.badgeName}
                                  </span>
                                ) : null}
                              </Link>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleSubItemDropdown(e.target);
                                }}
                                className="nav-link"
                                to="/#"
                                data-bs-toggle="collapse"
                                itemID={subItem.id}
                                aria-expanded={
                                  openItems.includes(subItem.id)
                                    ? "true"
                                    : "false"
                                }
                              >
                                <FormattedString id={props.t(subItem.id)} />
                                {subItem.badgeName ? (
                                  <span
                                    className={
                                      "badge badge-pill bg-" +
                                      subItem.badgeColor
                                    }
                                    data-key="t-new"
                                  >
                                    {subItem.badgeName}
                                  </span>
                                ) : null}
                              </Link>
                              <Collapse
                                className="menu-dropdown"
                                isOpen={openItems.includes(subItem.id)}
                                id="sidebarEcommerce"
                              >
                                <ul className="nav nav-sm flex-column">
                                  {subItem.childItems &&
                                    (subItem.childItems || []).map(
                                      (childItem, key) => (
                                        <React.Fragment key={key}>
                                          {!childItem.childItems ? (
                                            <li className="nav-item">
                                              <Link
                                                to={
                                                  childItem.link
                                                    ? childItem.link
                                                    : "/#"
                                                }
                                                onClick={(e) =>
                                                  handleLinkClick(
                                                    e,
                                                    childItem.link,
                                                  )
                                                }
                                                data-item-id={item.id}
                                                className="nav-link"
                                                itemID={childItem.id}
                                                aria-expanded={
                                                  openItems.includes(
                                                    childItem.id,
                                                  )
                                                    ? "true"
                                                    : "false"
                                                }
                                              >
                                                <FormattedString
                                                  id={props.t(childItem.id)}
                                                />
                                              </Link>
                                            </li>
                                          ) : (
                                            <li className="nav-item">
                                              <Link
                                                to="/#"
                                                className="nav-link"
                                                onClick={childItem.click}
                                                data-bs-toggle="collapse"
                                                itemID={item.id}
                                                aria-expanded={
                                                  openItems.includes(item.id)
                                                    ? "true"
                                                    : "false"
                                                }
                                              >
                                                <FormattedString
                                                  id={props.t(childItem.id)}
                                                />
                                              </Link>
                                              <Collapse
                                                className="menu-dropdown"
                                                isOpen={openItems.includes(
                                                  item.id,
                                                )}
                                                id="sidebaremailTemplates"
                                              >
                                                <ul className="nav nav-sm flex-column">
                                                  {childItem.childItems.map(
                                                    (subChildItem, key) => (
                                                      <li
                                                        className="nav-item"
                                                        key={key}
                                                      >
                                                        <Link
                                                          to={
                                                            subChildItem.link
                                                              ? subChildItem.link
                                                              : "/#"
                                                          }
                                                          onClick={(e) =>
                                                            handleLinkClick(
                                                              e,
                                                              subChildItem.link,
                                                            )
                                                          }
                                                          className="nav-link"
                                                          data-key="t-basic-action"
                                                          itemID={item.id}
                                                          aria-expanded={
                                                            openItems.includes(
                                                              item.id,
                                                            )
                                                              ? "true"
                                                              : "false"
                                                          }
                                                        >
                                                          <FormattedString
                                                            id={props.t(
                                                              subChildItem.label,
                                                            )}
                                                          />
                                                        </Link>
                                                      </li>
                                                    ),
                                                  )}
                                                </ul>
                                              </Collapse>
                                            </li>
                                          )}
                                        </React.Fragment>
                                      ),
                                    )}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  className="nav-link menu-link"
                  to={item.link ? item.link : "/#"}
                  onClick={(e) => handleLinkClick(e, item.link)}
                >
                  <i className={item.icon}></i>{" "}
                  <span>
                    <FormattedString id={props.t(item.id)} />
                  </span>
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
