import React from "react";
import { Row, Col } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import PriceField from "./fields/PriceField";
import DueDateField from "./fields/DueDateField";
import CurrencyField from "./fields/CurrencyField";
import TypeField from "./fields/TypeField";
import PointOfSellField from "./fields/PointOfSellField";
import RemoveInstallmentButton from "./fields/RemoveInstallmentButton";
import "./InstallmentFormFields.scss";
import { useFormContext } from "../../../provider/utils/FormContext";
import { calculateRemainingPrice } from "../../../provider/utils/utils";

const InstallmentFormFields = ({
  installment,
  index,
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  isDisabled,
  advancedManagement,
  currentInstallmentCount,
  installmentPlans,
}) => {
  const { maxInstallmentSum, setInstallmentsError } = useFormContext();
  const canEditInstallment = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const remainingPrice = calculateRemainingPrice(
    formik,
    maxInstallmentSum,
    index,
  );

  const hasErrors = formik.errors.installments?.[index];
  const isLastInstallment = index === currentInstallmentCount - 1;

  const hasPaidInstallments = formik.values.installments.some(
    (installment) => installment.isPaid === true,
  );

  const lowerInstallmentPlanExists = installmentPlans.some(
    (plan) => plan.installmentCount < currentInstallmentCount,
  );

  const canRemoveInstallment =
    (!canEditInstallment &&
      !hasPaidInstallments &&
      lowerInstallmentPlanExists) ||
    canEditInstallment;

  return (
    <div className="installment-form-fields">
      <Row className="mb-2">
        <Col>
          <h5
            className={`text-left ${hasErrors ? "red-header" : "blue-header"}`}
          >
            {getString("installment")} {index + 1}
          </h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={2}>
          <PriceField
            formik={formik}
            index={index}
            remainingPrice={remainingPrice}
            isDisabled={isDisabled}
            maxInstallmentSum={maxInstallmentSum}
            canEditInstallment={canEditInstallment}
          />
        </Col>
        <Col md={2}>
          <DueDateField formik={formik} index={index} isDisabled={isDisabled} />
        </Col>
        {advancedManagement && (
          <>
            <Col md={2}>
              <CurrencyField
                formik={formik}
                index={index}
                isDisabled={isDisabled}
                canEditInstallment={canEditInstallment}
              />
            </Col>
            <Col md={2}>
              <TypeField
                formik={formik}
                index={index}
                installmentTypes={installmentTypes}
                isDisabled={isDisabled}
                canEditInstallment={canEditInstallment}
                installment={installment}
              />
            </Col>
            <Col md={2}>
              <PointOfSellField
                formik={formik}
                index={index}
                installment={installment}
                pointOfSellOptions={pointOfSellOptions}
                isDisabled={isDisabled}
                canEditInstallment={canEditInstallment}
              />
            </Col>
          </>
        )}
        {!isDisabled && isLastInstallment && (
          <Col
            md={2}
            className="d-flex align-items-center mb-3"
            style={{ marginTop: "calc(19px + 0.5rem)" }}
          >
            <RemoveInstallmentButton
              canRemoveInstallment={canRemoveInstallment}
              removeInstallment={
                canRemoveInstallment ? removeInstallment : null
              }
              index={index}
              setInstallmentsError={setInstallmentsError}
              formik={formik}
              tooltipHidden={hasPaidInstallments}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InstallmentFormFields;
