import React from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { FormattedString } from "Components/Common/FormattedString";

const RecentSearches = () => {
  const { recentSearches, searchValue, closeDropdown, dropdownRef } =
    useSearch();

  if (searchValue !== "" || recentSearches.length === 0) {
    return null;
  }

  const handleLinkClick = () => {
    closeDropdown(dropdownRef.current);
  };

  return (
    <>
      <div className="dropdown-header">
        <h6 className="text-overflow text-muted mb-0 text-uppercase">
          <FormattedString id="recent_searches" />
        </h6>
      </div>
      <div className="dropdown-item bg-transparent text-wrap">
        <div
          className="recent-searches-wrapper"
          style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
        >
          {recentSearches
            .slice(-5)
            .reverse()
            .map((search, index) => (
              <Link
                key={index}
                to={`/search?searchTerm=${encodeURIComponent(search)}`}
                className="btn btn-soft-secondary btn-sm rounded-pill"
                onClick={handleLinkClick}
              >
                {search} <i className="mdi mdi-magnify ms-1"></i>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
};

export default RecentSearches;
