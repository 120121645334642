import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import eventEmitter from "utils/eventEmitter";
import error from "assets/images/error.svg";

export const Popup400 = () => {
  const [visible, setVisible] = useState(false);
  const [_code, setCode] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const handleShowPopup = (code, msg) => {
      setCode(code);
      setMessage(msg);
      setVisible(true);
    };

    eventEmitter.on("showPopup", handleShowPopup);

    return () => {
      eventEmitter.off("showPopup", handleShowPopup);
    };
  }, []);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => setVisible(false)}
      id="error-details-modal"
      title={getString("error_details")}
      showFooter={false}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center pt-4">
              <div className="">
                <img
                  src={error}
                  alt=""
                  className="error-basic-img error-basic-img--small move-animation"
                />
              </div>
              <div className="mt-n4">
                <h3
                  className="text-uppercase"
                  style={{ wordBreak: "break-word" }}
                >
                  {message}
                </h3>
                <p className="text-muted mb-4">
                  <FormattedString id="error_popup_paragraph" />
                </p>
                <Button
                  className="mb-3"
                  color="success"
                  onClick={() => setVisible(false)}
                >
                  <i className="mdi mdi-close me-1"></i>
                  <FormattedString id="close" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SimplestModal>
  );
};
