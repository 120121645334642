import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Button, Spinner } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getString } from "Components/Common/FormattedString";
import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import {
  getApplicationsListData,
  postApplication,
} from "store/applications/action";
import { FormSelect } from "Components/Entity/FormSelect";
import FormTextField from "Components/Entity/FormTextField";
import { createApplicationSchema } from "../../models/applications";
import { getProgramListData } from "store/programs/action";
import { getCustomersListData } from "store/customers/action";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { programStatus } from "models/programStatuses";

const AddApplication = ({ visibility, toggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, formValidationErrors } = useSelector(
    (state) => state.Applications,
  );
  const { loading: programsLoading, programsData } = useSelector(
    (state) => state.Programs,
  );
  const { loading: customersLoading, customersData } = useSelector(
    (state) => state.Customers,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      programId: "",
      customerId: "",
      phonePrefix: "",
      phoneNumber: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: createApplicationSchema,
    onSubmit: async (values) => {
      const applicationData = { ...values };
      if (!applicationData.customerId) {
        delete applicationData.customerId;
      }
      dispatch(
        postApplication(applicationData, (response) => {
          const applicationId = response.applicationId;
          if (applicationId) {
            navigate(`/pp-application/${applicationId}`);
          } else {
            dispatch(getApplicationsListData());
            toggle(false);
          }
        }),
      );
    },
  });

  useEffect(() => {
    dispatch(getProgramListData());
    dispatch(getCustomersListData(1, null, 999999));
  }, [dispatch]);

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  const isRegistrationNeeded = () => {
    return validation.values.customerId === "";
  };

  const handleCustomerChange = (selectedOption) => {
    handleSelectChange(validation, selectedOption, "customerId");
    validation.setErrors({});
  };

  const programOptions = programsData
    .filter((program) =>
      [
        programStatus.in_preparation,
        programStatus.ongoing,
        programStatus.on_sale,
      ].includes(program.status),
    )
    .map((program) => ({
      value: program.customId,
      label: `${program.name} (${program.customId})`,
    }));

  const customerOptions = customersData.map((customer) => ({
    value: customer.id,
    label: `${customer.firstName} ${customer.lastName} (${customer.email})`,
  }));

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("create_application")}
        formId={"add-application"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        {programsLoading || customersLoading ? (
          <PreloaderWrap />
        ) : (
          <Form
            id="add-application"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <Card>
              <CardBody className="card-body">
                <FormSelect
                  label={getString("program")}
                  name="program"
                  value={validation.values.programId}
                  defaultValue={{
                    value: validation.values.programId,
                    label: getString(validation.values.programId),
                  }}
                  onChange={(selectedOption) =>
                    handleSelectChange(validation, selectedOption, "programId")
                  }
                  options={programOptions}
                  isMulti={false}
                  error={validation.errors.programId}
                />
                <FormSelect
                  label={getString("customer")}
                  name="customer"
                  isClearable={true}
                  value={validation.values.customerId}
                  onChange={handleCustomerChange}
                  options={customerOptions}
                  isMulti={false}
                  error={validation.errors.customerId}
                />
                {isRegistrationNeeded() && (
                  <>
                    <FormTextField
                      label={getString("phone_prefix")}
                      name="phonePrefix"
                      value={validation.values.phonePrefix}
                      onChange={(e) =>
                        handleInputChange(validation, e, "phonePrefix")
                      }
                      error={validation.errors.phonePrefix}
                      touched={validation.touched.phonePrefix}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("phone_number")}
                      name="phoneNumber"
                      value={validation.values.phoneNumber}
                      onChange={(e) =>
                        handleInputChange(validation, e, "phoneNumber")
                      }
                      error={validation.errors.phoneNumber}
                      touched={validation.touched.phoneNumber}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("email")}
                      name="email"
                      value={validation.values.email}
                      onChange={(e) =>
                        handleInputChange(validation, e, "email")
                      }
                      error={validation.errors.email}
                      touched={validation.touched.email}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("first_name")}
                      name="firstName"
                      value={validation.values.firstName}
                      onChange={(e) =>
                        handleInputChange(validation, e, "firstName")
                      }
                      error={validation.errors.firstName}
                      touched={validation.touched.firstName}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("last_name")}
                      name="lastName"
                      value={validation.values.lastName}
                      onChange={(e) =>
                        handleInputChange(validation, e, "lastName")
                      }
                      error={validation.errors.lastName}
                      touched={validation.touched.lastName}
                      required={isRegistrationNeeded()}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Form>
        )}
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddApplication;
