import React, { useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { GenerateNewReportCanvas } from "./components/GenerateNewReportCanvas";

const Payments = () => {
  document.title = getString("payments_page_title");
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);

  const handleGenerateNewReport = () => {
    setIsCanvasOpen(true);
  };

  const handleCloseCanvas = () => {
    setIsCanvasOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("payments")}
                  headerButtons={
                    <Button
                      color="light"
                      className="generate-new-report-button text-primary ms-2"
                      onClick={handleGenerateNewReport}
                    >
                      <FormattedString id="generate_report" />
                    </Button>
                  }
                >
                  <div className="text-center py-5">
                    <h5 className="text-muted">
                      <FormattedString id="nothing_to_show" />
                    </h5>
                  </div>
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isCanvasOpen && (
        <GenerateNewReportCanvas
          isOpen={isCanvasOpen}
          toggle={handleCloseCanvas}
        />
      )}
    </React.Fragment>
  );
};

export default Payments;
