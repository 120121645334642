import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { downloadBlob } from "helpers/fileUtils";
import { generateInsuranceReport } from "helpers/API/core-service/cs_backend_helper";

const Insurance = () => {
  document.title = getString("insurance_page_title");
  const [loading, setLoading] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);

  const handleGenerateReport = () => {
    setLoading(true);
    generateInsuranceReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "insurance_report.csv");
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseCanvas = () => {
    setIsCanvasOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("insurance")}
                  headerButtons={
                    <Button
                      color="light"
                      className="generate-report-button text-primary"
                      onClick={handleGenerateReport}
                      disabled={loading}
                    >
                      <FormattedString id="generate_insurance_report" />
                      {loading && (
                        <Spinner size="sm" color="primary" className="ms-2" />
                      )}
                    </Button>
                  }
                >
                  <div className="text-center py-5">
                    <h5 className="text-muted">
                      <FormattedString id="nothing_to_show" />
                    </h5>
                  </div>
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isCanvasOpen && (
        <GenerateNewReportCanvas
          isOpen={isCanvasOpen}
          toggle={handleCloseCanvas}
        />
      )}
    </React.Fragment>
  );
};

export default Insurance;
