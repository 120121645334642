import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "Components/Entity/EntityCardList";
import PresetTableTabs from "./components/PresetTableTabs";
import GlobalFilter from "./components/GlobalFilter";
import Pagination from "./components/Pagination";
import TableContent from "./components/TableContent";
import { DefaultColumnFilter } from "../filters";
import {
  PresetTableProvider,
  usePresetTableContext,
} from "./PresetTableContext";
import { getString } from "../FormattedString";
import { customSortMapping } from "./components/utils";
import { DEFAULT_PAGE_SIZE } from "resources/consts";
import { generateDefaultPreset } from "./components/defaultPresets";

const PresetTableContent = forwardRef(
  (
    {
      title,
      getDataAction,
      disablePagination = false,
      disableSearchBar = false,
      data,
      count,
      currentPage,
      totalPages,
      loading,
      error,
      onRetry,
      headerButtons,
    },
    ref,
  ) => {
    const {
      searchParams,
      isInitialRender,
      globalFilter,
      customSetGlobalFilter,
      handlePageChange,
      sortBy,
      activePreset,
      setActivePreset,
      presets,
      tableName,
    } = usePresetTableContext();

    const currentPresetObject =
      presets.find((p) => p.name === activePreset) ||
      generateDefaultPreset(tableName);

    const prevSortByRef = useRef(sortBy);

    const refreshData = () => {
      const filters = currentPresetObject.filters || {};
      const pageSize = currentPresetObject.pageSize || DEFAULT_PAGE_SIZE;
      const sortColumn = sortBy.length > 0 ? sortBy[0].id : null;
      const sortValue = customSortMapping[sortColumn] || sortColumn;

      getDataAction(
        searchParams.get("page") || "1",
        pageSize,
        globalFilter,
        sortValue,
        sortBy[0]?.desc ? "DESC" : "ASC",
        filters,
      );
    };

    useImperativeHandle(ref, () => ({
      refreshData,
    }));

    useEffect(() => {
      if (!currentPresetObject) {
        setActivePreset("default");
        return;
      }

      if (isInitialRender.current) {
        isInitialRender.current = false;
      }

      refreshData();
    }, [
      searchParams,
      currentPresetObject?.filters,
      currentPresetObject?.pageSize,
    ]);

    useEffect(() => {
      const sortColumn = sortBy.length > 0 ? sortBy[0].id : null;
      const sortValue = customSortMapping[sortColumn] || sortColumn;
      const filters = currentPresetObject.filters || {};
      const pageSize = currentPresetObject.pageSize || DEFAULT_PAGE_SIZE;

      if (JSON.stringify(prevSortByRef.current) !== JSON.stringify(sortBy)) {
        getDataAction(
          searchParams.get("page") || "1",
          pageSize,
          globalFilter,
          sortValue,
          sortBy[0]?.desc ? "DESC" : "ASC",
          filters,
        );
        prevSortByRef.current = sortBy;
      }
    }, [sortBy]);

    const pageSize = currentPresetObject?.pageSize || DEFAULT_PAGE_SIZE;
    const startIndex = (currentPage - 1) * pageSize + 1;
    const endIndex = Math.min(currentPage * pageSize, count);

    return (
      <div className="page-content" data-testid="preset-table-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList title={title} headerButtons={headerButtons}>
                  <PresetTableTabs />
                  <Row className="mb-3">
                    {!disableSearchBar && (
                      <GlobalFilter
                        globalFilter={globalFilter}
                        setGlobalFilter={customSetGlobalFilter}
                        SearchPlaceholder={getString(`${tableName}_search_for`)}
                      />
                    )}
                  </Row>
                  <div style={{ overflowX: "auto" }}>
                    <TableContent
                      data={data}
                      loading={loading}
                      error={error}
                      onRetry={onRetry}
                    />
                  </div>
                  {!disablePagination && data.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      loading={loading}
                      handlePageChange={handlePageChange}
                      count={count}
                      startIndex={startIndex}
                      endIndex={endIndex}
                    />
                  )}
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

const PresetTable = forwardRef((props, ref) => (
  <PresetTableProvider
    columns={props.columns}
    data={props.data}
    defaultColumn={{ Filter: DefaultColumnFilter }}
    tableName={props.tableName}
    count={props.count}
    currentPage={props.currentPage}
    totalPages={props.totalPages}
    pageSize={props.pageSize}
    getDataAction={props.getDataAction}
    filterMapping={props.filterMapping}
  >
    <PresetTableContent {...props} ref={ref} />
  </PresetTableProvider>
));

export default PresetTable;
