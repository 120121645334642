import "./ApplicationDetails.scss";

import { useLocation, useParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";

import { Container, TabContent } from "reactstrap";
import { ApplicationHeader } from "./partials/ApplicationHeader";
import { ApplicationTabSwitcher } from "./partials/ApplicationTabSwitcher";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import {
  getApplication,
  getApplicationActivity,
} from "helpers/API/core-service/cs_backend_helper";
import { getMeetingPointListData } from "store/meetingPoints/action";
import { useDispatch } from "react-redux";
import { useHasPermission } from "Components/Hooks/useHasPermission";

import { ApplicationOverviewTab } from "./partials/Tabs/ApplicationOverviewTab/ApplicationOverviewTab";
import { ApplicationActivityTab } from "./partials/Tabs/ApplicationActivityTab/ApplicationActivityTab";
import { programStatus } from "models/programStatuses";
import { getPointOfSellListData } from "store/pointOfSell/action";

export const ApplicationDetailsContext = createContext();

export const ApplicationDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const hash = location.hash.substring(1);

  const [applicationData, setApplicationData] = useState();
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [applicationErrors, setApplicationErrors] = useState();
  const [activeTab, setActiveTab] = useState(hash || "overview");
  const [editTileOpen, setEditTileOpen] = useState(false);

  const refreshApplicationData = () => {
    getApplication(id).then((r) => {
      setApplicationData(r);
      document.title =
        r.customId + " | " + r.season + " | Angloville - CRM Panel";

      setLoading(false);
    });
  };

  const refreshApplicationLogs = () => {
    getApplicationActivity(id).then((r) => {
      setApplicationLogs(r);
    });
  };

  const dispatch = useDispatch();

  const canUpdateApplication =
    useHasPermission("APPLICATION_UPDATE") &&
    applicationData?.status === programStatus.in_preparation;

  useEffect(() => {
    refreshApplicationData();
    dispatch(getMeetingPointListData());
    dispatch(getPointOfSellListData());
    refreshApplicationLogs();
  }, []);

  return loading === false ? (
    <>
      <div className="page-content application-details">
        <Container fluid className="mb-4">
          <ApplicationDetailsContext.Provider
            value={{
              applicationData,
              setApplicationData,
              activeTab,
              setActiveTab,
              refreshApplicationData,
              applicationErrors,
              setApplicationErrors,
              canUpdateApplication,
              applicationLogs,
              refreshApplicationLogs,
              editTileOpen,
              setEditTileOpen,
            }}
          >
            <ApplicationHeader />
            <ApplicationTabSwitcher />
            <TabContent activeTab={activeTab} className="pt-4">
              {activeTab === "overview" && <ApplicationOverviewTab />}
              {activeTab === "activity" && <ApplicationActivityTab />}
            </TabContent>
          </ApplicationDetailsContext.Provider>
        </Container>
      </div>
    </>
  ) : (
    <PreloaderWrap h100 />
  );
};
