// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-form-summary {
  background: #428bca;
  border-radius: 20px;
  padding: 21px;
  margin-bottom: 20px;
  color: white;
  position: relative;
}
.ip-form-summary--moved {
  background: #6c757d;
}
.ip-form-summary--moved .program-short__hotel a {
  color: #6c757d;
}
.ip-form-summary--moved .participant-short__sum::before {
  background: white;
}
.ip-form-summary--moved .discounts-short__section__item__name {
  color: #6c757d;
}
.ip-form-summary--moved .discounts-short__sum::before {
  background: #6c757d;
}
.ip-form-summary--moved .discounts-short__sum {
  color: #6c757d;
}
.ip-form-summary--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ip-form-summary--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ip-form-summary__section__header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ip-form-summary__no-participants, .ip-form-summary__no-discounts {
  margin-bottom: 10px;
}
.ip-form-summary__footer {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}
.ip-form-summary__placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #d1ecf1;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpApplications/IpApplicationDetails/partials/IpFormSummary/IpFormSummary.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAAI;EACE,cAAA;AAEN;AACI;EACE,iBAAA;AACN;AAEI;EACE,cAAA;AAAN;AAGI;EACE,mBAAA;AADN;AAII;EACE,cAAA;AAFN;AAME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAJJ;AAOE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AALJ;AASI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAPN;AAWE;EAEE,mBAAA;AAVJ;AAaE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAXJ;AAcE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAZJ","sourcesContent":[".ip-form-summary {\n  background: #428bca;\n  border-radius: 20px;\n  padding: 21px;\n  margin-bottom: 20px;\n  color: white;\n  position: relative;\n\n  &--moved {\n    background: #6c757d;\n    .program-short__hotel a {\n      color: #6c757d;\n    }\n\n    .participant-short__sum::before {\n      background: white;\n    }\n\n    .discounts-short__section__item__name {\n      color: #6c757d;\n    }\n\n    .discounts-short__sum::before {\n      background: #6c757d;\n    }\n\n    .discounts-short__sum {\n      color: #6c757d;\n    }\n  }\n\n  &--loading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n  }\n\n  &--centered {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__section {\n    &__header {\n      font-size: 20px;\n      font-weight: 700;\n      margin-bottom: 10px;\n    }\n  }\n\n  &__no-participants,\n  &__no-discounts {\n    margin-bottom: 10px;\n  }\n\n  &__footer {\n    display: flex;\n    justify-content: space-between;\n    font-size: 24px;\n    font-weight: 700;\n    text-align: left;\n  }\n\n  &__placeholder {\n    font-size: 16px;\n    font-weight: 400;\n    color: #d1ecf1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
