import React, { useContext } from "react";
import { Alert, Progress } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";
import { useFormContext } from "../../../provider/utils/FormContext";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";

const ParticipantAlert = ({ participant }) => {
  const { parentProgram } = useFormContext();
  const { applicationData } = useContext(ApplicationDetailsContext);

  const participantProgramPacketId = participant?.programPacket;

  const programPacket = parentProgram?.participants?.find(
    (p) => p.programPacketId === participantProgramPacketId,
  );

  const totalCapacity = programPacket?.summary?.totalCapacity || 1;
  const occupancyFraction = programPacket?.summary?.occupancyFraction || 0;

  const actualParticipants = (occupancyFraction / 100) * totalCapacity;
  const totalParticipantsAfterAdding = actualParticipants + 1;
  const exceededBy = Math.max(0, totalParticipantsAfterAdding - totalCapacity);
  const isCapacityExceeded = exceededBy > 0;

  const isStatusAllowed =
    applicationData?.status &&
    (applicationData.status === "cancelled" ||
      applicationData.status === "not_paid" ||
      applicationData.status.startsWith("af_"));

  if (isCapacityExceeded && isStatusAllowed) {
    return (
      <Alert color="warning">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center mb-2">
            <i
              className="ri-error-warning-line"
              style={{ marginRight: "4px" }}
              role="img"
            ></i>
            <span style={{ marginRight: "8px" }}>
              {participant.firstName} {participant.lastName}
            </span>
            <FormattedString id="program_packet_capacity_exceeded_warning" />
          </div>
          <div className="w-100">
            <Progress
              color={isCapacityExceeded ? "danger" : "success"}
              value={totalParticipantsAfterAdding}
              max={totalCapacity}
            >
              {Math.round(totalParticipantsAfterAdding)} / {totalCapacity}{" "}
              <FormattedString id="participants" />
            </Progress>
            {isCapacityExceeded && (
              <div className="text-danger mt-2">
                <FormattedString id="capacity_for" />{" "}
                {programPacket?.programPacketLabel}{" "}
                <FormattedString id="exceeded_by" /> {Math.round(exceededBy)}{" "}
                {exceededBy === 1 ? (
                  <FormattedString id="person" />
                ) : (
                  <FormattedString id="people" />
                )}
              </div>
            )}
          </div>
        </div>
      </Alert>
    );
  }

  return <></>;
};

export default ParticipantAlert;
