import React from "react";
import { FormattedString, getString } from "Components/Common/FormattedString";
import FormNumberField from "Components/Entity/FormNumberField";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { handleNumberInputChange2 } from "helpers/validation_helper";

const PriceField = ({
  maxInstallmentSum,
  formik,
  index,
  remainingPrice,
  isDisabled,
  canEditInstallment,
}) => {
  const priceError =
    formik.errors.installments?.[index]?.price ||
    (remainingPrice !== 0 &&
      remainingPrice !== formik.values.installments[index].price &&
      !isDisabled)
      ? formik.errors.installments?.[index]?.price ||
        `${getString("prices_sum_error")} ${maxInstallmentSum}`
      : "";

  return (
    <FormNumberField
      label={
        priceError !== "" ? (
          <AngloTooltip text={priceError.toString()}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: "red",
              }}
            >
              <i className="ri-information-line"></i>
              <FormattedString id="price" />
            </div>
          </AngloTooltip>
        ) : (
          <FormattedString id="price" />
        )
      }
      name={`installments[${index}].price`}
      value={formik.values.installments[index].price}
      onChange={(e) =>
        handleNumberInputChange2(formik, e, `installments[${index}].price`)
      }
      error={
        (remainingPrice !== 0 &&
          remainingPrice !== formik.values.installments[index].price &&
          !isDisabled) ||
        formik.errors.installments?.[index]?.price
          ? " "
          : ""
      }
      disabled={isDisabled || !canEditInstallment}
      min={0}
      max={remainingPrice === 0 ? undefined : remainingPrice}
    />
  );
};

export default PriceField;
