import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { getCustomersListData } from "../../store/customers/action";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { format } from "date-fns";
import PresetTable from "Components/Common/PresetTable/PresetTable";
import AddApplication from "pages/Applications/AddApplication";
import { Link, useNavigate } from "react-router-dom";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const Customers = () => {
  document.title = getString("customers_page_title");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [applicationAddVisible, setApplicationAddVisible] = useState(false);
  const [defaultCustomer, setDefaultCustomer] = useState(null);
  const [editCustomerDetailsVisible, setEditCustomerDetailsVisible] =
    useState(false);

  const { customersData, count, currentPage, totalPages, loading, error } =
    useSelector((state) => state.Customers);

  const toggleApplicationAdd = (customer) => {
    setDefaultCustomer(customer);
    setApplicationAddVisible(!applicationAddVisible);
  };

  const toggleEditCustomerDetails = (customer) => {
    setDefaultCustomer(customer);
    setEditCustomerDetailsVisible(!editCustomerDetailsVisible);
    navigate(`/customer/${customer.id}/edit`);
  };

  const columns = [
    {
      id: "customer_name",
      Header: getString("customer_name"),
      accessor: (row) => (
        <div className="cell-customer-name">
          <Link to={`/customer/${row.id}`}>
            {row.firstName} {row.lastName}
          </Link>
        </div>
      ),
    },
    {
      id: "phone_number",
      Header: getString("phone_number"),
      accessor: (d) => (
        <div className="cell-phone-number">
          +{d.phonePrefix} {splitNumbers(d.phoneNumber)}
        </div>
      ),
    },
    {
      id: "email",
      Header: getString("email"),
      accessor: (d) => (
        <div
          className="cell-email d-flex align-items-center"
          style={{ gap: "8px", color: "#6c757d" }}
        >
          <i
            className="ri-clipboard-line"
            style={{ cursor: "pointer", color: "inherit" }}
            onClick={() => handleCopyToClipboard(d.email)}
          ></i>
          <span
            style={{ cursor: "pointer", color: "inherit" }}
            onClick={() => handleCopyToClipboard(d.email)}
          >
            {d.email}
          </span>
        </div>
      ),
    },
    {
      id: "ap_count",
      Header: getString("ap_count"),
      accessor: (d) => (
        <div className="cell-ap-count">{d.applicationsCount}</div>
      ),
    },
    {
      id: "pp_count",
      Header: getString("pp_count"),
      accessor: (d) => (
        <div className="cell-pp-count">{d.participantsCount}</div>
      ),
    },
    {
      id: "last_program",
      Header: getString("last_program"),
      accessor: (d) => (
        <div className="cell-last-program">
          <div>{d.lastProgramName}</div>
          {d.lastProgramStartDate && d.lastProgramEndDate ? (
            <div>
              {format(new Date(d.lastProgramStartDate), "yyyy-MM-dd")} -{" "}
              {format(new Date(d.lastProgramEndDate), "yyyy-MM-dd")}
            </div>
          ) : (
            <div>-</div>
          )}
        </div>
      ),
    },
    {
      id: "note",
      Header: getString("note"),
      accessor: (d) => (
        <div className="cell-note">{d.customerNote ? d.customerNote : "-"}</div>
      ),
    },
    {
      id: "Actions",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          <FormattedString id="actions" />
        </div>
      ),
      disableSortBy: true,
      accessor: (cellProps) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <WithPermission permissionName="PP_APPLICATION_CREATE">
            <div className="col-auto">
              <AngloTooltip stringId="add_application">
                <button
                  className="btn btn-sm btn-soft-success btn-icon"
                  onClick={() => toggleApplicationAdd(cellProps)}
                >
                  <i className="ri-file-add-line"></i>
                </button>
              </AngloTooltip>
            </div>
          </WithPermission>
          <ActionButtons
            customShowDetailsLink={`/customer/${cellProps.id}`}
            itemId={cellProps.id}
            onEdit={() => toggleEditCustomerDetails(cellProps)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <PresetTable
        title={getString("customers_list")}
        headerButtons={
          <button
            type="button"
            className="btn btn-soft-success"
            onClick={() => toggleApplicationAdd()}
          >
            <i className="ri-add-circle-line align-middle me-1"></i>
            <FormattedString id="add_application" />
          </button>
        }
        getDataAction={(...props) => dispatch(getCustomersListData(...props))}
        columns={columns}
        data={customersData}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        loading={loading}
        error={error}
        onRetry={() => dispatch(getCustomersListData())}
        tableName="customers"
      />
      {applicationAddVisible && (
        <AddApplication
          visibility={applicationAddVisible}
          toggle={toggleApplicationAdd}
          defaultCustomer={defaultCustomer}
        />
      )}
    </>
  );
};

export default Customers;
