import React from "react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { IpParticipantStatus, ipColorMap } from "models/ipParticipants";
import "./IpSummaryCell.scss";

const IpSummaryCell = ({ summary }) => {
  const { totalCapacity, quantity } = summary;

  const invitedCount =
    quantity
      .find((q) => q.type === "non_ns_ip")
      ?.statusCount.find((s) => s.status === IpParticipantStatus.INVITED)
      ?.count || 0;
  const awaitingTravelDetailsCount =
    quantity
      .find((q) => q.type === "non_ns_ip")
      ?.statusCount.find(
        (s) => s.status === IpParticipantStatus.AWAITING_TRAVEL_DETAILS,
      )?.count || 0;
  const partlyConfirmedCount =
    quantity
      .find((q) => q.type === "non_ns_ip")
      ?.statusCount.find(
        (s) => s.status === IpParticipantStatus.PARTLY_CONFIRMED,
      )?.count || 0;
  const fullyConfirmedCount =
    quantity
      .find((q) => q.type === "non_ns_ip")
      ?.statusCount.find((s) => s.status === IpParticipantStatus.CONFIRMED)
      ?.count || 0;

  const sum =
    invitedCount +
    awaitingTravelDetailsCount +
    partlyConfirmedCount +
    fullyConfirmedCount;

  return (
    <div className="d-flex align-items-center ip-summary-cell">
      <span className="ip-summary-cell__bracket">(</span>
      <AngloTooltip stringId="invited">
        <span
          className="ip-summary-cell__val"
          style={{ color: ipColorMap[IpParticipantStatus.INVITED] }}
        >
          {invitedCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> + </span>
      <AngloTooltip stringId="awaiting_travel_details">
        <span
          className="ip-summary-cell__val"
          style={{
            color: ipColorMap[IpParticipantStatus.AWAITING_TRAVEL_DETAILS],
          }}
        >
          {awaitingTravelDetailsCount}
        </span>
      </AngloTooltip>
      <span className="ip-summary-cell__bracket">)</span>
      <span className="text-muted"> </span>
      <AngloTooltip stringId="partly_confirmed">
        <span
          className="ip-summary-cell__val"
          style={{ color: ipColorMap[IpParticipantStatus.PARTLY_CONFIRMED] }}
        >
          {partlyConfirmedCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> + </span>
      <AngloTooltip stringId="fully_confirmed">
        <span
          className="ip-summary-cell__val"
          style={{ color: ipColorMap[IpParticipantStatus.CONFIRMED] }}
        >
          {fullyConfirmedCount}
        </span>
      </AngloTooltip>
      <span className="text-muted"> = </span>
      <AngloTooltip stringId="sum">
        <span
          className="ip-summary-cell__val ip-summary-cell__sum"
          style={{ color: ipColorMap["total_sum"] }}
        >
          {sum}
        </span>
      </AngloTooltip>
      <span className="text-muted"> / </span>
      <AngloTooltip stringId="total_capacity">
        <span
          className="ip-summary-cell__val ip-summary-cell__total"
          style={{ color: ipColorMap["total_capacity"] }}
        >
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};

export default IpSummaryCell;
