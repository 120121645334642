import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_PACKETS_LIST_DATA,
  SET_CURRENT_PAGE,
  POST_PROGRAM_PACKET,
} from "./actionType";

export const INIT_STATE = {
  programPacketsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  count: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
};

const ProgramPackets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAM_PACKETS_LIST_DATA:
    case POST_PROGRAM_PACKET:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROGRAM_PACKETS_LIST_DATA:
          return {
            ...state,
            programPacketsData: action.payload.data.data,
            count: action.payload.data.count,
            currentPage: action.payload.data.currentPage,
            totalPages: action.payload.data.totalPages,
            pageSize: action.payload.data.pageSize,
            loading: false,
            error: null,
          };
        case POST_PROGRAM_PACKET:
          return {
            ...state,
            loading: false,
            error: null,
          };
        default:
          return state;
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROGRAM_PACKETS_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };
        case POST_PROGRAM_PACKET:
          return {
            ...state,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
          };
        default:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
      }

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};

export default ProgramPackets;
