import React, { useContext, useMemo, useState } from "react";
import "./Payments.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useTable } from "react-table";
import { Table, Row, Col, Progress } from "reactstrap";
import { format } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { splitNumbers } from "helpers/utils";
import { toast_success } from "helpers/toast_helper";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

export const Payments = () => {
  const {
    applicationData: { applicationInstallments, applicationId, customId },
  } = useContext(ApplicationDetailsContext);

  const data = useMemo(() => {
    return (applicationInstallments || []).sort(
      (a, b) => new Date(b.dueDate) - new Date(a.dueDate),
    );
  }, [applicationInstallments]);

  const canEditApplication = useHasPermission("APPLICATION_UPDATE");

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: getString("number"),
        accessor: (row, i) => i + 1,
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: getString("dueDate"),
        accessor: "dueDate",
        Cell: ({ value }) => format(new Date(value), "yyyy-MM-dd"),
      },
      {
        Header: getString("amount"),
        accessor: (row) => `${splitNumbers(row.price)} ${row.currency}`,
        Cell: ({ value }) => value,
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value }) => getString(value),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: ({ row }) => {
          const { isPaid, isOverDue } = row.original;
          const status = isPaid ? "Paid" : isOverDue ? "Overdue" : "Future";
          return (
            <div className="d-flex align-items-center gap-1">
              {status === "Paid" && (
                <i className="ri-checkbox-circle-line align-middle text-success"></i>
              )}
              {status === "Future" && (
                <i className="ri-time-line align-middle text-warning"></i>
              )}
              {status === "Overdue" && (
                <i className="ri-close-circle-line align-middle text-danger"></i>
              )}{" "}
              {status}
            </div>
          );
        },
      },
      {
        Header: getString("action"),
        accessor: "action",
        Cell: ({ row }) => {
          const { isPaid, isOverDue, id } = row.original;
          const status = isPaid ? "Paid" : isOverDue ? "Overdue" : "Future";
          const [copied, setCopied] = useState(false);

          if (status === "Future") {
            const baseUrl = process.env.REACT_APP_CUSTOMER_PANEL_URL;
            const paymentUrl = `${baseUrl}application/${customId}/payment-url/${id}`;
            const handleCopy = () => {
              if (!copied) {
                navigator.clipboard.writeText(paymentUrl).then(() => {
                  setCopied(true);
                  toast_success(getString("link_copied"));
                  setTimeout(() => setCopied(false), 4000);
                });
              }
            };

            return (
              <div className="payments__copy">
                <AngloTooltip stringId={copied ? "link_copied" : "copy_link"}>
                  <div
                    className={`payments__copy__icon ${copied ? "copied" : ""}`}
                    onClick={handleCopy}
                    style={{ pointerEvents: copied ? "none" : "auto" }}
                  >
                    <i
                      className={copied ? "ri-check-line" : "ri-file-copy-line"}
                    ></i>
                  </div>
                </AngloTooltip>
              </div>
            );
          }
          return null;
        },
        headerClassName: "text-end",
        className: "text-end",
      },
    ];

    return baseColumns;
  }, [canEditApplication, applicationId]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const statusCounts = data.reduce(
    (acc, { isPaid, isOverDue }) => {
      const status = isPaid ? "Paid" : isOverDue ? "Overdue" : "Future";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    { Paid: 0, Future: 0, Overdue: 0 },
  );

  const total = statusCounts.Paid + statusCounts.Future + statusCounts.Overdue;

  return (
    <div className="payments">
      <div className="table-responsive mt-4 mt-xl-0">
        <Table
          {...getTableProps()}
          className="table-striped table-nowrap align-middle mb-0 table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    className={column.headerClassName}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const { isPaid, isOverDue } = row.original;
              const rowClassName = isPaid
                ? "table-success"
                : isOverDue
                  ? "table-danger"
                  : "";
              return (
                <tr {...row.getRowProps()} key={index} className={rowClassName}>
                  {row.cells.map((cell, index) => (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row className="mt-3 justify-content-center">
        <Col
          className="d-flex align-items-center justify-content-center"
          key="paid"
        >
          <div className="dot bg-success me-2"></div>
          <span className="text-muted">
            <FormattedString id="paid" />: {statusCounts.Paid}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          key="future"
        >
          <div
            className="dot me-2"
            style={{ backgroundColor: "#878a99" }}
          ></div>
          <span className="text-muted">
            <FormattedString id="future" />: {statusCounts.Future}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          key="overdue"
        >
          <div className="dot bg-danger me-2"></div>
          <span className="text-muted">
            <FormattedString id="overdue" />: {statusCounts.Overdue}
          </span>
        </Col>
      </Row>
      <Row className="mt-3" key="progress">
        <Col>
          <Progress multi>
            <Progress
              bar
              color="success"
              value={(statusCounts.Paid / total) * 100}
            />
            <Progress
              bar
              style={{ backgroundColor: "#878a99" }}
              value={(statusCounts.Future / total) * 100}
            />
            <Progress
              bar
              color="danger"
              value={(statusCounts.Overdue / total) * 100}
            />
          </Progress>
        </Col>
      </Row>
    </div>
  );
};
