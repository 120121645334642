import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocation } from "helpers/utils";
import { getString, FormattedString } from "Components/Common/FormattedString";

const EntityListArchive = ({ toggleFilter, useLocationState = true }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    useLocationState && getLocation(2) === "archived" ? "archived" : "visible",
  );
  const navigate = useNavigate();

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      toggleFilter(tab);
      if (useLocationState) {
        navigate(
          tab === "archived"
            ? `/${getLocation()}/archived`
            : `/${getLocation()}`,
        );
      }
    }
  };

  useEffect(() => {
    if (useLocationState) {
      const currentTab = getLocation(2) === "archived" ? "archived" : "visible";
      toggleTab(currentTab);
    }
  }, [location, useLocationState]);

  return (
    <React.Fragment>
      <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === "visible" },
              "fw-semibold",
            )}
            onClick={() => {
              toggleTab("visible");
            }}
            href="#"
          >
            <i className="ri-file-list-2-line me-1 align-bottom"></i>
            <FormattedString id={getString("all")} />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === "archived" },
              "fw-semibold",
            )}
            onClick={() => {
              toggleTab("archived");
            }}
            href="#"
          >
            <i className="ri-archive-line me-1 align-bottom"></i>
            <FormattedString id={getString("archived")} />
          </NavLink>
        </NavItem>
      </Nav>
    </React.Fragment>
  );
};

export default EntityListArchive;
