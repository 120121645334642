// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-overview-tab__close {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: black;
  transition: color 0.3s;
  font-size: 16px;
}
.application-overview-tab__close:hover {
  color: darkgray;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationOverviewTab/ApplicationOverviewTab.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;AAAJ;AAEI;EACE,eAAA;AAAN","sourcesContent":[".application-overview-tab {\n  &__close {\n    background: none;\n    border: none;\n    padding: 0;\n    cursor: pointer;\n    color: black;\n    transition: color 0.3s;\n    font-size: 16px;\n\n    &:hover {\n      color: darkgray;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
