import React from "react";
import { FormattedString } from "Components/Common/FormattedString";
import FormTextField from "Components/Entity/FormTextField";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { handleInputChange } from "helpers/validation_helper";

const CurrencyField = ({ formik, index, isDisabled, canEditInstallment }) => {
  const currencyError = formik.errors.installments?.[index]?.currency || "";

  return (
    <FormTextField
      label={
        currencyError !== "" ? (
          <AngloTooltip text={currencyError.toString()}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: "red",
              }}
            >
              <i className="ri-information-line"></i>
              <FormattedString id="currency" />
            </div>
          </AngloTooltip>
        ) : (
          <FormattedString id="currency" />
        )
      }
      name={`installments[${index}].currency`}
      value={formik.values.installments[index].currency}
      onChange={(e) =>
        handleInputChange(formik, e, `installments[${index}].currency`)
      }
      error={formik.errors.installments?.[index]?.currency ? " " : ""}
      disabled={isDisabled || !canEditInstallment}
    />
  );
};

export default CurrencyField;
