import React, { useContext, useEffect } from "react";
import { ApplicationMoveFormProvider } from "./provider/ApplicationMoveFormProvider";
import ApplicationMoveFormContent from "./ApplicationMoveFormContent";
import { ApplicationDetailsContext } from "../../ApplicationDetails";

const ApplicationMoveForm = () => {
  const { refreshApplicationData } = useContext(ApplicationDetailsContext);

  useEffect(() => {
    refreshApplicationData();
  }, []);

  return (
    <ApplicationMoveFormProvider>
      <ApplicationMoveFormContent />
    </ApplicationMoveFormProvider>
  );
};

export default ApplicationMoveForm;
