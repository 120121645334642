import React from "react";
import { Input, Label, FormFeedback } from "reactstrap";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const FormNumberField = ({
  label,
  name,
  value,
  onChange,
  error,
  disabled = false,
  min,
  max,
  tooltip,
}) => {
  const isInvalid =
    !!error || (value !== "" && (value < min || value > max) && !disabled);

  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
        {tooltip && (
          <AngloTooltip stringId={tooltip}>
            <i className="ri-information-line"></i>
          </AngloTooltip>
        )}
      </Label>
      <Input
        type="number"
        className="form-control"
        id={name}
        name={name}
        onChange={(e) => onChange(e, name)}
        value={value !== null && value !== undefined ? value : ""}
        invalid={isInvalid}
        disabled={disabled}
        min={min}
        max={max}
        onWheel={(e) => e.target.blur()}
      />
      {isInvalid && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormNumberField;
