import React, { useContext, useState } from "react";
import { Col, Progress, Row, TabPane } from "reactstrap";

import { Tile } from "Components/Common/Tile/Tile";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { SimplestTable } from "Components/Common/SimplestTable/SimplestTable";

import { HotelView } from "pages/Programs/ProgramDetails/partials/components/HotelsView/HotelView";
import { NewValuePlaceholder } from "Components/Common/NewValuePlaceholder/NewValuePlaceholder";
import { ProgramProgress } from "Components/Common/ProgramProgress/ProgramProgress";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { MeetingPointsView } from "pages/Programs/ProgramDetails/partials/components/MeetingPointsView/MeetingPointsView";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { ProgramInstallmentsView } from "../components/ProgramInstallmentsView/ProgramInstallmentsView";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { DeleteProgramPacketModal } from "../modals/DeleteProgramPacketModal";
import { PacketTotalPrice } from "../components/PacketTotalPrice";
import { AddInstallmentPlanCanvas } from "../modals/AddInstallmentPlanCanvas";
import { AddProgramPacketCanvas } from "../modals/AddProgramPacketCanvas";
import { EditProgramPacketCanvas } from "../modals/EditProgramPacketCanvas";
import { CoordinatorsView } from "../components/CoordinatorsView/CoordinatorsView";
import { AssignCoordinatorCanvas } from "../modals/AssignCoordinatorCanvas";
import { AssignHotelCanvas } from "../modals/AssignHotelCanvas";
import { AssignPpUpsellCanvas } from "../modals/AssignPpUpsellCanvas";
import { AssignIpUpsellCanvas } from "../modals/AssignIpUpsellCanvas";
import { DeletePpUpsellModal } from "../modals/DeletePpUpsellModal";
import { DeleteIpUpsellModal } from "../modals/DeleteIpUpsellModal";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { AssignIpCanvas } from "../modals/AssignIpCanvas";
import { InternationalParticipantsTable } from "../components/InternationalParticipantsTable/InternationalParticipantsTable";
import { ParticipantsTable } from "../components/ParticipantsTable/ParticipantsTable";
import { AddDiscountCanvas } from "../modals/AddDiscountCanvas";
import { DiscountsView } from "../components/DiscountsView/DiscountsView";
import { UpsellsOverview } from "../components/UpsellsOverview/UpsellsOverview";
import CopyToClipboardButton from "Components/Entity/CopyToClipboardButton/CopyToClipboardButton";
import { calculatePercentage } from "helpers/utils";

export const OverviewTab = () => {
  const { programData, programErrors, canUpdateProgram } = useContext(
    ProgramDetailsContext,
  );

  const [assignIpCanvasVisible, setAssignIpCanvasVisible] = useState(false);
  const [assignPpUpusellCanvasVisible, setAssignPpUpsellCanvasVisible] =
    useState(false);
  const [assignIpUpusellCanvasVisible, setAssignIpUpsellCanvasVisible] =
    useState(false);
  const [addProgramPacketCanvasVisible, setAddProgramPacketCanvasVisible] =
    useState(false);
  const [assignCoordinatorCanvasVisible, setAssignCoordinatorCanvasVisible] =
    useState(false);
  const [assignHotelCanvasVisible, setAssignHotelCanvasVisible] =
    useState(false);
  const [addDiscountCanvasVisible, setAddDiscountCanvasVisible] =
    useState(false);
  const [deleteProgramPacketModalVisible, setDeleteProgramPacketModalVisible] =
    useState(false);
  const [programPacketToEdit, setProgramPacketToEdit] = useState(null);
  const [addInstallmentPlanCanvasVisible, setAddInstallmentPlanCanvasVisible] =
    useState(false);
  const [ppUpsellToDelete, setPpUpsellToDelete] = useState(null);
  const [deletePpUpsellModalVisible, setDeletePpUpsellModalVisible] =
    useState(false);
  const [ipUpsellToDelete, setIpUpsellToDelete] = useState(null);
  const [deleteIpUpsellModalVisible, setDeleteIpUpsellModalVisible] =
    useState(false);
  const [editProgramPacketCanvasVisible, setEditProgramPacketCanvasVisible] =
    useState(false);

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={3}>
          <ProgramProgress programData={programData} />

          {programData.hotel ? (
            <Tile
              title={getString("hotel")}
              titleDropdown={
                canUpdateProgram && (
                  <OutlineButton
                    onClick={() => setAssignHotelCanvasVisible(true)}
                  >
                    <FormattedString id="hotel_assign" />
                  </OutlineButton>
                )
              }
              gap="14px"
              error={programErrors?.hotel}
            >
              <HotelView hotel={programData.hotel} />
            </Tile>
          ) : (
            <Tile
              title={getString("hotel")}
              gap="14px"
              error={programErrors?.hotel}
            >
              <NewValuePlaceholder
                addStringId="hotel_assign"
                onClick={() => setAssignHotelCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}

          {programData.allCoordinators.length > 0 ? (
            <Tile
              title={getString("coordinators")}
              titleDropdown={
                canUpdateProgram && (
                  <OutlineButton
                    onClick={() => setAssignCoordinatorCanvasVisible(true)}
                  >
                    <FormattedString id="coordinator_assign" />
                  </OutlineButton>
                )
              }
              gap="14px"
              error={programErrors?.healthAndSafetyCoordinators}
            >
              <CoordinatorsView />
            </Tile>
          ) : (
            <Tile
              title={getString("coordinators")}
              error={programErrors?.healthAndSafetyCoordinators}
            >
              <NewValuePlaceholder
                addStringId="coordinator_assign"
                onClick={() => setAssignCoordinatorCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}

          <Tile
            title={getString("meeting_points")}
            gap="14px"
            error={
              programErrors?.programMeetingsPP ||
              programErrors?.programMeetingsNS
            }
          >
            <MeetingPointsView />
          </Tile>
        </Col>
        <Col xxl={5}>
          {programData.programPackets.length > 0 ? (
            <Tile
              title={getString("program_packets")}
              gap="1rem"
              paddingDisabled
              error={
                programErrors?.installmentPlans || programErrors?.programPrices
              }
              titleDropdown={
                canUpdateProgram && (
                  <div className="d-flex gap-2">
                    <OutlineButton
                      onClick={() => setAddInstallmentPlanCanvasVisible(true)}
                    >
                      <FormattedString id="installment_plans_manage" />
                    </OutlineButton>
                    <OutlineButton
                      onClick={() => setAddProgramPacketCanvasVisible(true)}
                    >
                      <FormattedString id="program_packet_add" />
                    </OutlineButton>
                  </div>
                )
              }
            >
              <SimplestTable
                data={programData.programPackets}
                columns={[
                  {
                    header: getString("packet"),
                    render: (packet) => <strong>{packet.name}</strong>,
                  },
                  {
                    header: getString("total_price"),
                    render: (packet) => (
                      <PacketTotalPrice programPacket={packet} />
                    ),
                  },
                  {
                    header: getString("installments"),
                    render: (packet) => (
                      <ProgramInstallmentsView programPacket={packet} />
                    ),
                  },
                  {
                    header: canUpdateProgram && (
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <FormattedString id="actions" />
                      </div>
                    ),
                    render: (packet) => (
                      <div className="d-flex justify-content-end">
                        <CopyToClipboardButton
                          onCopyToClipboard={() => {
                            const baseUrl =
                              process.env.REACT_APP_CUSTOMER_PANEL_URL;
                            const paymentUrl = `${baseUrl}application/${packet.id}`;
                            return paymentUrl;
                          }}
                        />
                        {canUpdateProgram && (
                          <ActionButtons
                            onEdit={() => {
                              setProgramPacketToEdit(packet.id);
                              setEditProgramPacketCanvasVisible(true);
                            }}
                            onDelete={() => {
                              setProgramPacketToEdit(packet.id);
                              setDeleteProgramPacketModalVisible(true);
                            }}
                            itemId={packet.id}
                          />
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </Tile>
          ) : (
            <Tile
              title={getString("program_packets")}
              error={
                programErrors?.installmentPlans || programErrors?.programPrices
              }
              titleDropdown={
                canUpdateProgram && (
                  <div className="d-flex gap-2">
                    <OutlineButton
                      onClick={() => setAddInstallmentPlanCanvasVisible(true)}
                    >
                      <FormattedString id="installment_plans_manage" />
                    </OutlineButton>
                  </div>
                )
              }
            >
              <NewValuePlaceholder
                addStringId="program_packet_add"
                onClick={() => setAddProgramPacketCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}

          {programData.participantUpsells.length > 0 ? (
            <Tile
              title={getString("pp_upsells")}
              titleDropdown={
                canUpdateProgram && (
                  <OutlineButton
                    onClick={() => setAssignPpUpsellCanvasVisible(true)}
                  >
                    <FormattedString id="pp_upsells_assign" />
                  </OutlineButton>
                )
              }
            >
              <UpsellsOverview upsells={programData.participantUpsells} />
            </Tile>
          ) : (
            <Tile
              title={getString("pp_upsells")}
              gap="14px"
              error={programErrors?.participantUpsells}
            >
              <NewValuePlaceholder
                addStringId="pp_upsells_assign"
                onClick={() => setAssignPpUpsellCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}

          {programData.internationalParticipantUpsells.length > 0 ? (
            <Tile
              title={getString("ip_upsells")}
              paddingDisabled
              titleDropdown={
                canUpdateProgram && (
                  <OutlineButton
                    onClick={() => setAssignIpUpsellCanvasVisible(true)}
                  >
                    <FormattedString id="ip_upsells_assign" />
                  </OutlineButton>
                )
              }
            >
              <SimplestTable
                data={programData.internationalParticipantUpsells}
                disableHeader
                columns={[
                  {
                    header: getString("name"),
                    render: (upsell) => (
                      <div className="d-flex">
                        <AngloTooltip
                          text={upsell.description}
                          enabled={upsell.description}
                        >
                          <div
                            className="d-flex gap-1"
                            style={{ color: "var(--vz-heading-color)" }}
                          >
                            {upsell.description && (
                              <i className="ri-information-fill" />
                            )}
                            {upsell.name}
                          </div>
                        </AngloTooltip>
                      </div>
                    ),
                    style: { fontWeight: "500" },
                  },
                  {
                    header: getString("progress"),
                    style: { width: "20%" },
                    render: (upsell) => {
                      const progressVal = calculatePercentage(
                        upsell.confirmedCount,
                        upsell.confirmedSum,
                      );
                      return (
                        <Progress
                          value={progressVal}
                          color="danger"
                          className="animated-progress custom-progress progress-label"
                        >
                          <div className="label">{progressVal}%</div>
                        </Progress>
                      );
                    },
                  },
                  {
                    header: getString("upsells"),
                    render: (upsell) => <></>,
                  },
                  {
                    header: (
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <FormattedString id="actions" />
                      </div>
                    ),
                    render: (upsell) =>
                      canUpdateProgram && (
                        <ActionButtons
                          onDelete={() => {
                            setIpUpsellToDelete(upsell.id);
                            setDeleteIpUpsellModalVisible(true);
                          }}
                          itemId={upsell.id}
                        />
                      ),
                  },
                ]}
              />
            </Tile>
          ) : (
            <Tile title={getString("ip_upsells")} gap="14px">
              <NewValuePlaceholder
                addStringId="ip_upsells_assign"
                onClick={() => setAssignIpUpsellCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}

          <Tile title={getString("participants")} gap="1rem" paddingDisabled>
            <ParticipantsTable data={programData.participants} />
          </Tile>
        </Col>
        <Col xxl={4}>
          {programData.programDiscounts.length > 0 ? (
            <Tile
              title={getString("discounts")}
              gap="1rem"
              paddingDisabled
              titleDropdown={
                canUpdateProgram && (
                  <OutlineButton
                    onClick={() => setAddDiscountCanvasVisible(true)}
                  >
                    <FormattedString id="discount_add" />
                  </OutlineButton>
                )
              }
            >
              <DiscountsView />
            </Tile>
          ) : (
            <Tile
              title={getString("discounts")}
              gap="14px"
              error={programErrors?.programDiscounts}
            >
              <NewValuePlaceholder
                addStringId="discount_add"
                onClick={() => setAddDiscountCanvasVisible(true)}
                disabled={!canUpdateProgram}
              />
            </Tile>
          )}
          <Tile
            title={getString("international_participants")}
            gap="1rem"
            titleDropdown={
              canUpdateProgram && (
                <OutlineButton onClick={() => setAssignIpCanvasVisible(true)}>
                  <FormattedString id="assignIpParticipant" />
                </OutlineButton>
              )
            }
            paddingDisabled
          >
            <InternationalParticipantsTable
              data={programData.internationalParticipants}
            />
          </Tile>
        </Col>
      </Row>

      {assignPpUpusellCanvasVisible && (
        <AssignPpUpsellCanvas
          visible={assignPpUpusellCanvasVisible}
          setVisible={setAssignPpUpsellCanvasVisible}
        />
      )}

      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
        />
      )}

      {assignIpUpusellCanvasVisible && (
        <AssignIpUpsellCanvas
          visible={assignIpUpusellCanvasVisible}
          setVisible={setAssignIpUpsellCanvasVisible}
        />
      )}

      {addProgramPacketCanvasVisible && (
        <AddProgramPacketCanvas
          visible={addProgramPacketCanvasVisible}
          setVisible={setAddProgramPacketCanvasVisible}
        />
      )}

      {assignCoordinatorCanvasVisible && (
        <AssignCoordinatorCanvas
          visible={assignCoordinatorCanvasVisible}
          setVisible={setAssignCoordinatorCanvasVisible}
        />
      )}

      {assignHotelCanvasVisible && (
        <AssignHotelCanvas
          visible={assignHotelCanvasVisible}
          setVisible={setAssignHotelCanvasVisible}
        />
      )}

      {addDiscountCanvasVisible && (
        <AddDiscountCanvas
          visible={addDiscountCanvasVisible}
          setVisible={setAddDiscountCanvasVisible}
        />
      )}

      {deleteProgramPacketModalVisible && (
        <DeleteProgramPacketModal
          itemId={programPacketToEdit}
          visibility={true}
          toggle={() => setDeleteProgramPacketModalVisible(false)}
        />
      )}

      {addInstallmentPlanCanvasVisible && (
        <AddInstallmentPlanCanvas
          visible={addInstallmentPlanCanvasVisible}
          setVisible={setAddInstallmentPlanCanvasVisible}
        />
      )}

      {deletePpUpsellModalVisible && (
        <DeletePpUpsellModal
          itemId={ppUpsellToDelete}
          visible={deletePpUpsellModalVisible}
          setVisible={setDeletePpUpsellModalVisible}
        />
      )}

      {deleteIpUpsellModalVisible && (
        <DeleteIpUpsellModal
          itemId={ipUpsellToDelete}
          visible={deleteIpUpsellModalVisible}
          setVisible={setDeleteIpUpsellModalVisible}
        />
      )}

      {editProgramPacketCanvasVisible && (
        <EditProgramPacketCanvas
          visible={editProgramPacketCanvasVisible}
          setVisible={setEditProgramPacketCanvasVisible}
          programPacketToEdit={programPacketToEdit}
        />
      )}
    </TabPane>
  );
};
