import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Row, Col, Button } from "reactstrap";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";

import { useFormContext } from "../../provider/utils/FormContext";
import { getString } from "Components/Common/FormattedString";
import { genderOptions } from "models/genders";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import moment from "moment";
import FormDateField from "Components/Entity/FormDateField";

const ParticipantForm = ({
  participant,
  index,
  programPacketOptions,
  smallestMinAge,
  highestMaxAge,
}) => {
  const { formik, parentProgram } = useFormContext();
  const [filteredProgramPacketOptions, setFilteredProgramPacketOptions] =
    useState(programPacketOptions);

  const calculateMinMaxDate = (startDate, minAge, maxAge) => {
    const minDate = moment(startDate)
      .subtract(maxAge + 1, "years")
      .add(1, "days")
      .format("YYYY-MM-DD");
    const maxDate = moment(startDate)
      .subtract(minAge, "years")
      .format("YYYY-MM-DD");
    return { minDate, maxDate };
  };

  const filterProgramPackets = (dateOfBirth) => {
    const filteredOptions = programPacketOptions.filter((packet) => {
      const { minDate, maxDate } = calculateMinMaxDate(
        parentProgram.startDate,
        packet.ppMinAge,
        packet.ppMaxAge,
      );
      return moment(dateOfBirth).isBetween(minDate, maxDate, undefined, "[]");
    });
    setFilteredProgramPacketOptions(filteredOptions);
  };

  useEffect(() => {
    if (participant.dateOfBirth) {
      filterProgramPackets(participant.dateOfBirth);
    }
  }, [participant.dateOfBirth]);

  useEffect(() => {
    if (participant.dateOfBirth) {
      const { minDate, maxDate } = calculateMinMaxDate(
        parentProgram.startDate,
        smallestMinAge,
        highestMaxAge,
      );

      if (
        moment(participant.dateOfBirth).isBetween(
          minDate,
          maxDate,
          undefined,
          "[]",
        )
      ) {
        const previousProgramPacket = participant.programPacket;
        const isPreviousProgramPacketValid = filteredProgramPacketOptions.some(
          (option) => option.value === previousProgramPacket,
        );

        if (isPreviousProgramPacketValid) {
          formik.setFieldValue(
            `participants[${index}].programPacket`,
            previousProgramPacket,
          );
        } else if (filteredProgramPacketOptions.length === 1) {
          formik.setFieldValue(
            `participants[${index}].programPacket`,
            filteredProgramPacketOptions[0].value,
          );
        } else {
          formik.setFieldValue(`participants[${index}].programPacket`, "");
        }
      }
    }
  }, [participant.dateOfBirth, filteredProgramPacketOptions]);

  const { minDate, maxDate } = calculateMinMaxDate(
    parentProgram.startDate,
    smallestMinAge,
    highestMaxAge,
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormSelect
                name={`participants[${index}].gender`}
                id={`participants[${index}].gender`}
                label={getString("gender")}
                isMulti={false}
                options={genderOptions}
                value={participant.gender}
                defaultValue={genderOptions.find(
                  (r) => r.value === participant.gender,
                )}
                onChange={(selectedOption) => {
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `participants[${index}].gender`,
                  );
                }}
                error={formik.errors.participants?.[index]?.gender}
              />
            </Col>
            <Col md={6}>
              <FormSelect
                key={participant.programPacket}
                name={`participants[${index}].programPacket`}
                id={`participants[${index}].programPacket`}
                label={getString("program_packet")}
                isMulti={false}
                options={filteredProgramPacketOptions}
                value={participant.programPacket}
                defaultValue={filteredProgramPacketOptions.find(
                  (r) => r.value === participant.programPacket,
                )}
                onChange={(selectedOption) => {
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `participants[${index}].programPacket`,
                  );
                }}
                error={formik.errors.participants?.[index]?.programPacket}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("first_name")}
                name={`participants[${index}].firstName`}
                value={participant.firstName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].firstName`,
                  )
                }
                error={formik.errors.participants?.[index]?.firstName}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("last_name")}
                name={`participants[${index}].lastName`}
                value={participant.lastName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].lastName`,
                  )
                }
                error={formik.errors.participants?.[index]?.lastName}
                touched={formik.touched.participants?.[index]?.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("email")}
                name={`participants[${index}].email`}
                type="email"
                value={participant.email}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].email`)
                }
                error={formik.errors.participants?.[index]?.email}
                touched={formik.touched.participants?.[index]?.email}
              />
            </Col>
            <Col md={6}>
              <FormPhoneField
                label={getString("phone_number")}
                name={`participants[${index}].phoneNumber`}
                phonePrefixName={`participants[${index}].phonePrefix`}
                value={participant.phoneNumber}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].phoneNumber`,
                  )
                }
                phonePrefix={participant.phonePrefix}
                onPrefixChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].phonePrefix`,
                  )
                }
                error={
                  formik.errors.participants?.[index]?.phoneNumber ||
                  formik.errors.participants?.[index]?.phonePrefix
                }
                touched={
                  formik.touched.participants?.[index]?.phoneNumber ||
                  formik.touched.participants?.[index]?.phonePrefix
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormDateField
                label={getString("date_of_birth")}
                name={`participants[${index}].dateOfBirth`}
                value={participant.dateOfBirth}
                onChange={(e) => {
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].dateOfBirth`,
                  );
                  filterProgramPackets(e.target.value);
                }}
                error={formik.errors.participants?.[index]?.dateOfBirth}
                touched={formik.touched.participants?.[index]?.dateOfBirth}
                min={minDate}
                max={maxDate}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("comment")}
                name={`participants[${index}].comment`}
                value={participant.comment}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].comment`)
                }
                error={formik.errors.participants?.[index]?.comment}
                touched={formik.touched.participants?.[index]?.comment}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ParticipantForm;
