import React, { useState } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import { isObjectEmpty } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import ParticipantForm from "./ParticipantForm";
import ParticipantTabs from "./ParticipantTabs";

const ParticipantsStep = () => {
  const { formik, validateStep, parentProgram } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);

  const handleAddParticipant = () => {
    validateStep().then((res) => {
      if (isObjectEmpty(res)) {
        formik.setFieldValue("participants", [
          ...formik.values.participants,
          {
            id: uuidv4(),
            gender: "",
            programPacket: "",
            firstName: "",
            lastName: "",
            email: "",
            phonePrefix: "",
            phoneNumber: "",
            dateOfBirth: "",
            comment: "",
            upsells: { diet: "", insurance: "", upsells: [] },
          },
        ]);
        setActiveTab(formik.values.participants.length);
      }
    });
  };

  const handleRemoveParticipant = (index) => {
    const updatedParticipants = formik.values.participants.filter(
      (_, i) => i !== index,
    );
    formik.setFieldValue("participants", updatedParticipants);

    const updatedErrors = formik.errors.participants
      ? formik.errors.participants.filter((_, i) => i !== index)
      : [];

    formik.setErrors({
      ...formik.errors,
      participants: updatedErrors,
    });

    if (activeTab >= updatedParticipants.length) {
      setActiveTab(updatedParticipants.length - 1);
    }
  };

  const programPacketOptions = parentProgram.programPackets.map((packet) => ({
    value: packet.id,
    label: packet.name,
  }));

  return (
    <div className="participants-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="participants" />
          </h2>
        </Col>
      </Row>
      <ParticipantTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleAddParticipant={handleAddParticipant}
        handleRemoveParticipant={handleRemoveParticipant}
      />
      <TabContent activeTab={activeTab}>
        {formik.values.participants.map((participant, index) => (
          <TabPane tabId={index} key={`${participant.id}${index}`}>
            <ParticipantForm
              participant={participant}
              index={index}
              programPacketOptions={programPacketOptions}
            />
          </TabPane>
        ))}
      </TabContent>
      {formik.errors.participants &&
        typeof formik.errors.participants === "string" && (
          <div className="text-danger">{formik.errors.participants}</div>
        )}
      <ApplicationStepNavigation />
    </div>
  );
};

export default ParticipantsStep;
