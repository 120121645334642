import { createContext, useContext } from "react";

const FormContext = createContext();

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useFormContext must be used within a FormContext.Provider",
    );
  }
  return context;
};

export default FormContext;
