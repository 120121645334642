import logoBg from "assets/images/logo.svg";
import { Col, Row } from "reactstrap";
import { ApplicationDetailsContext } from "../ApplicationDetails";
import { useContext, useState } from "react";
import { formatDate } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import { Link } from "react-router-dom";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { toast_info } from "helpers/toast_helper";
import { WithRole } from "Components/Common/WithRole/WithRole";

export const ApplicationHeader = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(applicationData.id);
    toast_info("Application ID copied to clipboard");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="application-details__header">
      <div className="application-details__header__foreground">
        <div
          className={`application-details__header__background ${applicationData.status === "moved" ? "application-details__header__background--moved" : ""}`}
        >
          <img
            src={logoBg}
            alt=""
            className="application-details__header__background__image"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="application-details__header__avatar">
              <i className="application-details__header__thumb ri-file-list-line"></i>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="application-details__header__top">
                <div className="application-details__header__top__left">
                  <h3 className="application-details__header__top__left__name">
                    {applicationData.customId}
                  </h3>
                  <p className="application-details__header__top__left__paragraph">
                    {applicationData.programName}
                  </p>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="hstack text-white gap-1">
                    {applicationData.hotelName && (
                      <div className="me-2">
                        <i className="ri-building-line me-1 text-white fs-16 align-middle"></i>
                        {applicationData.hotelName}
                      </div>
                    )}
                    <div>
                      <i className="ri-calendar-2-line me-1 text-white fs-16 align-middle"></i>
                      {formatDate(applicationData.startDate)} -{" "}
                      {formatDate(applicationData.endDate)}
                    </div>
                  </div>
                  {applicationData.meetingPoint && (
                    <div className="mt-2 text-white">
                      <i className="ri-map-pin-line me-1 text-white fs-16 align-middle"></i>
                      {applicationData.meetingPoint.city},{" "}
                      {applicationData.meetingPoint.address}
                    </div>
                  )}
                  {applicationData.createdAt && (
                    <div className="mt-2 text-white">
                      <i className="ri-time-line me-1 text-white fs-16 align-middle"></i>
                      Created: {formatDate(applicationData.createdAt)}
                    </div>
                  )}
                  {applicationData?.movedTo && (
                    <div className="mt-2 text-white">
                      <i className="ri-arrow-right-line me-1 text-white fs-16 align-middle"></i>
                      Moved to:{" "}
                      <Link
                        to={`/pp-application/${applicationData.movedTo.id}`}
                        className="text-white fw-bold"
                      >
                        {applicationData.movedTo.customId}, (
                        {applicationData.movedTo.programName}){" "}
                        {formatDate(applicationData.movedTo.startDate)} -{" "}
                        {formatDate(applicationData.movedTo.endDate)}
                      </Link>
                    </div>
                  )}
                  {applicationData?.movedFrom && (
                    <div className="mt-2 text-white">
                      <i className="ri-arrow-left-line me-1 text-white fs-16 align-middle"></i>
                      Moved from:{" "}
                      <Link
                        to={`/pp-application/${applicationData.movedFrom.id}`}
                        className="text-white fw-bold"
                      >
                        {applicationData.movedFrom.customId}, (
                        {applicationData.movedFrom.programName}){" "}
                        {formatDate(applicationData.movedFrom.startDate)} -{" "}
                        {formatDate(applicationData.movedFrom.endDate)}
                      </Link>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <div className="d-flex align-items-end gap-2">
              <WithRole roleName="ROLE_IT">
                <AngloTooltip stringId="copy_application_id_to_clipboard">
                  <button
                    type="button"
                    className="btn btn-secondary application-copy-btn"
                    onClick={copyToClipboard}
                  >
                    <i
                      className={copied ? "ri-check-line" : "ri-file-copy-line"}
                    ></i>
                  </button>
                </AngloTooltip>
              </WithRole>
              <div
                className={`application-status ${applicationData.status === "moved" ? "application-status--moved" : ""}`}
              >
                <FormattedString id={applicationData.status} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
