import React, { useContext } from "react";
import { Col, CardText, CardTitle } from "reactstrap";
import { formatDate, isDifferent } from "helpers/utils";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useFormContext } from "../../../provider/utils/FormContext";
import { getString, FormattedString } from "Components/Common/FormattedString";

const OldProgram = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { formik, targetProgram } = useFormContext();

  const getProgramPacketName = (packetId) => {
    if (!targetProgram) {
      return getString("unknownPacket");
    }
    const packet = targetProgram.programPackets.find(
      (packet) => packet.id === packetId,
    );
    return packet ? packet.name : getString("unknownPacket");
  };

  const getUpsellName = (upsellId) => {
    if (!targetProgram) {
      return getString("unknownUpsell");
    }
    const upsell = targetProgram.participantUpsells.find(
      (upsell) => upsell.id === upsellId,
    );
    return upsell ? upsell.name : getString("unknownUpsell");
  };

  const getMeetingPointAddress = (meetingId) => {
    if (!targetProgram) {
      return getString("unknownMeetingPoint");
    }
    const meeting =
      targetProgram.programMeetingsPP.find(
        (meeting) => meeting.id === meetingId,
      ) ||
      targetProgram.programMeetingsNS.find(
        (meeting) => meeting.id === meetingId,
      );
    return meeting
      ? meeting.meetingPoint.address
      : getString("unknownMeetingPoint");
  };

  return (
    <Col md={5}>
      <CardText className="date-text">
        {formatDate(applicationData.startDate)} -{" "}
        {formatDate(applicationData.endDate)}
      </CardText>
      <CardTitle tag="h5" className="comparison-title old-program-header">
        <FormattedString id={getString("oldProgram")} />
      </CardTitle>
      <CardText
        className={`subheader ${
          isDifferent(applicationData.programName, targetProgram?.name)
            ? "text-different-old"
            : ""
        }`}
      >
        {applicationData.programName}
      </CardText>
      <ol className="participant-list old-program">
        {applicationData.participants.map((participant, index) => (
          <li key={index} className="participant-details">
            <h6>
              {participant.firstName} {participant.lastName}
            </h6>
            <ul>
              <li>
                <span className="value-key">
                  <FormattedString id={getString("package")} />
                </span>{" "}
                <span
                  className={`${
                    isDifferent(
                      participant.programPacketName,
                      getProgramPacketName(
                        formik.values.participants[index].programPacket,
                      ),
                    )
                      ? "text-different-old"
                      : ""
                  }`}
                >
                  {participant.programPacketName || (
                    <span style={{ color: "#999", fontWeight: 400 }}>
                      <FormattedString id={getString("noSelectedPackage")} />
                    </span>
                  )}
                </span>
              </li>
              <li>
                <span className="value-key">
                  <FormattedString id={getString("meeting")} />
                </span>{" "}
                <span
                  className={`${
                    isDifferent(
                      applicationData.meetingPoint?.address,
                      getMeetingPointAddress(formik.values.programMeeting),
                    )
                      ? "text-different-old"
                      : ""
                  }`}
                >
                  {applicationData.meetingPoint?.address || (
                    <span style={{ color: "#999", fontWeight: 400 }}>
                      <FormattedString
                        id={getString("noSelectedMeetingPoint")}
                      />
                    </span>
                  )}
                </span>
              </li>
              <li>
                <span className="value-key">
                  <FormattedString id={getString("upsells")} />
                </span>
              </li>
              <ul>
                <li>
                  <span className="value-key">
                    <FormattedString id={getString("diet")} />
                  </span>{" "}
                  <span
                    className={`${
                      isDifferent(
                        participant.dietName,
                        getUpsellName(
                          formik.values.participants[index].upsells?.diet,
                        ),
                      )
                        ? "text-different-old"
                        : ""
                    }`}
                  >
                    {participant.dietName || (
                      <span style={{ color: "#999", fontWeight: 400 }}>
                        <FormattedString id={getString("noSelectedDiet")} />
                      </span>
                    )}
                  </span>
                </li>
                <li>
                  <span className="value-key">
                    <FormattedString id={getString("insurance")} />
                  </span>{" "}
                  <span
                    className={`${
                      isDifferent(
                        participant.insuranceName,
                        getUpsellName(
                          formik.values.participants[index].upsells?.insurance,
                        ),
                      )
                        ? "text-different-old"
                        : ""
                    }`}
                  >
                    {participant.insuranceName || (
                      <span style={{ color: "#999", fontWeight: 400 }}>
                        <FormattedString
                          id={getString("noSelectedInsurance")}
                        />
                      </span>
                    )}
                  </span>
                </li>
                {participant.upsells?.map((u, i) => (
                  <li key={i}>
                    <span className="value-key">
                      <FormattedString id={getString("other")} />
                    </span>{" "}
                    <span
                      className={`${
                        isDifferent(
                          u.name,
                          getUpsellName(
                            formik.values.participants[index].upsells?.upsells[
                              i
                            ],
                          ),
                        )
                          ? "text-different-old"
                          : ""
                      }`}
                    >
                      {u.name || (
                        <span style={{ color: "#999", fontWeight: 400 }}>
                          <FormattedString id={getString("noSelectedUpsell")} />
                        </span>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </ul>
            <i className="ri-arrow-right-line arrow-icon"></i>
          </li>
        ))}
      </ol>
    </Col>
  );
};

export default OldProgram;
