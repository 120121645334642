import React from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import SummaryDetails from "../SummaryDetails";
import InstallmentForm from "./InstallmentForm";
import InstallmentPlanTile from "./InstallmentPlanTile";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const InstallmentContent = ({
  summaryData,
  showInstallmentForm,
  installmentPlans,
  setShowInstallmentForm,
  setInstallmentsError,
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  advancedManagement,
  installmentsError,
  addInstallment,
  handleSelectPlan,
}) => {
  const hasPaidInstallments = formik.values.installments.some(
    (installment) => installment.isPaid === true,
  );
  const canEditInstallments = useHasPermission("PP_APPLICATION_ACCOUNTING");

  return (
    <>
      {summaryData && (
        <SummaryDetails
          summary={summaryData.summary}
          discounts={summaryData.discounts}
        />
      )}
      {showInstallmentForm ? (
        <>
          {installmentPlans.length > 0 &&
            (!hasPaidInstallments || canEditInstallments) && (
              <Row className="mb-2">
                <Col>
                  <Button
                    color="link"
                    className="select-other-plan-btn"
                    onClick={() => {
                      setShowInstallmentForm(false);
                      setInstallmentsError(null);
                      formik.setErrors({});
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>{" "}
                    <FormattedString id="select_other_plan" />
                  </Button>
                </Col>
              </Row>
            )}
          <InstallmentForm
            formik={formik}
            installmentTypes={installmentTypes}
            pointOfSellOptions={pointOfSellOptions}
            removeInstallment={removeInstallment}
            advancedManagement={advancedManagement}
            installmentsError={installmentsError}
            addInstallment={addInstallment}
            installments={formik.values.installments}
            currentInstallmentCount={formik.values.installments.length}
            installmentPlans={installmentPlans}
          />
        </>
      ) : (
        <>
          <h3 className="text-center mb-4 mt-4" style={{ color: "#495057" }}>
            {getString("select_initial_installment_plan")}
          </h3>
          {installmentPlans.length > 0 ? (
            <Row className="installment-plans-grid">
              {installmentPlans.map((plan) => (
                <InstallmentPlanTile
                  key={plan.installmentPlanId}
                  plan={plan}
                  handleSelectPlan={
                    !hasPaidInstallments || canEditInstallments
                      ? handleSelectPlan
                      : null
                  }
                />
              ))}
            </Row>
          ) : (
            <Alert color="info" className="text-center">
              <FormattedString id="no_installment_plans_to_show" />
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default InstallmentContent;
