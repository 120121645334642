import { FormattedString } from "Components/Common/FormattedString";
import React from "react";
import { Card, CardBody } from "reactstrap";
import "./InstallmentPlanTile.scss";

const InstallmentPlanTile = ({ plan, handleSelectPlan }) => {
  return (
    <Card
      onClick={() => handleSelectPlan(plan)}
      className="installment-plan-tile"
    >
      <CardBody className="text-center">
        <div className="installment-amount">{plan.installmentCount}</div>
        <div className="installment-label">
          <FormattedString id="installments_sm" />
        </div>
      </CardBody>
    </Card>
  );
};

export default InstallmentPlanTile;
