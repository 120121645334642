import React from "react";
import "./InternationalParticipantsTable.scss";

import { getString } from "Components/Common/FormattedString";
import { Progress } from "reactstrap";
import { InternationalParticipantEquation } from "Components/Common/InternationalParticipantEquation/InternationalParticipantEquation";
import { DeleteIpModal } from "../../modals/DeleteIpModal";
import { InternationalParticipantEquationPerType } from "Components/Common/InternationalParticipantEquationPerType/InternationalParticipantEquationPerType";

export const InternationalParticipantsTable = ({ data }) => {
  const columns = [
    {
      header: getString("packet_label"),
      render: (participant) => getString(participant.type),
    },
    {
      header: getString("participants"),
      render: (participant, row) => (
        <span
          className={`international-participants-table__type--${participant.type}`}
        >
          <InternationalParticipantEquationPerType
            summary={participant}
            packet={row}
          />
        </span>
      ),
    },
    {
      header: getString("progress"),
      style: { width: "25%" },
      render: () => <></>,
    },
  ];

  const sortOrder = ["ns", "non_ns_ip", "esl"];

  return (
    <table className="international-participants-table table table-hover table-nowrap align-middle mb-0">
      <DeleteIpModal />
      <thead className="table-light">
        <tr className="text-muted">
          {columns.map((column, index) => (
            <th key={index} scope="col" style={column.style}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((packet, index) => (
          <React.Fragment key={`${packet.programPacketId}-${index}`}>
            <tr className="international-participants-table__row international-participants-table__row--group-header">
              <td>
                <div className="d-flex align-items-center gap-2">
                  <span className="fw-bold">{packet.programPacketLabel}</span>
                </div>
              </td>
              <td>
                <InternationalParticipantEquation summary={packet.summary} />
              </td>
              <td>
                <Progress
                  value={packet.summary.occupancyFraction}
                  color="danger"
                  className="animated-progress custom-progress progress-label"
                >
                  <div className="label">
                    {packet.summary.occupancyFraction}%
                  </div>
                </Progress>
              </td>
            </tr>
            {packet.summary.quantity
              .sort(
                (a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type),
              )
              .map((item, index) => (
                <tr
                  className="international-participants-table__row"
                  key={item?.id || index}
                >
                  {columns.map((column, colIndex) => (
                    <td key={colIndex}>{column.render(item, packet)}</td>
                  ))}
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};
