import React from "react";
import { Button, Row, Col, Spinner } from "reactstrap";
import "./ApplicationMoveStepNavigation.scss";
import { FormattedString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";

const ApplicationMoveStepNavigation = () => {
  const { currentStep, handleSave, submitting } = useFormContext();

  return (
    <Row
      className="justify-content-center mt-4 application-move-step-nav"
      style={{ width: "100%" }}
    >
      <Col xs="auto" className="d-flex gap-2">
        {currentStep === 1 && (
          <Button
            color="success"
            onClick={handleSave}
            disabled={submitting}
            data-testid="submit-button"
          >
            {submitting ? (
              <Spinner size="sm" />
            ) : (
              <FormattedString id="submit" />
            )}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ApplicationMoveStepNavigation;
