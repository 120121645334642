import React, { useEffect, useState, useMemo } from "react";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getAbandonedApplicationsData } from "helpers/API/core-service/cs_backend_helper";
import CustomIdCell from "pages/Applications/cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "pages/Applications/cells/InstallmentsCell/InstallmentsCell";
import PackageCell from "pages/Applications/cells/PackageCell/PackageCell";
import ParticipantAgeCell from "pages/Applications/cells/ParticipantAgeCell/ParticipantAgeCell";
import GenderCell from "pages/Applications/cells/GenderCell/GenderCell";
import ParticipantNameCell from "pages/Applications/cells/ParticipantNameCell/ParticipantNameCell";

const AbandonedApplications = () => {
  const [applicationsData, setApplicationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getAbandonedApplicationsData()
      .then((data) => {
        setApplicationsData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: (row) => (
          <CustomIdCell
            customId={row.customId}
            to={`/pp-application/${row.id}`}
          />
        ),
      },
      {
        id: "status",
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <div
            className="text-nowrap"
            style={{ color: value === "moved" ? "red" : "inherit" }}
          >
            <FormattedString id={value} />
          </div>
        ),
      },
      {
        id: "installments_short",
        Header: getString("installments_short"),
        accessor: (d) => (
          <InstallmentsCell
            installmentsPaid={d.installmentsPaid}
            installmentsCount={d.installmentsCount}
            applicationStatus={d.applicationStatus}
          />
        ),
      },
      {
        id: "name",
        Header: getString("name"),
        accessor: (d) =>
          `${d.customerFirstName || ""} ${d.customerLastName || ""}`.trim() ||
          "-",
      },
      {
        id: "program",
        Header: getString("program"),
        accessor: "programName",
      },
      {
        id: "package",
        Header: getString("package"),
        accessor: (d) => (
          <PackageCell
            packages={d.participants?.map((p) => p.programPacketName)}
          />
        ),
      },
      {
        id: "age",
        Header: getString("age"),
        accessor: (d) => (
          <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
        ),
      },
      {
        id: "gender",
        Header: getString("gender"),
        accessor: (d) => (
          <GenderCell genders={d.participants?.map((p) => p.gender)} />
        ),
      },
      {
        id: "participant_name",
        Header: getString("participant_name"),
        accessor: (d) => (
          <ParticipantNameCell
            names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div className="abandoned-applications">
      <TableContainerExternalPagination
        columns={columns || []}
        count={applicationsData.length}
        currentPage={1}
        totalPages={1}
        isPaginationActive={false}
        loading={loading}
        globalFilter=""
        setGlobalFilter={() => {}}
        onPageChange={() => {}}
        data={applicationsData || []}
        isGlobalFilter={false}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("applications_search_for")}
        error={error}
        onRetry={() => {
          setLoading(true);
          getAbandonedApplicationsData()
            .then((data) => {
              setApplicationsData(data);
              setLoading(false);
            })
            .catch((err) => {
              setError(err);
              setLoading(false);
            });
        }}
      />
    </div>
  );
};

export { AbandonedApplications };
