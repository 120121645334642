import { Nav, NavItem, NavLink } from "reactstrap";
import { ApplicationDetailsContext } from "../ApplicationDetails";
import { useContext } from "react";
import classNames from "classnames";
import { ApplicationStatusChanger } from "./components/ApplicationStatusChanger/ApplicationStatusChanger";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { FormattedString } from "Components/Common/FormattedString";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

export const ApplicationTabSwitcher = () => {
  const { activeTab, setActiveTab, applicationData, setEditTileOpen } =
    useContext(ApplicationDetailsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const toggleTab = (tab) => {
    setActiveTab(tab);
    setEditTileOpen(false);
    navigate(`/pp-application/${id}#${tab}`);
  };

  const canEditApplication =
    useHasPermission("PP_APPLICATION_UPDATE") &&
    applicationData?.status !== "archived" &&
    applicationData?.status !== "moved";

  const handleEditButtonClick = () => {
    setActiveTab("overview");
    if (location.pathname.includes("/edit")) {
      navigate(location.pathname.replace("/edit", ""));
    } else {
      navigate(`/pp-application/${id}/edit`);
    }
  };

  const handleMoveButtonClick = () => {
    setActiveTab("overview");
    if (location.pathname.includes("/move")) {
      navigate(location.pathname.replace("/move", ""));
    } else {
      navigate(`/pp-application/${id}/move`);
    }
  };

  return (
    <div className="application-details__nav">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <NavLink
            href="#overview"
            className={classNames(
              { active: activeTab === "overview" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("overview");
            }}
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="overview" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#activity"
            className={classNames(
              { active: activeTab === "activity" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("activity");
            }}
          >
            <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="activityLog" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="application-details__nav__right d-flex align-items-center gap-3">
        {applicationData?.status !== "moved" && (
          <OutlineButton onClick={handleMoveButtonClick}>
            <i className="ri-arrow-right-s-line"></i>
            <FormattedString id="moveToAnotherProgram" />
          </OutlineButton>
        )}
        <WithPermission permissionName="PP_APPLICATION_UPDATE">
          <AngloTooltip
            enabled={applicationData?.status === "moved"}
            stringId={"Can't edit moved application"}
          >
            <OutlineButton
              onClick={handleEditButtonClick}
              disabled={!canEditApplication}
            >
              <i className="ri-pencil-line"></i>
              <FormattedString id="editApplication" />
            </OutlineButton>
          </AngloTooltip>
        </WithPermission>
        <ApplicationStatusChanger disabled={!canEditApplication} />
      </div>
    </div>
  );
};
