import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const ipTypeOptions = [
  { value: "ip", label: getStringSync("ip") },
  { value: "non_ns_ip", label: getStringSync("non_ns_ip") },
  { value: "ns", label: getStringSync("ns") },
  { value: "esl", label: getStringSync("esl") },
];

export const ipDocumentTypeOptions = [
  { value: "id", label: getStringSync("id") },
  { value: "passport", label: getStringSync("passport") },
  { value: "residence_card", label: getStringSync("residence_card") },
  { value: "dbs_check", label: getStringSync("dbs_check") },
  { value: "travel_details", label: getStringSync("travel_details") },
  { value: "programme_deposit", label: getStringSync("programme_deposit") },
  { value: "crc", label: getStringSync("crc") },
  { value: "crc_dbs_fee", label: getStringSync("crc_dbs_fee") },
  { value: "crc_history", label: getStringSync("crc_history") },
  {
    value: "crc_sworn_declaration",
    label: getStringSync("crc_sworn_declaration"),
  },
  {
    value: "programme_compliance_agreement",
    label: getStringSync("programme_compliance_agreement"),
  },
  {
    value: "know_how_training_quiz_results",
    label: getStringSync("know_how_training_quiz_results"),
  },
  { value: "warning_letter", label: getStringSync("warning_letter") },
  { value: "termination_letter", label: getStringSync("termination_letter") },
  { value: "others", label: getStringSync("others") },
];

const createIpParticipantSchema = Yup.object({
  gender: Yup.string().required(getStringSync("enter_ip_participant_gender")),
  firstName: Yup.string()
    .required(getStringSync("enter_ip_participant_first_name"))
    .min(4, getStringSync("invalid_length"))
    .max(32, getStringSync("invalid_length")),
  lastName: Yup.string()
    .required(getStringSync("enter_ip_participant_last_name"))
    .min(4, getStringSync("invalid_length"))
    .max(64, getStringSync("invalid_length")),
  email: Yup.string()
    .email(getStringSync("invalid_mail"))
    .required(getStringSync("mail_required"))
    .min(5, getStringSync("invalid_length"))
    .max(255, getStringSync("invalid_length")),
  phonePrefix: Yup.string()
    .required(getStringSync("enter_ip_participant_phone_prefix"))
    .matches(/^\d+$/, getStringSync("invalid_phone_prefix")),
  phoneNumber: Yup.string()
    .required(getStringSync("enter_ip_participant_phone_number"))
    .matches(/^\d+$/, getStringSync("invalid_phone_number")),
  dateOfBirth: Yup.date()
    .required(getStringSync("enter_ip_participant_date_of_birth"))
    .max(new Date(), getStringSync("invalid_date_of_birth")),
  countryOfOrigin: Yup.string().required(
    getStringSync("select_ip_participant_country_of_origin"),
  ),
  preferredDiet: Yup.string().required(
    getStringSync("enter_ip_participant_preferred_diet"),
  ),
  type: Yup.string().required(getStringSync("enter_ip_participant_type")),
});

export { createIpParticipantSchema, ipTypeOptions };
