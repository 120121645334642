// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}
.summary-card .summary-title,
.summary-card .discounts-title,
.summary-card .total-price-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.summary-card .summary-item {
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.summary-card .summary-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.summary-card .participant-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.summary-card .upsells-list {
  list-style-type: none;
  padding-left: 0;
}
.summary-card .upsells-list li {
  margin-bottom: 0.5rem;
}
.summary-card .upsell-name {
  font-weight: bold;
  color: #6c757d;
}
.summary-card .separator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.summary-card .separator .vertical-line {
  width: 1px;
  height: 100%;
  background-color: #dee2e6;
}
.summary-card .custom-muted {
  color: #505050;
}
.summary-card p.card-text {
  margin-bottom: 7px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationMoveForm/steps/SummaryStep/SummaryDetails.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;AACF;AACE;;;EAGE,kBAAA;EACA,mBAAA;AACJ;AAEE;EACE,6BAAA;EACA,oBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,mBAAA;EACA,iBAAA;EACA,gBAAA;AAAN;AAIE;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;AAFJ;AAKE;EACE,qBAAA;EACA,eAAA;AAHJ;AAKI;EACE,qBAAA;AAHN;AAOE;EACE,iBAAA;EACA,cAAA;AALJ;AAQE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AANJ;AAQI;EACE,UAAA;EACA,YAAA;EACA,yBAAA;AANN;AASE;EACE,cAAA;AAPJ;AAUE;EACE,kBAAA;AARJ","sourcesContent":[".summary-card {\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 20px;\n  background-color: #f9f9f9;\n\n  .summary-title,\n  .discounts-title,\n  .total-price-title {\n    font-size: 1.25rem;\n    margin-bottom: 1rem;\n  }\n\n  .summary-item {\n    border-bottom: 1px solid #eee;\n    padding-bottom: 1rem;\n    margin-bottom: 1rem;\n\n    &:last-child {\n      border-bottom: none;\n      padding-bottom: 0;\n      margin-bottom: 0;\n    }\n  }\n\n  .participant-name {\n    font-size: 14px;\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n  }\n\n  .upsells-list {\n    list-style-type: none;\n    padding-left: 0;\n\n    li {\n      margin-bottom: 0.5rem;\n    }\n  }\n\n  .upsell-name {\n    font-weight: bold;\n    color: #6c757d;\n  }\n\n  .separator {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .vertical-line {\n      width: 1px;\n      height: 100%;\n      background-color: #dee2e6;\n    }\n  }\n  .custom-muted {\n    color: #505050;\n  }\n\n  p.card-text {\n    margin-bottom: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
