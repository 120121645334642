import React, { useContext, useMemo, useState } from "react";
import "./Payments.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useTable } from "react-table";
import { Table } from "reactstrap";
import { format } from "date-fns";
import { getString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";
import MarkAsPaidOffcanvas from "./MarkAsPaidOffCanvas";

export const PaymentsMoved = () => {
  const {
    applicationData: { applicationInstallments, id },
    updateApplicationInstallments,
  } = useContext(ApplicationDetailsContext);

  const [offcanvasVisible, setOffcanvasVisible] = useState(false);
  const [selectedInstallment] = useState(null);

  const toggleOffcanvas = () => setOffcanvasVisible(!offcanvasVisible);

  const updatePaymentStatus = (installmentId, paymentDate) => {
    const updatedInstallments = applicationInstallments.map((payment) =>
      payment.id === installmentId
        ? { ...payment, paidAt: paymentDate, isPaid: true }
        : payment,
    );
    updateApplicationInstallments(updatedInstallments);
  };

  const data = useMemo(() => {
    return (applicationInstallments || [])
      .filter((payment) => payment.isPaid)
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  }, [applicationInstallments]);

  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (row, i) => i + 1,
        Cell: ({ row }) => row.index + 1,
        style: { width: "20px" },
        className: "index-column",
      },
      {
        Header: getString("paymentDate"),
        accessor: "paymentDate",
        Cell: ({ row }) => {
          const { paidAt, dueDate } = row.original;
          return format(new Date(paidAt || dueDate), "yyyy-MM-dd");
        },
      },
      {
        Header: getString("amount"),
        accessor: (row) => `${splitNumbers(row.price)} ${row.currency}`,
        Cell: ({ value }) => value,
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value }) => getString(value),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: () => (
          <div className="d-flex align-items-center gap-1 status-text moved">
            <i className="ri-close-circle-line align-middle"></i> Moved
          </div>
        ),
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="payments">
      <div className="table-responsive mt-4 mt-xl-0">
        <Table
          {...getTableProps()}
          className="table-striped table-nowrap align-middle mb-0 table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    className={column.headerClassName}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className="table-danger">
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      key={index}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <MarkAsPaidOffcanvas
        isOpen={offcanvasVisible}
        toggle={toggleOffcanvas}
        selectedInstallment={selectedInstallment}
        applicationId={id}
        updatePaymentStatus={updatePaymentStatus}
      />
    </div>
  );
};
