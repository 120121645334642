import React from "react";
import { Col, Row, Button } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";
import steps, {
  STEP_INSTALLMENTS,
  STEP_PARTICIPANTS,
  STEP_PAYER_DETAILS,
  STEP_PROGRAM_MEETING,
} from "./provider/utils/stepsConfig";
import { useFormContext } from "./provider/utils/FormContext";

const ApplicationFormContent = () => {
  const { currentStep, handleStepChange, formik } = useFormContext();
  const CurrentStepComponent = steps.find(
    (step) => step.id === currentStep,
  ).component;

  const hasErrors = (stepId) => {
    switch (stepId) {
      case STEP_PARTICIPANTS:
        return (
          Array.isArray(formik.errors.participants) &&
          formik.errors.participants.some((participantErrors) => {
            const { upsells, ...rest } = participantErrors || {};
            return Object.keys(rest).length > 0;
          })
        );
      case STEP_PROGRAM_MEETING:
        return (
          Array.isArray(formik.errors.participants) &&
          formik.errors.participants.some(
            (participantErrors) =>
              Object.keys(participantErrors?.upsells || {}).length,
          )
        );
      case STEP_PAYER_DETAILS:
        return !!Object.keys(formik.errors.payerDetails || {}).length;
      case STEP_INSTALLMENTS:
        return !!Object.keys(formik.errors.summary || {}).length;
      default:
        return false;
    }
  };

  return (
    <Row className="application-form">
      <Col md={3} className="steps-control">
        {steps.map((step) => (
          <Button
            key={step.id}
            color={currentStep === step.id ? "primary" : "secondary"}
            onClick={() => handleStepChange(step.id - currentStep)}
            block
            className={hasErrors(step.id) ? "bg-danger" : ""}
          >
            <FormattedString id="step" /> {step.id} - {step.name}
          </Button>
        ))}
      </Col>
      <Col md={9} className="step-body">
        <CurrentStepComponent />
      </Col>
    </Row>
  );
};

export default ApplicationFormContent;
