// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results-container {
  padding: 20px;
}
.search-results-container .card .card-body .mb-3:first-of-type:not(.text-muted) {
  height: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.search-results-container .card .card-body .mb-3:first-of-type:not(.text-muted) input {
  display: none;
}

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-actions {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/SearchPage/components/SearchResults/SearchResults.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAGM;EACE,SAAA;EACA,2BAAA;EACA,gBAAA;AADR;AAGQ;EACE,aAAA;AADV;;AAQA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALF;;AAQA;EACE,aAAA;EACA,mBAAA;AALF","sourcesContent":[".search-results-container {\n  padding: 20px;\n\n  .card {\n    .card-body {\n      .mb-3:first-of-type:not(.text-muted) {\n        height: 0;\n        margin-bottom: 0 !important;\n        overflow: hidden;\n\n        input {\n          display: none;\n        }\n      }\n    }\n  }\n}\n\n.search-results-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.search-actions {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
