const strings = {
  en: {
    translation: {
      native_speakers: "International Participants",
      polish_people: "Polish People",
      coordinator: "Coordinator",
      health_and_safety: "Health and Safety",
      SUPER_ADMIN: "Super Admin",

      // roles
      ROLE_SUPER_ADMIN: "Super Admin",
      ROLE_ADMIN: "Admin",
      ROLE_SALES_TEAM: "Sales Team",
      ROLE_PRODUCT: "Product",
      ROLE_MANAGER: "Manager",
      ROLE_PRODUCT_TEAM: "Product Team",
      ROLE_IP_SALES_TEAM: "IP Sales Team",
      ROLE_MARKETING_TEAM: "Marketing Team",
      ROLE_ACCOUNTANT_TEAM: "Accountant Team",

      // global vals
      selected_user: "Selected user",
      type: "Type",
      actions: "Actions",
      additional_price: "Additional Price",
      date_modifier: "Date Modifier",
      group_type: "Group Type",
      add: "Add",
      back: "Back",
      duplicate: "Duplicate",
      edit: "Edit",
      save: "Save",
      submit: "Submit",
      cancel: "Cancel",
      delete: "Delete",
      remove: "Remove",
      archive: "Archive",
      restore: "Restore",
      show_details: "Show details",
      hour: "Hour",
      role: "Role",
      business_unit: "Business unit",
      copy_to_clipboard: "Copy to clipboard",
      copy_link: "Copy link",
      link_copied: "Link copied",
      download: "Download",
      open_in_new_tab: "Open in new tab",
      add_new_value: "Add new value",
      reassign: "Reassign",
      all_business_units: "All Business Units",
      overview: "Overview",
      log: "Log",
      close: "Close",
      view: "View",
      create: "Create",

      // tooltips
      restore_from_archive: "Restore from archive",

      // login page
      login_page_title: "Sign in | Angloville CRM",
      login_welcome_back: "Welcome Back!",
      login_sign_in_to_continue: "Sign in to continue to Angloville CRM.",
      login_google: "Sign in with Google",

      // sidebar menu names
      menu: "Menu",
      "pp-applications": "PP Applications",
      "ip-applications": "IP Applications",
      coordinators: "Coordinators",
      "product-management": "Product Management",
      programs: "Programs",
      hotels: "Venues",
      "program-packet-templates": "Program Package Templates",
      "meeting-points": "Meeting Points",
      meetings: "Meetings",
      upsells: "Upsells",
      settings: "Settings",
      users: "Users",
      payments: "Payments",
      "payment-methods": "Payment Methods",
      "point-of-sell": "Points of Sell",
      "installment-plans": "Installment Plans",
      applications: "Applications",
      "ip-participants": "IP Participants",

      // backend-like table labels
      name: "Name",
      address: "Address",
      zipCode: "Zip Code",
      city: "City",
      mapUrl: "Map URL",
      contactInfo: "Contact Info",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      filePath: "File Path",
      roles: "Roles",
      businessUnits: "Business Units",
      startHour: "Start Hour",
      endHour: "End Hour",
      dateModifier: "Date Modifier",
      meetingPoint: "Meeting Point",
      additionalPrice: "Additional Price",
      groupType: "Group Type",
      provider: "Provider",
      businessUnit: "Business Unit",
      description: "Description",
      basePrice: "Base Price",
      attachments: "Attachments",
      paymentMethod: "Payment Method",
      label: "Label",
      ppMinAge: "PP Min Age",
      ppMaxAge: "PP Max Age",
      ipMinAge: "IP Min Age",
      ipMaxAge: "IP Max Age",
      availabilityDays: "Availability Days",
      installmentCount: "Installment Count",
      dueDays: "Due Days",
      days: "Days",
      currency: "Currency",
      pointOfSell: "Point of Sell",
      educational: "Educational",
      touristic: "Touristic",
      ski_group_level: "Ski Group Level",
      ppCapacity: "PP Capacity",
      ipCapacity: "IP Capacity",
      nsCapacity: "NS Capacity",
      nonNsIpCapacity: "Non NS IP Capacity",
      eslCapacity: "ESL Capacity",
      programPacketTemplate: "Program Packet Template",
      installmentPlan: "Installment Plan",
      installment: "Installment",
      healthAndSafetyCoordinators: "Health and Safety Coordinators",
      meetingPP: "Participants Meeting",
      meetingNS: "International Participants Meeting",
      programPackets: "Program Packets",
      participantUpsells: "PP Upsells",
      internationalParticipantUpsells: "IP Upsells",
      startDate: "Start Date",
      endDate: "End Date",
      healthAndSafetyCapacity: "Health and Safety Capacity",
      price: "Price",
      installmentPosition: "Installment Position",
      targetAudience: "Target Audience",
      junior: "Junior",
      kids: "Kids",
      adult: "Adult",
      family: "Family",
      company_or_school: "Company or School",
      season: "Season",
      programMeetingsNS: "IP Team Meeting Points",
      programMeetingsPP: "Participant Meeting Points",
      customId: "Internal ID",
      emailDescription: "Email Description",

      // delete modal
      delete_modal_title: "Confirm deleting this",
      delete_modal_warning:
        "Deleting is irreversible, and the deleted item will be lost permanently.",
      delete_modal_reminder: "Keep in mind that deleting is permanent!",

      // detach modal
      detach_modal_title: "Confirm removing this",
      detach_modal_title_2: "from a program?",
      detach_modal_warning:
        "Removing values from a program is reversible and generally safe.",
      detach_modal_reminder:
        "Keep in mind that you can always add the values back!",

      // archive modal
      archive_modal_title: "Confirm archiving this",
      archive_modal_warning:
        "Archiving is reversible, and the deleted item will be moved to the archive. You can safely restore it from the archive.",
      archive_modal_reminder:
        "Keep in mind that archiving may impact related configurations!",

      // restore modal
      restore_modal_title: "Confirm restoring this",
      restore_modal_warning:
        "Restoring brings the item back from the archive, making it available on other pages.",

      // duplicate modal
      duplicate_modal_title: "Confirm duplicating this",
      duplicate_modal_warning:
        "Duplicating will create a new item with the same data.",

      // hotels
      hotels_page_title: "Venues management | Angloville CRM",
      hotel_sm: "venue",
      hotels_add_new_hotel: "Add New Venue",
      hotels_edit: "Edit venue",
      hotels_list: "Venues list",
      hotels_search_for: "Search for venue...",
      hotel: "Venue",
      hotel_details: "Venue Details",
      hotel_select: "Select Venue",

      // coordinators
      coordinators_page_title: "Coordinators management | Angloville CRM",
      coordinator_sm: "coordinator",
      coordinators_add_new_coordinator: "Add New Coordinator",
      coordinators_search_for: "Search for coordinator...",
      coordinators_edit: "Edit coordinator",
      coordinators_list: "Coordinators list",
      coordinator_details: "Coordinator Details",
      coordinator_add: "Add Coordinator",
      health_and_safety_sm: "health and safety",

      // meeting points
      meeting_points_page_title: "Meeting Points management | Angloville CRM",
      meeting_points_list: "Meeting Points list",
      meeting_point: "Meeting Point",
      meeting_point_sm: "meeting point",
      meeting_points_add_new_meeting_point: "Add new meeting point",
      meeting_points_edit: "Edit meeting point",
      meeting_points_search_for: "Search for meeting point...",
      meeting_point_details: "Meeting Point Details",
      meeting_point_add: "Add Meeting Point",
      meeting_point_selected: "Selected Meeting Point",

      // meetings
      meetings_page_title: "Meetings management | Angloville CRM",
      meeting_sm: "meeting",
      meetings_list: "Meetings list",
      meetings_add_new_meeting: "Add new meeting",
      meetings_edit: "Edit meeting",
      meetings_search_for: "Search for meeting...",
      meeting_details: "Meeting Details",
      meeting_selected: "Selected Meeting",
      participants_meeting_assign: "Assign Participants Meeting",
      ns_meeting_assign: "Assign IP Meeting",
      add_participants_meeting_point: "Add Participants Meeting Point",
      edit_participants_meeting_point: "Edit Participants Meeting Point",
      add_native_speakers_meeting_point:
        "Add International Participants Meeting Point",
      edit_native_speakers_meeting_point:
        "Edit International Participants Meeting Point",
      native_speakers_meeting: "International Participants Meeting",
      participants_meeting: "Participants Meeting",

      // users
      users_page_title: "Users management | Angloville CRM",
      user_sm: "user",
      users_list: "Users list",
      users_add_new_user: "Add new user",
      users_edit: "Edit user",
      users_card_title: "CRM Users management",
      users_cannot_delete_yourself: "You cannot delete your own account.",
      users_search_for: "Search for user...",
      user_details: "User Details",
      user_selected: "Selected User",

      // programs
      programs_page_title: "Programs management | Angloville CRM",
      program_sm: "program",
      programs_list: "Programs list",
      programs_add_new_program: "Add new program",
      programs_edit: "Edit program",
      programs_search_for: "Search for program...",
      program_details: "Program Details",
      editProgram: "Edit program",
      ip_assign: "Assign IP",
      program_packet_selected: "Selected Program Packet",
      ip_selected: "Selected IP",
      participant_type: "Participant Type",

      // program status
      in_preparation: "In preparation",
      on_sale: "On sale",
      ongoing: "Ongoing",
      settlement: "Settlement",
      cancelled: "Cancelled",
      archived: "Archived",

      // application status
      pre_booking: "Pre Booking",
      partly_paid: "Partly Paid",
      fully_paid: "Fully Paid",
      wait_list: "Wait List",
      cancelled_paid: "Cancelled Paid",
      no_show: "No Show",
      interrupted: "Interrupted",
      moved: "Moved",
      credit: "Credit",
      cancelled_credit: "Cancelled Credit",

      // attachments
      attachments_page_title: "Attachments management | Angloville CRM",
      attachment_sm: "attachment",
      attachments_add_new_attachment: "Add New Attachment",
      attachments_search_for: "Search for attachment...",
      attachments_edit: "Edit attachment",
      attachments_list: "Attachments list",
      attachment_details: "Attachment Details",
      file_name: "File Name",
      upload_file: "Upload File",
      attachments_sm: "attachments",
      attachments_added: "Attachments added",
      delete_attachment: "Delete attachment",
      load_more: "Load more",

      // payment_methods
      payment_methods_page_title: "Payment Methods management | Angloville CRM",
      payment_methods_search_for: "Search for payment method...",
      payment_methods_list: "Payment Methods list",
      payment_method_details: "Payment Method Details",

      // upsells
      upsells_page_title: "Upsells management | Angloville CRM",
      upsells_search_for: "Search for upsell...",
      upsell_details: "Upsell Details",
      upsells_list: "Upsells list",
      upsell_sm: "upsell",
      upsells_edit: "Edit upsell",
      upsells_add_new_upsell: "Add new upsell",
      upsell_pp_selected: "Selected PP Upsell",
      upsell_ip_selected: "Selected IP Upsell",
      description_app: "Application Description",
      description_app_tooltip: "Description for the application",
      email_description_tooltip: "Description for the email",

      // applications
      applications_page_title: "Applications management | Angloville CRM",
      applications_search_for: "Search for application...",
      applications_list: "Recent applications",
      application_details: "Application Details",
      activityLog: "Activity Log",
      application_note: "Application Note",
      customer_note: "Customer Note",
      client_comment: "Client Comment",
      client_note: "Client Note",
      customer_details: "Customer Details",
      billing_data: "Billing Data",
      automated_emails: "Automated Emails",
      editApplication: "Edit Application",
      number: "Number",
      amount: "Amount",
      dueDate: "Due Date",
      paid: "Paid",
      future: "Future",
      overdue: "Overdue",
      note: "Note",
      entity_note: "Entity Note",
      no_activities_available: "No activities available",
      mail_preview: "Mail Preview",
      no_mail_preview_available: "Mail preview is not available",
      no_utms_available: "No UTM parameters available",
      billing_data_not_available: "Billing data not available",
      add_comment: "Add comment",
      generate_report: "Generate report",
      add_new_note: "Add new note",
      selected_name: "Selected Name",
      installments_short: "Inst.",
      customer_name: "Customer Name",
      program: "Program",
      week: "Week",
      date: "Date",
      package: "Package",
      age: "Age",
      participant_name: "Participant Name",
      internal_comment: "Internal Comment",
      utm_parameters: "UTM Parameters",
      recent_activity: "Recent Activity",
      new_note: "New Note",
      special_needs: "Special Needs",
      accommodation: "Accommodation",
      no_payment: "No payment",
      "application.updated": "Application updated",
      "application.email_sent": "Email sent",
      client_details: "Client Details",
      comment: "Comment",
      noParticipants: "No participants",
      noDiscounts: "No discounts",
      na: "N/A",
      transport: "Transport",
      sum_of_prices: "The sum of installment prices is",
      desired_installment_sum: "Desired installment sum is",
      create_application: "Create Application",

      // document types
      programme_deposit: "Programme Deposit",
      crc: "CRC",
      crc_dbs_fee: "CRC DBS Fee",
      crc_history: "CRC History",
      crc_sworn_declaration: "CRC Sworn Declaration",
      programme_compliance_agreement: "Programme Compliance Agreement",
      know_how_training_quiz_results: "Know-How Training Quiz Results",
      warning_letter: "Warning Letter",
      termination_letter: "Termination Letter",
      others: "Others",

      // customers
      customers_page_title: "Clients management | Angloville CRM",
      customer: "Client",
      customers: "Clients",
      customers_list: "Clients list",
      ap_count: "Applications",
      pp_count: "Participants",
      last_program: "Last Program",
      recent_applications: "Recent Applications",
      first_name: "First Name",
      last_name: "Last Name",
      last_packet: "Last Package",
      participant_note: "Participant Note",

      // points of sell
      point_of_sell_page_title: "Points of Sell management | Angloville CRM",
      point_of_sell_search_for: "Search for point of sell...",
      point_of_sell_list: "Points of Sell list",
      point_of_sell_details: "Point of Sell Details",

      // program packets
      program_packet_add: "Add Package",
      program_packet_sm: "program package",
      program_packet: "Program Package",
      program_packet_edit: "Edit Program Package",
      ipCapacities: "IP Capacities",

      // program packet templates
      program_packet_templates_page_title:
        "Program Packet Templates management | Angloville CRM",
      program_packet_templates_list: "Program Package Templates list",
      program_packet_templates_search_for:
        "Search for program package template...",
      program_packet_template_details: "Program Package Template Details",
      program_packet_template_sm: "program package template",
      program_packet_templates_add_new_program_packet_template:
        "Add new program package template",
      descriptionItems: "Description Items",
      addNewItem: "Add new item",
      detailedDescription: "Detailed Description",

      // program details
      program_archived_alert:
        "This program is currently archived, you can’t edit it.",
      program_completion_progress: "Program Completion Progress",
      hotel_assign: "Assign Venue",
      coordinator_assign: "Assign Coordinator",
      polish_team_meeting: "Polish Team Meeting",
      ns_team_meeting: "IP Team Meeting",
      meeting_points: "Meeting Points",
      program_packets: "Program Packages",
      packet: "Package",
      value: "Value",
      discount: "Discount",
      range: "Range",
      total_price: "Total Price",

      participants: "Participants",
      participants_assign: "Assign Participants",
      native_speakers_assign: "Assign International Participants",
      status: "Status",
      progress: "Progress",
      pp_upsells: "PP Upsells",
      pp_upsell_assign: "Assign PP Upsell",
      pp_upsells_assign: "Assign PP Upsells",
      ip_upsells: "IP Upsells",
      ip_upsell_assign: "Assign IP Upsell",
      ip_upsells_assign: "Assign IP Upsells",
      discounts: "Discounts",
      summary: "Summary",
      discount_name: "Discount Name",
      date_range: "Date Range",
      discount_value: "Discount Value",
      international_participants: "International Participants",
      ip_tour: "IP Tour",
      program_start_hour: "Program Start Hour",
      program_date_modified: "Program Date Modified",
      programs_edit_program: "Edit Program",
      edit_basic_program_data: "Edit basic program data",

      // discounts
      discount_type: "Discount Type",
      active_amount: "Active Amount",
      passive_amount: "Passive Amount",
      returning: "Returning",
      time_related: "Time Related",
      invitation: "Invitation",
      program_discount_sm: "program discount",
      discount_add: "Add Discount",
      discount_edit: "Edit Discount",
      discount_type_select: "Select discount type",
      start_date: "Start Date",
      end_date: "End Date",
      activeAmount: "Active Amount",
      passiveAmount: "Passive Amount",
      no_discounts_available: "No discounts available",

      // installment plans
      installment_plans_page_title:
        "Installment Plans management | Angloville CRM",
      installment_plans_search_for: "Search for installment plan...",
      installments: "Installments",
      installment_plan_sm: "installment plan",
      installment_plans_add_new_installment_plan: "Add new installment plan",
      installments_manage: "Manage Installments",
      installment_plans_manage: "Manage Installment Plans",
      installment_prices_manage: "Manage Installment Prices for %s",
      installment_plan_add: "Add Installment Plan",
      define_installment_prices: "Define Installment Prices",
      click_to_set_installment_prices: "Click to set installment prices",
      installment_plans_list: "Installment Plans list",
      add_plan: "Add Plan",
      installmentsPaid: "Installments Paid",
      installmentsCount: "Installments Count",
      add_installment: "Add Installment",
      meeting_price: "Meeting Price",

      // international participants types
      ip: "IP",
      non_ns_ip: "NNS",
      ns: "NS",
      esl: "ESL",

      // international participants
      ip_participants_page_title: "IP Participants management | Angloville CRM",
      ip_participants_search_for: "Search for IP participant...",
      ip_participants_card_title: "IP Participants management",
      ip_participant: "IP Participant",
      ip_participants_add_new: "Add new IP participant",
      ip_participant_details: "IP Participant Details",
      ip_participants_edit: "Edit IP participant",
      block: "Block",
      unblock: "Unblock",
      gender: "Gender",
      ip_type: "IP Type",
      male: "Male",
      female: "Female",
      country_of_origin: "Country of Origin",
      preferredDiet: "Preferred Diet",
      phone_number: "Phone Number",
      date_of_birth: "Date of Birth",
      preferred_diet: "Preferred Diet",
      customerId: "Customer ID",
      countryOfOrigin: "Country of Origin",
      international_participant: "International Participant",
      phonePrefix: "Phone Prefix",
      phoneNumber: "Phone Number",
      dateOfBirth: "Date of Birth",
      documents: "Documents",
      id: "ID",
      passport: "Passport",
      residence_card: "Residence Card",
      dbs_check: "DBS Check",
      travel_details: "Travel Details",
      total_sum: "Total Sum",
      total_capacity: "Total Capacity",
      vegetarian: "Vegetarian",
      regular: "Regular",
      assignIpParticipant: "Assign IP Participant",
      internationalParticipants: "International Participants",
      action: "Action",
      participant_search_for: "Search for participant...",
      international_participant_search_for:
        "Search for international participant...",
      af_participants_data: "AF Participants Data",
      af_summary: "AF Summary",
      af_billing_data: "AF Billing Data",
      af_participant_upsells: "AF Participant Upsells",
      last_login: "Last Login",
      diet: "Diet",
      insurance: "Insurance",
      sum: "Sum",
      block_modal_title: "Confirm blocking this",
      ip_participant_sm: "IP participant",
      block_modal_warning:
        "Blocking is irreversible. Once you block this user, you will not be able to unblock them. Please proceed with caution.",

      // pp applications
      add_internal_comment: "Add Internal Comment",
      internal_comment_content: "Internal Comment Content",
      gender_required: "Gender is required",
      selected_packet_required: "Selected Packet is required",
      first_name_required: "First Name is required",
      last_name_required: "Last Name is required",
      invalid_email: "Invalid email",
      date_of_birth_required: "Date of Birth is required",
      diet_required: "Diet is required",
      insurance_required: "Insurance is required",
      at_least_one_participant_required: "At least one participant is required",
      payer_type_required: "Payer Type is required",
      city_required: "City is required",
      zip_code_required: "Zip Code is required",
      address_required: "Address is required",
      installment_plan_required: "Installment Plan is required",
      payment_method_required: "Payment Method is required",
      tax_number_required: "Tax Number is required",
      company_name_required: "Company Name is required",
      previous: "Previous",
      next: "Next",
      editing_application: "Editing Application",
      client_data: "Client Data",
      step: "Step",
      payer_details: "Payer Details",
      summary_and_payment: "Summary and Payment",
      add_participant: "Add Participant",
      selected_packet: "Selected Packet",
      participant: "Participant",
      installment_plan: "Installment Plan",
      payment_method: "Payment Method",
      payer_type: "Payer Type",
      individual: "Individual",
      person: "Person",
      company: "Company",
      phone_prefix: "Phone Prefix",
      company_name: "Company Name",
      tax_number: "Tax Number",
      zip_code: "Zip Code",
      program_meeting: "Program Meeting",
      point_of_sell: "Point of Sell",
      due_days: "Due Days",
      select_program: "Please select program",
      phone_prefix_required: "Phone Prefix is required",
      phone_number_required: "Phone Number is required",
      email_required: "Email is required",
      program_meeting_required: "Program Meeting is required",

      // application update modal
      update_installment_plans: "Update Installment Plans",
      ok: "OK",
      changes_not_saved: "Changes not saved",
      update_installment_plans_paragraph: `You have made changes to the installment plans. To proceed, you have to update the installment plans for this application.`,

      // error popup
      error_details: "Error Details",
      error_popup_paragraph: `If you believe this is an error, please contact IT support or your system administrator. They will be able to assist you in resolving this issue. Thank you for your understanding and patience.`,

      // validation messages
      select_user: "Please select user",
      select_type: "Please select type",
      select_group_type: "Please select group type",
      select_meeting_point: "Please select meeting point",
      select_option: "Please select an option",
      select_at_least_one_option: "Please select at least one option",
      select_hotel: "Please select venue",
      enter_hotel: "Please enter venue name",
      enter_hotel_address: "Please enter venue address",
      enter_zip_code: "Please enter zip code",
      enter_city: "Please enter city",
      enter_additional_price: "Please enter additional price",
      invalid_city_name: "Invalid city name",
      enter_map_url: "Please enter map url",
      map_url_too_short: "Map URL is too short",
      enter_contact_info: "Please enter contact info",
      enter_valid_hour: "Please enter valid hour",
      additional_price_too_small:
        "Additional price must be greater or equal to 0",
      enter_user_first_name: "Please enter user first name",
      invalid_length: "Invalid length",
      invalid_mail: "Invalid email address",
      mail_required: "Please enter user email",
      name_required: "Please enter name",
      availability_days_required: "Please enter availability days",
      due_days_required: "Please enter due days",
      currency_required: "Please enter currency",
      type_required: "Please enter type",
      point_of_sell_required: "Please enter point of sell",
      installment_count_required: "Please enter installment count",
      max_installment_count_message: "Max installment count is 10",
      installment_plan_details: "Installment Plan Details",
      select_installment_plan: "Please select the installment plan",
      pp_capacity_required: "Please enter PP capacity",
      ip_capacity_required: "Please enter IP capacity",
      program_packet_template_required:
        "Please select program package template",
      upsell_required: "Please select the upsell",
      meeting_required: "Please select the meeting",
      hotel_required: "Please select the venue",
      enter_upsell_type: "Please enter the upsell type",
      enter_upsell_name: "Please enter the upsell name",
      enter_upsell_price: "Please enter the upsell price",
      enter_upsell_installment_position:
        "Please enter the installment position",
      label_required: "Please enter label",
      pp_min_age_required: "Please enter PP min age",
      pp_max_age_required: "Please enter PP max age",
      ip_min_age_required: "Please enter IP min age",
      ip_max_age_required: "Please enter IP max age",
      target_audience_required: "Please enter target audience",
      business_unit_required: "Please enter business unit",
      file_name_required: "Please enter file name",
      select_installment_position: "Please select installment position",
      participants_meeting_updated: "Participants Meeting updated successfully",
      native_speakers_meeting_updated:
        "International Participants Meeting updated successfully",
      min_installment_count_message: "Min installment count is 2",
      min_availability_days_message: "Min availability days is 1",
      min_due_days_message: "Min due days is 1",
      min_currency_message: "Min currency length is 3",
      price_must_be_integer_or_proper_float:
        "Price must be an integer or have exactly one decimal place",
      due_days_order_message: "Due days must be in descending order",
      businessUnitIdRequired: "Business Unit ID is required",
      nameMinLength: "Name must be at least {min} characters",
      nameRequired: "Name is required",
      endDateRequired: "End Date is required",
      startDateRequired: "Start Date is required",
      healthAndSafetyCapacityRequired: "Health and Safety Capacity is required",
      healthAndSafetyCapacityPositive:
        "Health and Safety Capacity must be a positive number",
      healthAndSafetyCapacityInteger:
        "Health and Safety Capacity must be an integer",
      seasonMinLength: "Season must be at least {min} characters",
      seasonRequired: "Season is required",
      program_packet_required: "Program Packet is required",
      ip_required: "IP is required",
      required_field: "This field is required",
      name_selection_is_required: "Name selection is required",
      note_cannot_be_empty: "Note cannot be empty",
      at_least_one_upsell_required: "At least one upsell is required",
      discount_type_required: "Discount type is required",
      active_amount_required: "Active amount is required",
      discount_name_required: "Discount name is required",
      start_date_required: "Start date is required",
      end_date_required: "End date is required",
      passive_amount_required: "Passive amount is required",
      enter_ip_participant_gender: "Please enter IP participant gender",
      enter_ip_participant_first_name: "Please enter IP participant first name",
      enter_ip_participant_last_name: "Please enter IP participant last name",
      enter_ip_participant_phone_number:
        "Please enter IP participant phone number",
      enter_ip_participant_date_of_birth:
        "Please enter IP participant date of birth",
      select_ip_participant_country_of_origin:
        "Please select IP participant country of origin",
      enter_ip_participant_preferred_diet:
        "Please enter IP participant preferred diet",
      enter_ip_participant_type: "Please enter IP participant type",
      invalid_date_of_birth: "Invalid date of birth",
      description_required: "Description is required",
      ns_capacity_required: "NS capacity is required",
      non_ns_ip_capacity_required: "Non NS IP capacity is required",
      esl_capacity_required: "ESL capacity is required",
      participant_type_required: "Participant type is required",

      // success messages
      installment_plan_added: "Installment plan added successfully",
      program_packet_added: "Program package added successfully",
      hotel_assigned: "Venue assigned successfully",
      coordinator_assigned: "Coordinator assigned successfully",
      coordinator_detached: "Coordinator detached successfully",
      health_and_safety_assigned: "Health and safety assigned successfully",
      installment_plan_detached: "Installment plan detached successfully",
      meeting_detached: "Meeting detached successfully",
      upsell_assigned: "Upsell assigned successfully",
      upsell_detached: "Upsell detached successfully",
      health_and_safety_detached: "Health and safety detached successfully",
      program_packet_deleted: "Program package deleted successfully",
      prices_defined: "Prices defined successfully",
      ns_team_meeting_deleted: "IP Team Meeting deleted successfully",
      polish_team_meeting_deleted: "Polish Team Meeting deleted successfully",
      program_hotel_removed: "Venue removed from program successfully",
      meeting_assigned: "Meeting assigned successfully",
      attachment_added: "Attachment added successfully",
      attachment_removed: "Attachment removed successfully",
      participants_meeting_created: "Participants Meeting created successfully",
      participants_meeting_deleted: "Participants Meeting deleted successfully",
      native_speakers_meeting_created:
        "International Participants Meeting created successfully",
      native_speakers_meeting_deleted:
        "International Participants Meeting deleted successfully",
      program_status_updated: "Program status updated successfully",
      comment_added: "Comment added successfully",
      no_attachments: "No attachments",
      discount_added: "Discount added successfully",
      program_discount_deleted: "Program discount deleted successfully",
      copied: "Copied",
      application_status_updated: "Application status updated successfully",
      application_updated_successfully: "Application updated successfully",

      // error messages
      program_status_update_failed: "Failed to update program status",
      you_have_been_logged_out: "You have been logged out",
      unexpected_error_occurred:
        "An unexpected error occurred. Please try again later.",
      data_load_error: "Unable to load data. Please try again later.",
      retry: "Retry",

      // access
      you_dont_have_access: "You don't have access to this resource",
      assign_hotel_to_program_first: "Assign a venue to the program first",

      // country names
      united_states: "United States",
      great_britain: "Great Britain",
      canada: "Canada",
      australia: "Australia",
      germany: "Germany",
      france: "France",
      italy: "Italy",
      spain: "Spain",
      japan: "Japan",
      china: "China",
      india: "India",
      brazil: "Brazil",
      russia: "Russia",
      south_africa: "South Africa",
      nigeria: "Nigeria",
      mexico: "Mexico",
      south_korea: "South Korea",
      argentina: "Argentina",
      saudi_arabia: "Saudi Arabia",
      turkey: "Turkey",
      sweden: "Sweden",
      norway: "Norway",
      finland: "Finland",
      denmark: "Denmark",
      netherlands: "Netherlands",
      belgium: "Belgium",
      switzerland: "Switzerland",
      austria: "Austria",
      poland: "Poland",
      czech_republic: "Czech Republic",
      hungary: "Hungary",
      portugal: "Portugal",
      greece: "Greece",
      ireland: "Ireland",
      romania: "Romania",
      bulgaria: "Bulgaria",
      croatia: "Croatia",
      serbia: "Serbia",
      ukraine: "Ukraine",
      belarus: "Belarus",
      israel: "Israel",
      egypt: "Egypt",
      pakistan: "Pakistan",
      indonesia: "Indonesia",
      malaysia: "Malaysia",
      singapore: "Singapore",
      thailand: "Thailand",
      vietnam: "Vietnam",
      philippines: "Philippines",
      bangladesh: "Bangladesh",
      iran: "Iran",
      iraq: "Iraq",
      syria: "Syria",
      afghanistan: "Afghanistan",
      chile: "Chile",
      colombia: "Colombia",
      venezuela: "Venezuela",
      peru: "Peru",
      uruguay: "Uruguay",
      ecuador: "Ecuador",
      bolivia: "Bolivia",
      paraguay: "Paraguay",
      cuba: "Cuba",
      dominican_republic: "Dominican Republic",
      jamaica: "Jamaica",
      haiti: "Haiti",
      guatemala: "Guatemala",
      honduras: "Honduras",
      el_salvador: "El Salvador",
      nicaragua: "Nicaragua",
      costa_rica: "Costa Rica",
      panama: "Panama",
      trinidad_and_tobago: "Trinidad and Tobago",
      barbados: "Barbados",
      belize: "Belize",
      guyana: "Guyana",
      suriname: "Suriname",
      new_zealand: "New Zealand",
      sri_lanka: "Sri Lanka",
      myanmar: "Myanmar",
      south_sudan: "South Sudan",
      morocco: "Morocco",
      algeria: "Algeria",
      tunisia: "Tunisia",
      libya: "Libya",
      gambia: "Gambia",
      senegal: "Senegal",
      mauritania: "Mauritania",
      mali: "Mali",
      guinea: "Guinea",
      ivory_coast: "Ivory Coast",
      burkina_faso: "Burkina Faso",
      niger: "Niger",
      togo: "Togo",
      benin: "Benin",
      mauritius: "Mauritius",
      liberia: "Liberia",
      sierra_leone: "Sierra Leone",
      ghana: "Ghana",
      chad: "Chad",
      central_african_republic: "Central African Republic",
      cameroon: "Cameroon",
      cape_verde: "Cape Verde",
      sao_tome_and_principe: "Sao Tome and Principe",
      equatorial_guinea: "Equatorial Guinea",
      gabon: "Gabon",
      congo: "Congo",
      democratic_republic_of_the_congo: "Democratic Republic of the Congo",
      angola: "Angola",
      guinea_bissau: "Guinea-Bissau",
      british_indian_ocean_territory: "British Indian Ocean Territory",
      ascension_island: "Ascension Island",
      seychelles: "Seychelles",
      sudan: "Sudan",
      rwanda: "Rwanda",
      ethiopia: "Ethiopia",
      somalia: "Somalia",
      djibouti: "Djibouti",
      kenya: "Kenya",
      tanzania: "Tanzania",
      uganda: "Uganda",
      burundi: "Burundi",
      mozambique: "Mozambique",
      zambia: "Zambia",
      madagascar: "Madagascar",
      reunion: "Reunion",
      zimbabwe: "Zimbabwe",
      namibia: "Namibia",
      malawi: "Malawi",
      lesotho: "Lesotho",
      botswana: "Botswana",
      eswatini: "Eswatini",
      comoros: "Comoros",
      saint_helena: "Saint Helena",
      eritrea: "Eritrea",
      aruba: "Aruba",
      faroe_islands: "Faroe Islands",
      greenland: "Greenland",
      gibraltar: "Gibraltar",
      luxembourg: "Luxembourg",
      iceland: "Iceland",
      albania: "Albania",
      malta: "Malta",
      cyprus: "Cyprus",
      lithuania: "Lithuania",
      latvia: "Latvia",
      estonia: "Estonia",
      moldova: "Moldova",
      armenia: "Armenia",
      andorra: "Andorra",
      monaco: "Monaco",
      san_marino: "San Marino",
      vatican_city: "Vatican City",
      montenegro: "Montenegro",
      kosovo: "Kosovo",
      slovenia: "Slovenia",
      bosnia_and_herzegovina: "Bosnia and Herzegovina",
      north_macedonia: "North Macedonia",
      slovakia: "Slovakia",
      liechtenstein: "Liechtenstein",
      falkland_islands: "Falkland Islands",
      saint_pierre_and_miquelon: "Saint Pierre and Miquelon",
      guadeloupe: "Guadeloupe",
      french_guiana: "French Guiana",
      martinique: "Martinique",
      curacao: "Curacao",
      timor_leste: "Timor-Leste",
      norfolk_island: "Norfolk Island",
      brunei: "Brunei",
      nauru: "Nauru",
      papua_new_guinea: "Papua New Guinea",
      tonga: "Tonga",
      solomon_islands: "Solomon Islands",
      vanuatu: "Vanuatu",
      fiji: "Fiji",
      palau: "Palau",
      wallis_and_futuna: "Wallis and Futuna",
      cook_islands: "Cook Islands",
      niue: "Niue",
      samoa: "Samoa",
      kiribati: "Kiribati",
      new_caledonia: "New Caledonia",
      tuvalu: "Tuvalu",
      french_polynesia: "French Polynesia",
      tokelau: "Tokelau",
      micronesia: "Micronesia",
      marshall_islands: "Marshall Islands",
      north_korea: "North Korea",
      hong_kong: "Hong Kong",
      macau: "Macau",
      cambodia: "Cambodia",
      laos: "Laos",
      taiwan: "Taiwan",
      maldives: "Maldives",
      lebanon: "Lebanon",
      jordan: "Jordan",
      kuwait: "Kuwait",
      yemen: "Yemen",
      oman: "Oman",
      palestine: "Palestine",
      united_arab_emirates: "United Arab Emirates",
      bahrain: "Bahrain",
      qatar: "Qatar",
      bhutan: "Bhutan",
      mongolia: "Mongolia",
      nepal: "Nepal",
      tajikistan: "Tajikistan",
      turkmenistan: "Turkmenistan",
      azerbaijan: "Azerbaijan",
      georgia: "Georgia",
      kyrgyzstan: "Kyrgyzstan",
      uzbekistan: "Uzbekistan",
    },
  },
  // Add more languages here
};

export default strings;
