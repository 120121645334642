// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installments-cell {
  background: #ff8a8a;
  color: #cb4545;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.installments-cell--full {
  background: #c1ffc1 !important;
  color: #45cb45 !important;
}
.installments-cell--partial {
  background: #ffeb8a !important;
  color: #cb9a45 !important;
}
.installments-cell--none {
  background: #ff8a8a !important;
  color: #cb4545 !important;
}
.installments-cell--gray {
  background-color: gray !important;
  color: white !important;
}
.installments-cell--red {
  background-color: red !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/InstallmentsCell/InstallmentsCell.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,8BAAA;EACA,yBAAA;AACJ;AAEE;EACE,8BAAA;EACA,yBAAA;AAAJ;AAGE;EACE,8BAAA;EACA,yBAAA;AADJ;AAIE;EACE,iCAAA;EACA,uBAAA;AAFJ;AAKE;EACE,gCAAA;EACA,uBAAA;AAHJ","sourcesContent":[".installments-cell {\n  background: #ff8a8a;\n  color: #cb4545;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &--full {\n    background: #c1ffc1 !important;\n    color: #45cb45 !important;\n  }\n\n  &--partial {\n    background: #ffeb8a !important;\n    color: #cb9a45 !important;\n  }\n\n  &--none {\n    background: #ff8a8a !important;\n    color: #cb4545 !important;\n  }\n\n  &--gray {\n    background-color: gray !important;\n    color: white !important;\n  }\n\n  &--red {\n    background-color: red !important;\n    color: white !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
