import React from "react";
import { getString } from "Components/Common/FormattedString";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const RemoveInstallmentButton = ({
  canRemoveInstallment,
  removeInstallment,
  index,
  setInstallmentsError,
  formik,
  tooltipHidden,
}) => {
  return (
    <AngloTooltip
      text={
        canRemoveInstallment
          ? ""
          : getString("no_installment_plan_available", null, {
              amount: index,
            })
      }
      enabled={!canRemoveInstallment && !tooltipHidden}
    >
      <div
        className="fancy-remove-icon"
        onClick={() => {
          removeInstallment(index);
          setInstallmentsError(null);
          formik.setFieldError("installments", undefined);
        }}
        disabled={
          formik.values.installments.length === 1 || !canRemoveInstallment
        }
      >
        <i className="ri-delete-bin-line"></i>
      </div>
    </AngloTooltip>
  );
};

export default RemoveInstallmentButton;
