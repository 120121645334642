import React, { useContext, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleInputChange,
  handlePhoneChange,
  setValidationErrors,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString, getStringSync } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import FormTextField from "Components/Entity/FormTextField";
import FormCheckboxField from "Components/Entity/FormCheckboxField";
import * as Yup from "yup";
import { toast_success } from "helpers/toast_helper";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";
import { editCustomerDetails } from "helpers/API/account-service/as_backend_helper";

const EditCustomerDetailsCanvas = ({ visible, setVisible }) => {
  const { customerData, refreshCustomerData } = useContext(
    CustomerDetailsContext,
  );
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      phonePrefix: customerData.phonePrefix || "",
      phoneNumber: customerData.phoneNumber || "",
      email: customerData.email || "",
      firstName: customerData.firstName || "",
      lastName: customerData.lastName || "",
      lang: customerData.lang || "",
      smsAgreement: customerData.phoneMarketingAgreement || false,
      emailAgreement: customerData.emailMarketingAgreement || false,
    },
    validationSchema: Yup.object({
      phonePrefix: Yup.string().required(getStringSync("enter_phone_prefix")),
      phoneNumber: Yup.string().required(getStringSync("enter_phone_number")),
      email: Yup.string()
        .email(getStringSync("enter_valid_email"))
        .required(getStringSync("enter_email")),
      firstName: Yup.string().required(getStringSync("enter_first_name")),
      lastName: Yup.string().required(getStringSync("enter_last_name")),
      lang: Yup.string().required(getStringSync("select_language")),
      smsAgreement: Yup.boolean(),
      emailAgreement: Yup.boolean(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      editCustomerDetails(customerData.id, values)
        .then(() => {
          toast_success(getString("customer_details_updated"));
          refreshCustomerData();
          setVisible(false);
        })
        .catch((e) => {
          console.error(e.message);
          setValidationErrors(validation, e.data.fields);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      title={getString("edit_customer_details")}
      formId={"edit-customer-details"}
      validationRule={validation.isValid}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Form
        id="edit-customer-details"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            <FormPhoneField
              label={getString("phone_number")}
              name="phoneNumber"
              phonePrefixName="phonePrefix"
              value={validation.values["phoneNumber"] || ""}
              onChange={(e) => handlePhoneChange(validation, e)}
              phonePrefix={validation.values.phonePrefix || ""}
              onPrefixChange={(e) => handlePhoneChange(validation, e)}
              error={
                validation.errors["phoneNumber"] ||
                validation.errors["phonePrefix"]
              }
              setError={(error) =>
                validation.setFieldError("phoneNumber", error)
              }
            />
            <FormTextField
              label={getString("email")}
              name="email"
              value={validation.values.email}
              onChange={(e) => handleInputChange(validation, e, "email")}
              error={validation.errors.email}
            />
            <FormTextField
              label={getString("first_name")}
              name="firstName"
              value={validation.values.firstName}
              onChange={(e) => handleInputChange(validation, e, "firstName")}
              error={validation.errors.firstName}
            />
            <FormTextField
              label={getString("last_name")}
              name="lastName"
              value={validation.values.lastName}
              onChange={(e) => handleInputChange(validation, e, "lastName")}
              error={validation.errors.lastName}
            />
            <FormTextField
              label={getString("language")}
              name="lang"
              value={validation.values.lang}
              onChange={(e) => handleInputChange(validation, e, "lang")}
              error={validation.errors.lang}
            />
            <FormCheckboxField
              label={getString("sms_agreement")}
              name="smsAgreement"
              checked={validation.values.smsAgreement}
              onChange={(e) => handleInputChange(validation, e, "smsAgreement")}
              error={validation.errors.smsAgreement}
            />
            <FormCheckboxField
              label={getString("email_agreement")}
              name="emailAgreement"
              checked={validation.values.emailAgreement}
              onChange={(e) =>
                handleInputChange(validation, e, "emailAgreement")
              }
              error={validation.errors.emailAgreement}
            />
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default EditCustomerDetailsCanvas;
