import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { format } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { Badge } from "Components/Common/Badges/Badges";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { IpDetailsContext } from "pages/IpParticipants/IpParticipantDetails/IpParticipantDetails";

export const IpApplications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const initialSearchTerm = searchParams.get("searchTerm") || "";
  const [globalFilter, setGlobalFilter] = useState(initialSearchTerm);
  const isInitialRender = useRef(true);

  const { ipApplications, refreshIpApplications, ipApplicationsLoading } =
    useContext(IpDetailsContext);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const params = {};
    if (globalFilter) {
      params.searchTerm = globalFilter;
    }
    if (pageNumber > 1) {
      params.page = pageNumber.toString();
    }
    setSearchParams(params);
  }, [pageNumber, globalFilter]);

  const columns = useMemo(
    () => [
      {
        Header: getString("participant_name"),
        accessor: (d) => (
          <span className="text-decoration-none">
            {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
          </span>
        ),
        Cell: ({ row }) => (
          <span className="text-decoration-none">
            {`${row.original.firstName || ""} ${row.original.lastName || ""}`.trim() ||
              "-"}
          </span>
        ),
      },
      {
        Header: getString("package_name"),
        accessor: "programPacketName",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => <Badge size="medium">{value}</Badge>,
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("venue"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value || "N/A"}</span>,
      },
      {
        Header: getString("program_dates"),
        accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
              new Date(row.original.programEndDate),
              "yyyy-MM-dd",
            )}`}
          </div>
        ),
      },
      {
        Header: getString("program_status"),
        accessor: "programStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  return ipApplications ? (
    <div className="ip-applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={ipApplications.count || 0}
        currentPage={ipApplications.currentPage || 1}
        totalPages={ipApplications.totalPages || 1}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={(page) => refreshIpApplications(page)}
        loading={ipApplicationsLoading}
        data={ipApplications.data || []}
        isGlobalFilter={true}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_applications_search_for")}
      />
    </div>
  ) : (
    <PreloaderWrap />
  );
};
