import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_PROGRAM_LIST_DATA,
  POST_PROGRAM,
  EDIT_PROGRAM,
  DELETE_PROGRAM,
  DUPLICATE_PROGRAM,
} from "./actionType";
import { programsApiSuccess, programsApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getProgramList,
  postProgram,
  editProgram,
  deleteProgram,
  duplicateProgram,
} from "../../helpers/API/core-service/cs_backend_helper";
import { getString } from "Components/Common/FormattedString";

function* getProgramListData({ payload: data }) {
  try {
    const response = yield call(getProgramList, data);
    const sortedResponse = response.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate),
    );
    yield put(programsApiSuccess(GET_PROGRAM_LIST_DATA, sortedResponse));
  } catch (error) {
    const errorMessage =
      error?.data?.message || getString("unexpected_error_occurred");
    yield put(programsApiError(GET_PROGRAM_LIST_DATA, errorMessage));
  }
}

function* createProgram({ payload: program }) {
  try {
    const response = yield call(postProgram, program.data, program.onSuccess);
    yield put(programsApiSuccess(POST_PROGRAM, response));
    toast_success("Program added successfully");
  } catch (error) {
    yield put(programsApiError(POST_PROGRAM, error));
    toast_error(error.data.message);
  }
}

function* editProgramEntry({ payload: program }) {
  try {
    const response = yield call(
      editProgram,
      program.id,
      program.data,
      program.onSuccess,
    );
    yield put(programsApiSuccess(EDIT_PROGRAM, response));
    toast_success("Program edited successfully");
  } catch (error) {
    yield put(programsApiError(EDIT_PROGRAM, error));
    toast_error(error.data.message);
  }
}

function* deleteProgramEntry({ payload: program }) {
  try {
    const response = yield call(deleteProgram, program.id, program.onSuccess);
    yield put(programsApiSuccess(DELETE_PROGRAM, response));
    toast_success("Program removed");
  } catch (error) {
    yield put(programsApiError(DELETE_PROGRAM, error));
    toast_error(error.data.message);
  }
}

function* duplicateProgramEntry({ payload: program }) {
  try {
    const response = yield call(
      duplicateProgram,
      program.id,
      program.onSuccess,
    );
    yield put(programsApiSuccess(DELETE_PROGRAM, response));
    toast_success("Program duplicated");
  } catch (error) {
    yield put(programsApiError(DUPLICATE_PROGRAM, error));
    toast_error(error.data.message);
  }
}

export function* watchGetProgramListData() {
  yield takeEvery(GET_PROGRAM_LIST_DATA, getProgramListData);
}

export function* watchPostProgram() {
  yield takeEvery(POST_PROGRAM, createProgram);
}
export function* watchEditProgram() {
  yield takeEvery(EDIT_PROGRAM, editProgramEntry);
}
export function* watchDeleteProgram() {
  yield takeEvery(DELETE_PROGRAM, deleteProgramEntry);
}

export function* watchDuplicateProgram() {
  yield takeEvery(DUPLICATE_PROGRAM, duplicateProgramEntry);
}

function* programsSaga() {
  yield all([
    fork(watchGetProgramListData),
    fork(watchPostProgram),
    fork(watchEditProgram),
    fork(watchDeleteProgram),
    fork(watchDuplicateProgram),
  ]);
}

export default programsSaga;
