import React, { useContext, useEffect, useState, useRef } from "react";
import { Col, CardText, CardTitle } from "reactstrap";
import { formatDate, isDifferent } from "helpers/utils";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useFormContext } from "../../../provider/utils/FormContext";
import moment from "moment";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import {
  calculateMinMaxDate,
  isValidYear,
} from "../../../provider/utils/utils";

const NewProgram = ({ handleEdit }) => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { formik, targetProgram } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = React.useState({});

  const getProgramPacketName = (packetId) => {
    if (!targetProgram) {
      return null;
    }
    const packet = targetProgram.programPackets.find(
      (packet) => packet.id === packetId,
    );
    return packet ? packet.name : null;
  };

  const getUpsellName = (upsellId) => {
    if (!targetProgram) {
      return null;
    }
    const upsell = targetProgram.participantUpsells.find(
      (upsell) => upsell.id === upsellId,
    );
    return upsell ? upsell.name : null;
  };

  const getMeetingPointAddress = (meetingId) => {
    if (!targetProgram) {
      return null;
    }
    const meeting =
      targetProgram.programMeetingsPP.find(
        (meeting) => meeting.id === meetingId,
      ) ||
      targetProgram.programMeetingsNS.find(
        (meeting) => meeting.id === meetingId,
      );
    return meeting ? meeting.meetingPoint.address : null;
  };

  const getProgramPacketWarning = (dateOfBirth, programPacket) => {
    if (!isValidYear(dateOfBirth)) {
      return null;
    }

    const packet = targetProgram.programPackets.find(
      (packet) => packet.id === programPacket,
    );

    if (!packet) {
      return null;
    }

    const { minDate, maxDate } = calculateMinMaxDate(
      targetProgram.startDate,
      packet.programPacketTemplate.ppMinAge,
      packet.programPacketTemplate.ppMaxAge,
    );

    if (!moment(dateOfBirth).isBetween(minDate, maxDate, undefined, "[]")) {
      return getString("programPacketOutOfBounds");
    }

    return null;
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isProgramNotSelected, setIsProgramNotSelected] = useState(true);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setIsProgramNotSelected(false);
  }, [targetProgram]);

  return (
    <Col md={6}>
      <CardText className="date-text" style={{ minHeight: "21px" }}>
        {targetProgram
          ? `${formatDate(targetProgram.startDate)} - ${formatDate(targetProgram.endDate)}`
          : ""}
      </CardText>
      <CardTitle tag="h5" className="comparison-title text-primary">
        <FormattedString id={getString("newProgram")} />
      </CardTitle>
      <CardText
        className={`subheader editable-program ${
          isDifferent(applicationData.programName, targetProgram?.name)
            ? "text-different-new"
            : ""
        } ${formik.errors.program ? "text-danger" : ""}`}
        onClick={() =>
          handleEdit(
            {
              programName: targetProgram?.name || applicationData.programName,
            },
            "program",
          )
        }
      >
        {isProgramNotSelected ? (
          <span className="placeholder-text text-danger">
            <FormattedString id={getString("notSelected")} />
          </span>
        ) : (
          targetProgram?.name || (
            <span className="placeholder-text text-danger">
              <FormattedString id={getString("notSelected")} />
            </span>
          )
        )}
        {!isProgramNotSelected && <i className="edit-icon ri-edit-line"></i>}
      </CardText>
      <ol className="participant-list new-program">
        {formik.values.participants.map((participant, index) => {
          const warning = getProgramPacketWarning(
            participant.dateOfBirth,
            participant.programPacket,
          );
          return (
            <li
              key={index}
              className={`participant-details participant-details--new ${
                formik.errors.participants?.[index] ? "is-invalid" : ""
              } ${isProgramNotSelected ? "not-editable" : ""}`}
              onClick={
                isProgramNotSelected
                  ? null
                  : () =>
                      handleEdit(
                        {
                          participantIndex: index,
                          participantData: formik.values.participants[index],
                        },
                        "participant",
                      )
              }
            >
              <h6
                className={`editable-value ${isProgramNotSelected ? "not-editable" : ""}`}
              >
                {participant.firstName} {participant.lastName}
                {!isProgramNotSelected && (
                  <i className="edit-icon ri-edit-line"></i>
                )}
              </h6>
              <ul>
                <li>
                  <span className="value-key">
                    <FormattedString id={getString("package")} />
                  </span>{" "}
                  <span
                    className={`editable-value ${
                      isDifferent(
                        getProgramPacketName(participant.programPacket),
                        applicationData.participants[index].programPacketName,
                      )
                        ? "text-different-new"
                        : ""
                    } ${warning ? "text-warning" : ""} ${
                      formik.errors.participants?.[index]?.programPacket
                        ? "is-invalid"
                        : ""
                    } ${isProgramNotSelected ? "not-editable" : ""}`}
                  >
                    {isProgramNotSelected ? (
                      <span className="placeholder-text text-danger">
                        <FormattedString id={getString("notSelected")} />
                      </span>
                    ) : (
                      getProgramPacketName(participant.programPacket) || (
                        <span className="placeholder-text text-danger">
                          <FormattedString id={getString("notSelected")} />
                        </span>
                      )
                    )}
                    {warning && (
                      <>
                        <AngloTooltip
                          stringId={getString("programPacketOutOfBounds")}
                          isOpen={tooltipOpen[index]}
                          target={`warning-icon-${index}`}
                          toggle={() => toggleTooltip(index)}
                        >
                          <i
                            id={`warning-icon-${index}`}
                            className="ri-error-warning-line"
                          ></i>
                        </AngloTooltip>
                      </>
                    )}
                  </span>
                </li>
                <li>
                  <span className="value-key">
                    <FormattedString id={getString("meeting")} />
                  </span>{" "}
                  <span
                    className={`editable-value ${
                      isDifferent(
                        getMeetingPointAddress(formik.values.programMeeting),
                        applicationData.meetingPoint?.address,
                      )
                        ? "text-different-new"
                        : ""
                    } ${formik.errors.programMeeting ? "is-invalid" : ""} ${
                      isProgramNotSelected ? "not-editable" : ""
                    }`}
                  >
                    {isProgramNotSelected ? (
                      <span className="placeholder-text text-danger">
                        <FormattedString id={getString("notSelected")} />
                      </span>
                    ) : (
                      getMeetingPointAddress(formik.values.programMeeting) || (
                        <span className="placeholder-text text-danger">
                          <FormattedString id={getString("notSelected")} />
                        </span>
                      )
                    )}
                  </span>
                </li>
                <li>
                  <span className="value-key">
                    <FormattedString id={getString("upsells")} />
                  </span>
                </li>
                <ul>
                  <li>
                    <span className="value-key">
                      <FormattedString id={getString("diet")} />
                    </span>{" "}
                    <span
                      className={`editable-value ${
                        isDifferent(
                          getUpsellName(participant.upsells?.diet),
                          applicationData.participants[index].dietName,
                        )
                          ? "text-different-new"
                          : ""
                      } ${
                        formik.errors.participants?.[index]?.upsells?.diet
                          ? "is-invalid"
                          : ""
                      } ${isProgramNotSelected ? "not-editable" : ""}`}
                    >
                      {isProgramNotSelected ? (
                        <span className="placeholder-text text-danger">
                          <FormattedString id={getString("notSelected")} />
                        </span>
                      ) : (
                        getUpsellName(participant.upsells?.diet) || (
                          <span className="placeholder-text text-danger">
                            <FormattedString id={getString("notSelected")} />
                          </span>
                        )
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="value-key">
                      <FormattedString id={getString("insurance")} />
                    </span>{" "}
                    <span
                      className={`editable-value ${
                        isDifferent(
                          getUpsellName(participant.upsells?.insurance),
                          applicationData.participants[index].insuranceName,
                        )
                          ? "text-different-new"
                          : ""
                      } ${
                        formik.errors.participants?.[index]?.upsells?.insurance
                          ? "is-invalid"
                          : ""
                      } ${isProgramNotSelected ? "not-editable" : ""}`}
                    >
                      {isProgramNotSelected ? (
                        <span className="placeholder-text text-danger">
                          <FormattedString id={getString("notSelected")} />
                        </span>
                      ) : (
                        getUpsellName(participant.upsells?.insurance) || (
                          <span className="placeholder-text text-danger">
                            <FormattedString id={getString("notSelected")} />
                          </span>
                        )
                      )}
                    </span>
                  </li>
                  {participant.upsells?.upsells?.map((upsellId) => (
                    <li key={upsellId}>
                      <span className="value-key">
                        <FormattedString id={getString("other")} />
                      </span>{" "}
                      <span
                        className={`editable-value ${
                          isDifferent(
                            getUpsellName(upsellId),
                            applicationData.participants[index].upsells?.find(
                              (u) => u.id === upsellId,
                            )?.name,
                          )
                            ? "text-different-new"
                            : ""
                        } ${isProgramNotSelected ? "not-editable" : ""}`}
                      >
                        {isProgramNotSelected ? (
                          <span className="placeholder-text text-danger">
                            <FormattedString id={getString("notSelected")} />
                          </span>
                        ) : (
                          getUpsellName(upsellId) || (
                            <span className="placeholder-text text-danger">
                              <FormattedString id={getString("notSelected")} />
                            </span>
                          )
                        )}
                      </span>
                    </li>
                  ))}
                </ul>
              </ul>
            </li>
          );
        })}
      </ol>
    </Col>
  );
};

export default NewProgram;
