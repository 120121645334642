import React, { useState } from "react";
import { Form, Card, CardBody, FormText, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import FormDateField from "Components/Entity/FormDateField";

import { getString } from "Components/Common/FormattedString";
import { toast_success, toast_error } from "helpers/toast_helper";
import { markAsPaid } from "helpers/API/core-service/cs_backend_helper";
import { format } from "date-fns";
import { externalizeDate } from "helpers/utils";
import { FormSelect } from "Components/Entity/FormSelect";

const MarkAsPaidOffcanvas = ({
  isOpen,
  toggle,
  selectedInstallment,
  applicationId,
  updatePaymentStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const today = format(new Date(), "yyyy-MM-dd");

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      paymentDate: today,
      pointOfSell: "manual_bank_transfer",
    },
    validationSchema: Yup.object({
      paymentDate: Yup.string().required(getString("select_payment_date")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      markAsPaid(applicationId, selectedInstallment.id, {
        paymentDate: externalizeDate(values.paymentDate),
      })
        .then(() => {
          toast_success(getString("payment_marked_as_paid"));
          toggle();
          updatePaymentStatus(selectedInstallment.id, values.paymentDate);
        })
        .catch((error) => {
          console.error("Error marking payment as paid:", error);
          toast_error(getString("error_marking_payment_as_paid"));
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <OffcanvasRight
      isOpen={isOpen}
      toggle={toggle}
      title={getString("mark_as_paid")}
      formId="mark-as-paid-form"
      validationRule={!!validation.values.paymentDate}
      buttonLabel={getString("confirm")}
      loading={loading}
      onSubmit={validation.handleSubmit}
    >
      <Form
        id="mark-as-paid-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            <FormGroup>
              <FormSelect
                name="pointOfSell"
                label={getString("point_of_sell")}
                defaultValue={{
                  value: "manual_bank_transfer",
                  label: getString("manual_bank_transfer"),
                }}
                value={{
                  value: "manual_bank_transfer",
                  label: getString("manual_bank_transfer"),
                }}
                isDisabled={true}
                options={[
                  {
                    value: "manual_bank_transfer",
                    label: getString("manual_bank_transfer"),
                  },
                ]}
              />
            </FormGroup>
            <FormDateField
              label={getString("payment_date")}
              name="paymentDate"
              value={validation.values.paymentDate}
              onChange={validation.handleChange}
              error={validation.errors.paymentDate}
              touched={validation.touched.paymentDate}
            />
            <FormText
              color="muted"
              className="text-center mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: "center",
              }}
            >
              <i className="ri-error-warning-line"></i>{" "}
              {getString("make_sure_you_entered_proper_date")}
            </FormText>
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default MarkAsPaidOffcanvas;
