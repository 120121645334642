import "./CustomerDetails.scss";

import { useLocation, useParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";

import { Container, TabContent } from "reactstrap";
import { CustomerHeader } from "./partials/CustomerHeader";
import { CustomerTabSwitcher } from "./partials/CustomerTabSwitcher";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import {
  getCustomer,
  getCustomerApplications,
  getCustomerParticipants,
} from "helpers/API/core-service/cs_backend_helper";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { CustomerOverviewTab } from "./partials/Tabs/CustomerOverviewTab/CustomerOverviewTab";
import { CustomerParticipantsTab } from "./partials/Tabs/CustomerParticipantsTab/CustomerParticipantsTab";
import { programStatus } from "models/programStatuses";

export const CustomerDetailsContext = createContext();

export const CustomerDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const hash = location.hash.substring(1);

  const [customerData, setCustomerData] = useState();
  const [customerApplications, setCustomerApplications] = useState([]);
  const [customerParticipants, setCustomerParticipants] = useState([]);
  const [customerApplicationsLoading, setCustomerApplicationsLoading] =
    useState(true);

  const [loading, setLoading] = useState(true);
  const [customerErrors, setCustomerErrors] = useState();

  const [activeTab, setActiveTab] = useState(hash || "overview");
  const refreshCustomerData = () => {
    getCustomer(id).then((r) => {
      setCustomerData(r);
      document.title =
        r.firstName +
        " " +
        r.lastName +
        " | " +
        "Client details " +
        " | Angloville - CRM Panel";

      setLoading(false);
    });
  };

  const refreshCustomerApplications = (page = 1) => {
    setCustomerApplicationsLoading(true);
    getCustomerApplications(id, page)
      .then((r) => {
        setCustomerApplications(r);
        setCustomerApplicationsLoading(false);
      })
      .catch(() => {
        setCustomerApplicationsLoading(false);
      });
  };

  const refreshCustomerParticipants = () => {
    getCustomerParticipants(id).then((r) => {
      setCustomerParticipants(r);
    });
  };

  const canUpdateCustomer =
    useHasPermission("CUSTOMER_UPDATE") &&
    customerData?.status === programStatus.in_preparation;

  useEffect(() => {
    refreshCustomerData();
    refreshCustomerApplications();
    refreshCustomerParticipants();
  }, [id]);

  return loading === false ? (
    <>
      <div className="page-content customer-details">
        <Container fluid className="mb-4">
          <CustomerDetailsContext.Provider
            value={{
              customerData,
              setCustomerData,
              activeTab,
              setActiveTab,
              refreshCustomerData,
              customerErrors,
              setCustomerErrors,
              canUpdateCustomer,
              customerApplications,
              refreshCustomerApplications,
              customerParticipants,
              refreshCustomerParticipants,
              customerApplicationsLoading,
            }}
          >
            <CustomerHeader />
            <CustomerTabSwitcher />
            <TabContent activeTab={activeTab} className="pt-4">
              {activeTab === "overview" && <CustomerOverviewTab />}
              {activeTab === "participants" && <CustomerParticipantsTab />}
            </TabContent>
          </CustomerDetailsContext.Provider>
        </Container>
      </div>
    </>
  ) : (
    <PreloaderWrap h100 />
  );
};
