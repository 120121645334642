import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RecentSearches from "./RecentSearches";
import PagesList from "./PagesList";
import { useSearch } from "../SearchProvider";
import "./SearchDropdown.scss";
import CustomersList from "./CustomersList";
import ApplicationsList from "./ApplicationsList";
import ProgramsList from "./ProgramsList";
import { FormattedString } from "Components/Common/FormattedString";
import IpApplicationsList from "./IpApplicationsList";
import IpParticipantsList from "./IpParticipantsList";

const SearchDropdown = () => {
  const {
    dropdownRef,
    searchValue,
    closeDropdown,
    recentSearches,
    setRecentSearches,
    setSearchValue,
  } = useSearch();
  const navigate = useNavigate();

  const handleLinkClick = (url) => {
    if (searchValue) {
      if (!recentSearches.includes(searchValue)) {
        setRecentSearches([...recentSearches, searchValue]);
      }
      setSearchValue("");
    }
    closeDropdown(dropdownRef.current);
    navigate(url);
  };

  return (
    <div
      className="search-dropdown dropdown-menu dropdown-menu-lg"
      id="search-dropdown"
      ref={dropdownRef}
    >
      {(recentSearches.length > 0 || searchValue) && (
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <RecentSearches />
          {searchValue && (
            <>
              <PagesList />
              <ProgramsList />
              <ApplicationsList />
              <CustomersList />
              <IpApplicationsList />
              <IpParticipantsList />
            </>
          )}
        </div>
      )}
      {searchValue && (
        <>
          <hr style={{ margin: "0" }} />
          <div className="text-center search-dropdown__view-all">
            <Link
              to={`/search?searchTerm=${encodeURIComponent(searchValue)}`}
              onClick={() =>
                handleLinkClick(
                  `/search?searchTerm=${encodeURIComponent(searchValue)}`,
                )
              }
            >
              <FormattedString id="view_all_results" />{" "}
              <i className="ri-arrow-right-line ms-1"></i>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchDropdown;
