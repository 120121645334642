import { FormattedString } from "Components/Common/FormattedString";
import React from "react";

export const ApplicationInstallmentsDifference = ({ oldApp, newApp }) => {
  const oldInstallments = oldApp.applicationInstallments || [];
  const newInstallments = newApp.applicationInstallments || [];

  const getChangedValues = (oldInstallment, newInstallment) => {
    const changes = {};
    for (const key in newInstallment) {
      if (
        key === "type" &&
        oldInstallment[key]?.name === newInstallment[key]?.name
      ) {
        continue;
      }
      if (
        newInstallment[key] !== oldInstallment[key] &&
        !["id", "pointOfSellId"].includes(key)
      ) {
        changes[key] = {
          oldValue: oldInstallment[key],
          newValue: newInstallment[key],
        };
      }
    }
    return changes;
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const formatValue = (key, value) => {
    if (key === "dueDate") {
      return formatDate(value);
    }
    if (key === "type" && value && value.name) {
      return value.name;
    }
    return value;
  };

  const updatedInstallments = oldInstallments
    .map((oldInstallment, index) => {
      const newInstallment = newInstallments[index];
      if (newInstallment) {
        return {
          id: oldInstallment.id,
          changes: getChangedValues(oldInstallment, newInstallment),
        };
      }
      return null;
    })
    .filter((installment) => installment !== null);

  const hasInstallments =
    oldInstallments.length > 0 && newInstallments.length > 0;
  const hasNewInstallments = newInstallments.length > oldInstallments.length;

  return (
    <li className="list-group-item">
      {hasInstallments ? (
        <>
          <FormattedString id="updated_installments" />
          <ul>
            {updatedInstallments.map((installment, index) => (
              <li key={index}>
                <FormattedString
                  id="installment"
                  values={{ number: index + 1 }}
                />
                {Object.keys(installment.changes).map((key, idx) => (
                  <span key={idx}>
                    {" "}
                    <FormattedString id={key} />:{" "}
                    <strong>
                      {formatValue(key, installment.changes[key].oldValue)}
                    </strong>{" "}
                    <FormattedString id="to" />{" "}
                    <strong>
                      {formatValue(key, installment.changes[key].newValue)}
                    </strong>
                    {idx < Object.keys(installment.changes).length - 1
                      ? ","
                      : ""}
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </>
      ) : hasNewInstallments ? (
        <>
          <FormattedString id="added_new_installments" />
          <ul>
            {newInstallments
              .slice(oldInstallments.length)
              .map((installment, index) => (
                <li key={index}>
                  <FormattedString
                    id="installment"
                    values={{ number: index + 1 }}
                  />
                  {Object.keys(installment).map(
                    (key, idx) =>
                      !["id", "pointOfSellId"].includes(key) && (
                        <span key={idx}>
                          {" "}
                          <FormattedString id={key} />:{" "}
                          <strong>{formatValue(key, installment[key])}</strong>
                          {idx < Object.keys(installment).length - 1 ? "," : ""}
                        </span>
                      ),
                  )}
                </li>
              ))}
          </ul>
        </>
      ) : (
        <strong>
          <FormattedString id="no_installments_to_display" />
        </strong>
      )}
    </li>
  );
};

export const PayerDetailsDifference = ({ oldApp, newApp }) => {
  const oldPayerDetails = oldApp.payerDetails || {};
  const newPayerDetails = newApp.payerDetails || {};

  const getChangedValues = (oldDetails, newDetails) => {
    const changes = {};
    for (const key in newDetails) {
      if (
        newDetails[key] !== oldDetails[key] &&
        !["id", "customId", "customerId"].includes(key)
      ) {
        changes[key] = {
          oldValue: oldDetails[key],
          newValue: newDetails[key],
        };
      }
    }
    return changes;
  };

  const updatedPayerDetails = {
    firstName: newPayerDetails.firstName,
    lastName: newPayerDetails.lastName,
    changes: getChangedValues(oldPayerDetails, newPayerDetails),
  };

  const hasOldPayerDetails = Object.keys(oldPayerDetails).length > 0;
  const hasNewPayerDetails = Object.keys(newPayerDetails).length > 0;

  const formatValue = (value) => {
    if (value && typeof value === "object" && value.name) {
      return value.name;
    }
    return value ? value.toString() : "";
  };

  return (
    <li className="list-group-item">
      {hasOldPayerDetails && hasNewPayerDetails ? (
        <>
          <FormattedString id="updated_payer_details" />
          <ul>
            <li>
              {updatedPayerDetails.firstName} {updatedPayerDetails.lastName}:
              {Object.keys(updatedPayerDetails.changes).map((key, idx) => (
                <span key={idx}>
                  {" "}
                  <FormattedString id={key} />:{" "}
                  <strong>
                    {formatValue(updatedPayerDetails.changes[key].oldValue)}
                  </strong>{" "}
                  <FormattedString id="to" />{" "}
                  <strong>
                    {formatValue(updatedPayerDetails.changes[key].newValue)}
                  </strong>
                  {idx < Object.keys(updatedPayerDetails.changes).length - 1
                    ? ","
                    : ""}
                </span>
              ))}
            </li>
          </ul>
        </>
      ) : hasNewPayerDetails ? (
        <>
          <FormattedString id="added_new_payer_details" />
          <ul>
            <li>
              {newPayerDetails.firstName} {newPayerDetails.lastName}:
              {Object.keys(newPayerDetails).map(
                (key, idx) =>
                  ![
                    "id",
                    "customId",
                    "customerId",
                    "firstName",
                    "lastName",
                  ].includes(key) && (
                    <span key={idx}>
                      {" "}
                      <FormattedString id={key} />:{" "}
                      <strong>{formatValue(newPayerDetails[key])}</strong>
                      {idx < Object.keys(newPayerDetails).length - 1 ? "," : ""}
                    </span>
                  ),
              )}
            </li>
          </ul>
        </>
      ) : (
        <strong>
          <FormattedString id="no_payer_details_to_display" />
        </strong>
      )}
    </li>
  );
};

export const EntityNotesDifference = ({ difference }) => {
  const note = difference.item.rhs;

  return (
    <li className="list-group-item">
      <FormattedString id="added_new" /> <FormattedString id="entity_note" />:{" "}
      <strong>{note.name}</strong> - {note.content}
    </li>
  );
};

export const ApplicationInvitesDifference = ({ difference }) => {
  const invite = difference?.item?.rhs || {};
  const statusNameDiff = difference?.status?.name;
  const statusValueDiff = difference?.status?.value;

  const statusNameChange = statusNameDiff
    ? `${statusNameDiff.lhs} to ${statusNameDiff.rhs}`
    : invite.status?.name;

  const statusValueChange = statusValueDiff
    ? `${statusValueDiff.lhs} to ${statusValueDiff.rhs}`
    : invite.status?.value;

  return (
    <li className="list-group-item">
      <FormattedString id="updated" />{" "}
      <FormattedString id="application_invites" />:{" "}
      <strong>{invite.invitedEmail || "N/A"}</strong> -{" "}
      <FormattedString id={statusNameChange || "N/A"} /> (
      <FormattedString id={statusValueChange || "N/A"} />)
    </li>
  );
};

export const MeetingPointDifference = ({ difference }) => {
  let changeType = "changed";
  let details;

  const lhsCity = difference.lhs?.city;
  const rhsCity = difference.rhs?.city;

  if (lhsCity && rhsCity) {
    details = (
      <>
        <FormattedString id="from" /> <strong>{lhsCity}</strong>{" "}
        <FormattedString id="to" /> <strong>{rhsCity}</strong>
      </>
    );
  } else {
    changeType = "added_new";
    details = <strong>{rhsCity || lhsCity}</strong>;
  }

  return (
    <li className="list-group-item">
      <FormattedString id={changeType} /> <FormattedString id="meeting_point" />{" "}
      {details}
    </li>
  );
};

export const ApplicationStatusDifference = ({ difference }) => {
  let changeType = "changed";
  let details;
  if (difference.name.lhs && difference.name.rhs) {
    details = (
      <>
        <FormattedString id="from" /> <strong>{difference.name.lhs}</strong>{" "}
        <FormattedString id="to" /> <strong>{difference.name.rhs}</strong>
      </>
    );
  } else {
    changeType = "added_new";
    details = <strong>{difference.name.rhs || difference.name.lhs}</strong>;
  }

  return (
    <li className="list-group-item">
      <FormattedString id={changeType} /> <FormattedString id="status" />{" "}
      {details}
    </li>
  );
};

export const ParticipantsDifference = ({ oldApp, newApp }) => {
  const oldParticipants = oldApp.participants || [];
  const newParticipants = newApp.participants || [];

  const getChangedValues = (oldParticipant, newParticipant) => {
    const changes = {};
    for (const key in newParticipant) {
      if (
        newParticipant[key] !== oldParticipant[key] &&
        !["insuranceId", "dietId", "participantId", "programPacketId"].includes(
          key,
        )
      ) {
        changes[key] = {
          oldValue: oldParticipant[key],
          newValue: newParticipant[key],
        };
      }
    }
    return changes;
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const formatValue = (key, value) => {
    if (key === "dateOfBirth") {
      return formatDate(value);
    }
    if (key === "upsells") {
      return value?.map((upsell) => upsell.name).join(", ");
    }
    return JSON.stringify(value);
  };

  const updatedParticipants = oldParticipants
    .map((oldParticipant, index) => {
      const newParticipant = newParticipants[index];
      if (newParticipant) {
        return {
          firstName: oldParticipant.firstName,
          lastName: oldParticipant.lastName,
          changes: getChangedValues(oldParticipant, newParticipant),
        };
      }
      return null;
    })
    .filter((participant) => participant !== null);

  const hasParticipants =
    oldParticipants.length > 0 && newParticipants.length > 0;
  const hasNewParticipants = newParticipants.length > oldParticipants.length;

  return (
    <li className="list-group-item">
      {hasParticipants ? (
        <>
          <FormattedString id="updated_participants" />
          <ul>
            {updatedParticipants.map((participant, index) => (
              <li key={index}>
                {participant.firstName} {participant.lastName}:
                {Object.keys(participant.changes).map((key, idx) => (
                  <span key={idx}>
                    {" "}
                    <FormattedString id={key} />:{" "}
                    <strong>
                      {formatValue(key, participant.changes[key].oldValue)}
                    </strong>{" "}
                    <FormattedString id="to" />{" "}
                    <strong>
                      {formatValue(key, participant.changes[key].newValue)}
                    </strong>
                    {idx < Object.keys(participant.changes).length - 1
                      ? ","
                      : ""}
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </>
      ) : hasNewParticipants ? (
        <>
          <FormattedString id="added_new_participants" />
          <ul>
            {newParticipants
              .slice(oldParticipants.length)
              .map((participant, index) => (
                <li key={index}>
                  {participant.firstName} {participant.lastName}:
                  {Object.keys(participant).map(
                    (key, idx) =>
                      ![
                        "insuranceId",
                        "dietId",
                        "participantId",
                        "programPacketId",
                        "firstName",
                        "lastName",
                      ].includes(key) && (
                        <span key={idx}>
                          {" "}
                          <FormattedString id={key} />:{" "}
                          <strong>{formatValue(key, participant[key])}</strong>
                          {idx < Object.keys(participant).length - 1 ? "," : ""}
                        </span>
                      ),
                  )}
                </li>
              ))}
          </ul>
        </>
      ) : (
        <strong>
          <FormattedString id="no_participants_to_display" />
        </strong>
      )}
    </li>
  );
};

export const DiscountsDifference = ({ difference }) => {
  const oldDiscounts = [];
  const newDiscounts = [];

  if (Array.isArray(difference)) {
    difference.forEach((diff, index) => {
      const discountIndex = index;
      if (!oldDiscounts[discountIndex]) {
        oldDiscounts[discountIndex] = {};
      }
      if (!newDiscounts[discountIndex]) {
        newDiscounts[discountIndex] = {};
      }

      Object.keys(diff).forEach((key) => {
        if (key !== "path" && key !== "kind") {
          if (diff[key].kind === "E") {
            oldDiscounts[discountIndex][key] = diff[key].lhs;
            newDiscounts[discountIndex][key] = diff[key].rhs;
          } else if (diff[key].kind === "N") {
            newDiscounts[discountIndex][key] = diff[key].rhs;
          }
        }
      });
    });
  } else {
    const { amount, description } = difference.item.rhs;
    return (
      <li className="list-group-item">
        <FormattedString id="added_new" /> <FormattedString id="discount" />{" "}
        <strong>{amount}</strong> {description}
      </li>
    );
  }

  const oldDiscountsValues = oldDiscounts
    .map((discount) =>
      Object.entries(discount)
        .filter(
          ([key, value]) =>
            key !== "id" && value !== 0 && typeof value !== "object",
        )
        .map(([_key, value]) => value)
        .join(", "),
    )
    .join(", ");

  const newDiscountsValues = newDiscounts
    .map((discount) =>
      Object.entries(discount)
        .filter(
          ([key, value]) =>
            key !== "id" && value !== 0 && typeof value !== "object",
        )
        .map(([_key, value]) => value)
        .join("; "),
    )
    .join("; ");

  return (
    <li className="list-group-item">
      {oldDiscountsValues.length === 0 ? (
        <>
          <FormattedString id="added_new" /> <FormattedString id="discount" />{" "}
          <strong>{newDiscountsValues}</strong>
        </>
      ) : (
        <>
          <FormattedString id="updated" /> <FormattedString id="discounts" />:{" "}
          <FormattedString id="from" /> {oldDiscountsValues}{" "}
          <FormattedString id="to" /> <strong>{newDiscountsValues}</strong>
        </>
      )}
    </li>
  );
};
