// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-move-step-nav .btn {
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.application-move-step-nav .btn.btn-primary {
  background-color: #1f4f78;
}
.application-move-step-nav .btn.btn-primary:hover {
  background-color: #163a5a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.application-move-step-nav .btn.btn-secondary {
  background-color: #2a7f99;
}
.application-move-step-nav .btn.btn-secondary:hover {
  background-color: #1f5f73;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.application-move-step-nav .btn.bg-danger {
  background-color: #b52b27;
}
.application-move-step-nav .btn.bg-danger:hover {
  background-color: #8a1f1c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationMoveForm/components/ApplicationMoveStepNavigation/ApplicationMoveStepNavigation.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,wCAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAEM;EACE,yBAAA;EACA,wCAAA;AAAR;AAII;EACE,yBAAA;AAFN;AAIM;EACE,yBAAA;EACA,wCAAA;AAFR;AAMI;EACE,yBAAA;AAJN;AAMM;EACE,yBAAA;EACA,wCAAA;AAJR","sourcesContent":[".application-move-step-nav {\n  .btn {\n    margin-bottom: 10px;\n    border: none;\n    border-radius: 8px;\n    color: white;\n    font-weight: bold;\n    transition: all 0.3s ease;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n\n    &.btn-primary {\n      background-color: #1f4f78;\n\n      &:hover {\n        background-color: #163a5a;\n        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n      }\n    }\n\n    &.btn-secondary {\n      background-color: #2a7f99;\n\n      &:hover {\n        background-color: #1f5f73;\n        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n      }\n    }\n\n    &.bg-danger {\n      background-color: #b52b27;\n\n      &:hover {\n        background-color: #8a1f1c;\n        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
