import logoBg from "assets/images/logo.svg";
import { Col, Row, UncontrolledAlert } from "reactstrap";
import { ProgramDetailsContext } from "../ProgramDetails";
import { useContext } from "react";
import { formatDate } from "helpers/utils";
import graduation from "assets/images/icons/ri-graduation-cap-line.svg";
import { FormattedString } from "Components/Common/FormattedString";

export const ProgramHeader = () => {
  const { programData } = useContext(ProgramDetailsContext);

  const meetingPoints = programData.programMeetingsPP
    .map((meeting) => meeting.meetingPoint)
    .filter((point) => point);

  const participantsFraction = programData.programParticipantsTotalCapacity
    ? Math.round(
        (programData.programParticipantsSum /
          programData.programParticipantsTotalCapacity) *
          100,
      )
    : 0;

  const internationalParticipantsFraction =
    programData.programInternationalParticipantsTotalCapacity
      ? Math.round(
          (programData.programInternationalParticipantsSum /
            programData.programInternationalParticipantsTotalCapacity) *
            100,
        )
      : 0;

  const isValidNumber = (value) => !isNaN(value) && isFinite(value);

  const participantsFractionDisplay = isValidNumber(participantsFraction)
    ? participantsFraction
    : 0;

  const internationalParticipantsFractionDisplay = isValidNumber(
    internationalParticipantsFraction,
  )
    ? internationalParticipantsFraction
    : 0;

  return (
    <div
      className={`program-details__header ${
        programData.status === "archived"
          ? "program-details__header--archived"
          : ""
      }`}
    >
      {programData.status === "archived" && (
        <UncontrolledAlert
          color="warning"
          className="alert-border-left material-shadow"
        >
          <i className="ri-alert-line me-3 align-middle fs-16"></i>
          <FormattedString id="program_archived_alert" />
        </UncontrolledAlert>
      )}
      <div className="profile-foreground position-relative mx-n4 mt-n4">
        <div className="profile-wid-bg">
          <img src={logoBg} alt="program-bg" className="profile-wid-img" />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="avatar-lg">
              <img
                src={graduation}
                alt="graduation-cap"
                className="program-details__header__thumb"
              />
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="program-details__header__top">
                <div className="program-details__header__top__left">
                  <h3 className="program-details__header__top__left__name">
                    <span className="program-details__header__top__left__custom-id">
                      #{programData.customId}
                    </span>{" "}
                    | {programData.name}
                  </h3>
                  <p className="program-details__header__top__left__paragraph">
                    {programData.season}
                  </p>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="hstack text-white-50 gap-1">
                    {programData.hotel && (
                      <div className="me-2">
                        <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {programData.hotel?.name}
                      </div>
                    )}
                    {programData.startDate && (
                      <div>
                        <i className="ri-calendar-2-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {formatDate(programData.startDate)} -{" "}
                        {formatDate(programData.endDate)}
                      </div>
                    )}
                  </div>
                  {meetingPoints.length > 0 && (
                    <div className="hstack text-white-50 gap-1">
                      <div className="me-2">
                        <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {meetingPoints
                          .map((meetingPoint) => meetingPoint?.city)
                          .join(" / ")}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <Row className="text text-white-50 text-center">
              <Col lg={6} xs={4}>
                <div className="p-2">
                  <h4 className="text-white mb-1">
                    {participantsFractionDisplay}%
                  </h4>
                  <p className="fs-14 mb-0">Participants</p>
                </div>
              </Col>
              <Col lg={6} xs={4}>
                <div className="p-2">
                  <h4 className="text-white mb-1">
                    {internationalParticipantsFractionDisplay}%
                  </h4>
                  <p className="fs-14 mb-0">International participants</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
