// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-short {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 24px;
}
.program-short__hotel {
  color: black;
  font-size: 12px;
  font-weight: 700;
}
.program-short__hotel a {
  color: rgb(56, 151, 221) !important;
}
.program-short__meeting {
  color: black;
  font-size: 12px;
  font-weight: 700;
}
.program-short__meeting--from {
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
.program-short__dates {
  margin-top: 2px;
  color: black;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
.program-short__dates--bold {
  font-weight: 700;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationOverviewTab/partials/FormSummary/components/ProgramShort/ProgramShort.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAAI;EACE,mCAAA;AAEN;AAEE;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAAN;AAIE;EACE,eAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAII;EACE,gBAAA;EACA,eAAA;AAFN","sourcesContent":[".program-short {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 14px;\n  background-color: white;\n  border-radius: 20px;\n  margin-bottom: 24px;\n\n  &__hotel {\n    color: black;\n    font-size: 12px;\n    font-weight: 700;\n    a {\n      color: rgb(56, 151, 221) !important;\n    }\n  }\n\n  &__meeting {\n    color: black;\n    font-size: 12px;\n    font-weight: 700;\n\n    &--from {\n      font-size: 10px;\n      font-weight: 400;\n      text-align: center;\n    }\n  }\n\n  &__dates {\n    margin-top: 2px;\n    color: black;\n    font-size: 10px;\n    font-weight: 400;\n    text-align: center;\n\n    &--bold {\n      font-weight: 700;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
