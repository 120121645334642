import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIpApplicationsListData } from "../../store/ipApplications/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import { format } from "date-fns";
import "./IpApplicationsList.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { AssignIpCanvas } from "./modals/AssignIpCanvas/AssignIpCanvas";
import { ChangeIpApplicationStatusCanvas } from "./modals/ChangeIpApplicationStatusCanvas/ChangeIpApplicationStatusCanvas";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import InternalCommentCell from "./cells/InternalCommentCell/InternalCommentCell";

const IpApplicationsList = ({
  assignIpCanvasVisible,
  setAssignIpCanvasVisible,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);
  const [changeStatusCanvasVisible, setChangeStatusCanvasVisible] =
    useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const {
    ipApplicationsData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.IpApplications);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(getIpApplicationsListData(pageNumber, globalFilter));
  }, [searchParams]);

  const toggleChangeStatusModal = (application) => {
    setSelectedApplication(application);
    setChangeStatusCanvasVisible(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("program"),
        accessor: "programName",
        Cell: ({ row }) => (
          <Link
            to={`/ip-participants/${row.original.internationalParticipantId}`}
            className="d-flex align-items-center gap-1"
          >
            <i className="ri-information-line"></i>
            {row.original.programName || "-"}
          </Link>
          // <Link
          //   to={`/ip-applications/${row.original.id}`}
          //   className="d-flex align-items-center gap-1"
          // >
          //   <i className="ri-information-line"></i>
          //   {row.original.programName || "-"}
          // </Link>
        ),
      },
      {
        Header: getString("ip_name"),
        accessor: (d) => (
          <div
            className="d-flex align-items-center gap-1"
            style={{ color: "#6c757d" }}
          >
            <Link to={`/ip-participants/${d.internationalParticipantId}`}>
              <i className="ri-user-line"></i>
            </Link>
            <span className="text-decoration-none">
              {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
            </span>
          </div>
        ),
      },
      {
        Header: getString("package_name"),
        accessor: "programPacketName",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => (
          <span className="badge">
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value || "N/A"}</span>,
      },
      {
        Header: getString("program_dates"),
        accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
              new Date(row.original.programEndDate),
              "yyyy-MM-dd",
            )}`}
          </div>
        ),
      },
      {
        Header: getString("program_status"),
        accessor: "programStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        id: "internal_comment",
        Header: getString("internal_comment"),
        accessor: (d) => (
          <InternalCommentCell
            internalComment={d.internalComment}
            entityId={d.id}
            status={d.applicationStatus}
          />
        ),
        disableSortBy: true,
      },
      {
        id: "Actions",
        Header: () => (
          <div style={{ textAlign: "right" }}>
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        Cell: ({ row }) => (
          <WithPermission permissionName="IP_APPLICATION_UPDATE">
            <div className="d-flex gap-2">
              <AngloTooltip stringId="change_status">
                <button
                  type="button"
                  className="btn btn-sm btn-soft-secondary btn-icon"
                  onClick={() => toggleChangeStatusModal(row.original)}
                >
                  <i className="ri-exchange-line"></i>
                </button>
              </AngloTooltip>
              <AngloTooltip stringId="show_details">
                <button
                  type="button"
                  className="btn btn-sm btn-soft-primary btn-icon"
                  onClick={() =>
                    navigate(`/ip-applications/${row.original.id}`)
                  }
                >
                  <i className="ri-eye-line"></i>
                </button>
              </AngloTooltip>
            </div>
          </WithPermission>
        ),
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="ip-applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={ipApplicationsData || []}
        isGlobalFilter={true}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_applications_search_for")}
        error={error}
        onRetry={() =>
          dispatch(getIpApplicationsListData(pageNumber, globalFilter))
        }
      />
      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
          refreshData={() =>
            dispatch(getIpApplicationsListData(pageNumber, globalFilter))
          }
        />
      )}
      {changeStatusCanvasVisible && (
        <ChangeIpApplicationStatusCanvas
          visible={changeStatusCanvasVisible}
          setVisible={setChangeStatusCanvasVisible}
          application={selectedApplication}
          refreshData={() =>
            dispatch(getIpApplicationsListData(pageNumber, globalFilter))
          }
        />
      )}
    </div>
  );
};

export { IpApplicationsList };
