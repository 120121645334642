import React from "react";
import {
  ApplicationInstallmentsDifference,
  ApplicationInvitesDifference,
  ApplicationStatusDifference,
  DiscountsDifference,
  EntityNotesDifference,
  MeetingPointDifference,
  ParticipantsDifference,
  PayerDetailsDifference,
} from "./partials/DifferenceComponents";

import { diff } from "deep-diff";
import set from "lodash/set";
import { FormattedString } from "Components/Common/FormattedString";

const generateDiffApp = (oldApp, newApp) => {
  const differences = diff(oldApp, newApp);
  const diffApp = {};

  if (!differences) {
    return diffApp;
  }

  differences.forEach((difference) => {
    if (!difference.path || difference.path.length === 0) {
      return;
    }
    const path = difference.path.join(".");
    set(diffApp, path, difference);
  });

  return diffApp;
};

export const generateChangesList = (oldApp, newApp) => {
  const diffApp = generateDiffApp(oldApp, newApp);

  const items = Object.keys(diffApp).map((rootKey, index) => {
    let content;

    switch (rootKey) {
      case "status":
        content = (
          <ApplicationStatusDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "entityNotes":
        content = (
          <EntityNotesDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "applicationInvites":
        content = (
          <ApplicationInvitesDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "applicationInstallments":
        content = (
          <ApplicationInstallmentsDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "meetingPoint":
        content = (
          <MeetingPointDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "payerDetails":
        content = (
          <PayerDetailsDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "participants":
        content = (
          <ParticipantsDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      case "discounts":
        content = (
          <DiscountsDifference
            key={index}
            changeType="Changed"
            difference={diffApp[rootKey]}
            oldApp={oldApp}
            newApp={newApp}
          />
        );
        break;
      default:
        content = (
          <li className="list-group-item" key={index}>
            <FormattedString id="updated" /> {rootKey}:{" "}
            {JSON.stringify(diffApp[rootKey])}
          </li>
        );
        break;
    }

    return content;
  });

  return items;
};
