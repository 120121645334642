import { parseApplicationErrorToFormikErrors } from "./parseApplicationErrorToFormikErrors";
import mapFormikValuesToDTO from "./mapFormikValuesToDTO";
import { toast_success } from "helpers/toast_helper";
import { updateApplicationState } from "helpers/API/core-service/cs_backend_helper";
import { isObjectEmpty } from "helpers/utils";
import { getString } from "Components/Common/FormattedString";

export const handleSave = async ({
  currentStep,
  formik,
  applicationData,
  setSubmitting,
  refreshApplicationData,
  setEditTileOpen,
  validateStep,
  setCurrentStep,
}) => {
  if (currentStep === 4) {
    await handleConfirmSave({
      currentStep,
      formik,
      applicationData,
      setSubmitting,
      refreshApplicationData,
      setEditTileOpen,
      validateStep,
    });
  } else {
    setSubmitting(true);
    const errors = await validateStep();
    if (isObjectEmpty(errors)) {
      const dto = mapFormikValuesToDTO(formik.values, currentStep);
      try {
        const response = await updateApplicationState(applicationData.id, dto);
        if (response.recalculated === true) {
          setCurrentStep(4);
        } else {
          toast_success(getString("application_updated_successfully"));
          refreshApplicationData();
          setEditTileOpen(false);
        }
      } catch (error) {
        parseApplicationErrorToFormikErrors(error.data, formik);
      } finally {
        setSubmitting(false);
      }
    } else {
      formik.setErrors(errors);
      setSubmitting(false);
    }
  }
};

export const handleConfirmSave = async ({
  currentStep,
  formik,
  applicationData,
  setSubmitting,
  refreshApplicationData,
  setEditTileOpen,
  validateStep,
}) => {
  setSubmitting(true);
  const errors = await validateStep();
  if (isObjectEmpty(errors)) {
    const dto = mapFormikValuesToDTO(formik.values, currentStep);
    try {
      await updateApplicationState(applicationData.id, dto);
      toast_success(getString("application_updated_successfully"));
      refreshApplicationData();
      setEditTileOpen(false);
    } catch (error) {
      parseApplicationErrorToFormikErrors(error.data, formik);
    } finally {
      setSubmitting(false);
    }
  } else {
    setSubmitting(false);
  }
};

export const calculateRemainingPrice = (formik, maxInstallmentSum, index) => {
  return maxInstallmentSum !== 0
    ? maxInstallmentSum -
        formik.values.installments.reduce((sum, inst, i) => {
          return index !== undefined && i === index ? sum : sum + inst.price;
        }, 0)
    : undefined;
};
