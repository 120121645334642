import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import TableContainer from "Components/Common/TableContainerReactTable";
import { getProgramListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { FormattedString, getString } from "Components/Common/FormattedString";
import FormattedDate from "Components/Common/FormattedDate/FormattedDate";
import { Badge, GenericBadge } from "Components/Common/Badges/Badges";

const ProgramsList = () => {
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("searchTerm") || "";

  const fetchPrograms = useCallback(
    debounce(() => {
      setLoading(true);
      getProgramListPromise()
        .then((response) => {
          setPrograms(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_programs"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  useEffect(() => {
    const newPrograms = [...programs];
    const filtered = newPrograms.filter((program) =>
      program.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredPrograms(filtered);
  }, [programs, searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: getString("customId"),
        accessor: "customId",
        Cell: ({ row, value }) => (
          <Link to={`/program/${row.original.id}`}>#{value}</Link>
        ),
      },
      {
        Header: getString("name"),
        accessor: "name",
        Cell: (cellProps) => (
          <Link to={`/program/${cellProps.row.original.id}`}>
            {cellProps.value}
          </Link>
        ),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: ({ value }) => <Badge>{value}</Badge>,
      },
      {
        Header: getString("startDate"),
        accessor: "startDate",
        Cell: ({ value }) => (
          <GenericBadge>
            <FormattedDate dateString={value} />
          </GenericBadge>
        ),
      },
      {
        Header: getString("endDate"),
        accessor: "endDate",
        Cell: ({ value }) => (
          <GenericBadge>
            <FormattedDate dateString={value} />
          </GenericBadge>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value ? value : "N/A"}</span>,
      },
    ],
    [],
  );

  return (
    <Card>
      <CardHeader>
        <h5 style={{ marginBottom: 0 }}>
          <FormattedString id="programs" />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <TableContainer
            key={searchQuery}
            columns={columns || []}
            data={filteredPrograms || []}
            isPagination={true}
            isGlobalFilter={false}
            isBordered={false}
            customPageSize={10}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            SearchPlaceholder={getString("programs_search_for")}
            error={null}
            loading={loading}
            onRetry={() => fetchPrograms()}
            excludeUrlHandling
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ProgramsList;
