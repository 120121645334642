import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { internalizeDate, isObjectEmpty, splitNumbers } from "helpers/utils";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import FormContext from "./utils/FormContext";
import { validate } from "./utils/validation";
import { handleSave } from "./utils/utils";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import confirm_img from "assets/imagesn/confirm.gif";
import {
  STEP_INSTALLMENTS,
  STEP_PARTICIPANTS,
  STEP_PAYER_DETAILS,
  STEP_PROGRAM_MEETING,
} from "./utils/stepsConfig";
import { PayerTypes } from "models/payerTypes";

export const ApplicationFormProvider = ({ children }) => {
  const { applicationData, refreshApplicationData, setEditTileOpen } =
    useContext(ApplicationDetailsContext);
  const [parentProgram, setParentProgram] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState(false);
  const [maxInstallmentSum, setMaxInstallmentSum] = useState(
    applicationData.applicationInstallments.reduce(
      (sum, installment) => sum + installment.price,
      0,
    ),
  );
  const [installmentsError, setInstallmentsError] = useState(null);

  useEffect(() => {
    getProgram(applicationData.programId).then((res) => {
      setParentProgram(res);
      formik.setFieldValue("program", res.customId);

      const matchingMeeting = res.programMeetingsPP.find(
        (meeting) =>
          meeting.meetingPoint.id === applicationData?.meetingPoint?.id,
      );
      if (matchingMeeting) {
        formik.setFieldValue("programMeeting", matchingMeeting.id);
      }
    });
  }, [applicationData.programId]);

  const initialValues = {
    program: "",
    programMeeting: "",
    participants:
      applicationData.participants?.length > 0
        ? applicationData.participants.map((participant) => ({
            gender: participant.gender || "",
            programPacket: participant.programPacketId || "",
            firstName: participant.firstName || "",
            lastName: participant.lastName || "",
            email: participant.email || "",
            phonePrefix: participant.phonePrefix || "",
            phoneNumber: participant.phoneNumber || "",
            dateOfBirth: internalizeDate(participant.dateOfBirth) || "",
            comment: participant.comment || "",
            upsells: {
              diet: participant.dietId || "",
              insurance: participant.insuranceId || "",
              upsells: participant.upsells?.map((upsell) => upsell.id) || [],
            },
          }))
        : [
            {
              gender: "",
              programPacket: "",
              firstName: "",
              lastName: "",
              email: "",
              phonePrefix: "",
              phoneNumber: "",
              dateOfBirth: "",
              comment: "",
              upsells: {
                diet: "",
                insurance: "",
                upsells: [],
              },
            },
          ],
    payerDetails: {
      payerType:
        applicationData.payerDetails?.type === PayerTypes.COMPANY
          ? PayerTypes.COMPANY
          : PayerTypes.PERSON,
      companyName: applicationData.payerDetails?.companyName || "",
      taxNumber: applicationData.payerDetails?.taxNumber || "",
      firstName: applicationData.payerDetails?.firstName || "",
      lastName: applicationData.payerDetails?.lastName || "",
      city: applicationData.payerDetails?.city || "",
      zipCode: applicationData.payerDetails?.zipCode || "",
      address: applicationData.payerDetails?.address || "",
    },
    installments: [],
  };

  const [currentStep, setCurrentStep] = useState(STEP_PARTICIPANTS);

  const formik = useFormik({
    initialValues,
    validate,
  });

  const handlePayerTypeChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue("payerDetails.payerType", value);
    formik.setFieldValue("payerDetails.firstName", "");
    formik.setFieldValue("payerDetails.lastName", "");
    formik.setFieldValue("payerDetails.companyName", "");
    formik.setFieldValue("payerDetails.taxNumber", "");
    formik.validateForm();
  };

  const validateStep = (step = currentStep) => {
    return formik.validateForm().then((errors) => {
      const formattedErrors = {};
      Object.keys(errors).forEach((key) => {
        const keys = key.split(".");
        keys.reduce((acc, part, index) => {
          const arrayMatch = part.match(/^(\w+)\[(\d+)\]$/);
          if (arrayMatch) {
            const [_, arrayName, arrayIndex] = arrayMatch;
            acc[arrayName] = acc[arrayName] || [];
            acc[arrayName][arrayIndex] = acc[arrayName][arrayIndex] || {};
            return acc[arrayName][arrayIndex];
          } else {
            if (index === keys.length - 1) {
              acc[part] = errors[key];
            } else {
              acc[part] = acc[part] || {};
            }
            return acc[part];
          }
        }, formattedErrors);
      });

      switch (step) {
        case STEP_PARTICIPANTS: {
          const participantsErrors = formik.values.participants.map(
            (_, index) => {
              const { upsells, ...rest } =
                formattedErrors.participants?.[index] || {};
              return rest;
            },
          );

          const hasErrors = participantsErrors.some(
            (error) => Object.keys(error).length > 0,
          );

          formik.setErrors({ participants: participantsErrors });
          return hasErrors ? { participants: participantsErrors } : {};
        }
        case STEP_PROGRAM_MEETING: {
          const participantsErrorsStep2 = formik.values.participants.map(
            (_, index) => formattedErrors.participants?.[index] || {},
          );

          const hasErrorsStep2 = participantsErrorsStep2.some(
            (error) => Object.keys(error).length > 0,
          );

          formik.setErrors({
            participants: participantsErrorsStep2,
            programMeeting: formattedErrors.programMeeting,
          });
          return hasErrorsStep2 || formattedErrors.programMeeting
            ? {
                participants: participantsErrorsStep2,
                programMeeting: formattedErrors.programMeeting,
              }
            : {};
        }
        case STEP_PAYER_DETAILS: {
          const payerDetailsErrors = Object.keys(formattedErrors).reduce(
            (acc, key) => {
              if (key.startsWith("payerDetails")) {
                acc[key] = formattedErrors[key];
              }
              return acc;
            },
            {},
          );
          formik.setErrors({ payerDetails: payerDetailsErrors });
          return payerDetailsErrors;
        }
        case STEP_INSTALLMENTS: {
          const installmentsErrors = formattedErrors.installments || {};
          if (isObjectEmpty(installmentsErrors)) {
            const sumOfPrices = formik.values.installments.reduce(
              (sum, installment) => sum + installment.price,
              0,
            );
            if (sumOfPrices !== maxInstallmentSum && maxInstallmentSum !== 0) {
              const currency = formik.values.installments[0]?.currency || "PLN";

              const errorString = `${getString("sum_of_prices")} ${splitNumbers(sumOfPrices)} ${currency}. ${getString("desired_installment_sum")} ${splitNumbers(maxInstallmentSum)} ${currency}.`;

              setInstallmentsError(errorString);
              installmentsErrors.sum = errorString;
            } else {
              setInstallmentsError(null);
            }
          }
          formik.setErrors({ installments: installmentsErrors });
          return installmentsErrors;
        }
        default:
          return Promise.resolve({});
      }
    });
  };

  const handleStepChange = (direction) => {
    if (direction > 0) {
      const targetStep = currentStep + direction;
      const validateSteps = async () => {
        for (let step = currentStep; step < targetStep; step++) {
          const errors = await validateStep(step);
          if (Object.keys(errors).length > 0) {
            formik.setErrors(errors);
            return;
          }
        }
        setCurrentStep(targetStep);
      };
      validateSteps();
    } else {
      setCurrentStep((prevStep) => prevStep + direction);
    }
  };

  const handleModalClose = () => {
    setModal(false);
    // setCurrentStep(STEP_INSTALLMENTS);
  };

  return (
    <FormContext.Provider
      value={{
        setModal,
        formik,
        currentStep,
        setCurrentStep,
        handleStepChange,
        handlePayerTypeChange,
        validateStep,
        parentProgram,
        handleSave: () =>
          handleSave({
            currentStep,
            formik,
            applicationData,
            setSubmitting,
            setModal,
            refreshApplicationData,
            setEditTileOpen,
            validateStep,
            setCurrentStep,
          }),
        submitting,
        maxInstallmentSum,
        setMaxInstallmentSum,
        installmentsError,
        setInstallmentsError,
      }}
    >
      {parentProgram ? children : <PreloaderWrap />}
      <Modal isOpen={modal} toggle={handleModalClose} centered>
        <ModalHeader toggle={handleModalClose}>
          <FormattedString id="update_installment_plans" />
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div>
            <img
              src={confirm_img}
              alt={getString("confirmation")}
              height={250}
              className="error-basic-img move-animation"
            />
          </div>
          <div className="mt-4">
            <h4 className="mb-3">
              <FormattedString id="changes_not_saved" />
            </h4>
            <p>
              <FormattedString id="update_installment_plans_paragraph" />
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" onClick={handleModalClose}>
            <FormattedString id="ok" />
          </Button>
        </ModalFooter>
      </Modal>
    </FormContext.Provider>
  );
};
