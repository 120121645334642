import React, { useContext, useState } from "react";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { toast_error, toast_success } from "helpers/toast_helper";
import NewInvitationForm from "../../Tabs/ApplicationOverviewTab/partials/NewInvitationForm";
import { addApplicationInvitation } from "helpers/API/core-service/cs_backend_helper";

export const ApplicationInvites = () => {
  const {
    applicationData: { id, applicationInvites = [], status },
    refreshApplicationData,
  } = useContext(ApplicationDetailsContext);

  const [showInviteForm, setShowInviteForm] = useState(false);
  const hasInvites = applicationInvites.length > 0;

  const handleSubmitInvitation = (applicationId, email) => {
    return addApplicationInvitation(applicationId, email)
      .then(() => {
        toast_success(getString("invitation_sent"));
        refreshApplicationData();
      })
      .catch(() => {
        toast_error(getString("invitation_requirements_not_met"));
      });
  };

  return (
    <div className="application-invites">
      <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
        {hasInvites ? (
          applicationInvites.map(({ status, invitedEmail }, key) => (
            <li key={key} className="application-invite">
              <div className="d-flex align-items-center gap-1">
                <div className="flex-shrink-0">
                  <AngloTooltip stringId={getString(status)}>
                    {status === "not_sent" && (
                      <i className="ri-question-fill text-danger fs-16 me-2 align-middle"></i>
                    )}
                    {status === "pending" && (
                      <i className="ri-question-fill text-warning fs-16 me-2 align-middle"></i>
                    )}
                    {status === "application_created" && (
                      <i className="ri-alert-fill text-warning fs-16 me-2 align-middle"></i>
                    )}
                    {status === "application_paid" && (
                      <i className="ri-checkbox-circle-fill text-success fs-16 me-2 align-middle"></i>
                    )}
                  </AngloTooltip>
                </div>
                <a
                  href={`mailto:${invitedEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {invitedEmail}
                </a>
              </div>
            </li>
          ))
        ) : (
          <div>
            <FormattedString id="no_invites" />
          </div>
        )}
      </ul>

      <div className="mt-3">
        {!showInviteForm && (
          <WithPermission permissionName="PP_APPLICATION_UPDATE">
            <LinkButton
              onClick={() => setShowInviteForm(true)}
              disabled={status === "moved"}
            >
              <i className="ri-add-line" style={{ marginRight: "4px" }}></i>
              <FormattedString id="invite_new" />
            </LinkButton>
          </WithPermission>
        )}

        {showInviteForm && (
          <NewInvitationForm
            handleSubmitInvitation={(values) =>
              handleSubmitInvitation(id, values.email)
            }
            toggle={() => setShowInviteForm(false)}
            existingInvites={applicationInvites.map(
              ({ invitedEmail }) => invitedEmail,
            )}
          />
        )}
      </div>
    </div>
  );
};
