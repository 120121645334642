import { getStringSync } from "Components/Common/FormattedString";
import SelectProgramStep from "../../steps/SelectProgramStep/SelectProgramStep";
import ParticipantsStep from "../../steps/ParticipantsStep/ParticipantsStep";
import UpsellsStep from "../../steps/UpsellsStep/UpsellsStep";

import InstallmentsStep from "../../steps/InstallmentsStep/InstallmentsStep";

const steps = [
  {
    id: 1,
    name: getStringSync("select_program"),
    component: SelectProgramStep,
  },
  { id: 2, name: getStringSync("participants"), component: ParticipantsStep },
  { id: 3, name: getStringSync("upsells"), component: UpsellsStep },
  { id: 4, name: getStringSync("summary"), component: InstallmentsStep },
];

export default steps;

export const STEP_SELECT_PROGRAM = 1;
export const STEP_PARTICIPANTS = 2;
export const STEP_UPSELLS = 3;
export const STEP_SUMMARY = 4;
