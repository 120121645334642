import React, { useContext } from "react";
import "./ParticipantShort.scss";
import { splitNumbers } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";

const ParticipantShort = ({ participant }) => {
  const { parentProgram } = useContext(ApplicationDetailsContext);

  const programPackets = parentProgram?.programPackets;
  const programPacket = programPackets?.find(
    (packet) => packet.id === participant.programPacketId,
  );

  const diet = {
    name: participant?.dietName || <FormattedString id="na" />,
    price: participant?.dietPrice || "",
  };
  const insurance = {
    name: participant?.insuranceName || <FormattedString id="na" />,
    price: participant?.insurancePrice || "",
  };
  const addons = participant?.upsells || [];

  return (
    <div className="participant-short">
      <div className="participant-short__header">
        <div className="participant-short__name">
          <span className="participant-short__name--content">
            {participant?.firstName} {participant?.lastName}
          </span>{" "}
        </div>
      </div>
      <div className="participant-short__section">
        <div className="participant-short__section__title">
          <FormattedString id="packet" />
        </div>
        <div className="participant-short__section__items">
          <div className="participant-short__section__item">
            <div className="participant-short__section__item__name">
              {participant.programPacketLabel}
              {programPacket && (
                <span className="participant-short__section__item__custom-id">
                  {" "}
                  | #{programPacket.customId}
                </span>
              )}
            </div>
            <div className="participant-short__section__item__price">
              {participant?.programPacketPrice
                ? `${splitNumbers(participant.programPacketPrice)} zł`
                : ""}
            </div>
          </div>
        </div>
      </div>
      {diet && (
        <div className="participant-short__section">
          <div className="participant-short__section__title">
            <FormattedString id="diet" />
          </div>
          <div className="participant-short__section__items">
            <div className="participant-short__section__item">
              <div className="participant-short__section__item__name">
                {diet.name}
              </div>
              <div className="participant-short__section__item__price">
                {diet?.price ? `${splitNumbers(diet.price)} zł` : ""}
              </div>
            </div>
          </div>
        </div>
      )}
      {insurance && (
        <div className="participant-short__section">
          <div className="participant-short__section__title">
            <FormattedString id="insurance" />
          </div>
          <div className="participant-short__section__items">
            <div className="participant-short__section__item">
              <div className="participant-short__section__item__name">
                {insurance.name}
              </div>
              <div className="participant-short__section__item__price">
                {insurance?.price ? `${splitNumbers(insurance.price)} zł` : ""}
              </div>
            </div>
          </div>
        </div>
      )}

      {addons.length > 0 && (
        <div className="participant-short__section">
          <div className="participant-short__section__title">
            <FormattedString id="upsells" />
          </div>
          <div className="participant-short__section__items">
            {participant.transportPrice && (
              <div className="participant-short__section__item">
                <div className="participant-short__section__item__name">
                  <FormattedString id="transport" />
                </div>
                <div className="participant-short__section__item__price">
                  {participant?.transportPrice
                    ? `${splitNumbers(participant.transportPrice)} zł`
                    : ""}
                </div>
              </div>
            )}
            {addons.map((addon, index) => (
              <div key={index} className="participant-short__section__item">
                <div className="participant-short__section__item__name">
                  {addon.name}
                </div>
                <div className="participant-short__section__item__price">
                  {addon?.price ? `${splitNumbers(addon.price)} zł` : ""}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="participant-short__sum">
        <div className="participant-short__sum__name">
          <FormattedString id="sum" />:
        </div>
        <div className="participant-short__sum__value">
          {participant?.summaryPrice ? (
            `${splitNumbers(participant.summaryPrice)} zł`
          ) : (
            <FormattedString id="na" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantShort;
