import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { getProgramListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { Spinner } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import "./ProgramsList.scss";

const ProgramsList = () => {
  const {
    searchValue,
    setSearchValue,
    closeDropdown,
    dropdownRef,
    recentSearches,
    setRecentSearches,
  } = useSearch();
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxResults = 5;

  const fetchPrograms = useCallback(
    debounce(() => {
      setLoading(true);
      getProgramListPromise()
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate),
          );
          setPrograms(sortedResponse);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_programs"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const handleLinkClick = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
    }
    setSearchValue("");
    closeDropdown(dropdownRef.current);
  };

  const filteredPrograms = programs.filter((program) =>
    program?.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  if (filteredPrograms.length === 0 && !loading) {
    return null;
  }

  return (
    <>
      <div className="dropdown-header mt-2">
        <h6 className="text-overflow text-muted mb-1 text-uppercase">
          <FormattedString id="programs" />
        </h6>
      </div>
      {loading ? (
        <div className="text-center my-3">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {filteredPrograms.slice(0, maxResults).map((program, index) => (
            <Link
              key={index}
              to={`/program/${program.id}`}
              className="dropdown-item notify-item py-2"
              onClick={handleLinkClick}
            >
              <div className="d-flex">
                <i className="ri-bookmark-line me-3 fs-18 text-muted"></i>
                <div className="flex-grow-1">
                  <h6 className="m-0">{program.name}</h6>
                  <div className="programs-list__subheader">
                    <span className="fs-11 mb-0 text-muted">
                      {program.hotel?.name || "N/A"},{" "}
                      {program.hotel?.city || "N/A"}
                    </span>
                    <span className="fs-11 mb-0 text-muted">
                      <FormattedString id="season" />: {program.season}
                    </span>
                    <span className="fs-11 mb-0 text-muted">
                      <FormattedString id="status" />: {program.status}
                    </span>
                    <span className="fs-11 mb-0 text-muted">
                      <FormattedString id="dates" />:{" "}
                      {`${new Date(program.startDate).toLocaleDateString()} - ${new Date(
                        program.endDate,
                      ).toLocaleDateString()}`}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default ProgramsList;
