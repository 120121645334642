import React from "react";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./ParticipantEquation.scss";
import { getString } from "../FormattedString";
import {
  filterItemsByStatus,
  filterOtherItems,
  getDisplayItems,
  sumItemCounts,
} from "pages/Programs/ProgramDetails/participantUtils";
import { ParticipantStatus, ppColorMap } from "models/participants";

export const ParticipantEquation = ({ summary }) => {
  const { quantity, totalCapacity } = summary;

  const preBookingItems = filterItemsByStatus(
    quantity,
    ParticipantStatus.PRE_BOOKING,
  );
  const partlyPaidItems = filterItemsByStatus(
    quantity,
    ParticipantStatus.PARTLY_PAID,
  );
  const fullyPaidItems = filterItemsByStatus(
    quantity,
    ParticipantStatus.FULLY_PAID,
  );
  const waitListItems = filterItemsByStatus(
    quantity,
    ParticipantStatus.WAIT_LIST,
  );

  const otherItems = filterOtherItems(quantity, [
    ParticipantStatus.PRE_BOOKING,
    ParticipantStatus.PARTLY_PAID,
    ParticipantStatus.FULLY_PAID,
    ParticipantStatus.WAIT_LIST,
  ]);

  const otherSum = sumItemCounts(otherItems);

  const displayPreBookingItems = getDisplayItems(
    preBookingItems,
    ParticipantStatus.PRE_BOOKING,
  );
  const displayPartlyPaidItems = getDisplayItems(
    partlyPaidItems,
    ParticipantStatus.PARTLY_PAID,
  );
  const displayFullyPaidItems = getDisplayItems(
    fullyPaidItems,
    ParticipantStatus.FULLY_PAID,
  );

  const orderedItems = [
    ...displayPreBookingItems,
    ...displayPartlyPaidItems,
    ...displayFullyPaidItems,
  ];

  const totalSum = sumItemCounts(orderedItems);

  return (
    <div className="participant-equation">
      <>
        <div className="d-flex" style={{ gap: "3px" }}>
          (
          <AngloTooltip text="AF">
            <span
              className="participant-equation__val participant-equation__val--other"
              style={{ color: ppColorMap.other }}
            >
              {otherSum}
            </span>
          </AngloTooltip>
          {waitListItems.length > 0 && (
            <>
              /
              {waitListItems.map((item, index) => (
                <React.Fragment key={`wait_list-${index}`}>
                  <AngloTooltip text={getString(item.status)}>
                    <span
                      className={`participant-equation__val participant-equation__val--${item.status}`}
                      style={{ color: ppColorMap[item.status] }}
                    >
                      {item.count}
                    </span>
                  </AngloTooltip>
                  {index < waitListItems.length - 1 && (
                    <span className="participant-equation__sign">+</span>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          )
        </div>
        {waitListItems.length > 0 && orderedItems.length > 0 && (
          <span className="participant-equation__sign">+</span>
        )}
        {orderedItems.map((item, index) => (
          <React.Fragment key={index}>
            <AngloTooltip text={getString(item.status)}>
              <span
                className={`participant-equation__val participant-equation__val--${item.status}`}
                style={{ color: ppColorMap[item.status] }}
              >
                {item.count}
              </span>
            </AngloTooltip>
            {index < orderedItems.length - 1 && (
              <span className="participant-equation__sign">+</span>
            )}
          </React.Fragment>
        ))}
        <span className="participant-equation__sign">=</span>
      </>

      <AngloTooltip text={getString("total_sum")}>
        <span className="participant-equation__val participant-equation__val--sum">
          {totalSum}
        </span>
      </AngloTooltip>
      <span className="participant-equation__sign participant-equation__sign--bright">
        /
      </span>
      <AngloTooltip text={getString("total_capacity")}>
        <span className="participant-equation__val participant-equation__val--total">
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
