import React, { useContext, useState } from "react";
import { useFormContext } from "../../provider/utils/FormContext";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { useSelector } from "react-redux";
import { internalizeDate } from "helpers/utils";
import mapFormikValuesToDTO from "../../provider/utils/mapFormikValuesToDTO";
import { moveApplication } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { parseApplicationErrorToFormikErrors } from "../../provider/utils/parseApplicationErrorToFormikErrors";
import "./InstallmentsStep.scss";
import { installmentTypes } from "models/installmentPlans";
import InstallmentAlert from "./components/InstallmentAlert";
import InstallmentHeader from "./components/InstallmentHeader";
import InstallmentContent from "./components/InstallmentContent";
import { Button } from "reactstrap";
import ComparisonContainer from "./components/ComparisonContainer";

const InstallmentsStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const PointOfSell = useSelector((rootState) => rootState.PointOfSell);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);

  const pointOfSellOptions = PointOfSell.pointOfSellData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const {
    formik,
    installmentsError,
    setInstallmentsError,
    setMaxInstallmentSum,
    parentProgram,
  } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(null);
  const [advancedManagement, setAdvancedManagement] = useState(false);
  const [installmentPlans, setInstallmentPlans] = useState([]);
  const [showInstallmentForm, setShowInstallmentForm] = useState(
    formik.values.installments.length > 0,
  );
  const [alertVisible, setAlertVisible] = useState(false);
  const [showComparison, setShowComparison] = useState(true);

  const addInstallment = () => {
    const currentInstallmentCount = formik.values.installments.length;
    const nextPlan = installmentPlans.find(
      (plan) => plan.installmentCount === currentInstallmentCount + 1,
    );

    if (nextPlan) {
      handleSelectPlan(nextPlan);
    } else {
      const lastInstallment =
        formik.values.installments[formik.values.installments.length - 1];
      const newInstallment = {
        price: "",
        dueDate: "",
        currency: activeBusinessUnit.currency || "",
        type: lastInstallment ? lastInstallment.type : "",
        pointOfSell: lastInstallment ? lastInstallment.pointOfSell : "",
      };

      formik.setFieldValue("installments", [
        ...formik.values.installments,
        newInstallment,
      ]);
    }
  };

  const removeInstallment = (index) => {
    const currentInstallmentCount = formik.values.installments.length;
    const previousPlan = installmentPlans.find(
      (plan) => plan.installmentCount === currentInstallmentCount - 1,
    );

    if (previousPlan) {
      handleSelectPlan(previousPlan);
    } else {
      if (formik.values.installments.length > 1) {
        const updatedInstallments = formik.values.installments.filter(
          (_, i) => i !== index,
        );
        formik.setFieldValue("installments", updatedInstallments);
      }
    }
  };

  const handleSelectPlan = (plan) => {
    const finalInstallments = plan.installments.map((installment, index) => {
      const currentInstallment = formik.values.installments[index];
      return {
        price: currentInstallment?.isPaid
          ? currentInstallment.price
          : installment.price,
        dueDate: currentInstallment?.isPaid
          ? currentInstallment.dueDate
          : internalizeDate(installment.dueDate),
        currency: currentInstallment?.isPaid
          ? currentInstallment.currency
          : installment?.currency || activeBusinessUnit.currency,
        type: currentInstallment?.isPaid
          ? currentInstallment.type
          : installment.type,
        pointOfSell: currentInstallment?.isPaid
          ? currentInstallment.pointOfSell
          : installment?.pointOfSellId || pointOfSellOptions[0].value,
        isPaid: currentInstallment?.isPaid || installment.isPaid,
        isOverDue: installment.isOverDue,
      };
    });

    formik.setFieldValue("installments", finalInstallments);
    setMaxInstallmentSum(plan.sum);
    setShowInstallmentForm(true);
  };

  const fetchInstallmentData = async () => {
    try {
      const dto = mapFormikValuesToDTO(formik.values, 4, true);
      const response = await moveApplication(applicationData.id, dto);
      const { installmentPlans, summary, discounts } = response;

      const plansToUse =
        installmentPlans.length > 0
          ? installmentPlans
          : parentProgram.installmentPlans;
      const sortedInstallmentPlans = plansToUse.sort(
        (a, b) => a.installmentCount - b.installmentCount,
      );

      let finalInstallments =
        formik.values.installments.length > 0 ? formik.values.installments : [];

      if (
        !applicationData.applicationInstallments ||
        applicationData.applicationInstallments.length === 0
      ) {
        setInstallmentPlans(sortedInstallmentPlans);
        setSummaryData({ summary, discounts });
        setLoading(false);
        if (response.recalculated) {
          setAlertVisible(true);
        }
      } else {
        const matchingInstallmentPlan = plansToUse.find(
          (plan) =>
            plan.installmentCount ===
            applicationData.applicationInstallments.length,
        );

        finalInstallments = applicationData.applicationInstallments.map(
          (installment, index) => {
            const matchingInstallment =
              matchingInstallmentPlan?.installments[index];
            const matchingProgramParentInstallment =
              parentProgram.installmentPlans[0].installments[index];

            return {
              price: installment.price,
              dueDate: internalizeDate(installment.dueDate),
              currency:
                installment.currency ||
                matchingInstallment?.currency ||
                matchingProgramParentInstallment?.currency ||
                activeBusinessUnit.currency,
              type: installment.type,
              pointOfSell:
                installment.pointOfSellId ||
                matchingInstallment?.pointOfSellId ||
                matchingProgramParentInstallment?.pointOfSellId,
              isPaid: installment.isPaid,
              isOverDue: installment.isOverDue,
            };
          },
        );

        formik.setFieldValue("installments", finalInstallments);
        setShowInstallmentForm(finalInstallments.length > 0);
        setInstallmentPlans(sortedInstallmentPlans);
        setSummaryData({ summary, discounts });
        setLoading(false);
        if (installmentPlans[0].sum) {
          setMaxInstallmentSum(installmentPlans[0].sum);
        }
        if (response.recalculated) {
          setAlertVisible(true);
        }
      }
    } catch (error) {
      parseApplicationErrorToFormikErrors(error.data, formik);
      setLoading(false);
    }
  };

  const toggleAdvancedManagement = () => {
    setAdvancedManagement(!advancedManagement);
  };

  const handleAccept = () => {
    setShowComparison(false);
    fetchInstallmentData();
  };

  return (
    <div className="installments-step">
      {showComparison ? (
        <div>
          <h2 className="comparison-header">
            Comparison of Old and New Program Data
          </h2>
          <ComparisonContainer
            applicationData={applicationData}
            parentProgram={parentProgram}
            formik={formik}
          />
          <div className="button-container">
            <Button color="secondary" onClick={handleAccept}>
              Accept
            </Button>
          </div>
        </div>
      ) : (
        <>
          <InstallmentAlert alertVisible={alertVisible} />
          <InstallmentHeader
            advancedManagement={advancedManagement}
            toggleAdvancedManagement={toggleAdvancedManagement}
          />
          {loading ? (
            <PreloaderWrap />
          ) : (
            <InstallmentContent
              summaryData={summaryData}
              showInstallmentForm={showInstallmentForm}
              installmentPlans={installmentPlans}
              setShowInstallmentForm={setShowInstallmentForm}
              setInstallmentsError={setInstallmentsError}
              formik={formik}
              installmentTypes={installmentTypes}
              pointOfSellOptions={pointOfSellOptions}
              removeInstallment={removeInstallment}
              advancedManagement={advancedManagement}
              installmentsError={installmentsError}
              addInstallment={addInstallment}
              handleSelectPlan={handleSelectPlan}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InstallmentsStep;
