import React, { useState, useEffect, useContext } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import { internalizeDate, isObjectEmpty } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import ParticipantForm from "./ParticipantForm";
import ParticipantTabs from "./ParticipantTabs";
import { getCustomerParticipants } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import ParticipantAdder from "./ParticipantAdder";
import "./ParticipantsStep.scss";
import { v4 as uuidv4 } from "uuid";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { toast_error } from "helpers/toast_helper";

const ParticipantsStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { formik, validateStep, parentProgram } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);
  const [existingParticipants, setExistingParticipants] = useState([]);
  const [shouldAddParticipantTab, setShouldAddParticipantTab] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const response = await getCustomerParticipants(
          applicationData.customerId,
        );
        setExistingParticipants(response);
      } catch (error) {
        console.error(error);
        toast_error("fetching_participants_error");
      } finally {
        setLoading(false);
      }
    };

    fetchParticipants();
  }, [parentProgram.customerId]);

  useEffect(() => {
    if (formik.values.participants.length === 0) {
      setShouldAddParticipantTab(0);
    }
  }, [formik.values.participants]);

  const handleAddParticipant = () => {
    validateStep().then((res) => {
      if (isObjectEmpty(res)) {
        if (filteredExistingParticipants.length > 0) {
          setShouldAddParticipantTab(formik.values.participants.length);
        } else {
          addNewParticipant();
        }
        setActiveTab(formik.values.participants.length);
      }
    });
  };

  const handleRemoveParticipant = (index) => {
    const updatedParticipants = formik.values.participants.filter(
      (_, i) => i !== index,
    );
    formik.setFieldValue("participants", updatedParticipants);

    const updatedErrors = formik.errors.participants
      ? formik.errors.participants.filter((_, i) => i !== index)
      : [];

    formik.setErrors({
      ...formik.errors,
      participants: updatedErrors,
    });

    if (activeTab >= updatedParticipants.length) {
      setActiveTab(updatedParticipants.length - 1);
    }

    if (shouldAddParticipantTab === index) {
      setShouldAddParticipantTab(null);
    }
  };

  const [programPacketOptions, setProgramPacketOptions] = useState([]);

  useEffect(() => {
    if (parentProgram) {
      const options = parentProgram.programPackets.map((packet) => ({
        value: packet.id,
        label: packet.label,
        ppMinAge: packet.programPacketTemplate.ppMinAge,
        ppMaxAge: packet.programPacketTemplate.ppMaxAge,
      }));
      setProgramPacketOptions(options);
    }
  }, [parentProgram]);

  const generateChecksum = (participant) => {
    return `${participant.firstName}-${participant.lastName}-${internalizeDate(participant.dateOfBirth)}`;
  };

  const { smallestMinAge, highestMaxAge } = programPacketOptions.reduce(
    (acc, packet) => {
      if (packet.ppMinAge < acc.smallestMinAge) {
        acc.smallestMinAge = packet.ppMinAge;
      }
      if (packet.ppMaxAge > acc.highestMaxAge) {
        acc.highestMaxAge = packet.ppMaxAge;
      }
      return acc;
    },
    { smallestMinAge: Infinity, highestMaxAge: -Infinity },
  );

  const filteredExistingParticipants = existingParticipants.filter(
    (existingParticipant) => {
      const existingChecksum = generateChecksum(existingParticipant);

      const isParticipantInBounds = (
        dateOfBirth,
        minAge,
        maxAge,
        programStartDate,
      ) => {
        const birthDate = new Date(dateOfBirth);
        const startDate = new Date(programStartDate);
        const ageDifMs = startDate.getTime() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age >= minAge && age <= maxAge;
      };

      return (
        !formik.values.participants.some(
          (participant) => generateChecksum(participant) === existingChecksum,
        ) &&
        isParticipantInBounds(
          existingParticipant.dateOfBirth,
          smallestMinAge,
          highestMaxAge,
          parentProgram.startDate,
        )
      );
    },
  );

  const addNewParticipant = () => {
    formik.setFieldValue("participants", [
      ...formik.values.participants,
      {
        id: uuidv4(),
        gender: "",
        programPacket: "",
        firstName: "",
        lastName: "",
        email: "",
        phonePrefix: "",
        phoneNumber: "",
        dateOfBirth: "",
        comment: "",
        upsells: { diet: "", insurance: "", upsells: [] },
      },
    ]);
    setActiveTab(formik.values.participants.length);
    setShouldAddParticipantTab(null);
  };

  if (loading) {
    return (
      <div className="participants-step">
        <PreloaderWrap color="primary" />
      </div>
    );
  }

  return (
    <div className="participants-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="participants" />
          </h2>
        </Col>
      </Row>
      <ParticipantTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleAddParticipant={handleAddParticipant}
        handleRemoveParticipant={handleRemoveParticipant}
      />
      <TabContent activeTab={activeTab}>
        {formik.values.participants.map((participant, index) => (
          <TabPane tabId={index} key={`${participant.id}${index}`}>
            {shouldAddParticipantTab === index ? (
              <ParticipantAdder
                filteredExistingParticipants={filteredExistingParticipants}
                setActiveTab={setActiveTab}
                setShouldAddParticipantTab={setShouldAddParticipantTab}
              />
            ) : (
              <ParticipantForm
                participant={participant}
                index={index}
                programPacketOptions={programPacketOptions}
                smallestMinAge={smallestMinAge}
                highestMaxAge={highestMaxAge}
              />
            )}
          </TabPane>
        ))}
        {shouldAddParticipantTab === formik.values.participants.length && (
          <TabPane tabId={formik.values.participants.length}>
            <ParticipantAdder
              filteredExistingParticipants={filteredExistingParticipants}
              setActiveTab={setActiveTab}
              setShouldAddParticipantTab={setShouldAddParticipantTab}
            />
          </TabPane>
        )}
      </TabContent>
      {formik.errors.participants &&
        typeof formik.errors.participants === "string" && (
          <div className="text-danger">{formik.errors.participants}</div>
        )}
      <ApplicationStepNavigation />
    </div>
  );
};

export default ParticipantsStep;
