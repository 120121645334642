import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsListData } from "../../store/applications/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import CustomIdCell from "./cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "./cells/InstallmentsCell/InstallmentsCell";
import { format } from "date-fns";
import "./ApplicationsList.scss";
import PackageCell from "./cells/PackageCell/PackageCell";
import GenderCell from "./cells/GenderCell/GenderCell";
import ParticipantNameCell from "./cells/ParticipantNameCell/ParticipantNameCell";
import ParticipantAgeCell from "./cells/ParticipantAgeCell/ParticipantAgeCell";
import InternalCommentCell from "./cells/InternalCommentCell/InternalCommentCell";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";

const ApplicationsList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    applicationsData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.Applications);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  // Fetch initial data and update when searchParams change
  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(getApplicationsListData(pageNumber, globalFilter));
  }, [searchParams]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: (row) => (
          <CustomIdCell
            customId={row.customId}
            to={`/pp-application/${row.id}`}
          />
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            <FormattedString id={value} />
          </div>
        ),
      },
      {
        Header: getString("installments_short"),
        accessor: (d) => (
          <InstallmentsCell
            installmentsPaid={d.installmentsPaid}
            installmentsCount={d.installmentsCount}
          />
        ),
      },
      {
        Header: getString("name"),
        accessor: (d) =>
          `${d.customerFirstName || ""} ${d.customerLastName || ""}`.trim() ||
          "-",
      },
      {
        Header: getString("program"),
        accessor: "programName",
      },
      {
        Header: getString("week"),
        accessor: "week",
      },
      {
        Header: getString("date"),
        accessor: (d) => (
          <div>
            <div>{format(new Date(d.dateStart), "yyyy-MM-dd")} - </div>
            <div>{format(new Date(d.dateEnd), "yyyy-MM-dd")}</div>
          </div>
        ),
      },
      {
        Header: getString("package"),
        accessor: (d) => (
          <PackageCell
            packages={d.participants?.map((p) => p.programPacketName)}
          />
        ),
      },
      {
        Header: getString("age"),
        accessor: (d) => (
          <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
        ),
      },
      {
        Header: getString("gender"),
        accessor: (d) => (
          <GenderCell genders={d.participants?.map((p, index) => p.gender)} />
        ),
      },
      {
        Header: getString("participant_name"),
        accessor: (d) => (
          <ParticipantNameCell
            names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
          />
        ),
      },
      {
        Header: getString("internal_comment"),
        accessor: (d) => (
          <InternalCommentCell
            internalComment={d.internalComment}
            entityId={d.id}
          />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            customShowDetailsLink={`/pp-application/${cellProps.id}`}
            itemId={cellProps.id}
          />
        ),
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={applicationsData || []}
        isGlobalFilter={true}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("applications_search_for")}
        error={error}
        onRetry={() =>
          dispatch(getApplicationsListData(pageNumber, globalFilter))
        }
      />
    </div>
  );
};

export { ApplicationsList };
