import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const targetAudienceOptions = [
  { value: "junior", label: getStringSync("junior") },
  { value: "kids", label: getStringSync("kids") },
  { value: "adult", label: getStringSync("adult") },
  { value: "family", label: getStringSync("family") },
  { value: "company_or_school", label: getStringSync("company_or_school") },
];

const createProgramPacketTemplateSchema = Yup.object({
  name: Yup.string().min(4).required(getStringSync("name_required")),
  label: Yup.string().min(4).required(getStringSync("label_required")),
  ppMinAge: Yup.number().min(1).required(getStringSync("pp_min_age_required")),
  ppMaxAge: Yup.number().min(1).required(getStringSync("pp_max_age_required")),
  ipMinAge: Yup.number().min(1).required(getStringSync("ip_min_age_required")),
  ipMaxAge: Yup.number().min(1).required(getStringSync("ip_max_age_required")),
  targetAudience: Yup.string()
    .oneOf(targetAudienceOptions.map((option) => option.value))
    .required(getStringSync("target_audience_required")),
  descriptionItems: Yup.array().of(Yup.string()),
  detailedDescription: Yup.string(),
});

export { createProgramPacketTemplateSchema, targetAudienceOptions };
