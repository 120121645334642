import React from "react";
import "./InstallmentsCell.scss";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const InstallmentsCell = ({
  installmentsPaid,
  installmentsCount,
  applicationStatus,
}) => {
  const ratio = installmentsPaid / installmentsCount;
  let className = "installments-cell";

  if (applicationStatus === "moved") {
    className += " installments-cell--red";
  } else if (installmentsPaid === 0 && installmentsCount === 0) {
    className += " installments-cell--gray";
  } else if (ratio === 1) {
    className += " installments-cell--full";
  } else if (ratio > 0) {
    className += " installments-cell--partial";
  } else {
    className += " installments-cell--none";
  }

  return (
    <div className={className}>
      <AngloTooltip stringId="installmentsPaid">
        {applicationStatus === "moved" ? "-" : installmentsPaid}
      </AngloTooltip>
      /
      <AngloTooltip stringId="installmentsCount">
        {installmentsCount}
      </AngloTooltip>
    </div>
  );
};

export default InstallmentsCell;
