import * as Yup from "yup";
import { getString } from "../Components/Common/FormattedString";

const priceSchema = Yup.object({
  price: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() === "") {
        return null;
      }
      return value;
    })
    .required(getString("installment_price_required"))
    .test(
      "is-decimal-or-integer",
      getString("installment_price_must_be_integer_or_proper_float"),
      (value) => value === null || /^\d+(\.\d)?$/.test(value),
    ),
});

const pricesForPacketInstallmentSchema = Yup.object({
  prices: Yup.array().of(priceSchema).required(),
});

export { pricesForPacketInstallmentSchema };
