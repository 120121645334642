// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-dropdown {
  width: 500px;
  margin-top: 4px;
}
.search-dropdown:empty {
  display: none;
}
.search-dropdown__view-all a {
  padding-top: 4px;
  background-color: transparent;
  cursor: pointer;
  color: var(--vz-primary);
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}
.search-dropdown__view-all a:hover {
  color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Search/components/SearchDropdown.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ;AAEI;EACE,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,wBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sDACE;AADR;AAIM;EACE,cAAA;AAFR","sourcesContent":[".search-dropdown {\n  width: 500px;\n  margin-top: 4px;\n\n  &:empty {\n    display: none;\n  }\n  &__view-all {\n    a {\n      padding-top: 4px;\n      background-color: transparent;\n      cursor: pointer;\n      color: var(--vz-primary);\n      border: none;\n      width: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      transition:\n        color 0.3s ease,\n        text-decoration 0.3s ease;\n\n      &:hover {\n        color: #0056b3;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
