import React, { useState } from "react";
import * as Yup from "yup";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { toast_error, toast_success } from "helpers/toast_helper";
import { generateNewPaymentReport } from "helpers/API/core-service/cs_backend_helper";
import FormDateRange from "Components/Entity/FormDateRange";
import { downloadBlob } from "helpers/fileUtils";

export const GenerateNewReportCanvas = ({ isOpen, toggle }) => {
  const [loading, setLoading] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  const minDate = "2024-12-01";

  const handleGenerateNewReport = (values) => {
    setLoading(true);
    generateNewPaymentReport(values.from, values.to)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "payment_report.csv");
        toast_success(getString("report_generated"));
        toggle();
      })
      .catch((error) => {
        console.error(getString("generate_report_error"), error);
        toast_error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      from: minDate,
      to: today,
    },
    validationSchema: Yup.object({
      from: Yup.date().required(getString("start_date_required")),
      to: Yup.date().required(getString("end_date_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);

      handleGenerateNewReport(values);
    },
  });

  const handleDateChange = (e, name) => {
    validation.setFieldValue(name, e.target.value || undefined);
  };

  return (
    <OffcanvasRight
      isOpen={isOpen}
      toggle={toggle}
      title={getString("generate_report")}
      formId="generate-new-report-form"
      validationRule={true}
      buttonLabel={getString("generate")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="generate-new-report-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormDateRange
              label={getString("date_range")}
              startName="from"
              endName="to"
              startValue={validation.values.from}
              endValue={validation.values.to}
              onChange={handleDateChange}
              startError={validation.errors.from}
              endError={validation.errors.to}
              data-testid="date-range"
              min={minDate}
              max={today}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
