import React from "react";
import { Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { getString } from "Components/Common/FormattedString";

const SearchInput = () => {
  const {
    searchInputRef,
    dropdownRef,
    clearButtonRef,
    searchValue,
    setSearchValue,
    recentSearches,
    setRecentSearches,
    openDropdown,
    closeDropdown,
  } = useSearch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    if (
      dropdownRef.current &&
      !dropdownRef.current.classList.contains("show")
    ) {
      openDropdown(dropdownRef.current);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigate(`/search?searchTerm=${encodeURIComponent(searchValue)}`);
      if (searchValue && !recentSearches.includes(searchValue)) {
        setRecentSearches([...recentSearches, searchValue]);
      }
      closeDropdown(dropdownRef.current);
      setSearchValue("");
    }
  };

  return (
    <div className="position-relative">
      <Input
        type="text"
        className="form-control"
        placeholder={getString("search_placeholder")}
        id="search-options"
        value={searchValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        autoComplete="off"
        style={{ width: "500px" }}
        ref={searchInputRef}
        onFocus={() => openDropdown(dropdownRef.current)}
        onClick={() => openDropdown(dropdownRef.current)}
      />
      <span className="mdi mdi-magnify search-widget-icon"></span>
      {searchValue && (
        <span
          className="mdi mdi-close-circle search-widget-icon search-widget-icon-close"
          id="search-close-options"
          ref={clearButtonRef}
          onClick={() => setSearchValue("")}
        ></span>
      )}
    </div>
  );
};

export default SearchInput;
