import React from "react";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import { useFormContext } from "../../provider/utils/FormContext";
import { getString } from "Components/Common/FormattedString";
import { genderOptions } from "models/genders";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";

const ParticipantForm = ({ participant, index, programPacketOptions }) => {
  const { formik } = useFormContext();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormSelect
                name={`participants[${index}].gender`}
                id={`participants[${index}].gender`}
                label={getString("gender")}
                isMulti={false}
                options={genderOptions}
                value={participant.gender}
                defaultValue={genderOptions.find(
                  (r) => r.value === participant.gender,
                )}
                onChange={(selectedOption) => {
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `participants[${index}].gender`,
                  );
                }}
                error={formik.errors.participants?.[index]?.gender}
              />
            </Col>
            <Col md={6}>
              <FormSelect
                name={`participants[${index}].programPacket`}
                id={`participants[${index}].programPacket`}
                label={getString("program_packet")}
                isMulti={false}
                options={programPacketOptions}
                value={participant.programPacket}
                defaultValue={programPacketOptions.find(
                  (r) => r.value === participant.programPacket,
                )}
                onChange={(selectedOption) => {
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `participants[${index}].programPacket`,
                  );
                }}
                error={formik.errors.participants?.[index]?.programPacket}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("first_name")}
                name={`participants[${index}].firstName`}
                value={participant.firstName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].firstName`,
                  )
                }
                error={formik.errors.participants?.[index]?.firstName}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("last_name")}
                name={`participants[${index}].lastName`}
                value={participant.lastName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].lastName`,
                  )
                }
                error={formik.errors.participants?.[index]?.lastName}
                touched={formik.touched.participants?.[index]?.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("email")}
                name={`participants[${index}].email`}
                type="email"
                value={participant.email}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].email`)
                }
                error={formik.errors.participants?.[index]?.email}
                touched={formik.touched.participants?.[index]?.email}
              />
            </Col>
            <Col md={3}>
              <FormTextField
                label={getString("phone_prefix")}
                name={`participants[${index}].phonePrefix`}
                value={participant.phonePrefix}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].phonePrefix`,
                  )
                }
                error={formik.errors.participants?.[index]?.phonePrefix}
                touched={formik.touched.participants?.[index]?.phonePrefix}
              />
            </Col>
            <Col md={3}>
              <FormTextField
                label={getString("phone_number")}
                name={`participants[${index}].phoneNumber`}
                value={participant.phoneNumber}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].phoneNumber`,
                  )
                }
                error={formik.errors.participants?.[index]?.phoneNumber}
                touched={formik.touched.participants?.[index]?.phoneNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("date_of_birth")}
                name={`participants[${index}].dateOfBirth`}
                type="date"
                value={participant.dateOfBirth}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].dateOfBirth`,
                  )
                }
                error={formik.errors.participants?.[index]?.dateOfBirth}
                touched={formik.touched.participants?.[index]?.dateOfBirth}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("comment")}
                name={`participants[${index}].comment`}
                value={participant.comment}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].comment`)
                }
                error={formik.errors.participants?.[index]?.comment}
                touched={formik.touched.participants?.[index]?.comment}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ParticipantForm;
