import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import InstallmentList from "./InstallmentList";
import ApplicationStepNavigation from "../../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { getString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { useFormContext } from "../../../provider/utils/FormContext";
import { calculateRemainingPrice } from "../../../provider/utils/utils";
import { splitNumbers } from "helpers/utils";
import "./InstallmentForm.scss";

const InstallmentForm = ({
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  advancedManagement,
  installmentsError,
  addInstallment,
  currentInstallmentCount,
  installmentPlans,
}) => {
  const { maxInstallmentSum } = useFormContext();
  const remainingPrice = calculateRemainingPrice(formik, maxInstallmentSum);

  const canAddInstallment = useHasPermission("PP_APPLICATION_ACCOUNTING");
  const higherInstallmentPlanExists = installmentPlans.some(
    (plan) => plan.installmentCount > currentInstallmentCount,
  );

  const totalInsertedInstallments = maxInstallmentSum - remainingPrice;
  const isSumCorrect = totalInsertedInstallments === maxInstallmentSum;

  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (!isSumCorrect) {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isSumCorrect]);

  const hasPaidInstallments = formik.values.installments.some(
    (installment) => installment.isPaid === true,
  );

  const currency = formik.values.installments[0]?.currency || "";

  const canModifyInstallments = !hasPaidInstallments || canAddInstallment;

  const canAddNewInstallment =
    canModifyInstallments && (canAddInstallment || higherInstallmentPlanExists);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <InstallmentList
        installments={formik.values.installments}
        formik={formik}
        installmentTypes={installmentTypes}
        pointOfSellOptions={pointOfSellOptions}
        removeInstallment={removeInstallment}
        advancedManagement={advancedManagement}
        currentInstallmentCount={currentInstallmentCount}
        installmentPlans={installmentPlans}
      />
      {installmentsError && (
        <Row className="mb-3">
          <Col>
            <div className="text-danger">
              {JSON.stringify(installmentsError)}
            </div>
          </Col>
        </Row>
      )}
      <Row className="mb-3 justify-content-between">
        <Col xs="auto">
          {canModifyInstallments ? (
            <div
              className={`add-installment-btn ${!canAddNewInstallment ? "disabled" : ""}`}
              onClick={canAddNewInstallment ? addInstallment : null}
            >
              <AngloTooltip
                text={
                  canAddNewInstallment
                    ? ""
                    : getString("no_installment_plan_available", null, {
                        amount: currentInstallmentCount,
                      })
                }
                enabled={!canAddNewInstallment}
              >
                <i className="ri-add-line add-installment-btn__icon"></i>{" "}
                {getString("add_installment")}
              </AngloTooltip>
            </div>
          ) : (
            <div className="text-info d-flex align-items-center">
              <i className="ri-information-line me-2"></i>
              {getString("payment_already_done_no_edit_permission")}
            </div>
          )}
        </Col>
        <Col xs="auto">
          <div className={`d-flex ${shake ? "shake" : ""}`}>
            <AngloTooltip
              text={getString("sums_have_to_be_equal", null, {
                amount: splitNumbers(maxInstallmentSum),
              })}
            >
              <i
                className={`ri-information-line ${isSumCorrect ? "text-primary" : "text-danger"}`}
              ></i>
            </AngloTooltip>
            <span
              className={`fw-bold ms-2 ${isSumCorrect ? "text-primary" : "text-danger"}`}
            >
              {splitNumbers(totalInsertedInstallments)} {currency}
            </span>
            <span className="ms-1">
              / {splitNumbers(maxInstallmentSum)} {currency}
            </span>
          </div>
        </Col>
      </Row>
      <ApplicationStepNavigation />
    </Form>
  );
};

export default InstallmentForm;
