import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIpParticipantListData,
  editIpParticipant,
} from "../../store/ipParticipants/action";

import { useFormik } from "formik";
import { createSelector } from "reselect";
import {
  handleInputChange,
  handlePhoneChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";

import { Card, CardBody, Form } from "reactstrap";

import { createIpParticipantSchema } from "../../models/ipParticipants";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "../../Components/Entity/FormTextField";
import { FormSelect } from "../../Components/Entity/FormSelect";
import { getString } from "Components/Common/FormattedString";
import { genderOptions } from "models/genders";
import suggestedDiets from "models/diets";
import FormDateField from "Components/Entity/FormDateField";
import { externalizeDate, internalizeDate } from "helpers/utils";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";
import countries from "models/countries";

const EditIpParticipant = ({
  ipParticipant,
  toggle,
  visibility,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const selectIpParticipantsState = (state) => state.IpParticipants;
  const selectIpParticipantsProperties = createSelector(
    selectIpParticipantsState,
    (state) => ({
      businessUnitsData: state.businessUnitsData,
      isIpParticipantEdited: state.isIpParticipantEdited,
      error: state.error,
      loading: state.loading,
      formValidationErrors: state.formValidationErrors,
    }),
  );

  const { loading, formValidationErrors } = useSelector(
    selectIpParticipantsProperties,
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      gender: ipParticipant?.gender || "",
      firstName: ipParticipant?.firstName || "",
      lastName: ipParticipant?.lastName || "",
      email: ipParticipant?.email || "",
      phonePrefix: ipParticipant?.phonePrefix || "44",
      phoneNumber: ipParticipant?.phoneNumber || "",
      dateOfBirth: internalizeDate(ipParticipant?.dateOfBirth) || "",
      countryOfOrigin: ipParticipant?.countryOfOrigin || "",
      preferredDiet: ipParticipant?.preferredDiet || "",
      status: ipParticipant?.status || "",
    },
    validationSchema: createIpParticipantSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values,
      };
      newValues.dateOfBirth = externalizeDate(values.dateOfBirth);

      dispatch(
        editIpParticipant(ipParticipant.id, newValues, () => {
          dispatch(getIpParticipantListData());
          toggle(null, false);
          if (onSuccess) {
            onSuccess();
          }
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={`${getString("ip_participants_edit")} ${ipParticipant?.firstName + " " + ipParticipant?.lastName || ""}`}
        formId="edit-ipParticipant"
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-ipParticipant"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormSelect
                label={getString("gender")}
                name="gender"
                options={genderOptions}
                value={validation.values.gender}
                defaultValue={genderOptions.find(
                  (r) => r.value === validation.values.gender,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "gender")
                }
                error={validation.errors.gender}
                touched={validation.touched.gender}
              />
              <FormTextField
                label={getString("firstName")}
                name="firstName"
                value={validation.values.firstName}
                onChange={(e) => handleInputChange(validation, e, "firstName")}
                error={validation.errors.firstName}
                touched={validation.touched.firstName}
              />
              <FormTextField
                label={getString("lastName")}
                name="lastName"
                value={validation.values.lastName}
                onChange={(e) => handleInputChange(validation, e, "lastName")}
                error={validation.errors.lastName}
                touched={validation.touched.lastName}
              />
              <FormTextField
                label={getString("email")}
                name="email"
                type="email"
                value={validation.values.email}
                onChange={(e) => handleInputChange(validation, e, "email")}
                error={validation.errors.email}
                touched={validation.touched.email}
              />
              <FormPhoneField
                label={getString("phoneNumber")}
                name="phoneNumber"
                phonePrefixName="phonePrefix"
                value={validation.values["phoneNumber"] || ""}
                onChange={(e) => handlePhoneChange(validation, e)}
                phonePrefix={validation.values.phonePrefix || ""}
                onPrefixChange={(e) => handlePhoneChange(validation, e)}
                error={
                  validation.errors["phoneNumber"] ||
                  validation.errors["phonePrefix"]
                }
                setError={(error) =>
                  validation.setFieldError("phoneNumber", error)
                }
                preferredCountries={["gb", "us", "ca", "au"]}
              />
              <FormDateField
                label={getString("dateOfBirth")}
                name="dateOfBirth"
                value={validation.values.dateOfBirth}
                onChange={(e) =>
                  handleInputChange(validation, e, "dateOfBirth")
                }
                error={validation.errors.dateOfBirth}
                touched={validation.touched.dateOfBirth}
              />
              <FormSelect
                label={getString("countryOfOrigin")}
                name="countryOfOrigin"
                options={countries}
                defaultValue={countries.find(
                  (r) => r.value === validation.values.countryOfOrigin,
                )}
                value={validation.values.countryOfOrigin}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "countryOfOrigin",
                  )
                }
                error={validation.errors.countryOfOrigin}
                touched={validation.touched.countryOfOrigin}
              />
              <FormSelect
                label={getString("preferredDiet")}
                name="preferredDiet"
                options={suggestedDiets}
                value={validation.values.preferredDiet}
                defaultValue={suggestedDiets.find(
                  (r) => r.value === validation.values.preferredDiet,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "preferredDiet",
                  )
                }
                error={validation.errors.preferredDiet}
                touched={validation.touched.preferredDiet}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditIpParticipant;
