import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { getApplicationsListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { Spinner } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";

const ApplicationsList = () => {
  const {
    searchValue,
    setSearchValue,
    closeDropdown,
    dropdownRef,
    recentSearches,
    setRecentSearches,
  } = useSearch();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxResults = 5;

  const fetchApplications = useCallback(
    debounce((searchValue) => {
      setLoading(true);
      getApplicationsListPromise(1, maxResults, searchValue)
        .then((response) => {
          setApplications(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_applications"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchApplications(searchValue);
  }, [searchValue, fetchApplications]);

  const handleLinkClick = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
    }
    setSearchValue("");
    closeDropdown(dropdownRef.current);
  };

  if (applications.length === 0 && !loading) {
    return null;
  }

  return (
    <>
      <div className="dropdown-header mt-2">
        <h6 className="text-overflow text-muted mb-1 text-uppercase">
          <FormattedString id="applications" />
        </h6>
      </div>
      {loading ? (
        <div className="text-center my-3">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {applications.map((app, index) => (
            <Link
              key={index}
              to={`/pp-application/${app.id}`}
              className="dropdown-item notify-item py-2"
              onClick={handleLinkClick}
            >
              <div className="d-flex">
                <i className="ri-apps-line me-3 fs-18 text-muted"></i>
                <div className="flex-grow-1">
                  <h6 className="m-0">
                    #{app.customId} | {app.programName} |{" "}
                    {app.customerFirstName} {app.customerLastName}
                  </h6>
                  <span
                    className="fs-11 mb-0 text-muted"
                    style={{ marginRight: "4px" }}
                  >
                    {app.programName}
                  </span>
                  <span
                    className="fs-11 mb-0 text-muted"
                    style={{ marginRight: "4px" }}
                  >
                    {getString("status")}: {app.applicationStatus}
                  </span>
                  <span
                    className="fs-11 mb-0 text-muted"
                    style={{ marginRight: "4px" }}
                  >
                    {getString("paid")}: {app.paidAmount} {app.currency}
                  </span>
                  <span className="fs-11 mb-0 text-muted">
                    {getString("total")}: {app.totalAmount} {app.currency}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default ApplicationsList;
