import React from "react";
import { Card, CardBody, Form } from "reactstrap";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";
import FormDateField from "Components/Entity/FormDateField";
import {
  handleInputChange,
  handlePhoneChange,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormContext } from "../../../provider/utils/FormContext";
import { genderOptions } from "models/genders";
import { getString } from "Components/Common/FormattedString";
import moment from "moment";

const EditParticipantOffcanvas = ({ isOpen, toggle, editData }) => {
  const { formik, targetProgram } = useFormContext();

  const programPacketOptions = targetProgram?.programPackets.map((packet) => ({
    value: packet.id,
    label: packet.label,
    ppMinAge: packet.programPacketTemplate.ppMinAge,
    ppMaxAge: packet.programPacketTemplate.ppMaxAge,
  }));

  const dietOptions = targetProgram.participantUpsells
    .filter((upsell) => upsell.type === "diet")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const insuranceOptions = targetProgram.participantUpsells
    .filter((upsell) => upsell.type === "insurance")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const upsellOptions = targetProgram.participantUpsells
    .filter((upsell) => upsell.type !== "diet" && upsell.type !== "insurance")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const programMeetingOptions = targetProgram.programMeetingsPP?.map(
    (meeting) => ({
      value: meeting.id,
      label: `${meeting.meetingPoint.address}, ${meeting.meetingPoint.city} (${meeting.startHour} - ${meeting.endHour})`,
    }),
  );

  const calculateMinMaxDate = (startDate, minAge, maxAge) => {
    const minDate = moment(startDate)
      .subtract(maxAge + 1, "years")
      .add(1, "days")
      .format("YYYY-MM-DD");
    const maxDate = moment(startDate)
      .subtract(minAge, "years")
      .format("YYYY-MM-DD");
    return { minDate, maxDate };
  };

  const isValidYear = (dateOfBirth) => {
    const year = moment(dateOfBirth).year();
    return year >= 1000 && year <= 9999;
  };

  const getProgramPacketWarning = (dateOfBirth, programPacket) => {
    if (!isValidYear(dateOfBirth)) {
      return null;
    }

    const packet = programPacketOptions.find(
      (packet) => packet.value === programPacket,
    );

    if (!packet) {
      return null;
    }

    const { minDate, maxDate } = calculateMinMaxDate(
      targetProgram.startDate,
      packet.ppMinAge,
      packet.ppMaxAge,
    );

    if (!moment(dateOfBirth).isBetween(minDate, maxDate, undefined, "[]")) {
      return getString("program_packet_outside_age_requirements");
    }

    return null;
  };

  const { participantIndex: index, participantData } = editData;

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    if (!formik.values.participants[index].programPacket) {
      formik.setFieldError(
        `participants[${index}].programPacket`,
        "Package is required",
      );
      hasError = true;
    }

    if (!formik.values.programMeeting) {
      formik.setFieldError("programMeeting", "Meeting is required");
      hasError = true;
    }

    if (!formik.values.participants[index].upsells.diet) {
      formik.setFieldError(
        `participants[${index}].upsells.diet`,
        "Diet is required",
      );
      hasError = true;
    }

    if (!formik.values.participants[index].upsells.insurance) {
      formik.setFieldError(
        `participants[${index}].upsells.insurance`,
        "Insurance is required",
      );
      hasError = true;
    }

    if (hasError) {
      return;
    }

    toggle(false);
  };

  return (
    <OffcanvasRight
      isOpen={isOpen}
      toggle={toggle}
      title={`Edit ${participantData.firstName} ${participantData.lastName}`}
      formId="edit-participant"
      validationRule={true}
      buttonLabel="Save"
    >
      <Form id="edit-participant" onSubmit={handleSubmit} action="#">
        <Card>
          <CardBody>
            <FormSelect
              name={`participants[${index}].gender`}
              id={`participants[${index}].gender`}
              label={getString("gender")}
              isMulti={false}
              options={genderOptions}
              value={formik.values.participants[index].gender}
              defaultValue={genderOptions.find(
                (r) => r.value === formik.values.participants[index].gender,
              )}
              onChange={(selectedOption) => {
                handleSelectChange(
                  formik,
                  selectedOption,
                  `participants[${index}].gender`,
                );
              }}
              error={formik.errors.participants?.[index]?.gender}
              isDisabled
            />
            <FormSelect
              key={formik.values.participants[index].programPacket}
              name={`participants[${index}].programPacket`}
              id={`participants[${index}].programPacket`}
              label={getString("program_packet")}
              isMulti={false}
              options={programPacketOptions}
              value={formik.values.participants[index].programPacket}
              defaultValue={programPacketOptions.find(
                (r) =>
                  r.value === formik.values.participants[index].programPacket,
              )}
              onChange={(selectedOption) => {
                handleSelectChange(
                  formik,
                  selectedOption,
                  `participants[${index}].programPacket`,
                );
                formik.setFieldError(
                  `participants[${index}].programPacket`,
                  undefined,
                );
              }}
              error={formik.errors.participants?.[index]?.programPacket}
              warning={getProgramPacketWarning(
                formik.values.participants[index].dateOfBirth,
                formik.values.participants[index].programPacket,
              )}
            />
            <FormSelect
              name="programMeeting"
              id="programMeeting"
              label={getString("program_meeting")}
              isMulti={false}
              options={programMeetingOptions}
              value={programMeetingOptions.find(
                (r) => r.value === formik.values.programMeeting,
              )}
              onChange={(selectedOption) => {
                handleSelectChange(formik, selectedOption, "programMeeting");
              }}
              error={formik.errors.programMeeting}
            />
            <FormTextField
              label={getString("first_name")}
              name={`participants[${index}].firstName`}
              value={formik.values.participants[index].firstName}
              onChange={(e) =>
                handleInputChange(formik, e, `participants[${index}].firstName`)
              }
              error={formik.errors.participants?.[index]?.firstName}
              disabled
            />
            <FormTextField
              label={getString("last_name")}
              name={`participants[${index}].lastName`}
              value={formik.values.participants[index].lastName}
              onChange={(e) =>
                handleInputChange(formik, e, `participants[${index}].lastName`)
              }
              error={formik.errors.participants?.[index]?.lastName}
              touched={formik.touched.participants?.[index]?.lastName}
              disabled
            />
            <FormTextField
              label={getString("email")}
              name={`participants[${index}].email`}
              type="email"
              value={formik.values.participants[index].email}
              onChange={(e) =>
                handleInputChange(formik, e, `participants[${index}].email`)
              }
              error={formik.errors.participants?.[index]?.email}
              touched={formik.touched.participants?.[index]?.email}
              disabled
            />

            <FormPhoneField
              label={getString("phone_number")}
              name={`participants[${index}].phoneNumber`}
              phonePrefixName={`participants[${index}].phonePrefix`}
              value={formik.values.participants[index].phoneNumber}
              onChange={(e) => handlePhoneChange(formik, e)}
              phonePrefix={formik.values.participants[index].phonePrefix}
              onPrefixChange={(e) => handlePhoneChange(formik, e)}
              error={
                formik.errors.participants?.[index]?.phoneNumber ||
                formik.errors.participants?.[index]?.phonePrefix
              }
              setError={(error) =>
                formik.setFieldError(
                  `participants[${index}].phoneNumber`,
                  error,
                )
              }
              touched={
                formik.touched.participants?.[index]?.phoneNumber ||
                formik.touched.participants?.[index]?.phonePrefix
              }
              disabled
            />
            <FormDateField
              label={getString("date_of_birth")}
              name={`participants[${index}].dateOfBirth`}
              value={formik.values.participants[index].dateOfBirth}
              onChange={(e) => {
                handleInputChange(
                  formik,
                  e,
                  `participants[${index}].dateOfBirth`,
                );
              }}
              error={formik.errors.participants?.[index]?.dateOfBirth}
              touched={formik.touched.participants?.[index]?.dateOfBirth}
              disabled
            />
            <FormTextField
              label={getString("comment")}
              name={`participants[${index}].comment`}
              value={formik.values.participants[index].comment}
              onChange={(e) =>
                handleInputChange(formik, e, `participants[${index}].comment`)
              }
              error={formik.errors.participants?.[index]?.comment}
              touched={formik.touched.participants?.[index]?.comment}
              disabled
            />

            <FormSelect
              name={`participants[${index}].upsells.diet`}
              id={`participants[${index}].upsells.diet`}
              label={getString("diet")}
              isMulti={false}
              options={dietOptions}
              value={dietOptions.find(
                (r) =>
                  r.value === formik.values.participants[index].upsells.diet,
              )}
              onChange={(selectedOption) => {
                handleSelectChange(
                  formik,
                  selectedOption,
                  `participants[${index}].upsells.diet`,
                );
              }}
              error={formik.errors.participants?.[index]?.upsells?.diet}
            />

            <FormSelect
              name={`participants[${index}].upsells.insurance`}
              id={`participants[${index}].upsells.insurance`}
              label={getString("insurance")}
              isMulti={false}
              options={insuranceOptions}
              value={insuranceOptions.find(
                (r) =>
                  r.value ===
                  formik.values.participants[index].upsells.insurance,
              )}
              onChange={(selectedOption) => {
                handleSelectChange(
                  formik,
                  selectedOption,
                  `participants[${index}].upsells.insurance`,
                );
              }}
              error={formik.errors.participants?.[index]?.upsells?.insurance}
            />

            <FormSelect
              name={`participants[${index}].upsells.upsells`}
              id={`participants[${index}].upsells.upsells`}
              label={getString("upsells")}
              isMulti={true}
              options={upsellOptions}
              value={formik.values.participants[index].upsells.upsells}
              defaultValue={formik.values.participants[
                index
              ].upsells.upsells?.map((upsellValue) =>
                upsellOptions.find((r) => r.value === upsellValue),
              )}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions?.map(
                  (option) => option.value,
                );
                formik.setFieldValue(
                  `participants[${index}].upsells.upsells`,
                  selectedValues,
                );
              }}
              error={formik.errors.participants?.[index]?.upsells?.upsells}
            />
            {formik.errors.participants?.[index]?.upsells &&
              typeof formik.errors.participants[index].upsells === "string" && (
                <div className="text-danger">
                  {formik.errors.participants[index].upsells}
                </div>
              )}
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default EditParticipantOffcanvas;
