import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import "./ComparisonContainer.scss";
import OldProgram from "./OldProgram";
import NewProgram from "./NewProgram";
import EditParticipantOffcanvas from "./EditParticipantOffcanvas";
import EditProgramOffcanvas from "./EditProgramOffcanvas";

const ComparisonContainer = () => {
  const [offcanvasVisible, setOffcanvasVisible] = useState({
    participant: false,
    program: true,
  });
  const [editData, setEditData] = useState(null);

  const handleEdit = (data, type) => {
    setEditData(data);
    setOffcanvasVisible((prev) => ({ ...prev, [type]: true }));
  };

  return (
    <>
      <Card className="comparison-card mb-4">
        <CardBody>
          <Row>
            <OldProgram />
            <Col md={1} className="separator">
              <div className="vertical-line"></div>
            </Col>
            <NewProgram handleEdit={handleEdit} />
          </Row>
        </CardBody>
      </Card>

      {offcanvasVisible.participant && (
        <EditParticipantOffcanvas
          isOpen={offcanvasVisible.participant}
          toggle={() =>
            setOffcanvasVisible((prev) => ({ ...prev, participant: false }))
          }
          editData={editData || {}}
        />
      )}

      {offcanvasVisible.program && (
        <EditProgramOffcanvas
          isOpen={offcanvasVisible.program}
          toggle={() =>
            setOffcanvasVisible((prev) => ({ ...prev, program: false }))
          }
          editData={editData || {}}
        />
      )}
    </>
  );
};

export default ComparisonContainer;
