import React from "react";
import { getString } from "Components/Common/FormattedString";
import FormDateRange from "Components/Entity/FormDateRange";

const CreatedAtFilter = ({ onChange, filters }) => {
  const handleDateChange = (e, name) => {
    onChange("createdAt", {
      ...filters.createdAt,
      [name]: e.target.value || undefined,
    });
  };

  return (
    <FormDateRange
      label={getString("created_at")}
      startName="from"
      endName="to"
      startValue={filters.createdAt?.from || ""}
      endValue={filters.createdAt?.to || ""}
      onChange={handleDateChange}
      startError={filters.createdAt?.startError}
      endError={filters.createdAt?.endError}
      data-testid="date-range-createdAt"
      min={filters.createdAt?.minDate}
      max={filters.createdAt?.maxDate}
    />
  );
};

export default CreatedAtFilter;
