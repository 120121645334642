import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getApplicationsListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { format } from "date-fns";
import CustomIdCell from "pages/Applications/cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "pages/Applications/cells/InstallmentsCell/InstallmentsCell";
import PackageCell from "pages/Applications/cells/PackageCell/PackageCell";
import GenderCell from "pages/Applications/cells/GenderCell/GenderCell";
import ParticipantNameCell from "pages/Applications/cells/ParticipantNameCell/ParticipantNameCell";
import ParticipantAgeCell from "pages/Applications/cells/ParticipantAgeCell/ParticipantAgeCell";
import { handleCopyToClipboard } from "helpers/utils";

const ApplicationsList = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const maxResults = 10;

  const searchQuery = searchParams.get("searchTerm");

  const fetchApplications = useCallback(
    debounce((page, searchValue) => {
      setLoading(true);
      getApplicationsListPromise(page, maxResults, searchValue)
        .then((response) => {
          setApplications(response.data);
          setCount(response.count);
          setCurrentPage(response.currentPage);
          setTotalPages(response.totalPages);
          setPageSize(response.pageSize);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_applications"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchApplications(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchApplications]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const getDueDaysStyle = (value) => {
    if (value <= 0) {
      return { color: "red", fontWeight: "bold" };
    }
    if (value <= 5) {
      return { color: "orange", fontWeight: "bold" };
    }
    return { fontWeight: "normal" };
  };

  const columns = useMemo(
    () => [
      {
        id: "createdAt",
        Header: getString("created_date"),
        accessor: (d) => format(new Date(d.createdAt), "yyyy-MM-dd"),
        Cell: ({ value }) => <div style={{ color: "#6c757d" }}>{value}</div>,
      },
      {
        id: "id",
        Header: "ID",
        accessor: (row) => (
          <CustomIdCell
            customId={row.customId}
            to={`/pp-application/${row.id}`}
          />
        ),
        disableSortBy: true,
      },
      {
        id: "applicationStatus",
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <div
            className="text-nowrap"
            style={{ color: value === "moved" ? "red" : "inherit" }}
          >
            <FormattedString id={value} />
          </div>
        ),
      },
      {
        id: "installments_short",
        Header: getString("installments_short"),
        accessor: (d) => (
          <InstallmentsCell
            installmentsPaid={d.installmentsPaid}
            installmentsCount={d.installmentsCount}
            applicationStatus={d.applicationStatus}
          />
        ),
        disableSortBy: true,
      },
      {
        id: "payments",
        Header: getString("payments"),
        accessor: (d) => `${d.paidAmount} / ${d.totalAmount}`,
        Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
        disableSortBy: true,
      },
      {
        id: "customerId",
        Header: getString("client_name"),
        accessor: (d) => (
          <Link to={`/customer/${d.customerId}`}>
            {`${d.customerFirstName || ""} ${d.customerLastName || ""}`.trim() ||
              "-"}
          </Link>
        ),
      },
      {
        id: "email",
        Header: getString("email"),
        accessor: (d) => (
          <div
            className="cell-email d-flex align-items-center"
            style={{ gap: "8px", color: "#6c757d" }}
          >
            <i
              className="ri-clipboard-line"
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(d.customerEmail)}
            ></i>
            <span
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(d.customerEmail)}
            >
              {d.customerEmail}
            </span>
          </div>
        ),
        disableSortBy: true,
      },
      {
        id: "program",
        Header: getString("program"),
        accessor: "programName",
      },
      {
        id: "week",
        Header: getString("week"),
        accessor: "week",
        disableSortBy: true,
      },
      {
        id: "programStartDate",
        Header: getString("date"),
        accessor: (d) => (
          <div>
            <div>{format(new Date(d.dateStart), "yyyy-MM-dd")} - </div>
            <div>{format(new Date(d.dateEnd), "yyyy-MM-dd")}</div>
          </div>
        ),
      },
      {
        id: "package",
        Header: getString("package"),
        accessor: (d) => (
          <PackageCell
            packages={d.participants?.map((p) => p.programPacketName)}
          />
        ),
      },
      {
        id: "participantAge",
        Header: getString("age"),
        accessor: (d) => (
          <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
        ),
      },
      {
        id: "gender",
        Header: getString("gender"),
        accessor: (d) => (
          <GenderCell genders={d.participants?.map((p) => p.gender)} />
        ),
        disableSortBy: true,
      },
      {
        id: "participant_name",
        Header: getString("participant_name"),
        accessor: (d) => (
          <ParticipantNameCell
            names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
          />
        ),
        disableSortBy: true,
      },
      {
        id: "due_days",
        Header: getString("due_days"),
        accessor: "dueDays",
        Cell: ({ value }) => (
          <div style={getDueDaysStyle(value)}>
            {value !== null ? value : "-"}
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <Card>
      <CardHeader>
        <h5 style={{ marginBottom: 0 }}>
          <FormattedString id="applications" />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <TableContainerExternalPagination
            columns={columns || []}
            count={count}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            loading={loading}
            globalFilter={searchQuery}
            setGlobalFilter={() => {}}
            onPageChange={handlePageChange}
            data={applications || []}
            isBordered={false}
            isGlobalFilter={true}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            SearchPlaceholder={getString("applications_search_for")}
            error={null}
            onRetry={() => fetchApplications(currentPage, searchQuery)}
            preventLoading={false}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ApplicationsList;
