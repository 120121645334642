import React from "react";
import FormSwitch from "Components/Entity/FormSwitch";
import { getString } from "Components/Common/FormattedString";

const ShowOnlyOverdueFilter = ({ onChange, filters }) => {
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    onChange("onlyOverdue", isChecked ? true : null);
  };

  return (
    <FormSwitch
      label={getString("show_only_overdue")}
      name="onlyOverdue"
      checked={filters.onlyOverdue || false}
      onChange={handleSwitchChange}
      data-testid="switch-filter-showOnlyOverdue"
      noPadding
    />
  );
};

export default ShowOnlyOverdueFilter;
