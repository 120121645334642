import React from "react";
import "./PackageBadge.scss";
import classNames from "classnames";

const PackageBadge = ({ packageLabel, packageText }) => {
  const className =
    "package-badge--" +
    packageLabel
      .toLowerCase()
      .replace(/[\s()]/g, "_")
      .replace(/[^a-z0-9_]/g, "")
      .replace(/__+/g, "_")
      .replace(/_+$/, "");

  return (
    <div className="package-badge__container">
      <div className={classNames("package-badge", className)}>
        {packageText || packageLabel}
      </div>
    </div>
  );
};

export default PackageBadge;
