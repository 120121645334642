import React from "react";
import { Button, Row, Col, Spinner } from "reactstrap";
import "./ApplicationMoveStepNavigation.scss";
import { FormattedString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import { STEP_SELECT_PROGRAM } from "../../provider/utils/stepsConfig";

const ApplicationMoveStepNavigation = () => {
  const { currentStep, handleStepChange, handleSave, submitting } =
    useFormContext();

  return (
    <Row
      className="justify-content-center mt-4 application-move-step-nav"
      style={{ width: "100%" }}
    >
      <Col xs="auto" className="d-flex gap-2">
        <Button
          color="secondary"
          onClick={() => handleStepChange(-1)}
          disabled={currentStep === STEP_SELECT_PROGRAM || submitting}
          data-testid="previous-button"
        >
          <FormattedString id="previous" />
        </Button>
        {currentStep < 4 && (
          <Button
            color="primary"
            onClick={() => handleStepChange(1)}
            disabled={submitting}
            data-testid="next-button"
          >
            {submitting ? <Spinner size="sm" /> : <FormattedString id="next" />}
          </Button>
        )}
        {currentStep === 4 && ( //last  step
          <Button
            color="success"
            onClick={handleSave}
            disabled={submitting}
            data-testid="submit-button"
          >
            {submitting ? (
              <Spinner size="sm" />
            ) : (
              <FormattedString id="submit" />
            )}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ApplicationMoveStepNavigation;
