import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import TableContainer from "Components/Common/TableContainerReactTable";
import { getIpParticipantListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { FormattedString, getString } from "Components/Common/FormattedString";
import parsePhoneNumberFromString from "libphonenumber-js";
import { format } from "date-fns";

const IpParticipantsList = () => {
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("searchTerm") || "";

  const fetchParticipants = useCallback(
    debounce(() => {
      setLoading(true);
      getIpParticipantListPromise()
        .then((response) => {
          setParticipants(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_participants"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);

  useEffect(() => {
    const newParticipants = [...participants];
    const filtered = newParticipants.filter((participant) =>
      `${participant.firstName} ${participant.lastName}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
    setFilteredParticipants(filtered);
  }, [participants, searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: (cellProps) => `${cellProps.firstName} ${cellProps.lastName}`,
        Cell: (cellProps) => (
          <div className="d-flex align-items-center gap-2 text-bold">
            <i
              className={`ri-${cellProps.row.original.status === "active" ? "checkbox-circle-line" : "close-circle-line"} text-${cellProps.row.original.status === "active" ? "success" : "danger"}`}
              title={getString(cellProps.row.original.status)}
            ></i>
            <Link to={`/ip-participants/${cellProps.row.original.id}`}>
              {cellProps.row.original.firstName}{" "}
              {cellProps.row.original.lastName}
            </Link>
            {cellProps.row.original.status === "blocked" && (
              <i className="ri-lock-line ml-2" title="Blocked"></i>
            )}
          </div>
        ),
      },
      {
        Header: getString("email"),
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <a
            href={`mailto:${cellProps.value}`}
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {cellProps.value}
          </a>
        ),
      },
      {
        Header: getString("gender"),
        accessor: "gender",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{cellProps.value}</span>
        ),
      },
      {
        Header: getString("country_of_origin"),
        accessor: "countryOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{getString(cellProps.value)}</span>
        ),
      },
      {
        Header: getString("phone_number"),
        accessor: (cellProps) =>
          `${cellProps.phonePrefix} ${cellProps.phoneNumber}`,
        Cell: (cellProps) => {
          const phoneNumber = parsePhoneNumberFromString(
            `+${cellProps.row.original.phonePrefix}${cellProps.row.original.phoneNumber}`,
          );
          return (
            <span>{phoneNumber ? phoneNumber.formatInternational() : ""}</span>
          );
        },
      },
      {
        Header: getString("date_of_birth"),
        accessor: "dateOfBirth",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span>{format(new Date(cellProps.value), "dd-MM-yyyy")}</span>
        ),
      },
      {
        Header: getString("preferred_diet"),
        accessor: "preferredDiet",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{cellProps.value}</span>
        ),
      },
    ],
    [],
  );

  return (
    <Card>
      <CardHeader>
        <h5 style={{ marginBottom: 0 }}>
          <FormattedString id="ip_participants" />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <TableContainer
            key={searchQuery}
            columns={columns || []}
            data={filteredParticipants || []}
            isPagination={true}
            isGlobalFilter={false}
            isBordered={false}
            customPageSize={10}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            SearchPlaceholder={getString("participants_search_for")}
            error={null}
            loading={loading}
            onRetry={() => fetchParticipants()}
            excludeUrlHandling
          />
        )}
      </CardBody>
    </Card>
  );
};

export default IpParticipantsList;
