import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormSelect } from "Components/Entity/FormSelect";
import { useFormContext } from "../../provider/utils/FormContext";
import { handleSelectChange } from "helpers/validation_helper";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getProgramListData } from "store/programs/action";
import { programStatus } from "models/programStatuses";
import ApplicationMoveStepNavigation from "../../components/ApplicationMoveStepNavigation/ApplicationMoveStepNavigation";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import "./SelectProgramStep.scss";

const SelectProgramStep = () => {
  const dispatch = useDispatch();
  const { formik, setParentProgram } = useFormContext();
  const { loading: programsLoading, programsData } = useSelector(
    (state) => state.Programs,
  );
  const [loadingProgram, setLoadingProgram] = useState(false);

  const handleProgramChange = (selectedOption) => {
    setLoadingProgram(true);
    formik.setFieldValue("program", selectedOption.value);
    getProgram(selectedOption.id)
      .then((res) => {
        if (res) {
          setParentProgram(res);
          handleSelectChange(formik, selectedOption, "program");
        }
      })
      .finally(() => {
        setLoadingProgram(false);
      });
  };

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const programOptions = programsData
    .filter((program) => [programStatus.on_sale].includes(program.status))
    .map((program) => ({
      value: program.customId,
      id: program.id,
      label: `#${program.customId} ${program.name} ${new Date(program.startDate).toLocaleDateString()} - ${new Date(program.endDate).toLocaleDateString()}`,
    }));

  return (
    <div className="select-program-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="select_program" />
          </h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          {programsLoading ? (
            <PreloaderWrap />
          ) : (
            <div className="position-relative">
              <FormSelect
                name="program"
                id="program"
                label={getString("program")}
                isMulti={false}
                options={programOptions}
                defaultValue={programOptions.find(
                  (o) => o.value === formik.values.program,
                )}
                value={formik.values.program}
                onChange={handleProgramChange}
                error={formik.errors.program}
                disabled={loadingProgram}
              />
              {loadingProgram && (
                <Spinner
                  size="sm"
                  color="primary"
                  className="position-absolute"
                  style={{
                    top: "38px",
                    right: "10px",
                    backgroundColor: "white",
                  }}
                />
              )}
            </div>
          )}
        </Col>
      </Row>
      <ApplicationMoveStepNavigation />
    </div>
  );
};

export default SelectProgramStep;
