import React, { createContext, useContext, useRef, useState } from "react";
import { searchMenuItems } from "./utils";
import { handleClose as originalHandleClose } from "./utils";
import "./Search.scss";
import { useOnOutsideClick, useLocalStorage } from "Components/Hooks/UserHooks";
import { getString } from "Components/Common/FormattedString";

const SearchContext = createContext();

export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const clearButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const formRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [recentSearches, setRecentSearches] = useLocalStorage(
    "recentSearches",
    [],
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);

  const openDropdown = (dropdown) => {
    dropdown.classList.add("show");
    if (dropdownRef.current && dropdownRef.current.firstChild) {
      dropdownRef.current.firstChild.scrollTop = 0;
    }
    setIsDropdownOpen(true);
  };

  const closeDropdown = (dropdown) => {
    dropdown.classList.remove("show");
    setIsDropdownOpen(false);
  };

  const handleClose = (dropdown) => {
    if (
      searchInputRef.current &&
      searchInputRef.current === document.activeElement
    ) {
      return;
    }

    if (searchValue) {
      const updatedRecentSearches = [searchValue, ...recentSearches].slice(
        0,
        5,
      );
      setRecentSearches(updatedRecentSearches);
    }
    originalHandleClose(dropdown);
    setIsDropdownOpen(false);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    const filteredItems = searchMenuItems(value, getString);
    setFilteredMenuItems(filteredItems);
  };

  const updateRecentSearches = (newSearch) => {
    const updatedRecentSearches = [...newSearch].slice(-5);
    setRecentSearches(updatedRecentSearches);
  };

  useOnOutsideClick([dropdownRef, searchInputRef, formRef], () =>
    handleClose(dropdownRef.current),
  );

  return (
    <SearchContext.Provider
      value={{
        clearButtonRef,
        dropdownRef,
        searchInputRef,
        formRef,
        searchValue,
        setSearchValue: handleSearchChange,
        recentSearches,
        setRecentSearches: updateRecentSearches,
        openDropdown,
        closeDropdown,
        handleClose,
        isDropdownOpen,
        filteredMenuItems,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
