import "./FormSelect.scss";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import Select from "react-select";
import { FormFeedback, Label } from "reactstrap";

export const FormSelect = ({
  label,
  name,
  value,
  options,
  error,
  warning,
  "data-testid": dataTestId,
  ...rest
}) => {
  return (
    <div className="mb-3" data-testid={dataTestId || `select-${name}`}>
      {(label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      <Select
        name={name}
        options={options}
        value={options.find((v) => v === value)}
        className={classNames([
          "form-select",
          warning && "is-warning",
          !warning && error && "is-invalid",
        ])}
        isMulti={false}
        classNamePrefix="form-select"
        {...rest}
        padding="none"
      />
      {warning && (
        <FormFeedback className="warning-feedback">
          <i className="ri-error-warning-line"></i>
          {warning}
        </FormFeedback>
      )}
      {!warning && error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
