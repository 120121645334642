import "./IpApplicationDetails.scss";
import { useParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import {
  getIpApplication,
  getIpParticipantApplications,
} from "helpers/API/core-service/cs_backend_helper";
import { getMeetingPointListData } from "store/meetingPoints/action";
import { useDispatch } from "react-redux";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { programStatus } from "models/programStatuses";
import { getPointOfSellListData } from "store/pointOfSell/action";
import { IpApplicationHeader } from "./partials/IpApplicationHeader";
import { IpApplicationOverviewTab } from "./partials/Tabs/IpApplicationOverviewTab";

export const IpApplicationDetailsContext = createContext();

const IpApplicationDetailsContent = () => {
  const { id } = useParams();

  const [applicationData, setApplicationData] = useState();
  const [applicationLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [applicationErrors, setApplicationErrors] = useState();
  const [activeTab, setActiveTab] = useState("overview");
  const [editTileOpen, setEditTileOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [ipApplications, setIpApplications] = useState([]);
  const [ipApplicationsLoading, setIpApplicationsLoading] = useState(false);
  const [parentProgram, setParentProgram] = useState(null);

  const refreshApplicationData = () => {
    getIpApplication(id)
      .then((r) => {
        setApplicationData(r);
        document.title = `${r.customId} | ${r.internationalParticipant.firstName} ${r.internationalParticipant.lastName} | Angloville - CRM Panel`;

        refreshIpApplications(r.internationalParticipant.id);

        setLoading(false);
      })
      .catch((e) => console.error(e));
  };

  const refreshIpApplications = (participantId, page = 1) => {
    setIpApplicationsLoading(true);
    getIpParticipantApplications(participantId, page, "")
      .then((r) => {
        setIpApplications(r);

        if (!selectedApplication) {
          const applicationById = r.data.find((app) => app.id === id);
          setSelectedApplication(applicationById);
        }
        setIpApplicationsLoading(false);
      })
      .catch(() => {
        setIpApplicationsLoading(false);
      });
  };

  const dispatch = useDispatch();

  const canUpdateApplication =
    useHasPermission("PP_APPLICATION_UPDATE") &&
    applicationData?.status === programStatus.in_preparation;

  useEffect(() => {
    setSelectedApplication(null);
    refreshApplicationData();
    dispatch(getMeetingPointListData());
    dispatch(getPointOfSellListData());
  }, [dispatch, id]);

  useEffect(() => {
    setEditTileOpen(location.pathname.includes("/edit"));
  }, [location.pathname]);

  return loading === false ? (
    <div
      key={selectedApplication}
      className="page-content ip-application-details"
    >
      <Container fluid className="mb-4">
        <IpApplicationDetailsContext.Provider
          value={{
            applicationData,
            setApplicationData,
            activeTab,
            setActiveTab,
            refreshApplicationData,
            applicationErrors,
            setApplicationErrors,
            canUpdateApplication,
            applicationLogs,
            editTileOpen,
            setEditTileOpen,
            selectedApplication,
            setSelectedApplication,
            ipApplications,
            ipApplicationsLoading,
            refreshIpApplications,
            parentProgram,
            setParentProgram,
          }}
        >
          <IpApplicationHeader />
          <IpApplicationOverviewTab />
        </IpApplicationDetailsContext.Provider>
      </Container>
    </div>
  ) : (
    <PreloaderWrap h100 />
  );
};

export const IpApplicationDetails = () => {
  const { id } = useParams();
  return <IpApplicationDetailsContent key={id} />;
};
