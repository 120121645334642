// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installments-preview.empty-state {
  text-align: center;
  padding: 20px;
  color: #888;
}
.installments-preview .details-box__table {
  height: 1px;
}
.installments-preview .details-box__table .details-box__cell-content {
  padding: 15px 10px;
  border-bottom: solid var(--av-border-color) 1px;
  height: 100%;
}
.installments-preview > .details-box__content > table > tbody > tr > td:first-child {
  padding: 0;
  width: 32%;
  font-weight: 500;
  color: var(--av-bold-color);
}
.installments-preview > .details-box__content > table > tbody > tr > td:first-child .details-box__cell-content {
  display: flex;
  align-items: center;
  padding: 10px;
}
.installments-preview > .details-box__content > table > tbody > tr > td:nth-child(2) {
  padding: 0;
  color: var(--av-text-color);
  font-weight: 400;
}
.installments-preview > .details-box__content > table > tbody > tr > td:nth-child(2) .details-box__cell-content {
  display: flex;
  align-items: center;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DetailsBox/fields/InstallmentsPreview/InstallmentsPreview.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAEI;EACE,kBAAA;EACA,+CAAA;EACA,YAAA;AAAN;AAKI;EACE,UAAA;EACA,UAAA;EACA,gBAAA;EACA,2BAAA;AAHN;AAKM;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AAHR;AAMI;EACE,UAAA;EACA,2BAAA;EACA,gBAAA;AAJN;AAMM;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AAJR","sourcesContent":[".installments-preview {\n  &.empty-state {\n    text-align: center;\n    padding: 20px;\n    color: #888;\n  }\n\n  .details-box__table {\n    height: 1px;\n    .details-box__cell-content {\n      padding: 15px 10px;\n      border-bottom: solid var(--av-border-color) 1px;\n      height: 100%;\n    }\n  }\n\n  & > .details-box__content > table > tbody > tr {\n    & > td:first-child {\n      padding: 0;\n      width: 32%;\n      font-weight: 500;\n      color: var(--av-bold-color);\n\n      .details-box__cell-content {\n        display: flex;\n        align-items: center;\n        padding: 10px;\n      }\n    }\n    & > td:nth-child(2) {\n      padding: 0;\n      color: var(--av-text-color);\n      font-weight: 400;\n\n      .details-box__cell-content {\n        display: flex;\n        align-items: center;\n        padding: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
