import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { getCustomersListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { Spinner } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";

const CustomersList = () => {
  const {
    searchValue,
    setSearchValue,
    closeDropdown,
    dropdownRef,
    recentSearches,
    setRecentSearches,
  } = useSearch();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxResults = 5;

  const fetchCustomers = useCallback(
    debounce((searchValue) => {
      setLoading(true);
      getCustomersListPromise(1, maxResults, searchValue)
        .then((response) => {
          setCustomers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_customers"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchCustomers(searchValue);
  }, [searchValue, fetchCustomers]);

  const handleLinkClick = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
    }
    setSearchValue("");
    closeDropdown(dropdownRef.current);
  };

  if (customers.length === 0 && !loading) {
    return null;
  }

  return (
    <>
      <div className="dropdown-header mt-2">
        <h6 className="text-overflow text-muted mb-1 text-uppercase">
          <FormattedString id="customers" />
        </h6>
      </div>
      {loading ? (
        <div className="text-center my-3">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {customers.map((customer, index) => (
            <Link
              key={index}
              to={`/customer/${customer.id}`}
              className="dropdown-item notify-item py-2"
              onClick={handleLinkClick}
            >
              <div className="d-flex">
                <i className="ri-user-line me-3 fs-18 text-muted"></i>
                <div className="flex-grow-1">
                  <h6 className="m-0">{`${customer.firstName} ${customer.lastName}`}</h6>
                  <span className="fs-11 mb-0 text-muted">
                    {customer.email}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default CustomersList;
