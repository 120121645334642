import React from "react";
import { FormattedString, getString } from "Components/Common/FormattedString";

export const DetailsBoxTabSelect = () => {
  return (
    <div className="details-box__tab-select">
      <div className="details-box__tab-select__btn details-box__tab-select__btn--active">
        <FormattedString id={getString("details")} />
      </div>
      {/* <div className="details-box__tab-select__btn">
        <FormattedString id={getString("specification")} />
      </div> */}
    </div>
  );
};
