import React from "react";
import { Row, Col } from "reactstrap";
import InstallmentFormFields from "../InstallmentFormFields/InstallmentFormFields";

const InstallmentList = ({
  installments = [],
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  advancedManagement,
  currentInstallmentCount,
  installmentPlans,
}) => {
  return (
    <>
      {installments.map((installment, index) => (
        <InstallmentFormFields
          key={index}
          installment={installment}
          index={index}
          formik={formik}
          installmentTypes={installmentTypes}
          pointOfSellOptions={pointOfSellOptions}
          removeInstallment={removeInstallment}
          isDisabled={installment.isPaid}
          advancedManagement={advancedManagement}
          currentInstallmentCount={currentInstallmentCount}
          installmentPlans={installmentPlans}
        />
      ))}
      {typeof formik.errors.installments === "string" && (
        <Row className="mb-3">
          <Col>
            <div className="text-danger">{formik.errors.installments}</div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default InstallmentList;
