import React, { useContext, useMemo, useState } from "react";
import "./Payments.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useTable } from "react-table";
import { Table, Row, Col, Progress } from "reactstrap";
import { format, differenceInDays } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { splitNumbers } from "helpers/utils";
import { toast_success } from "helpers/toast_helper";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import MarkAsPaidOffcanvas from "./MarkAsPaidOffCanvas";

export const Payments = () => {
  const {
    applicationData: { applicationInstallments, id, customId },
    updateApplicationInstallments,
  } = useContext(ApplicationDetailsContext);

  const [offcanvasVisible, setOffcanvasVisible] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const openOffcanvas = () => setOffcanvasVisible(true);
  const closeOffcanvas = () => setOffcanvasVisible(false);

  const handleMarkAsPaid = (installment) => {
    setSelectedInstallment(installment);
    openOffcanvas();
  };

  const updatePaymentStatus = (installmentId, paymentDate) => {
    const updatedInstallments = applicationInstallments.map((payment) =>
      payment.id === installmentId
        ? { ...payment, paidAt: paymentDate, isPaid: true }
        : payment,
    );
    updateApplicationInstallments(updatedInstallments);
  };

  const data = useMemo(() => {
    return (applicationInstallments || []).sort(
      (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
    );
  }, [applicationInstallments]);

  const canMarkAsPaid = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const firstUnpaidIndex = useMemo(() => {
    return data.findIndex((payment) => !payment.isPaid && !payment.isPaid);
  }, [data]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "#",
        accessor: (row, i) => i + 1,
        Cell: ({ row }) => row.index + 1,
        style: { width: "20px" },
        className: "index-column",
      },
      {
        Header: getString("paymentDate"),
        accessor: "paymentDate",
        Cell: ({ row }) => {
          const { paidAt, dueDate, isPaid } = row.original;
          return isPaid
            ? format(new Date(paidAt || dueDate), "yyyy-MM-dd")
            : format(new Date(dueDate), "yyyy-MM-dd");
        },
      },
      {
        Header: getString("amount"),
        accessor: (row) => `${splitNumbers(row.price)} ${row.currency}`,
        Cell: ({ value }) => value,
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value }) => getString(value),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: ({ row }) => {
          const { isOverDue, dueDate, isPaid } = row.original;
          const daysUntilDue = differenceInDays(new Date(dueDate), new Date());
          let status = "Future";
          let statusClass = "future";

          if (isPaid) {
            status = "Paid";
            statusClass = "paid";
          } else if (isOverDue) {
            status = "Overdue";
            statusClass = "overdue";
          } else if (daysUntilDue <= 5) {
            status = "Awaiting";
            statusClass = "awaiting";
          }

          return (
            <div
              className={`d-flex align-items-center gap-1 status-text ${statusClass}`}
            >
              {status === "Paid" && (
                <i className="ri-checkbox-circle-line align-middle"></i>
              )}
              {status === "Future" && (
                <i className="ri-time-line align-middle"></i>
              )}
              {status === "Overdue" && (
                <i className="ri-close-circle-line align-middle"></i>
              )}
              {status === "Awaiting" && (
                <i className="ri-time-line align-middle"></i>
              )}{" "}
              {status}
            </div>
          );
        },
      },
      {
        Header: getString("action"),
        accessor: "action",
        Cell: ({ row }) => {
          const { isOverDue, isPaid } = row.original;
          const status = isPaid ? "Paid" : isOverDue ? "Overdue" : "Future";
          const [copied, setCopied] = useState(false);

          const baseUrl = process.env.REACT_APP_CUSTOMER_PANEL_URL;
          const paymentUrl = `${baseUrl}application/${customId}/payment-url`;
          const handleCopy = () => {
            if (!copied) {
              navigator.clipboard.writeText(paymentUrl).then(() => {
                setCopied(true);
                toast_success(getString("link_copied"));
                setTimeout(() => setCopied(false), 4000);
              });
            }
          };

          const actions = [];

          if (canMarkAsPaid && row.index === firstUnpaidIndex) {
            actions.push(
              <AngloTooltip stringId="mark_as_paid" key="mark_as_paid">
                <div
                  className="payments__mark_as_paid__icon"
                  onClick={() => handleMarkAsPaid(row.original)}
                  style={{ cursor: "pointer" }}
                >
                  $
                </div>
              </AngloTooltip>,
            );
          }

          if (status !== "Paid") {
            actions.push(
              <AngloTooltip
                stringId={copied ? "link_copied" : "copy_link"}
                key="copy_link"
              >
                <div
                  className={`payments__copy__icon ${copied ? "copied" : ""}`}
                  onClick={handleCopy}
                  style={{ pointerEvents: copied ? "none" : "auto" }}
                >
                  <i
                    className={copied ? "ri-check-line" : "ri-file-copy-line"}
                  ></i>
                </div>
              </AngloTooltip>,
            );
          }

          return actions.length > 0 ? (
            <div className="payments__copy d-flex gap-2">{actions}</div>
          ) : (
            "-"
          );
        },
        headerClassName: "text-end",
        className: "text-end",
      },
    ];

    return baseColumns;
  }, [canMarkAsPaid, firstUnpaidIndex]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const statusCounts = data.reduce(
    (acc, { isOverDue, dueDate, isPaid }) => {
      const daysUntilDue = differenceInDays(new Date(dueDate), new Date());
      let status = "Future";

      if (isPaid) {
        status = "Paid";
      } else if (isOverDue) {
        status = "Overdue";
      } else if (daysUntilDue <= 5) {
        status = "Awaiting";
      }

      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    { Overdue: 0, Awaiting: 0, Paid: 0, Future: 0 },
  );

  const total =
    statusCounts.Overdue +
    statusCounts.Awaiting +
    statusCounts.Paid +
    statusCounts.Future;

  return (
    <div className="payments">
      <div className="table-responsive mt-4 mt-xl-0">
        <Table
          {...getTableProps()}
          className="table-striped table-nowrap align-middle mb-0 table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    className={column.headerClassName}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const { isOverDue, dueDate, isPaid } = row.original;
              const daysUntilDue = differenceInDays(
                new Date(dueDate),
                new Date(),
              );
              let rowClassName = "";

              if (isPaid) {
                rowClassName = "table-success";
              } else if (isOverDue) {
                rowClassName = "table-danger";
              } else if (daysUntilDue <= 5) {
                rowClassName = "table-warning";
              }

              return (
                <tr {...row.getRowProps()} key={index} className={rowClassName}>
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      key={index}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row className="mt-3 justify-content-center">
        <Col
          className="d-flex align-items-center justify-content-center"
          key="overdue"
        >
          <div className="dot bg-danger me-2"></div>
          <span className="text-muted">
            <FormattedString id="overdue" />: {statusCounts.Overdue}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          key="awaiting"
        >
          <div className="dot bg-warning me-2"></div>
          <span className="text-muted">
            <FormattedString id="awaiting" />: {statusCounts.Awaiting}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          key="paid"
        >
          <div className="dot bg-success me-2"></div>
          <span className="text-muted">
            <FormattedString id="paid" />: {statusCounts.Paid}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          key="future"
        >
          <div
            className="dot me-2"
            style={{ backgroundColor: "#878a99" }}
          ></div>
          <span className="text-muted">
            <FormattedString id="future" />: {statusCounts.Future}
          </span>
        </Col>
      </Row>
      <Row className="mt-3" key="progress">
        <Col>
          <Progress multi>
            <Progress
              bar
              color="danger"
              value={(statusCounts.Overdue / total) * 100}
            />
            <Progress
              bar
              color="warning"
              value={(statusCounts.Awaiting / total) * 100}
            />
            <Progress
              bar
              color="success"
              value={(statusCounts.Paid / total) * 100}
            />
            <Progress
              bar
              style={{ backgroundColor: "#878a99" }}
              value={(statusCounts.Future / total) * 100}
            />
          </Progress>
        </Col>
      </Row>

      {offcanvasVisible && (
        <MarkAsPaidOffcanvas
          isOpen={offcanvasVisible}
          toggle={closeOffcanvas}
          selectedInstallment={selectedInstallment}
          applicationId={id}
          updatePaymentStatus={updatePaymentStatus}
        />
      )}
    </div>
  );
};
