import { getString } from "Components/Common/FormattedString";
import moment from "moment";

export const calculateMinMaxDate = (startDate, minAge, maxAge) => {
  const minDate = moment(startDate)
    .subtract(maxAge + 1, "years")
    .add(1, "days")
    .format("YYYY-MM-DD");
  const maxDate = moment(startDate)
    .subtract(minAge, "years")
    .format("YYYY-MM-DD");
  return { minDate, maxDate };
};

export const isValidYear = (dateOfBirth) => {
  const year = moment(dateOfBirth).year();
  return year >= 1000 && year <= 9999;
};

export const getProgramPacketWarning = (
  dateOfBirth,
  programPacket,
  programPacketOptions,
  parentProgram,
) => {
  if (!isValidYear(dateOfBirth)) {
    return null;
  }

  const packet = programPacketOptions.find(
    (packet) => packet.value === programPacket,
  );

  if (!packet) {
    return null;
  }

  const { minDate, maxDate } = calculateMinMaxDate(
    parentProgram.startDate,
    packet.ppMinAge,
    packet.ppMaxAge,
  );

  if (!moment(dateOfBirth).isBetween(minDate, maxDate, undefined, "[]")) {
    return getString("program_packet_outside_age_requirements");
  }

  return null;
};
