import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const ipTypeOptions = [
  { value: "ns", label: getStringSync("ns") },
  { value: "non_ns_ip", label: getStringSync("non_ns_ip") },
  { value: "esl", label: getStringSync("esl") },
];

export const ipDocumentTypeOptions = [
  { value: "id", label: getStringSync("id") },
  { value: "passport", label: getStringSync("passport") },
  { value: "residence_card", label: getStringSync("residence_card") },
  { value: "dbs_check", label: getStringSync("dbs_check") },
  { value: "travel_details", label: getStringSync("travel_details") },
  { value: "programme_deposit", label: getStringSync("programme_deposit") },
  { value: "crc", label: getStringSync("crc") },
  { value: "crc_dbs_fee", label: getStringSync("crc_dbs_fee") },
  { value: "crc_history", label: getStringSync("crc_history") },
  {
    value: "crc_sworn_declaration",
    label: getStringSync("crc_sworn_declaration"),
  },
  {
    value: "programme_compliance_agreement",
    label: getStringSync("programme_compliance_agreement"),
  },
  {
    value: "know_how_training_quiz_results",
    label: getStringSync("know_how_training_quiz_results"),
  },
  { value: "warning_letter", label: getStringSync("warning_letter") },
  { value: "termination_letter", label: getStringSync("termination_letter") },
  { value: "others", label: getStringSync("others") },
];

export const ipGroupedStatusOptions = [
  {
    label: getStringSync("initial_status"),
    options: [
      { value: "invited", label: getStringSync("invited") },
      {
        value: "awaiting_travel_details",
        label: getStringSync("awaiting_travel_details"),
      },
    ],
  },
  {
    label: getStringSync("confirmation_status"),
    options: [
      { value: "partly_confirmed", label: getStringSync("partly_confirmed") },
      { value: "confirmed", label: getStringSync("confirmed") },
      { value: "overbooked", label: getStringSync("overbooked") },
    ],
  },
  {
    label: getStringSync("attendance_status"),
    options: [
      { value: "wait_list", label: getStringSync("wait_list") },
      { value: "no_response", label: getStringSync("no_response") },
      { value: "uninvited", label: getStringSync("uninvited") },
      { value: "moved", label: getStringSync("moved") },
      { value: "dropped_out", label: getStringSync("dropped_out") },
      {
        value: "annulled_programme",
        label: getStringSync("annulled_programme"),
      },
      { value: "suspended", label: getStringSync("suspended") },
      { value: "expelled", label: getStringSync("expelled") },
    ],
  },
  {
    label: getStringSync("cancellation_status"),
    options: [
      { value: "14_day_remorse", label: getStringSync("14_day_remorse") },
      { value: "early_cancelled", label: getStringSync("early_cancelled") },
      { value: "cancelled", label: getStringSync("cancelled") },
      {
        value: "last_min_cancelled",
        label: getStringSync("last_min_cancelled"),
      },
      { value: "no_show", label: getStringSync("no_show") },
    ],
  },
];

const createIpParticipantSchema = Yup.object({
  gender: Yup.string().required(getStringSync("enter_ip_participant_gender")),
  firstName: Yup.string()
    .required(getStringSync("enter_ip_participant_first_name"))
    .min(4, getStringSync("invalid_length"))
    .max(32, getStringSync("invalid_length")),
  lastName: Yup.string()
    .required(getStringSync("enter_ip_participant_last_name"))
    .min(4, getStringSync("invalid_length"))
    .max(64, getStringSync("invalid_length")),
  email: Yup.string()
    .email(getStringSync("invalid_mail"))
    .required(getStringSync("mail_required"))
    .min(5, getStringSync("invalid_length"))
    .max(255, getStringSync("invalid_length")),
  phonePrefix: Yup.string()
    .required(getStringSync("enter_ip_participant_phone_prefix"))
    .matches(/^\d+$/, getStringSync("invalid_phone_prefix")),
  phoneNumber: Yup.string()
    .required(getStringSync("enter_ip_participant_phone_number"))
    .matches(/^\d+$/, getStringSync("invalid_phone_number")),
  dateOfBirth: Yup.date()
    .required(getStringSync("enter_ip_participant_date_of_birth"))
    .max(new Date(), getStringSync("invalid_date_of_birth")),
  countryOfOrigin: Yup.string().required(
    getStringSync("select_ip_participant_country_of_origin"),
  ),
  preferredDiet: Yup.string().required(
    getStringSync("enter_ip_participant_preferred_diet"),
  ),
});

export { createIpParticipantSchema, ipTypeOptions };

export const IpParticipantStatus = {
  INVITED: "invited",
  AWAITING_TRAVEL_DETAILS: "awaiting_travel_details",
  PARTLY_CONFIRMED: "partly_confirmed",
  CONFIRMED: "confirmed",
  OVERBOOKED: "overbooked",
};

export const ipColorMap = {
  [IpParticipantStatus.INVITED]: "rgb(128, 128, 128)",
  [IpParticipantStatus.AWAITING_TRAVEL_DETAILS]: "rgb(255, 0, 0)",
  [IpParticipantStatus.PARTLY_CONFIRMED]: "rgb(255, 165, 0)",
  [IpParticipantStatus.CONFIRMED]: "rgb(44, 160, 44)",
  [IpParticipantStatus.OVERBOOKED]: "rgb(44, 160, 44)",
  total_sum: "#299cdb",
  total_capacity: "black",
};
