import * as yup from "yup";
import { getStringSync } from "Components/Common/FormattedString";

const isValidYear = (value) => {
  const year = new Date(value).getFullYear();
  return year >= 1000 && year <= 9999;
};

const validationSchema = yup.object({
  program: yup.string().required(getStringSync("program_required")),
  programMeeting: yup
    .string()
    .required(getStringSync("program_meeting_required")),
  participants: yup
    .array()
    .of(
      yup.object({
        gender: yup.string().required(getStringSync("gender_required")),
        firstName: yup.string().required(getStringSync("first_name_required")),
        lastName: yup.string().required(getStringSync("last_name_required")),
        comment: yup.string(),
        email: yup.string().email(getStringSync("invalid_email")),
        phonePrefix: yup.string(),
        phoneNumber: yup
          .string()
          .nullable()
          .test(
            "is-valid-phone",
            getStringSync("phone_number_invalid"),
            (value) => !value || /^\d+$/.test(value),
          ),
        dateOfBirth: yup
          .string()
          .required(getStringSync("date_of_birth_required"))
          .test(
            "is-valid-year",
            getStringSync("date_of_birth_invalid_year"),
            (value) => isValidYear(value),
          ),
        programPacket: yup
          .string()
          .required(getStringSync("program_packet_required")),
        upsells: yup.object({
          diet: yup.string().required(getStringSync("diet_required")),
          insurance: yup.string().required(getStringSync("insurance_required")),
          upsells: yup.array().of(yup.string()),
        }),
      }),
    )
    .min(1, getStringSync("at_least_one_participant_required")),
  payerDetails: yup.object({
    payerType: yup.string().required(getStringSync("payer_type_required")),
    companyName: yup.string(),
    taxNumber: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    city: yup.string().required(getStringSync("city_required")),
    zipCode: yup.string().required(getStringSync("zip_code_required")),
    address: yup.string().required(getStringSync("address_required")),
  }),
  installments: yup
    .array()
    .of(
      yup.object({
        price: yup.number().required(getStringSync("price_required")),
        dueDate: yup.date().required(getStringSync("due_date_required")),
        currency: yup
          .string()
          .required(getStringSync("currency_required"))
          .length(3, getStringSync("currency_length")),
        type: yup.string().required(getStringSync("type_required")),
        pointOfSell: yup
          .string()
          .required(getStringSync("point_of_sell_required")),
      }),
    )
    .test(
      "dueDate-order",
      getStringSync("due_date_order"),
      function (installments) {
        if (!installments || installments.length < 2) {
          return true;
        }
        for (let i = 1; i < installments.length; i++) {
          if (
            new Date(installments[i].dueDate) <=
            new Date(installments[i - 1].dueDate)
          ) {
            return false;
          }
        }
        return true;
      },
    ),
});

export default validationSchema;
