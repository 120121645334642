import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useDispatch, useSelector } from "react-redux";
import { getIpParticipantListData } from "../../store/ipParticipants/action";
import EditIpParticipant from "./EditIpParticipant";
import DeleteIpParticipant from "./DeleteIpParticipant";
import { Badges } from "../../Components/Common/Badges/Badges";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
  splitNumbers,
} from "helpers/utils";
import { Link, useNavigate } from "react-router-dom";
import { IpParticipantDetails } from "./IpParticipantDetails";
import CustomActionButtons from "./components/CustomActionButtons/CustomActionButtons";
import { format } from "date-fns";
import AddIpParticipantAttachment from "./components/AddIpParticipantAttachment/AddIpParticipantAttachment";
import BlockIpModal from "./components/BlockIpModal/BlockIpModal";

const IpParticipantsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { ipParticipantsData, loading, error } = useSelector(
    (rootState) => rootState.IpParticipants,
  );

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [ipParticipantData, setIpParticipantData] = useState();
  const toggleEditModal = (ipParticipant, direction = false) => {
    setIpParticipantData(ipParticipant);
    setEditModalVisible(direction);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemId, setModalItemId] = useState(getItemUuid());
  const toggleDeleteModal = (id) => {
    id && setModalItemId(id);
    setDeleteModalVisible((p) => !p);
  };

  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const toggleAttachmentModal = (state, id) => {
    if (typeof state === "boolean") {
      setAttachmentModalVisible(state);
    } else {
      setAttachmentModalVisible((prev) => !prev);
    }
    setModalItemId(id);
  };

  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const toggleBlockModal = (state, id) => {
    setBlockModalVisible(state);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: (cellProps) => `${cellProps.firstName} ${cellProps.lastName}`,
        Cell: (cellProps) => (
          <div className="d-flex align-items-center gap-2 text-bold">
            <i
              className={`ri-${cellProps.row.original.status === "active" ? "checkbox-circle-line" : "close-circle-line"} text-${cellProps.row.original.status === "active" ? "success" : "danger"}`}
              title={getString(cellProps.row.original.status)}
            ></i>
            {cellProps.row.original.firstName} {cellProps.row.original.lastName}
            {cellProps.row.original.status === "blocked" && (
              <i className="ri-lock-line ml-2" title="Blocked"></i>
            )}
          </div>
        ),
      },
      {
        Header: getString("email"),
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <a
            href={`mailto:${cellProps.value}`}
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {cellProps.value}
          </a>
        ),
      },
      {
        Header: getString("gender"),
        accessor: "gender",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{cellProps.value}</span>
        ),
      },
      {
        Header: getString("country_of_origin"),
        accessor: "countryOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{getString(cellProps.value)}</span>
        ),
      },
      {
        Header: getString("phone_number"),
        accessor: (cellProps) =>
          `${cellProps.phonePrefix} ${cellProps.phoneNumber}`,
        Cell: (cellProps) => (
          <span>
            +{cellProps.row.original.phonePrefix}{" "}
            {splitNumbers(cellProps.row.original.phoneNumber)}
          </span>
        ),
      },
      {
        Header: getString("date_of_birth"),
        accessor: "dateOfBirth",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span>{format(new Date(cellProps.value), "dd-MM-yyyy")}</span>
        ),
      },
      {
        Header: getString("preferred_diet"),
        accessor: "preferredDiet",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <span className="text-capitalize">{cellProps.value}</span>
        ),
      },
      {
        Header: getString("type"),
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => <Badges feed={[cellProps.value]} />,
      },
      {
        Header: getString("documents"),
        accessor: "documents",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <Link
            onClick={(e) => {
              e.preventDefault();
              toggleDetailsModal(true, cellProps.row.original.id);
            }}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <i className="ri-file-list-line"></i>
            {cellProps.row.original.documents.length}
          </Link>
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => {
          const handleBlock =
            cellProps.status !== "blocked"
              ? () => toggleBlockModal(true, cellProps.id)
              : undefined;

          return (
            <CustomActionButtons
              onBlock={handleBlock}
              onEdit={() => toggleEditModal(cellProps, true)}
              onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
              itemId={cellProps.id}
              onUploadFiles={() => toggleAttachmentModal(true, cellProps.id)}
              permissionPrefix="IP_PARTICIPANT"
            />
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(getIpParticipantListData());
  }, [dispatch]);

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={ipParticipantsData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={5}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_participants_search_for")}
        error={error}
        loading={loading}
        onRetry={() => dispatch(getIpParticipantListData())}
        preventLoading={
          editModalVisible ||
          deleteModalVisible ||
          detailsModalVisible ||
          attachmentModalVisible ||
          blockModalVisible
        }
      />
      {editModalVisible && (
        <EditIpParticipant
          ipParticipant={ipParticipantData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
        />
      )}
      {deleteModalVisible && (
        <DeleteIpParticipant
          itemId={itemId}
          visibility={deleteModalVisible}
          toggle={toggleDeleteModal}
        />
      )}
      {detailsModalVisible && (
        <IpParticipantDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
      {attachmentModalVisible && (
        <AddIpParticipantAttachment
          itemId={itemId}
          visibility={attachmentModalVisible}
          toggle={toggleAttachmentModal}
        />
      )}
      {blockModalVisible && (
        <BlockIpModal
          itemId={itemId}
          visibility={blockModalVisible}
          toggle={toggleBlockModal}
        />
      )}
    </>
  );
};

export { IpParticipantsList };
