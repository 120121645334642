import {
  call,
  put,
  takeEvery,
  all,
  fork,
  takeLatest,
} from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

import {
  GET_PROGRAM_PACKETS_LIST_DATA,
  POST_PROGRAM_PACKET,
} from "./actionType";
import { programPacketsApiSuccess, programPacketsApiError } from "./action";

import {
  getProgramPacketsList,
  postProgramPacket,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getProgramPacketsListData({ payload: data }) {
  try {
    const { page, pageSize, searchTerm, orderBy, orderDirection, filters } =
      data;
    const response = yield call(
      getProgramPacketsList,
      page,
      pageSize,
      searchTerm,
      orderBy,
      orderDirection,
      filters,
    );

    yield put(
      programPacketsApiSuccess(GET_PROGRAM_PACKETS_LIST_DATA, response),
    );
  } catch (error) {
    yield put(programPacketsApiError(GET_PROGRAM_PACKETS_LIST_DATA, error));
  }
}

function* createProgramPacket({ payload: programPacket }) {
  try {
    const response = yield call(
      postProgramPacket,
      programPacket.data,
      programPacket.onSuccess,
    );
    yield put(programPacketsApiSuccess(POST_PROGRAM_PACKET, response));
    toast_success("Program packet added successfully");
  } catch (error) {
    yield put(programPacketsApiError(POST_PROGRAM_PACKET, error));
    toast_error(error.data.message);
  }
}

export function* watchGetProgramPacketsListData() {
  yield takeLatest(GET_PROGRAM_PACKETS_LIST_DATA, getProgramPacketsListData);
}

export function* watchPostProgramPacket() {
  yield takeEvery(POST_PROGRAM_PACKET, createProgramPacket);
}

function* programPacketsSaga() {
  yield all([
    fork(watchGetProgramPacketsListData),
    fork(watchPostProgramPacket),
  ]);
}

export default programPacketsSaga;
