import { FormattedString } from "Components/Common/FormattedString";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import { useContext } from "react";

export const CustomerDetails = () => {
  const {
    customerData: { firstName, lastName, phonePrefix, phoneNumber, email },
  } = useContext(CustomerDetailsContext);

  return (
    <div className="customer-details">
      <ul className="list-unstyled mb-0 vstack gap-3">
        <li>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
              <h6 className="fs-14 mb-1">
                {firstName || "N/A"} {lastName || "N/A"}
              </h6>
              <p className="text-muted mb-0">
                <FormattedString id="customer" />
              </p>
            </div>
          </div>
        </li>
        <li
          className="cell-email d-flex align-items-center"
          style={{ gap: "9px", color: "#6c757d", cursor: "pointer" }}
          onClick={() => handleCopyToClipboard(email)}
        >
          <i className="ri-mail-line align-middle text-muted fs-16"></i>
          <span style={{ color: "inherit" }}>{email || "N/A"}</span>
          <i className="ri-clipboard-line" style={{ color: "inherit" }}></i>
        </li>
        <li>
          <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
          <a href={`tel:+${phonePrefix}${phoneNumber}`}>
            +{phonePrefix || "N/A"} {splitNumbers(phoneNumber) || "N/A"}
          </a>
        </li>
      </ul>
    </div>
  );
};
