import React from "react";
import { ModalBody, Spinner } from "reactstrap";
import confirm_img from "../../assets/imagesn/confirm.gif";
import { Link } from "react-router-dom";
import { FormattedString } from "Components/Common/FormattedString";

const ConfirmEmailSendBody = ({
  emailType,
  toggle,
  sendEmailAction,
  loading,
}) => {
  return (
    <>
      <ModalBody className="text-center p-5">
        <div className="">
          <img
            src={confirm_img}
            alt=""
            height={250}
            className="error-basic-img move-animation"
          />
        </div>

        <div className="mt-4">
          <h4 className="mb-3">
            <FormattedString id="send_email_modal_title" />{" "}
            <FormattedString id={emailType + "_sm"} />{" "}
            <FormattedString id="mail" />?
          </h4>
          <p className="text-muted mb-4">
            <FormattedString id="send_email_modal_warning" />
          </p>
          <div className="hstack gap-4 justify-content-center">
            <Link
              to="#"
              className="btn btn-success fw-medium"
              onClick={() => toggle(false)}
            >
              <FormattedString id="cancel" />
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendEmailAction}
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" color="light" />
              ) : (
                <FormattedString id="send" />
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ConfirmEmailSendBody;
