// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installment-management-canvas .installments-preview tr th:last-child,
.installment-management-canvas .installments-preview tr td:last-child {
  display: none;
}
.installment-management-canvas .installments-preview th:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 45px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/modals/InstallmentManagementCanvas.scss"],"names":[],"mappings":"AAGM;;EAEE,aAAA;AAFR;AAMI;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,eAAA;AAJN","sourcesContent":[".installment-management-canvas {\n  .installments-preview {\n    tr {\n      th:last-child,\n      td:last-child {\n        display: none;\n      }\n    }\n\n    th:nth-child(2) {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      max-width: 45px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
