import React, { useEffect, useRef } from "react";
import { FormattedString } from "Components/Common/FormattedString";
import FormDateField from "Components/Entity/FormDateField";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { handleInputChange } from "helpers/validation_helper";
import { useFormContext } from "../../../../provider/utils/FormContext";

const DueDateField = ({ formik, index, isDisabled }) => {
  const { validateStep } = useFormContext();
  const isInitialRender = useRef(true);

  const dueDateError = formik.errors.installments?.[index]?.dueDate || "";
  const today = new Date();
  const oneYearBefore = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate(),
  );
  const oneYearAfter = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate(),
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    validateStep();
  }, [formik.values.installments[index].dueDate]);

  return (
    <div>
      <FormDateField
        label={
          dueDateError !== "" ? (
            <AngloTooltip text={dueDateError.toString()}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  color: "red",
                }}
              >
                <i className="ri-information-line"></i>
                <FormattedString id="dueDate" />
              </div>
            </AngloTooltip>
          ) : (
            <FormattedString id="dueDate" />
          )
        }
        name={`installments[${index}].dueDate`}
        value={formik.values.installments[index].dueDate}
        onChange={(e) => {
          handleInputChange(formik, e, `installments[${index}].dueDate`);
        }}
        error={formik.errors.installments?.[index]?.dueDate ? " " : ""}
        touched={formik.touched.installments?.[index]?.dueDate}
        disabled={isDisabled}
        min={oneYearBefore.toISOString().split("T")[0]}
        max={oneYearAfter.toISOString().split("T")[0]}
      />
    </div>
  );
};

export default DueDateField;
