import React from "react";
import { getString } from "Components/Common/FormattedString";
import FormSwitch from "Components/Entity/FormSwitch";

const OnlyOpenForApplicationsFilter = ({ onChange, filters }) => {
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    onChange("onlyOpenForApplications", isChecked ? true : null);
  };

  return (
    <FormSwitch
      label={getString("only_open_for_applications")}
      name="onlyOpenForApplications"
      checked={filters.onlyOpenForApplications || false}
      onChange={handleSwitchChange}
      data-testid="switch-filter-onlyOpenForApplications"
      noPadding
    />
  );
};

export default OnlyOpenForApplicationsFilter;
