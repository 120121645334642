import { getStringSync } from "Components/Common/FormattedString";
import ParticipantsStep from "../../steps/ParticipantsStep/ParticipantsStep";
import UpsellsStep from "../../steps/UpsellsStep/UpsellsStep";
import PayerDetailsStep from "../../steps/PayerDetailsStep/PayerDetailsStep";
import InstallmentsStep from "../../steps/InstallmentsStep/InstallmentsStep";

const steps = [
  { id: 1, name: getStringSync("participants"), component: ParticipantsStep },
  { id: 2, name: getStringSync("upsells"), component: UpsellsStep },
  { id: 3, name: getStringSync("payer_details"), component: PayerDetailsStep },
  { id: 4, name: getStringSync("installments"), component: InstallmentsStep },
];

export default steps;

export const STEP_PARTICIPANTS = 1;
export const STEP_UPSELLS = 2;
export const STEP_PAYER_DETAILS = 3;
export const STEP_INSTALLMENTS = 4;
