import { useSelector } from "react-redux";
import "./AttachmentAdder.scss";
import Select from "react-select";
import { Button } from "reactstrap";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { getString, FormattedString } from "Components/Common/FormattedString";

export const AttachmentAdder = ({
  activeAttachments,
  selectedAttachments,
  setSelectedAttachments,
}) => {
  const setSelectedAttachment = (value, i) => {
    const newAttachments = [...selectedAttachments];
    newAttachments[i] = value;
    setSelectedAttachments(newAttachments);
  };

  const unsetAttachment = (i) => {
    const newAttachments = [...selectedAttachments];
    newAttachments.splice(i, 1);
    setSelectedAttachments(newAttachments);
  };

  const { attachmentsData } = useSelector((rootState) => rootState.Attachments);
  const attachmentsOptions = (attachmentsData || [])
    .filter((a) => selectedAttachments.every((sa) => sa.value !== a.id))
    .filter((a) => activeAttachments.every((aa) => aa.id !== a.id))
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));

  return (
    <div className="attachment-adder">
      {selectedAttachments.map((a, i) => (
        <div className="attachment-adder__select" key={i}>
          <Select
            value={selectedAttachments[i]}
            className="w-100"
            onChange={(v) => setSelectedAttachment(v, i)}
            isMulti={false}
            options={attachmentsOptions}
          />
          <AngloTooltip stringId={getString("deleteAttachment")}>
            <i
              className="ri-delete-bin-line"
              onClick={() => unsetAttachment(i)}
            ></i>
          </AngloTooltip>
        </div>
      ))}

      <Button
        color="link"
        className="border-0 bg-transparent w-100"
        onClick={() => setSelectedAttachments((p) => [...p, {}])}
        disabled={
          (selectedAttachments.length > 0 &&
            !selectedAttachments[selectedAttachments.length - 1]?.value) ||
          attachmentsOptions.length === 0
        }
      >
        <i className="las la-plus"></i>
        <FormattedString id={getString("addNewAttachment")} />
      </Button>
    </div>
  );
};
