import React, { useEffect, useRef } from "react";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { payerTypeOptions, PayerTypes } from "models/payerTypes";

const PayerDetailsStep = () => {
  const { formik } = useFormContext();
  const prevPayerType = useRef(formik.values.payerDetails.payerType);

  useEffect(() => {
    if (prevPayerType.current !== formik.values.payerDetails.payerType) {
      if (formik.values.payerDetails.payerType === PayerTypes.PERSON) {
        formik.setFieldValue("payerDetails.companyName", "");
        formik.setFieldValue("payerDetails.taxNumber", "");
      } else if (formik.values.payerDetails.payerType === PayerTypes.COMPANY) {
        formik.setFieldValue("payerDetails.firstName", "");
        formik.setFieldValue("payerDetails.lastName", "");
      }
      prevPayerType.current = formik.values.payerDetails.payerType;
    }
  }, [formik.values.payerDetails.payerType]);

  return (
    <div className="payer-details-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="payer_details" />
          </h2>
        </Col>
      </Row>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                <FormSelect
                  name="payerDetails.payerType"
                  id="payerDetails.payerType"
                  label={getString("payer_type")}
                  isMulti={false}
                  options={payerTypeOptions}
                  value={payerTypeOptions.find(
                    (r) => r.value === formik.values.payerDetails.payerType,
                  )}
                  onChange={(selectedOption) => {
                    handleSelectChange(
                      formik,
                      selectedOption,
                      "payerDetails.payerType",
                    );
                  }}
                  error={formik.errors.payerDetails?.payerType}
                />
              </Col>
            </Row>
            {formik.values.payerDetails.payerType === PayerTypes.PERSON && (
              <Row>
                <Col md={6}>
                  <FormTextField
                    label={getString("first_name")}
                    name="payerDetails.firstName"
                    value={formik.values.payerDetails.firstName}
                    onChange={(e) =>
                      handleInputChange(formik, e, "payerDetails.firstName")
                    }
                    error={formik.errors.payerDetails?.firstName}
                    touched={formik.touched.payerDetails?.firstName}
                  />
                </Col>
                <Col md={6}>
                  <FormTextField
                    label={getString("last_name")}
                    name="payerDetails.lastName"
                    value={formik.values.payerDetails.lastName}
                    onChange={(e) =>
                      handleInputChange(formik, e, "payerDetails.lastName")
                    }
                    error={formik.errors.payerDetails?.lastName}
                    touched={formik.touched.payerDetails?.lastName}
                  />
                </Col>
              </Row>
            )}
            {formik.values.payerDetails.payerType === "company" && (
              <Row>
                <Col md={6}>
                  <FormTextField
                    label={getString("company_name")}
                    name="payerDetails.companyName"
                    value={formik.values.payerDetails.companyName}
                    onChange={(e) =>
                      handleInputChange(formik, e, "payerDetails.companyName")
                    }
                    error={formik.errors.payerDetails?.companyName}
                    touched={formik.touched.payerDetails?.companyName}
                  />
                </Col>
                <Col md={6}>
                  <FormTextField
                    label={getString("tax_number")}
                    name="payerDetails.taxNumber"
                    value={formik.values.payerDetails.taxNumber}
                    onChange={(e) =>
                      handleInputChange(formik, e, "payerDetails.taxNumber")
                    }
                    error={formik.errors.payerDetails?.taxNumber}
                    touched={formik.touched.payerDetails?.taxNumber}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6}>
                <FormTextField
                  label={getString("address")}
                  name="payerDetails.address"
                  value={formik.values.payerDetails.address}
                  onChange={(e) =>
                    handleInputChange(formik, e, "payerDetails.address")
                  }
                  error={formik.errors.payerDetails?.address}
                  touched={formik.touched.payerDetails?.address}
                />
              </Col>
              <Col md={3}>
                <FormTextField
                  label={getString("zip_code")}
                  name="payerDetails.zipCode"
                  value={formik.values.payerDetails.zipCode}
                  onChange={(e) =>
                    handleInputChange(formik, e, "payerDetails.zipCode")
                  }
                  error={formik.errors.payerDetails?.zipCode}
                  touched={formik.touched.payerDetails?.zipCode}
                />
              </Col>
              <Col md={3}>
                <FormTextField
                  label={getString("city")}
                  name="payerDetails.city"
                  value={formik.values.payerDetails.city}
                  onChange={(e) =>
                    handleInputChange(formik, e, "payerDetails.city")
                  }
                  error={formik.errors.payerDetails?.city}
                  touched={formik.touched.payerDetails?.city}
                />
              </Col>
            </Row>
            <ApplicationStepNavigation />
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default PayerDetailsStep;
