import "./ProgramDetails.scss";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";

import { Container, TabContent } from "reactstrap";
import { ProgramHeader } from "./partials/ProgramHeader";
import { ProgramTabSwitcher } from "./partials/ProgramTabSwitcher";
import { OverviewTab } from "./partials/Tabs/OverviewTab";
import { ParticipantsTab } from "./partials/Tabs/ParticipantsTab";
import { LogTab } from "./partials/Tabs/LogTab";
import { InternationalParticipantsTab } from "./partials/Tabs/InternationalParticipantsTab";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { getMeetingPointListData } from "store/meetingPoints/action";
import { useDispatch } from "react-redux";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import EditProgram from "../EditProgram";
import { programStatus } from "models/programStatuses";
import { calculateParticipantsSums } from "./participantUtils";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";

export const ProgramDetailsContext = createContext();

export const ProgramDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.substring(1);

  const [programData, setProgramData] = useState();
  const [loading, setLoading] = useState(true);
  const [programErrors, setProgramErrors] = useState();

  const isEditRoute = location.pathname === `/program/${id}/edit`;

  const toggleEditModal = () => {
    if (isEditRoute) {
      navigate(`/program/${id}`);
    } else {
      navigate(`/program/${id}/edit`);
    }
  };

  const [activeTab, setActiveTab] = useState(hash || "overview");
  const refreshProgramData = () => {
    getProgram(id)
      .then((r) => {
        const programCoordinators = r.programPackets
          .map((packet) =>
            packet.coordinators.map((coordinator) => ({
              ...coordinator,
              packetName: packet.name,
              packetId: packet.id,
            })),
          )
          .flat();

        const allCoordinators = [
          ...programCoordinators,
          ...r.healthAndSafetyCoordinators,
        ];

        const { programParticipantsSum, programParticipantsTotalCapacity } =
          calculateParticipantsSums(r.participants);

        const {
          programParticipantsSum: programInternationalParticipantsSum,
          programParticipantsTotalCapacity:
            programInternationalParticipantsTotalCapacity,
        } = calculateParticipantsSums(r.internationalParticipants);
        setProgramData({
          ...r,
          allCoordinators,
          programParticipantsSum,
          programParticipantsTotalCapacity,
          programInternationalParticipantsSum,
          programInternationalParticipantsTotalCapacity,
        });
        document.title =
          r.name + " | " + r.season + " | Angloville - CRM Panel";

        setLoading(false);
      })
      .catch((e) => console.error(e));
  };

  const dispatch = useDispatch();

  const canUpdateProgram =
    useHasPermission("PROGRAM_UPDATE") &&
    programData?.status === programStatus.in_preparation &&
    programData?.status !== "archived";

  const canGetMeetingPointListData = useHasPermission("MEETING_POINT_READ");
  const canGetProgramPacketTemplateListData = useHasPermission(
    "PROGRAM_PACKET_TEMPLATE_READ",
  );

  useEffect(() => {
    refreshProgramData();
    canGetMeetingPointListData && dispatch(getMeetingPointListData());
    canGetProgramPacketTemplateListData &&
      dispatch(getProgramPacketTemplateListData());
  }, [id]);

  return loading === false ? (
    <>
      <div className="page-content program-details">
        <Container fluid>
          <ProgramDetailsContext.Provider
            value={{
              programData,
              setProgramData,
              activeTab,
              setActiveTab,
              refreshProgramData,
              programErrors,
              setProgramErrors,
              canUpdateProgram,
            }}
          >
            <ProgramHeader />
            <ProgramTabSwitcher />
            <TabContent activeTab={activeTab} className="pt-4">
              {activeTab === "overview" && <OverviewTab />}
              {activeTab === "participants" && <ParticipantsTab />}
              {activeTab === "international-participants" && (
                <InternationalParticipantsTab />
              )}

              {activeTab === "log" && <LogTab />}
            </TabContent>
          </ProgramDetailsContext.Provider>
        </Container>
      </div>
      <EditProgram
        editedProgram={programData}
        visibility={isEditRoute}
        toggle={toggleEditModal}
        onSuccess={() => refreshProgramData()}
      />
    </>
  ) : (
    <PreloaderWrap h100 />
  );
};
