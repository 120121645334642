export const ParticipantStatus = {
  PRE_BOOKING: "pre_booking",
  PARTLY_PAID: "partly_paid",
  FULLY_PAID: "fully_paid",
  WAIT_LIST: "wait_list",
};

export const ppColorMap = {
  [ParticipantStatus.PRE_BOOKING]: "#ff0000",
  [ParticipantStatus.PARTLY_PAID]: "#ffa500",
  [ParticipantStatus.FULLY_PAID]: "#2ca02c",
  [ParticipantStatus.WAIT_LIST]: "#808080",
  other: "#808080",
};
