import logoBg from "assets/images/logo.svg";
import { Col, Row } from "reactstrap";
import { IpApplicationDetailsContext } from "../IpApplicationDetails";
import { useContext } from "react";
import { formatDate } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const IpApplicationHeader = () => {
  const { applicationData, parentProgram } = useContext(
    IpApplicationDetailsContext,
  );

  const headerClass = classNames("ip-application-details__header", {
    "ip-application-details__header--blocked":
      applicationData?.applicationStatus === "blocked",
  });

  return (
    <div className={headerClass}>
      <div className="ip-application-details__header__foreground">
        <div className="ip-application-details__header__background">
          <img
            src={logoBg}
            alt=""
            className="ip-application-details__header__background__image"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="ip-application-details__header__avatar">
              <i className="ip-application-details__header__thumb ri-file-list-line"></i>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="ip-application-details__header__top">
                <div className="ip-application-details__header__top__left">
                  <h3 className="ip-application-details__header__top__left__name">
                    <span style={{ color: "#ffcc00" }}>
                      #{applicationData?.customId}
                    </span>{" "}
                    | {applicationData?.programPacketName}
                  </h3>
                  <p className="ip-application-details__header__top__left__paragraph">
                    <i className="ri-calendar-event-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    {formatDate(
                      applicationData?.internationalParticipant?.dateOfBirth,
                    )}
                  </p>
                </div>
              </div>
              {applicationData?.programPacketName && (
                <Row>
                  <Col>
                    <div className="text-white-50">
                      <p className="ip-application-details__header__top__left__paragraph">
                        <i className="ri-bookmark-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        Program: {applicationData.programPacketName}
                      </p>
                      <p className="ip-application-details__header__top__left__paragraph">
                        <i className="ri-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        International Participant:{" "}
                        <Link
                          to={`/ip-participants/${applicationData?.internationalParticipant?.id}`}
                          className="text-white text-decoration-underline"
                        >
                          {applicationData?.internationalParticipant?.firstName}{" "}
                          {applicationData?.internationalParticipant?.lastName}
                        </Link>
                      </p>
                      <p className="ip-application-details__header__top__left__paragraph">
                        <i className="ri-map-pin-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        Meeting Point:{" "}
                        {applicationData?.meetingPoint
                          ? `${applicationData.meetingPoint.address}, ${applicationData.meetingPoint.city}`
                          : "-"}
                      </p>
                      <p className="ip-application-details__header__top__left__paragraph">
                        <i className="ri-calendar-event-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        Program Dates:{" "}
                        {parentProgram
                          ? `${formatDate(parentProgram.startDate)} - ${formatDate(parentProgram.endDate)}`
                          : "-"}
                      </p>
                      <p className="ip-application-details__header__top__left__paragraph">
                        <i className="ri-hotel-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        Hotel: {parentProgram?.hotel?.name || "-"}
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <Row className="text text-white-50 text-center align-items-center">
              <Col>
                <h4 className="text-white mb-1">
                  <FormattedString id={applicationData?.applicationStatus} />
                </h4>
                <h5 className="text-white-50">
                  <FormattedString id={applicationData?.type} />
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
