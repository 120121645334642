import "./ApplicationDetails.scss";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { Container, TabContent } from "reactstrap";
import { ApplicationHeader } from "./partials/ApplicationHeader";
import { ApplicationTabSwitcher } from "./partials/ApplicationTabSwitcher";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import {
  getApplication,
  getApplicationActivity,
} from "helpers/API/core-service/cs_backend_helper";
import { getMeetingPointListData } from "store/meetingPoints/action";
import { useDispatch } from "react-redux";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { ApplicationOverviewTab } from "./partials/Tabs/ApplicationOverviewTab/ApplicationOverviewTab";
import { ApplicationActivityTab } from "./partials/Tabs/ApplicationActivityTab/ApplicationActivityTab";
import { programStatus } from "models/programStatuses";
import { getPointOfSellListData } from "store/pointOfSell/action";

export const ApplicationDetailsContext = createContext();

export const ApplicationDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.substring(1);

  const [applicationData, setApplicationData] = useState();
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [applicationErrors, setApplicationErrors] = useState();
  const [activeTab, setActiveTab] = useState(hash || "overview");
  const [editTileOpen, setEditTileOpen] = useState(false);
  const [moveTileOpen, setMoveTileOpen] = useState(false);

  const refreshApplicationData = async () => {
    try {
      const r = await getApplication(id);

      if (r.movedFrom) {
        const movedFromData = await getApplication(r.movedFrom);
        r.movedFrom = movedFromData;
      }

      if (r.movedTo) {
        const movedToData = await getApplication(r.movedTo);
        r.movedTo = movedToData;
      }

      setApplicationData(r);
      document.title =
        r.customId + " | " + r.season + " | Angloville - CRM Panel";

      setLoading(false);
    } catch (error) {
      console.error("Error fetching application data:", error);
      setLoading(false);
    }
  };

  const refreshApplicationLogs = () => {
    getApplicationActivity(id).then((r) => {
      setApplicationLogs(r);
    });
  };

  const updateApplicationInstallments = (updatedInstallments) => {
    setApplicationData((prevData) => ({
      ...prevData,
      applicationInstallments: updatedInstallments,
    }));
  };

  const dispatch = useDispatch();

  const canUpdateApplication =
    useHasPermission("PP_APPLICATION_UPDATE") &&
    applicationData?.status === programStatus.in_preparation;

  useEffect(() => {
    refreshApplicationData();
    dispatch(getMeetingPointListData());
    dispatch(getPointOfSellListData());
    refreshApplicationLogs();
  }, [dispatch, id]);

  useEffect(() => {
    setEditTileOpen(location.pathname.includes("/edit"));
    setMoveTileOpen(location.pathname.includes("/move"));
  }, [location.pathname]);

  const handleEditTileClose = () => {
    setEditTileOpen(false);
    if (location.pathname.includes("/edit")) {
      navigate(location.pathname.replace("/edit", ""));
    }
  };

  const handleMoveTileClose = () => {
    setMoveTileOpen(false);
    if (location.pathname.includes("/move")) {
      navigate(location.pathname.replace("/move", ""));
    }
  };

  return loading === false ? (
    <div className="page-content application-details">
      <Container fluid className="mb-4">
        <ApplicationDetailsContext.Provider
          value={{
            applicationData,
            setApplicationData,
            activeTab,
            setActiveTab,
            refreshApplicationData,
            applicationErrors,
            setApplicationErrors,
            canUpdateApplication,
            applicationLogs,
            refreshApplicationLogs,
            editTileOpen,
            setEditTileOpen: handleEditTileClose,
            moveTileOpen,
            setMoveTileOpen: handleMoveTileClose,
            updateApplicationInstallments,
          }}
        >
          <ApplicationHeader />
          <ApplicationTabSwitcher />
          <TabContent activeTab={activeTab} className="pt-4">
            {activeTab === "overview" && <ApplicationOverviewTab />}
            {activeTab === "activity" && <ApplicationActivityTab />}
          </TabContent>
        </ApplicationDetailsContext.Provider>
      </Container>
    </div>
  ) : (
    <PreloaderWrap h100 />
  );
};
