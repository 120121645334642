import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { useSelector } from "react-redux";
import { installmentTypes } from "models/installmentPlans";
import { isObjectEmpty } from "helpers/utils";
import mapFormikValuesToDTO from "../../provider/utils/mapFormikValuesToDTO";
import { updateApplicationState } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { parseApplicationErrorToFormikErrors } from "../../provider/utils/parseApplicationErrorToFormikErrors";
import InstallmentList from "./InstallmentList";
import SummaryDetails from "./SummaryDetails";
import "./InstallmentsStep.scss";

const InstallmentsStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const PointOfSell = useSelector((rootState) => rootState.PointOfSell);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);

  const pointOfSellOptions = PointOfSell.pointOfSellData.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const {
    formik,
    validateStep,
    installmentsError,
    setInstallmentsError,
    setMaxInstallmentSum,
    setModal,
    parentProgram,
  } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(null);

  console.log(parentProgram.installmentPlans, "parentProgram");

  const addInstallment = () => {
    validateStep().then((errors) => {
      if (errors.sum) {
        delete errors.sum;
        setInstallmentsError(null);
      }
      if (isObjectEmpty(errors)) {
        formik.setFieldValue("installments", [
          ...formik.values.installments,
          {
            price: "",
            dueDays: "",
            currency: activeBusinessUnit.currency || "",
            type: "",
            pointOfSell: "",
          },
        ]);
      }
    });
  };

  const removeInstallment = (index) => {
    if (formik.values.installments.length > 1) {
      const updatedInstallments = formik.values.installments.filter(
        (_, i) => i !== index,
      );
      formik.setFieldValue("installments", updatedInstallments);
    }
  };

  useEffect(() => {
    const dto = mapFormikValuesToDTO(formik.values, 4, true);
    updateApplicationState(applicationData.id, dto)
      .then((response) => {
        const { installmentPlans, summary, discounts } = response;

        let finalInstallments = [];

        if (
          !applicationData.applicationInstallments ||
          applicationData.applicationInstallments.length === 0
        ) {
          finalInstallments = installmentPlans?.[0]?.installments.map(
            (installment, index) => {
              const dueDays = Math.ceil(
                (new Date(installment.dueDate) - new Date()) /
                  (1000 * 60 * 60 * 24),
              );

              const matchingProgramParentInstallment =
                parentProgram.installmentPlans[0].installments[index];

              return {
                price: installment.price,
                dueDays: dueDays,
                currency:
                  installment?.currency ||
                  matchingProgramParentInstallment?.currency ||
                  activeBusinessUnit.currency,
                type: installment.type,
                pointOfSell:
                  installment?.pointOfSellId ||
                  matchingProgramParentInstallment?.pointOfSellId,
                isPaid: installment.isPaid,
                isOverDue: installment.isOverDue,
              };
            },
          ) || [{}];
        } else {
          finalInstallments = applicationData.applicationInstallments.map(
            (installment, index) => {
              const matchingInstallment =
                installmentPlans?.[0]?.installments[index];
              const dueDays = Math.ceil(
                (new Date(installment.dueDate) - new Date()) /
                  (1000 * 60 * 60 * 24),
              );

              const matchingProgramParentInstallment =
                parentProgram.installmentPlans[0].installments[index];

              return {
                price: installment.price,
                dueDays: dueDays,
                currency:
                  installment.currency ||
                  matchingInstallment?.currency ||
                  matchingProgramParentInstallment?.currency ||
                  activeBusinessUnit.currency,
                type: installment.type,
                pointOfSell:
                  installment.pointOfSellId ||
                  matchingInstallment?.pointOfSellId ||
                  matchingProgramParentInstallment?.pointOfSellId,
                isPaid: installment.isPaid,
                isOverDue: installment.isOverDue,
              };
            },
          );
        }

        formik.setFieldValue("installments", finalInstallments);
        setSummaryData({ summary, discounts });
        setLoading(false);
        if (installmentPlans[0].sum) {
          setMaxInstallmentSum(installmentPlans[0].sum);
        }
        if (response.summary) {
          setModal(true);
        }
      })
      .catch((error) => {
        parseApplicationErrorToFormikErrors(error.data, formik);
      });
  }, []);

  return (
    <div className="installments-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary blue-header">
            <FormattedString id="installments" />
          </h2>
        </Col>
      </Row>
      {loading ? (
        <PreloaderWrap />
      ) : (
        <>
          {summaryData && (
            <SummaryDetails
              summary={summaryData.summary}
              discounts={summaryData.discounts}
            />
          )}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <InstallmentList
              installments={formik.values.installments}
              formik={formik}
              installmentTypes={installmentTypes}
              pointOfSellOptions={pointOfSellOptions}
              removeInstallment={removeInstallment}
            />
            {installmentsError && (
              <Row className="mb-3">
                <Col>
                  <div className="text-danger">
                    {JSON.stringify(installmentsError)}
                  </div>
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col>
                <div className="add-installment-btn" onClick={addInstallment}>
                  <i className="ri-add-line add-installment-btn__icon"></i>{" "}
                  {getString("add_installment")}
                </div>
              </Col>
            </Row>
            <ApplicationStepNavigation />
          </Form>
        </>
      )}
    </div>
  );
};

export default InstallmentsStep;
