import React, { useState } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  ButtonGroup,
  Button,
  Input,
  Row,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { getString } from "../../../../Components/Common/FormattedString";

const ApplicationStatistics = ({ applicationsStats }) => {
  const { dates, data } = applicationsStats;

  const getDefaultStartDate = () => {
    const startDate = new Date(2024, 11, 1);
    return startDate.toISOString().split("T")[0];
  };

  const getDefaultEndDate = () => {
    const endDate = new Date();
    return endDate.toISOString().split("T")[0];
  };

  const [selectedRange, setSelectedRange] = useState("WEEK");
  const [customRange, setCustomRange] = useState({
    from: getDefaultStartDate(),
    to: getDefaultEndDate(),
  });
  const [currentIndex, setCurrentIndex] = useState(dates.length);

  const filterDataByRange = (range, index) => {
    let filteredDates = [];
    let filteredData = data.map((series) => ({
      ...series,
      data: [],
    }));

    switch (range) {
      case "WEEK":
        filteredDates = dates.slice(index - 7, index);
        filteredData = data.map((series) => ({
          ...series,
          data: series.data.slice(index - 7, index),
        }));
        break;
      case "MONTH":
        filteredDates = dates.slice(index - 30, index);
        filteredData = data.map((series) => ({
          ...series,
          data: series.data.slice(index - 30, index),
        }));
        break;
      case "CUSTOM":
        const fromDate = new Date(customRange.from);
        const toDate = new Date(customRange.to);
        filteredDates = dates.filter((date) => {
          const currentDate = new Date(date);
          return currentDate >= fromDate && currentDate <= toDate;
        });
        filteredData = data.map((series) => ({
          ...series,
          data: series.data.slice(
            dates.indexOf(filteredDates[0]),
            dates.indexOf(filteredDates[filteredDates.length - 1]) + 1,
          ),
        }));
        break;
      default:
        break;
    }

    return { filteredDates, filteredData };
  };

  const { filteredDates, filteredData } = filterDataByRange(
    selectedRange,
    currentIndex,
  );

  const orderedData = [
    filteredData.find((series) => series.name === "Paid Applications"),
    filteredData.find((series) => series.name === "Pre-Booking Applications"),
    filteredData.find((series) => series.name === "Abandoned Applications"),
    filteredData.find((series) => series.name === "Canceled Applications"),
  ];

  const options = {
    chart: {
      height: 380,
      type: "area",
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: getChartColorsArray('["#28a745", "#ffc107", "#007bff", "#dc3545"]'),
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: filteredDates,
      tickAmount: Math.min(10, filteredDates.length),
      labels: {
        showDuplicates: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const handlePrev = () => {
    if (selectedRange === "WEEK" && currentIndex > 7) {
      setCurrentIndex(currentIndex - 7);
    } else if (selectedRange === "MONTH" && currentIndex > 30) {
      setCurrentIndex(currentIndex - 30);
    }
  };

  const handleNext = () => {
    if (selectedRange === "WEEK" && currentIndex < dates.length) {
      setCurrentIndex(currentIndex + 7);
    } else if (selectedRange === "MONTH" && currentIndex < dates.length) {
      setCurrentIndex(currentIndex + 30);
    }
  };

  return (
    <React.Fragment>
      <Col className="col-xxl-8 order-xxl-0 order-first">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div className="card-title mb-0">
                {getString("application_statistics")}
              </div>
              <ButtonGroup>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedRange("WEEK");
                    setCurrentIndex(dates.length);
                  }}
                  active={selectedRange === "WEEK"}
                >
                  {getString("week")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedRange("MONTH");
                    setCurrentIndex(dates.length);
                  }}
                  active={selectedRange === "MONTH"}
                >
                  {getString("month")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => setSelectedRange("CUSTOM")}
                  active={selectedRange === "CUSTOM"}
                >
                  {getString("everything")}
                </Button>
              </ButtonGroup>
            </div>
          </CardHeader>
          <CardBody>
            {selectedRange !== "CUSTOM" && (
              <div className="d-flex justify-content-end mb-3">
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={handlePrev}
                    disabled={
                      currentIndex <= (selectedRange === "WEEK" ? 7 : 30)
                    }
                  >
                    <i className="ri-arrow-left-s-line"></i>
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleNext}
                    disabled={currentIndex >= dates.length}
                  >
                    <i className="ri-arrow-right-s-line"></i>
                  </Button>
                </ButtonGroup>
              </div>
            )}
            {selectedRange === "CUSTOM" && (
              <Row className="mb-3">
                <Col md="6">
                  <Input
                    type="date"
                    value={customRange.from}
                    onChange={(e) =>
                      setCustomRange({ ...customRange, from: e.target.value })
                    }
                    placeholder={getString("from")}
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="date"
                    value={customRange.to}
                    onChange={(e) =>
                      setCustomRange({ ...customRange, to: e.target.value })
                    }
                    placeholder={getString("to")}
                  />
                </Col>
              </Row>
            )}
            <ReactApexChart
              dir="ltr"
              options={options}
              series={orderedData}
              type="area"
              height="380"
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ApplicationStatistics;
