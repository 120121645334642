import React from "react";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./InternationalParticipantEquationPerType.scss";
import { getString } from "../FormattedString";

const colorMap = {
  invited: "rgb(128, 128, 128)",
  awaiting_travel_details: "rgb(255, 0, 0)",
  partly_confirmed: "rgb(255, 165, 0)",
  confirmed: "rgb(44, 160, 44)",
  overbooked: "rgb(44, 160, 44)",
  total_sum: "#299cdb",
  total_capacity: "black",
};

const sumQuantitiesByStatus = (statusCount, statuses) => {
  return statuses.reduce((sum, status) => {
    const statusItem = statusCount.find((item) => item.status === status);
    return sum + (statusItem ? statusItem.count : 0);
  }, 0);
};

export const InternationalParticipantEquationPerType = ({
  summary,
  packet,
}) => {
  const { statusCount } = summary;
  const capacity = packet.summary.capacityPerType[summary.type];

  const invitedSum = sumQuantitiesByStatus(statusCount, ["invited"]);
  const awaitingTravelDetailsSum = sumQuantitiesByStatus(statusCount, [
    "awaiting_travel_details",
  ]);
  const partlyConfirmedSum = sumQuantitiesByStatus(statusCount, [
    "partly_confirmed",
  ]);
  const confirmedAndOverbookedSum = sumQuantitiesByStatus(statusCount, [
    "confirmed",
    "overbooked",
  ]);
  const totalSum = partlyConfirmedSum + confirmedAndOverbookedSum;

  return (
    <div className="international-participant-equation-per-type">
      <span className="international-participant-equation__bracket">(</span>
      <AngloTooltip text={getString("invited")}>
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["invited"] }}
        >
          {invitedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">+</span>
      <AngloTooltip text={getString("awaiting_travel_details")}>
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["awaiting_travel_details"] }}
        >
          {awaitingTravelDetailsSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__bracket">)</span>
      <AngloTooltip text={getString("partly_confirmed")}>
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["partly_confirmed"] }}
        >
          {partlyConfirmedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">+</span>
      <AngloTooltip
        text={getString("confirmed") + " + " + getString("overbooked")}
      >
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["confirmed"] }}
        >
          {confirmedAndOverbookedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">=</span>
      <AngloTooltip text={getString("total_sum")}>
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["total_sum"], fontWeight: 700 }}
        >
          {totalSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">/</span>
      <AngloTooltip text={getString("total_capacity")}>
        <span
          className="international-participant-equation__val"
          style={{ color: colorMap["total_capacity"] }}
        >
          {capacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
