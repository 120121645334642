import React, { useContext, useEffect, useState } from "react";
import "./ApplicationActivityTab.scss";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  TabPane,
  Table,
} from "reactstrap";
import { format } from "date-fns";
import { ApplicationDetailsContext } from "../../../ApplicationDetails";
import { CustomerDetails } from "../../components/CustomerDetails/CustomerDetails";
import { BillingData } from "../../components/BillingData/BillingData";
import { Tile } from "Components/Common/Tile/Tile";
import { FormattedString, getString } from "Components/Common/FormattedString";
import classNames from "classnames";
import { MailPreviewModal } from "./partials/MailPreviewModal";
import { ApplicationUtms } from "../../components/ApplicationUtms/ApplicationUtms";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";

export const ApplicationActivityTab = () => {
  const { applicationLogs, refreshApplicationLogs } = useContext(
    ApplicationDetailsContext,
  );
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapsedLogs, setCollapsedLogs] = useState({});

  useEffect(() => {
    refreshApplicationLogs();
  }, []);

  const handleEmailPreview = (emailId) => {
    setSelectedEmailId(emailId);
    setIsModalVisible(true);
  };

  const toggleCollapse = (index) => {
    setCollapsedLogs((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const getIconByEventName = (eventName) => {
    switch (eventName) {
      case "application.email_sent":
        return "ri-mail-open-line";
      case "application.updated":
        return "ri-edit-line";
      case "application.installment_paid":
        return "ri-money-dollar-circle-line";
      default:
        return "mdi mdi-account-circle";
    }
  };

  const generateSummaryString = (oldApp, newApp) => {
    const changes = [];
    if (oldApp.status?.value !== newApp.status?.value) {
      changes.push(
        `Status changed from ${oldApp.status?.name} to ${newApp.status?.name}`,
      );
    }
    if (oldApp.meetingPoint?.address !== newApp.meetingPoint?.address) {
      changes.push(
        `Meeting point changed from ${oldApp.meetingPoint?.address} to ${newApp.meetingPoint?.address}`,
      );
    }
    // Add more fields as needed
    return changes.join(", ");
  };

  const generateChangesTable = (oldApp, newApp) => {
    const rows = [];
    for (const key in newApp) {
      if (newApp[key] !== oldApp[key]) {
        rows.push(
          <tr key={key}>
            <td>{key}</td>
            <td>{JSON.stringify(oldApp[key])}</td>
            <td>{JSON.stringify(newApp[key])}</td>
          </tr>,
        );
      }
    }
    return rows;
  };

  return (
    <TabPane tabId="activity" className="application-activity-tab">
      <Row>
        <Col xxl={9}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 me-2">
                    <FormattedString id="recent_activity" />
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="application-activities">
                    {applicationLogs.length === 0 && (
                      <div className="application-activities__placeholder">
                        <FormattedString id="no_activities_available" />
                      </div>
                    )}
                    {applicationLogs.map((log, index) => (
                      <div className="d-flex mb-3 application-log" key={index}>
                        <div className="icon-wrap">
                          <i
                            className={classNames(
                              getIconByEventName(log.eventName),
                              "icon",
                            )}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <div className="d-flex align-items-center gap-2">
                            <div className="activity-title">
                              <FormattedString id={log.eventName} />
                            </div>
                            {log.eventName === "application.updated" && (
                              <LinkButton
                                onClick={() => toggleCollapse(index)}
                                className="application-activity-tab__view-email"
                              >
                                <i className="ri-edit-line"></i>{" "}
                                {collapsedLogs[index]
                                  ? "Hide Details"
                                  : "Show Details"}
                              </LinkButton>
                            )}
                            {log.emailId && (
                              <LinkButton
                                onClick={() => handleEmailPreview(log.emailId)}
                                className="application-activity-tab__view-email"
                              >
                                <i className="ri-mail-open-line"></i> View email
                              </LinkButton>
                            )}
                          </div>
                          <small className="text-muted">
                            {log.eventMessage} -{" "}
                            {format(
                              new Date(log.occurredOn),
                              "yyyy-MM-dd HH:mm:ss",
                            )}
                          </small>
                          {log.eventName === "application.updated" && (
                            <Collapse isOpen={collapsedLogs[index]}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Field</th>
                                    <th>Old Value</th>
                                    <th>New Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {generateChangesTable(
                                    log.oldApplication,
                                    log.newApplication,
                                  )}
                                </tbody>
                              </Table>
                            </Collapse>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xxl={3}>
          <Tile title={getString("customer_details")} gap="14px">
            <CustomerDetails />
          </Tile>
          <Tile
            title={getString("billing_data")}
            gap="14px"
            titleIcon={
              <i className="ri-map-pin-line align-middle text-muted"></i>
            }
          >
            <BillingData />
          </Tile>
          <Tile title={getString("utm_parameters")} gap="14px">
            <ApplicationUtms />
          </Tile>
        </Col>
      </Row>
      {isModalVisible && (
        <MailPreviewModal
          emailId={selectedEmailId}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
      )}
    </TabPane>
  );
};
