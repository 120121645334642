import { useContext, useEffect, useState } from "react";
import "./IpFormSummary.scss";
import { IpApplicationDetailsContext } from "../../IpApplicationDetails";
import ProgramShort from "./components/ProgramShort/ProgramShort";
import { FormattedString } from "Components/Common/FormattedString";
import { Spinner } from "reactstrap";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import InternationalParticipantsList from "./components/InternationalParticipantsList/InternationalParticipantsList";

export const IpFormSummary = () => {
  const {
    applicationData,
    selectedApplication,
    ipApplications,
    parentProgram,
    setParentProgram,
  } = useContext(IpApplicationDetailsContext);
  const [loading, setLoading] = useState(false);

  const selectedProgram = selectedApplication?.programId;

  useEffect(() => {
    if (selectedProgram) {
      setLoading(true);
      getProgram(selectedProgram).then((data) => {
        setParentProgram(data);
        setLoading(false);
      });
    } else {
      setParentProgram(null);
    }
  }, [selectedApplication, ipApplications]);

  const internationalParticipants =
    parentProgram?.internationalParticipants || [];

  const ipFormSummaryClass =
    !parentProgram || loading
      ? "ip-form-summary ip-form-summary--centered"
      : "ip-form-summary";

  return (
    <div
      className={`${ipFormSummaryClass} ${applicationData.status === "moved" ? "ip-form-summary--moved" : ""}`}
    >
      {loading ? (
        <div className="ip-form-summary ip-form-summary--loading">
          <Spinner />
        </div>
      ) : !parentProgram ? (
        <div className="ip-form-summary__placeholder">
          <FormattedString
            id="noProgramSelected"
            defaultMessage="No program selected"
          />
        </div>
      ) : (
        <>
          <div className="ip-form-summary__section">
            <div className="ip-form-summary__section__header">
              <FormattedString id="program" />
            </div>
            <ProgramShort programData={parentProgram} />
          </div>
          <div className="ip-form-summary__section">
            <div className="ip-form-summary__section__header">
              <FormattedString id="international_participants" />
            </div>
            {internationalParticipants.length > 0 ? (
              <InternationalParticipantsList
                packets={internationalParticipants}
              />
            ) : (
              <div className="ip-form-summary__no-participants">
                <FormattedString id="noParticipants" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
