import { IpApplicationDetailsContext } from "pages/IpApplications/IpApplicationDetails/IpApplicationDetails";
import { useContext } from "react";
import { InternationalParticipantShort } from "../InternationalParticipantShort/InternationalParticipantShort";

const InternationalParticipantsList = ({ packets }) => {
  const { applicationData } = useContext(IpApplicationDetailsContext);
  const internationalParticpantId = applicationData.internationalParticipant.id;

  return packets.map((packet, packetIndex) =>
    packet.data
      .filter(
        (participant) =>
          participant.internationalParticipantId === internationalParticpantId,
      )
      .map((participant, participantIndex) => (
        <InternationalParticipantShort
          key={`${packetIndex}-${participantIndex}`}
          participant={participant}
        />
      )),
  );
};

export default InternationalParticipantsList;
