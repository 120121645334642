import { FormattedString } from "Components/Common/FormattedString";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";
import { IpApplicationDetailsContext } from "pages/IpApplications/IpApplicationDetails/IpApplicationDetails";
import { useContext } from "react";

export const IpParticipantDetails = () => {
  const { applicationData } = useContext(IpApplicationDetailsContext);
  const participant = applicationData?.internationalParticipant || {};

  return (
    <div className="ip-participant-details">
      <ul className="list-unstyled mb-0 vstack gap-3">
        <li>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
              <h6 className="fs-14 mb-1">
                {participant.firstName || "N/A"} {participant.lastName || "N/A"}
              </h6>
              <p className="text-muted mb-0">
                <FormattedString id="participant" />
              </p>
            </div>
          </div>
        </li>
        <li>
          <div
            className="cell-email d-flex align-items-center"
            style={{ color: "#6c757d" }}
          >
            <i
              className="ri-clipboard-line me-2"
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(participant.email)}
            ></i>
            <span
              style={{ cursor: "pointer", color: "inherit" }}
              onClick={() => handleCopyToClipboard(participant.email)}
            >
              {participant.email || "N/A"}
            </span>
          </div>
        </li>
        <li>
          <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
          <a href={`tel:+${participant.phonePrefix}${participant.phoneNumber}`}>
            +{participant.phonePrefix || "N/A"}{" "}
            {splitNumbers(participant.phoneNumber) || "N/A"}
          </a>
        </li>
        <li>
          <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
          <span>
            <FormattedString id={participant.gender || "N/A"} />
          </span>
        </li>
        <li>
          <i className="ri-restaurant-line me-2 align-middle text-muted fs-16"></i>
          <span>
            <FormattedString id={participant.preferredDiet || "N/A"} />
          </span>
        </li>
        <li>
          <i className="ri-map-pin-line me-2 align-middle text-muted fs-16"></i>
          <span>{participant.countryOfOrigin || "N/A"}</span>
        </li>
        <li>
          <i className="ri-calendar-line me-2 align-middle text-muted fs-16"></i>
          <span>
            {participant.dateOfBirth
              ? new Date(participant.dateOfBirth).toLocaleDateString()
              : "N/A"}
          </span>
        </li>
      </ul>
    </div>
  );
};
