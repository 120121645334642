import React, { useCallback, useEffect, useState } from "react";
import { Label, FormFeedback } from "reactstrap";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import debounce from "lodash/debounce";

const FormHtmlEditor = ({
  label,
  name,
  height = 500,
  value,
  onChange,
  error,
  tooltip,
}) => {
  const [editorContent, setEditorContent] = useState(value);

  const stableOnChange = useCallback(onChange, []);

  const debouncedOnChange = useCallback(debounce(stableOnChange, 1000), [
    stableOnChange,
  ]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleEditorChange = (newValue) => {
    setEditorContent(newValue);
    debouncedOnChange(newValue);
  };

  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  return (
    <div className="mb-3" data-testid="form-html-editor">
      <Label
        htmlFor={name}
        className="form-label d-flex gap-1"
        data-testid="form-html-editor-label"
      >
        {label}
        {tooltip && (
          <AngloTooltip stringId={tooltip}>
            <i
              className="ri-information-line"
              data-testid="form-html-editor-tooltip"
            ></i>
          </AngloTooltip>
        )}
      </Label>
      <AceEditor
        mode="html"
        theme="github"
        name={name}
        value={editorContent}
        onChange={handleEditorChange}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ useWorker: false }}
        style={{ width: "100%", height: `${height}px` }}
        data-testid="form-html-editor-ace"
      />
      {error && (
        <FormFeedback type="invalid" data-testid="form-html-editor-error">
          {error}
        </FormFeedback>
      )}
    </div>
  );
};

export default FormHtmlEditor;
