import React from "react";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./InternationalParticipantEquation.scss";
import { getString } from "../FormattedString";
import { IpParticipantStatus, ipColorMap } from "models/ipParticipants";

const sumQuantitiesByStatus = (quantity, statuses) => {
  return quantity.reduce((sum, item) => {
    return (
      sum +
      item.statusCount.reduce((statusSum, statusItem) => {
        if (statuses.includes(statusItem.status)) {
          return statusSum + statusItem.count;
        }
        return statusSum;
      }, 0)
    );
  }, 0);
};

export const InternationalParticipantEquation = ({ summary }) => {
  const { quantity, totalCapacity } = summary;

  const invitedSum = sumQuantitiesByStatus(quantity, [
    IpParticipantStatus.INVITED,
  ]);
  const awaitingTravelDetailsSum = sumQuantitiesByStatus(quantity, [
    IpParticipantStatus.AWAITING_TRAVEL_DETAILS,
  ]);
  const partlyConfirmedSum = sumQuantitiesByStatus(quantity, [
    IpParticipantStatus.PARTLY_CONFIRMED,
  ]);
  const confirmedAndOverbookedSum = sumQuantitiesByStatus(quantity, [
    IpParticipantStatus.CONFIRMED,
    IpParticipantStatus.OVERBOOKED,
  ]);
  const totalSum = partlyConfirmedSum + confirmedAndOverbookedSum;

  return (
    <div
      className="international-participant-equation"
      style={{ fontWeight: 700 }}
    >
      <span className="international-participant-equation__bracket">(</span>
      <AngloTooltip text={getString(IpParticipantStatus.INVITED)}>
        <span
          className="international-participant-equation__val"
          style={{ color: ipColorMap[IpParticipantStatus.INVITED] }}
        >
          {invitedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">+</span>
      <AngloTooltip
        text={getString(IpParticipantStatus.AWAITING_TRAVEL_DETAILS)}
      >
        <span
          className="international-participant-equation__val"
          style={{
            color: ipColorMap[IpParticipantStatus.AWAITING_TRAVEL_DETAILS],
          }}
        >
          {awaitingTravelDetailsSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__bracket">)</span>
      <AngloTooltip text={getString(IpParticipantStatus.PARTLY_CONFIRMED)}>
        <span
          className="international-participant-equation__val"
          style={{ color: ipColorMap[IpParticipantStatus.PARTLY_CONFIRMED] }}
        >
          {partlyConfirmedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">+</span>
      <AngloTooltip
        text={
          getString(IpParticipantStatus.CONFIRMED) +
          " + " +
          getString(IpParticipantStatus.OVERBOOKED)
        }
      >
        <span
          className="international-participant-equation__val"
          style={{ color: ipColorMap[IpParticipantStatus.CONFIRMED] }}
        >
          {confirmedAndOverbookedSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">=</span>
      <AngloTooltip text={getString("total_sum")}>
        <span
          className="international-participant-equation__val"
          style={{ color: ipColorMap["total_sum"] }}
        >
          {totalSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign">/</span>
      <AngloTooltip text={getString("total_capacity")}>
        <span
          className="international-participant-equation__val international-participant-equation__val--total"
          style={{ color: ipColorMap["total_capacity"] }}
        >
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
