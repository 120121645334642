// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installment-plan-tile {
  cursor: pointer;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out, background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: unset;
  background: transparent;
  height: 120px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid #ddd;
  color: #6c757d;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.installment-plan-tile:hover {
  background-color: #6c757d;
  color: white;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.installment-plan-tile .card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 6px;
}
.installment-plan-tile .installment-amount {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 700;
  color: inherit;
}
.installment-plan-tile .installment-label {
  font-size: 14px;
  color: inherit;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/steps/InstallmentsStep/components/InstallmentPlanTile.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,8HACE;EAIF,gBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,wCAAA;AAHF;AAKE;EACE,yBAAA;EACA,YAAA;EACA,yCAAA;AAHJ;AAME;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;AAJJ;AAOE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AALJ;AAQE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AANJ","sourcesContent":[".installment-plan-tile {\n  cursor: pointer;\n  transition:\n    transform 0.4s ease-in-out,\n    box-shadow 0.4s ease-in-out,\n    background-color 0.4s ease-in-out,\n    color 0.4s ease-in-out;\n  margin-bottom: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: unset;\n  background: transparent;\n  height: 120px;\n  width: 120px;\n  border-radius: 8px;\n  border: 1px solid #ddd;\n  color: #6c757d;\n  font-weight: 700;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n\n  &:hover {\n    background-color: #6c757d;\n    color: white;\n    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);\n  }\n\n  .card-body {\n    padding: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background: transparent;\n    border-radius: 6px;\n  }\n\n  .installment-amount {\n    font-size: 22px;\n    margin-bottom: 0;\n    font-weight: 700;\n    color: inherit;\n  }\n\n  .installment-label {\n    font-size: 14px;\n    color: inherit;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
