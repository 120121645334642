import "./IpParticipantDetails.scss";

import { useParams, useSearchParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";

import { Container } from "reactstrap";
import { IpParticipantHeader } from "./partials/IpParticipantHeader";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import {
  getIpParticipant,
  getIpParticipantApplications,
} from "helpers/API/core-service/cs_backend_helper";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { IpParticipantOverviewTab } from "./partials/Tabs/IpParticipantOverviewTab/IpParticipantOverviewTab";
import { programStatus } from "models/programStatuses";

export const IpDetailsContext = createContext();

export const IpParticipantDetails = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [ipData, setIpData] = useState();
  const [ipApplications, setIpApplications] = useState([]);
  const [ipApplicationsLoading, setIpApplicationsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  const refreshIpData = () => {
    getIpParticipant(id).then((r) => {
      setIpData(r);
      document.title =
        r.firstName +
        " " +
        r.lastName +
        " | " +
        "Participant details " +
        " | Angloville - CRM Panel";

      setLoading(false);
    });
  };

  const refreshIpApplications = (page = 1, filter = "") => {
    setIpApplicationsLoading(true);
    getIpParticipantApplications(id, page, filter)
      .then((r) => {
        setIpApplications(r);
        setIpApplicationsLoading(false);
      })
      .catch(() => {
        setIpApplicationsLoading(false);
      });
  };

  const canUpdateParticipant =
    useHasPermission("PARTICIPANT_UPDATE") &&
    ipData?.status === programStatus.in_preparation;

  useEffect(() => {
    refreshIpData();
  }, [id]);

  useEffect(() => {
    refreshIpApplications(pageNumber, globalFilter);
  }, [searchParams]);

  return loading === false ? (
    <>
      <div className="page-content ip-participant-details">
        <Container fluid className="mb-4">
          <IpDetailsContext.Provider
            value={{
              ipData,
              setIpData,
              refreshIpData,
              canUpdateParticipant,
              ipApplications,
              refreshIpApplications,
              ipApplicationsLoading,
            }}
          >
            <IpParticipantHeader />
            <IpParticipantOverviewTab />
          </IpDetailsContext.Provider>
        </Container>
      </div>
    </>
  ) : (
    <PreloaderWrap h100 />
  );
};
