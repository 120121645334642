import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./InternalCommentCell.scss";
import { AddInternalCommentModal } from "./AddInternalCommentModal/AddInternalCommentModal";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { FormattedString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const InternalCommentCell = ({
  internalComment,
  entityId,
  status,
  onSuccess = () => {},
}) => {
  const [comment] = useState(internalComment || "");
  const [updatedComment, setUpdatedComment] = useState(internalComment || "");
  const [modalVisible, setModalVisible] = useState(false);

  const handleEdit = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const truncateComment = (comment) => {
    const maxLines = 7;
    const maxWidth = 200;
    const lines = comment.split("\n");
    if (lines.length > maxLines || comment.length > maxWidth) {
      return lines.slice(0, maxLines).join("\n").substring(0, maxWidth) + "...";
    }
    return comment;
  };

  const canEditApplication =
    useHasPermission("IP_APPLICATION_UPDATE") && status !== "moved";
  return (
    <div className="internal-comment-cell">
      <div className="internal-comment-cell__view">
        {updatedComment ? (
          <>
            <span className="internal-comment-cell__text">
              <AngloTooltip text={updatedComment}>
                {truncateComment(updatedComment)}
              </AngloTooltip>
            </span>
            {canEditApplication && (
              <Link
                onClick={handleEdit}
                className="internal-comment-cell__button internal-comment-cell__button--edit"
              >
                <i className="ri-edit-line"></i>
              </Link>
            )}
          </>
        ) : canEditApplication ? (
          <LinkButton
            onClick={handleEdit}
            className="internal-comment-cell__button internal-comment-cell__button--add"
          >
            <i className="ri-add-line"></i> <FormattedString id="add_comment" />
          </LinkButton>
        ) : (
          <span className="internal-comment-cell__no-comment">-</span>
        )}
      </div>
      {modalVisible && (
        <AddInternalCommentModal
          toggle={setModalVisible}
          visible={modalVisible}
          initialContent={updatedComment || comment}
          entityId={entityId}
          type={"ip_application"}
          onSave={(newComment) => setUpdatedComment(newComment)}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default InternalCommentCell;
