import React from "react";
import { Link } from "react-router-dom";
import Navdata from "Layouts/LayoutMenuData";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useSearch } from "../SearchProvider";

const flattenNavdata = (navdata, level = 1) => {
  const pages = [];

  const addItems = (items, level, parentMatch = false) => {
    items.forEach((item) => {
      if (item.isHeader) {
        return;
      }

      const page = {
        text: getString(item.id),
        link: item.link || null,
        icon: item.icon || "",
        level: level,
        parentMatch: parentMatch,
      };

      pages.push(page);

      if (item.subItems) {
        addItems(item.subItems, level + 1, parentMatch);
      }

      if (item.childItems) {
        addItems(item.childItems, level + 1, parentMatch);
      }
    });
  };

  addItems(navdata, level);
  return pages;
};

const filterPages = (pages, searchValue) => {
  const lowercasedFilter = searchValue.toLowerCase();
  const filteredPages = [];

  const addFilteredItems = (items, parentMatch = false) => {
    items.forEach((item) => {
      const match = item.text.toLowerCase().includes(lowercasedFilter);
      const shouldInclude = match || parentMatch;

      if (shouldInclude) {
        filteredPages.push({ ...item, parentMatch: match });
      }

      if (item.subItems) {
        addFilteredItems(item.subItems, shouldInclude);
      }

      if (item.childItems) {
        addFilteredItems(item.childItems, shouldInclude);
      }
    });
  };

  addFilteredItems(pages);
  return filteredPages;
};

const PagesList = () => {
  const {
    searchValue,
    setSearchValue,
    closeDropdown,
    dropdownRef,
    recentSearches,
    setRecentSearches,
  } = useSearch();
  const pageData = Navdata();
  const pages = flattenNavdata(pageData);
  const filteredPages = filterPages(pages, searchValue);
  const maxResults = 6;

  if (filteredPages.length === 0) {
    return null;
  }

  const handleLinkClick = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
    }
    setSearchValue("");
    closeDropdown(dropdownRef.current);
  };

  return (
    <>
      <div className="dropdown-header mt-2">
        <h6 className="text-overflow text-muted mb-1 text-uppercase">
          <FormattedString id="pages" />
        </h6>
      </div>
      {filteredPages.slice(0, maxResults).map((page, index) =>
        page.link || !searchValue ? (
          page.link ? (
            <Link
              key={index}
              to={page.link}
              className="dropdown-item notify-item"
              style={{ paddingLeft: `${page.level * 1.2}rem` }}
              onClick={handleLinkClick}
            >
              <i
                className={`${page.icon} align-middle fs-18 text-muted me-2`}
              ></i>
              <span>{page.text}</span>
            </Link>
          ) : (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.35rem 1.2rem",
                color: "gray",
                cursor: "default",
                paddingLeft: `${page.level * 1.2}rem`,
              }}
            >
              <i
                className={`${page.icon} align-middle fs-18 text-muted me-2`}
              ></i>
              <span>{page.text}</span>
            </div>
          )
        ) : null,
      )}
    </>
  );
};

export default PagesList;
