// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.package-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Customers/CustomerDetails/partials/components/CustomerApplications/cells/PackageCell/PackageCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;AACF","sourcesContent":[".package-cell {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-right: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
