import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { internalizeDate } from "helpers/utils";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { FormattedString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import classNames from "classnames";

const ParticipantAdder = ({
  filteredExistingParticipants,
  setActiveTab,
  setShouldAddParticipantTab,
}) => {
  const [loading, setLoading] = useState(true);
  const { formik, parentProgram } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const addNewParticipant = () => {
    formik.setFieldValue("participants", [
      ...formik.values.participants,
      {
        id: uuidv4(),
        gender: "",
        programPacket: "",
        firstName: "",
        lastName: "",
        email: "",
        phonePrefix: "",
        phoneNumber: "",
        dateOfBirth: "",
        comment: "",
        upsells: { diet: "", insurance: "", upsells: [] },
      },
    ]);
    formik.setErrors({});
    setActiveTab(formik.values.participants.length);
    setShouldAddParticipantTab(null);
  };

  const addExistingParticipant = (participant) => {
    const newParticipant = {
      ...participant,
      dateOfBirth: participant.dateOfBirth
        ? internalizeDate(participant.dateOfBirth)
        : "",
    };

    Object.keys(newParticipant).forEach((key) => {
      if (newParticipant[key] === null) {
        newParticipant[key] = "";
      }
    });

    formik.setFieldValue("participants", [
      ...formik.values.participants,
      newParticipant,
    ]);
    formik.setErrors({});
    setActiveTab(formik.values.participants.length);
    setShouldAddParticipantTab(null);
  };

  const calculateAge = (dateOfBirth, programStartDate) => {
    const birthDate = new Date(dateOfBirth);
    const startDate = new Date(programStartDate);
    const ageDifMs = startDate.getTime() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  useEffect(() => {
    if (filteredExistingParticipants.length === 0) {
      addNewParticipant();
    }
    setLoading(false);
  }, [filteredExistingParticipants]);

  if (loading) {
    return (
      <div className="participants-step">
        <PreloaderWrap />
      </div>
    );
  }

  return (
    <Card className="mt-4">
      <CardBody className="summary-card">
        <p className="summary-title">
          <FormattedString id="choose_an_option" />
        </p>
        <Row className="participant-list">
          {filteredExistingParticipants.map((existingParticipant, idx) => (
            <Col key={idx} md={12}>
              <div
                className={classNames("add-participant-btn", {
                  "add-participant-btn--out-of-bounds":
                    !existingParticipant.inBounds,
                })}
                onClick={() => addExistingParticipant(existingParticipant)}
              >
                <i className="ri-user-add-line add-participant-btn__icon"></i>
                <FormattedString id="add" /> {existingParticipant.firstName}{" "}
                {existingParticipant.lastName}{" "}
                <span style={{ color: "gray" }}>
                  (
                  {calculateAge(
                    existingParticipant.dateOfBirth,
                    parentProgram.startDate,
                  )}{" "}
                  <FormattedString id="years_old" />)
                </span>
                {!existingParticipant.inBounds && (
                  <>
                    <i
                      className="ri-error-warning-line"
                      id={`tooltip-${idx}`}
                    ></i>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[`tooltip-${idx}`]}
                      target={`tooltip-${idx}`}
                      toggle={() => toggleTooltip(`tooltip-${idx}`)}
                    >
                      <FormattedString id="this_participant_is_out_of_bounds" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Col>
          ))}
          <Col md={12}>
            <div
              className="add-participant-btn create-new-participant"
              onClick={addNewParticipant}
            >
              <i className="ri-add-line add-participant-btn__icon"></i>
              <FormattedString id="create_new_participant" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ParticipantAdder;
