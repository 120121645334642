import React from "react";
import { Row } from "reactstrap";
import classNames from "classnames";
import { usePresetTableContext } from "../PresetTableContext";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { getString, FormattedString } from "Components/Common/FormattedString";

const Pagination = ({ count, startIndex, endIndex }) => {
  const { currentPage, totalPages, loading, handlePageChange } =
    usePresetTableContext();

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 11;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - halfMaxPagesToShow);
      const endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className="page-item">
        {page === "..." ? (
          <span className="page-link">...</span>
        ) : (
          <LinkButton
            className={classNames("page-link", {
              active: currentPage === page,
              disabled: loading && currentPage !== page,
            })}
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(page);
            }}
            data-testid={`page-number-${page}`}
          >
            {page}
          </LinkButton>
        )}
      </li>
    ));
  };

  return (
    <Row
      className="align-items-center mt-2 g-3 text-center text-sm-start"
      data-testid="preset-table-pagination"
    >
      <div className="col-sm">
        <div className="text-muted" data-testid="pagination-info">
          {count === 0 ? (
            <>
              <FormattedString id={getString("showing")} />{" "}
              <span className="fw-semibold ms-1">0</span>{" "}
              <FormattedString id={getString("of")} />{" "}
              <span className="fw-semibold">{count}</span>{" "}
              <FormattedString id={getString("results")} />
            </>
          ) : (
            <>
              <FormattedString id={getString("showing")} />{" "}
              <span className="fw-semibold ms-1">{startIndex}</span> -{" "}
              <span className="fw-semibold">{endIndex}</span>{" "}
              <FormattedString id={getString("of")} />{" "}
              <span className="fw-semibold">{count}</span>{" "}
              <FormattedString id={getString("results")} />
            </>
          )}
        </div>
      </div>
      <div className="col-sm-auto">
        <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
          <li
            className={classNames("page-item", {
              disabled: currentPage === 1 || loading,
            })}
            data-testid="previous-page"
          >
            <LinkButton
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(currentPage - 1);
              }}
            >
              <FormattedString id={getString("previous")} />
            </LinkButton>
          </li>
          {renderPageNumbers()}
          <li
            className={classNames("page-item", {
              disabled: currentPage === totalPages || loading,
            })}
            data-testid="next-page"
          >
            <LinkButton
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(currentPage + 1);
              }}
            >
              <FormattedString id={getString("next")} />
            </LinkButton>
          </li>
        </ul>
      </div>
    </Row>
  );
};

export default Pagination;
