import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { internalizeDate } from "helpers/utils";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { FormattedString } from "Components/Common/FormattedString";

const ParticipantAdder = ({
  formik,
  filteredExistingParticipants,
  setActiveTab,
  setShouldAddParticipantTab,
}) => {
  const [loading, setLoading] = useState(true);

  const addNewParticipant = () => {
    formik.setFieldValue("participants", [
      ...formik.values.participants,
      {
        id: uuidv4(),
        gender: "",
        programPacket: "",
        firstName: "",
        lastName: "",
        email: "",
        phonePrefix: "",
        phoneNumber: "",
        dateOfBirth: "",
        comment: "",
        upsells: { diet: "", insurance: "", upsells: [] },
      },
    ]);
    formik.setErrors({});
    setActiveTab(formik.values.participants.length);
    setShouldAddParticipantTab(null);
  };

  const addExistingParticipant = (participant) => {
    const newParticipant = {
      ...participant,
      dateOfBirth: participant.dateOfBirth
        ? internalizeDate(participant.dateOfBirth)
        : "",
    };

    Object.keys(newParticipant).forEach((key) => {
      if (newParticipant[key] === null) {
        newParticipant[key] = "";
      }
    });

    formik.setFieldValue("participants", [
      ...formik.values.participants,
      newParticipant,
    ]);
    formik.setErrors({});
    setActiveTab(formik.values.participants.length);
    setShouldAddParticipantTab(null);
  };

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  useEffect(() => {
    if (filteredExistingParticipants.length === 0) {
      addNewParticipant();
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="participants-step">
        <PreloaderWrap />
      </div>
    );
  }

  return (
    <Card className="mt-4">
      <CardBody className="summary-card">
        <p className="summary-title">
          <FormattedString id="choose_an_option" />
        </p>
        <Row className="installment-list">
          {filteredExistingParticipants.map((existingParticipant, idx) => (
            <Col key={idx} md={12}>
              <div
                className="add-installment-btn"
                onClick={() => addExistingParticipant(existingParticipant)}
              >
                <i className="ri-user-add-line add-installment-btn__icon"></i>
                <FormattedString id="add" /> {existingParticipant.firstName}{" "}
                {existingParticipant.lastName}{" "}
                <span style={{ color: "gray" }}>
                  ({calculateAge(existingParticipant.dateOfBirth)}{" "}
                  <FormattedString id="years_old" />)
                </span>
              </div>
            </Col>
          ))}
          <Col md={12}>
            <div
              className="add-installment-btn create-new-participant"
              onClick={addNewParticipant}
            >
              <i className="ri-add-line add-installment-btn__icon"></i>
              <FormattedString id="create_new_participant" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ParticipantAdder;
