import React from "react";
import { Row } from "reactstrap";
import steps from "./provider/utils/stepsConfig";
import { useFormContext } from "./provider/utils/FormContext";

const ApplicationFormContent = () => {
  const { currentStep } = useFormContext();
  const CurrentStepComponent = steps.find(
    (step) => step.id === currentStep,
  ).component;

  return (
    <Row className="application-move-form">
      <CurrentStepComponent />
    </Row>
  );
};

export default ApplicationFormContent;
