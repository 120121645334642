import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomersList from "./CustomersList";
import ApplicationsList from "./ApplicationsList";
import ProgramsList from "./ProgramsList";
import { FormattedString, getString } from "Components/Common/FormattedString";
import "./SearchResults.scss";
import IpApplicationsList from "./IpApplicationsList";
import IpParticipantsList from "./IpParticipantsList";

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("searchTerm");

  const [searchInputValue, setSearchInputValue] = useState(query);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchInputValue(query);
  }, [query]);

  const handleSearchChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    if (searchInputValue.trim().length > 0) {
      searchParams.set("searchTerm", searchInputValue);
      setSearchParams(searchParams);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="search-results-container">
      <div className="search-results-header" style={{ marginBottom: "20px" }}>
        <h2>
          <FormattedString id="search_results_for" />: {query}
        </h2>
        <div className="search-actions">
          <Input
            type="text"
            value={searchInputValue}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            placeholder={getString("search_all")}
            className="form-control search-input"
            style={{ marginRight: "10px" }}
          />
          <Button color="primary" onClick={handleSearch}>
            <FormattedString id="search" />
          </Button>
        </div>
      </div>
      <div className="search-results-content">
        <ProgramsList />
        <ApplicationsList />
        <CustomersList />
        <IpApplicationsList />
        <IpParticipantsList />
      </div>
    </div>
  );
};

export default SearchResults;
