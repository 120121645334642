import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import "./CustomerParticipants.scss";
import { useSearchParams } from "react-router-dom";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import TableContainer from "Components/Common/TableContainerReactTable";
import classNames from "classnames";
import { format } from "date-fns";
import PackageBadge from "Components/Common/PackageBadge/PackageBadge";

export const CustomerParticipants = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const initialSearchTerm = searchParams.get("searchTerm") || "";
  const [globalFilter] = useState(initialSearchTerm);
  const isInitialRender = useRef(true);

  const { customerParticipants } = useContext(CustomerDetailsContext);

  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }

    const params = {};
    if (globalFilter) {
      params.searchTerm = globalFilter;
    }
    if (pageNumber > 1) {
      params.page = pageNumber.toString();
    }
    setSearchParams(params);
  }, [pageNumber, globalFilter]);

  const columns = useMemo(
    () => [
      {
        Header: getString("first_name"),
        accessor: "firstName",
      },
      {
        Header: getString("last_name"),
        accessor: "lastName",
      },
      {
        Header: getString("gender"),
        accessor: "gender",
        Cell: ({ value }) => (
          <div className="customer-participants__cell-gender">
            <div
              className={classNames([
                "customer-participants__cell-gender-item",
                "customer-participants__cell-gender-item--" + value,
              ])}
            >
              <FormattedString id={value} />
            </div>
          </div>
        ),
      },
      {
        Header: getString("date_of_birth"),
        accessor: "dateOfBirth",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            {value ? format(new Date(value), "dd.MM.yyyy") : "-"}
          </div>
        ),
      },
      {
        Header: getString("last_packet"),
        accessor: "lastPacket",
        Cell: ({ value }) => (
          <div className="customer-participants__cell-packet">
            <PackageBadge packageLabel={value} />
          </div>
        ),
      },
      {
        Header: getString("participant_note"),
        accessor: "participantNote",
        Cell: ({ value }) => <div className="text-nowrap">{value || "-"}</div>,
      },
    ],
    [],
  );

  return customerParticipants ? (
    <div className="customer-participants">
      <TableContainer
        columns={columns || []}
        data={customerParticipants || []}
        isPagination={true}
        isGlobalFilter={false}
        excludeUrlHandling
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </div>
  ) : (
    <PreloaderWrap />
  );
};
