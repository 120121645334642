import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIpApplicationsListData } from "../../store/ipApplications/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import { format } from "date-fns";
import "./IpApplicationsList.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { Badge } from "Components/Common/Badges/Badges";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { AssignIpCanvas } from "./modals/AssignIpCanvas";

const IpApplicationsList = ({
  assignIpCanvasVisible,
  setAssignIpCanvasVisible,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    ipApplicationsData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.IpApplications);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(getIpApplicationsListData(pageNumber, globalFilter));
  }, [searchParams]);

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: (d) => (
          <span className="text-decoration-none">
            {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
          </span>
        ),
        Cell: ({ row }) => (
          <span className="text-decoration-none">
            {`${row.original.firstName || ""} ${row.original.lastName || ""}`.trim() ||
              "-"}
          </span>
        ),
      },
      {
        Header: getString("date_of_birth"),
        accessor: "dateOfBirth",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            {format(new Date(value), "yyyy-MM-dd")}
          </div>
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("meeting_point"),
        accessor: "meetingPoint",
        Cell: ({ value }) =>
          value ? (
            <div className="text-nowrap">
              <strong>{value.address}</strong>, {value.city}
            </div>
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: getString("gender"),
        accessor: "gender",
        Cell: ({ value }) => (
          <span className={`gender-column ${value.toLowerCase()}`}>
            <i
              className={`ri-${value === "male" ? "men" : "women"}-line me-1`}
            ></i>
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("country_of_origin"),
        accessor: "countryOfOrigin",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            <FormattedString id={value} />
          </div>
        ),
      },
      {
        Header: getString("preferred_diet"),
        accessor: "preferredDiet",
        Cell: ({ value }) => (
          <span
            className={`preferred-diet-column ${value.toLowerCase().replace(/\s+/g, "-")}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => <Badge size="medium">{value}</Badge>,
      },
      // {
      //   id: "Actions",
      //   Header: () => (
      //     <div style={{ textAlign: "right" }}>
      //       <FormattedString id="actions" />
      //     </div>
      //   ),
      //   disableSortBy: true,
      //   accessor: (cellProps) => (
      //     <ActionButtons
      //       customShowDetailsLink={`/ip-application/${cellProps.id}`}
      //       itemId={cellProps.id}
      //     />
      //   ),
      // },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="ip-applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={ipApplicationsData || []}
        isGlobalFilter={true}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_applications_search_for")}
        error={error}
        onRetry={() =>
          dispatch(getIpApplicationsListData(pageNumber, globalFilter))
        }
      />
      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
          refreshData={() =>
            dispatch(getIpApplicationsListData(pageNumber, globalFilter))
          }
        />
      )}
    </div>
  );
};

export { IpApplicationsList };
