import React, { useContext } from "react";
import { Tile } from "Components/Common/Tile/Tile";
import { BillingData } from "pages/Applications/ApplicationDetails/partials/components/BillingData/BillingData";
import { ApplicationUtms } from "pages/Applications/ApplicationDetails/partials/components/ApplicationUtms/ApplicationUtms";
import { CustomerDetails } from "pages/Applications/ApplicationDetails/partials/components/CustomerDetails/CustomerDetails";
import { SalesPerson } from "pages/Applications/ApplicationDetails/partials/components/SalesPerson/SalesPerson";
import { getString } from "Components/Common/FormattedString";
import { ApplicationInvites } from "pages/Applications/ApplicationDetails/partials/components/ApplicationInvites/ApplicationInvites";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import EmailActions from "pages/Applications/ApplicationDetails/partials/components/EmailActions/EmailActions";

const ApplicationDetailsRight = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const salesPersonSet = applicationData.salesPerson !== null;

  return (
    <>
      <Tile title={getString("customer_details")} gap="14px">
        <CustomerDetails />
      </Tile>

      <Tile
        title={getString("billing_data")}
        gap="14px"
        titleIcon={<i className="ri-map-pin-line align-middle text-muted"></i>}
      >
        <BillingData />
      </Tile>

      {salesPersonSet ? (
        <Tile title={getString("sales_person")} gap="14px">
          <SalesPerson />
        </Tile>
      ) : (
        ""
      )}

      {applicationData.status !== "moved" && (
        <Tile title={getString("email_actions")} gap="14px">
          <EmailActions />
        </Tile>
      )}

      <Tile title={getString("application_invites")} gap="14px">
        <ApplicationInvites />
      </Tile>

      <Tile title={getString("utm_parameters")} gap="14px">
        <ApplicationUtms />
      </Tile>
    </>
  );
};

export default ApplicationDetailsRight;
