import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import ApplicationMoveStepNavigation from "../../components/ApplicationMoveStepNavigation/ApplicationMoveStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import { internalizeDate } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import ParticipantForm from "./ParticipantForm";
import ParticipantTabs from "./ParticipantTabs";
import ParticipantAdder from "./ParticipantAdder";
import "./ParticipantsStep.scss";

const ParticipantsStep = () => {
  const { formik, parentProgram } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);
  const [existingParticipants] = useState([]);
  const [shouldAddParticipantTab, setShouldAddParticipantTab] = useState(null);

  useEffect(() => {
    if (formik.values.participants.length === 0) {
      setShouldAddParticipantTab(0);
    }
  }, [formik.values.participants]);

  const programPacketOptions = parentProgram?.programPackets.map((packet) => ({
    value: packet.id,
    label: packet.label,
    ppMinAge: packet.programPacketTemplate.ppMinAge,
    ppMaxAge: packet.programPacketTemplate.ppMaxAge,
  }));

  const generateChecksum = (participant) => {
    return `${participant.firstName}-${participant.lastName}-${internalizeDate(participant.dateOfBirth)}`;
  };

  const { smallestMinAge, highestMaxAge } = programPacketOptions.reduce(
    (acc, packet) => {
      if (packet.ppMinAge < acc.smallestMinAge) {
        acc.smallestMinAge = packet.ppMinAge;
      }
      if (packet.ppMaxAge > acc.highestMaxAge) {
        acc.highestMaxAge = packet.ppMaxAge;
      }
      return acc;
    },
    { smallestMinAge: Infinity, highestMaxAge: -Infinity },
  );

  const filteredExistingParticipants = existingParticipants.filter(
    (existingParticipant) => {
      const existingChecksum = generateChecksum(existingParticipant);

      const isParticipantInBounds = (
        dateOfBirth,
        minAge,
        maxAge,
        programStartDate,
      ) => {
        const birthDate = new Date(dateOfBirth);
        const startDate = new Date(programStartDate);
        const ageDifMs = startDate.getTime() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age >= minAge && age <= maxAge;
      };

      return (
        !formik.values.participants.some(
          (participant) => generateChecksum(participant) === existingChecksum,
        ) &&
        isParticipantInBounds(
          existingParticipant.dateOfBirth,
          smallestMinAge,
          highestMaxAge,
          parentProgram.startDate,
        )
      );
    },
  );

  return (
    <div className="participants-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="participants" />
          </h2>
        </Col>
      </Row>
      <ParticipantTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <TabContent activeTab={activeTab}>
        {formik.values.participants.map((participant, index) => (
          <TabPane tabId={index} key={`${participant.id}${index}`}>
            {shouldAddParticipantTab === index ? (
              <ParticipantAdder
                filteredExistingParticipants={filteredExistingParticipants}
                setActiveTab={setActiveTab}
                setShouldAddParticipantTab={setShouldAddParticipantTab}
              />
            ) : (
              <ParticipantForm
                participant={participant}
                index={index}
                programPacketOptions={programPacketOptions}
                smallestMinAge={smallestMinAge}
                highestMaxAge={highestMaxAge}
              />
            )}
          </TabPane>
        ))}
        {shouldAddParticipantTab === formik.values.participants.length && (
          <TabPane tabId={formik.values.participants.length}>
            <ParticipantAdder
              filteredExistingParticipants={filteredExistingParticipants}
              setActiveTab={setActiveTab}
              setShouldAddParticipantTab={setShouldAddParticipantTab}
            />
          </TabPane>
        )}
      </TabContent>
      {formik.errors.participants &&
        typeof formik.errors.participants === "string" && (
          <div className="text-danger">{formik.errors.participants}</div>
        )}
      <ApplicationMoveStepNavigation />
    </div>
  );
};

export default ParticipantsStep;
