import { getString, getStringSync } from "Components/Common/FormattedString";
import "./ProgramStatusChanger.scss";
import { FormSelect } from "Components/Entity/FormSelect";
import { updateProgramStatus } from "helpers/API/core-service/cs_backend_helper";
import { toast_success } from "helpers/toast_helper";
import { statusOptions } from "models/programStatuses";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import React, { useContext, useEffect, useState } from "react";

export const ProgramStatusChanger = ({ disabled = false }) => {
  const { programData, setProgramErrors, refreshProgramData } = useContext(
    ProgramDetailsContext,
  );

  const programId = programData.id;
  const defaultStatus = programData.status;

  const [status, setStatus] = useState(
    statusOptions.find((option) => option.value === defaultStatus),
  );

  const handleChange = (selectedOption) => {
    updateProgramStatus(programId, { status: selectedOption.value })
      .then(() => {
        toast_success(getString("program_status_updated"));
        setStatus(selectedOption);
        refreshProgramData();
      })
      .catch((e) => {
        if (e?.data?.fields) {
          const mappedErrors = e.data.fields.reduce((acc, field) => {
            acc[field.name] = field.message;
            return acc;
          }, {});
          setProgramErrors(mappedErrors);
        }
      });
  };

  useEffect(() => {
    setProgramErrors({});
  }, [programData]);

  const archivedOption = {
    value: "archived",
    label: getStringSync("archived"),
  };

  return (
    <div className="program-status-changer">
      <FormSelect
        label="Program Status"
        className="program-status-changer__select"
        name="programStatus"
        value={status}
        defaultValue={
          programData?.status === "archived" ? archivedOption : undefined
        }
        options={statusOptions}
        onChange={handleChange}
        isDisabled={disabled}
      />
    </div>
  );
};
