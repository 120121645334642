import React, { useContext } from "react";
import { Modal } from "reactstrap";

import { getString } from "Components/Common/FormattedString";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";
import { deleteProgramDiscount } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_success } from "helpers/toast_helper";

export const DeleteProgramDiscountModal = ({ itemId, visibility, toggle }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteProgramDiscountEntry = () => {
    deleteProgramDiscount(programData.id, itemId).then(() => {
      toast_success(getString("program_discount_deleted"));
      toggle(false);
      refreshProgramData();
    });
  };

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString("program_discount_sm")}
        toggle={toggle}
        deleteAction={() => deleteProgramDiscountEntry()}
      />
    </Modal>
  );
};
