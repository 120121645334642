// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installment-form-fields .fancy-remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding-left: 1px;
  background-color: #ff4d4f;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
}
.installment-form-fields .fancy-remove-icon:hover {
  background-color: #ff7875;
  transform: scale(1.1);
}
.installment-form-fields .fancy-remove-icon:active {
  background-color: #d9363e;
  transform: scale(0.9);
}
.installment-form-fields .fancy-remove-icon i {
  font-size: 16px;
}
.installment-form-fields .fancy-remove-icon[disabled] {
  background-color: #d9d9d9;
  cursor: not-allowed;
  pointer-events: none;
}
.installment-form-fields .blue-header {
  color: #3897dd;
}
.installment-form-fields .red-header {
  color: #ff4d4f;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationMoveForm/steps/SummaryStep/InstallmentFormFields/InstallmentFormFields.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,2DACE;EAEF,yBAAA;UAAA,iBAAA;EACA,uBAAA;AAFJ;AAII;EACE,yBAAA;EACA,qBAAA;AAFN;AAKI;EACE,yBAAA;EACA,qBAAA;AAHN;AAMI;EACE,eAAA;AAJN;AAOI;EACE,yBAAA;EACA,mBAAA;EACA,oBAAA;AALN;AASE;EACE,cAAA;AAPJ;AAUE;EACE,cAAA;AARJ","sourcesContent":[".installment-form-fields {\n  .fancy-remove-icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 30px;\n    height: 30px;\n    padding-left: 1px;\n    background-color: #ff4d4f;\n    border-radius: 50%;\n    color: white;\n    cursor: pointer;\n    transition:\n      background-color 0.3s ease,\n      transform 0.3s ease;\n    user-select: none;\n    -webkit-user-drag: none;\n\n    &:hover {\n      background-color: #ff7875;\n      transform: scale(1.1);\n    }\n\n    &:active {\n      background-color: #d9363e;\n      transform: scale(0.9);\n    }\n\n    i {\n      font-size: 16px;\n    }\n\n    &[disabled] {\n      background-color: #d9d9d9;\n      cursor: not-allowed;\n      pointer-events: none;\n    }\n  }\n\n  .blue-header {\n    color: #3897dd;\n  }\n\n  .red-header {\n    color: #ff4d4f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
