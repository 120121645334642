import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getString } from "Components/Common/FormattedString";
import {
  handleInputChange,
  handlePhoneChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import {
  getApplicationsListData,
  postApplication,
} from "store/applications/action";
import { FormSelect } from "Components/Entity/FormSelect";
import FormTextField from "Components/Entity/FormTextField";
import { createApplicationSchema } from "../../models/applications";
import { getProgramListData } from "store/programs/action";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { programStatus } from "models/programStatuses";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";

const AddApplication = ({ visibility, toggle, defaultCustomer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, formValidationErrors } = useSelector(
    (state) => state.Applications,
  );
  const { loading: programsLoading, programsData } = useSelector(
    (state) => state.Programs,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      programId: "",
      customerId: defaultCustomer ? defaultCustomer.id : "",
      phonePrefix: "48",
      phoneNumber: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: createApplicationSchema,
    onSubmit: async (values) => {
      const applicationData = { ...values };
      if (!applicationData.customerId) {
        delete applicationData.customerId;
      }
      dispatch(
        postApplication(applicationData, (response) => {
          const applicationId = response.applicationId;
          if (applicationId) {
            navigate(`/pp-application/${applicationId}/edit`);
          } else {
            dispatch(getApplicationsListData());
            toggle(false);
          }
        }),
      );
    },
  });

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  const isRegistrationNeeded = () => {
    return validation.values.customerId === "";
  };

  const programOptions = programsData
    .filter((program) => [programStatus.on_sale].includes(program.status))
    .map((program) => ({
      value: program.customId,
      label: `#${program.customId} ${program.name} ${new Date(program.startDate).toLocaleDateString()} - ${new Date(program.endDate).toLocaleDateString()}`,
    }));

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("create_application")}
        formId={"add-application"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        {programsLoading ? (
          <PreloaderWrap />
        ) : (
          <Form
            id="add-application"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <Card>
              <CardBody className="card-body">
                <FormSelect
                  label={getString("program")}
                  name="program"
                  value={validation.values.programId}
                  defaultValue={{
                    value: validation.values.programId,
                    label: getString(validation.values.programId),
                  }}
                  onChange={(selectedOption) =>
                    handleSelectChange(validation, selectedOption, "programId")
                  }
                  options={programOptions}
                  isMulti={false}
                  error={validation.errors.programId}
                />
                {defaultCustomer ? (
                  <FormSelect
                    label={getString("customer")}
                    name="customer"
                    value={validation.values.customerId}
                    defaultValue={{
                      value: defaultCustomer.id,
                      label: `${defaultCustomer.firstName} ${defaultCustomer.lastName} (${defaultCustomer.email})`,
                    }}
                    isDisabled={true}
                    options={[]}
                    error={validation.errors.customerId}
                  />
                ) : null}
                {isRegistrationNeeded() && (
                  <>
                    <FormPhoneField
                      label={getString("phoneNumber")}
                      name="phoneNumber"
                      phonePrefixName="phonePrefix"
                      value={validation.values["phoneNumber"] || ""}
                      onChange={(e) =>
                        handlePhoneChange(validation, e, "phoneNumber")
                      }
                      phonePrefix={validation.values.phonePrefix || ""}
                      onPrefixChange={(e) =>
                        handlePhoneChange(validation, e, "phonePrefix")
                      }
                      error={
                        validation.errors["phoneNumber"] ||
                        validation.errors["phonePrefix"]
                      }
                      setError={(error) =>
                        validation.setFieldError("phoneNumber", error)
                      }
                    />
                    <FormTextField
                      label={getString("email")}
                      name="email"
                      value={validation.values.email}
                      onChange={(e) =>
                        handleInputChange(validation, e, "email")
                      }
                      error={validation.errors.email}
                      touched={validation.touched.email}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("first_name")}
                      name="firstName"
                      value={validation.values.firstName}
                      onChange={(e) =>
                        handleInputChange(validation, e, "firstName")
                      }
                      error={validation.errors.firstName}
                      touched={validation.touched.firstName}
                      required={isRegistrationNeeded()}
                    />
                    <FormTextField
                      label={getString("last_name")}
                      name="lastName"
                      value={validation.values.lastName}
                      onChange={(e) =>
                        handleInputChange(validation, e, "lastName")
                      }
                      error={validation.errors.lastName}
                      touched={validation.touched.lastName}
                      required={isRegistrationNeeded()}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Form>
        )}
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddApplication;
