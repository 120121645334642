import React from "react";
import { Input, Label, FormFeedback } from "reactstrap";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const FormTextField = ({
  label,
  name,
  value,
  onChange,
  error,
  type = "text",
  tooltip,
  disabled = false,
}) => {
  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
        {tooltip && (
          <AngloTooltip stringId={tooltip}>
            <i className="ri-information-line"></i>
          </AngloTooltip>
        )}
      </Label>
      <Input
        type={type}
        className="form-control"
        id={name}
        name={name}
        onChange={(e) => onChange(e, name)}
        value={value || ""}
        invalid={!!error}
        disabled={disabled}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormTextField;
