import set from "lodash/set";

function parseApplicationErrorToFormikErrors(error, formik) {
  if (!error) {
    return;
  }

  const errors = {};

  if (error.fields) {
    error.fields.forEach((field) => {
      set(errors, field.name, field.message || "Error occurred");
    });
  }
  formik.setErrors(errors);
}

export { parseApplicationErrorToFormikErrors };
