import React from "react";
import { Row, Col } from "reactstrap";
import { getString, FormattedString } from "Components/Common/FormattedString";
import FormSwitch from "Components/Entity/FormSwitch";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";

const InstallmentHeader = ({
  advancedManagement,
  toggleAdvancedManagement,
}) => {
  return (
    <Row className="mb-4 align-items-center">
      <Col>
        <h2
          className="text-left text-primary blue-header"
          style={{ marginBottom: 0 }}
        >
          <FormattedString id="installments" />
        </h2>
      </Col>
      <Col className="text-right">
        <WithPermission permissionName="PP_APPLICATION_ACCOUNTING">
          <FormSwitch
            label={getString("advanced_management")}
            name="advancedManagement"
            checked={advancedManagement}
            onChange={toggleAdvancedManagement}
            noMargin
          />
        </WithPermission>
      </Col>
    </Row>
  );
};

export default InstallmentHeader;
