// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installments-step .summary-card {
  margin-bottom: 20px;
}
.installments-step .installment-list {
  margin-top: 20px;
}
.installments-step .add-installment-btn {
  background-color: transparent;
  border: none;
  color: rgb(56, 151, 221);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.installments-step .add-installment-btn__icon {
  border: 1px solid rgb(56, 151, 221);
  border-radius: 50%;
  padding: 0px 3px;
  transition: background-color 0.3s, color 0.3s;
}
.installments-step .add-installment-btn:hover .add-installment-btn__icon {
  background-color: rgb(56, 151, 221);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/steps/InstallmentsStep/InstallmentsStep.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,6BAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AAFJ;AAII;EACE,mCAAA;EACA,kBAAA;EACA,gBAAA;EACA,6CACE;AAHR;AAQM;EACE,mCAAA;EACA,WAAA;AANR","sourcesContent":[".installments-step {\n  .summary-card {\n    margin-bottom: 20px;\n  }\n\n  .installment-list {\n    margin-top: 20px;\n  }\n\n  .add-installment-btn {\n    background-color: transparent;\n    border: none;\n    color: rgb(56, 151, 221);\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    cursor: pointer;\n    user-select: none;\n\n    &__icon {\n      border: 1px solid rgb(56, 151, 221);\n      border-radius: 50%;\n      padding: 0px 3px;\n      transition:\n        background-color 0.3s,\n        color 0.3s;\n    }\n\n    &:hover {\n      .add-installment-btn__icon {\n        background-color: rgb(56, 151, 221);\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
