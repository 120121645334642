import React, { useContext, useState } from "react";
import "./InternationalParticipantsTab.scss";
import { Card, CardBody, Button, TabPane } from "reactstrap";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { AssignIpCanvas } from "../modals/AssignIpCanvas";
import { DeleteIpModal } from "../modals/DeleteIpModal";
import TableContainer from "Components/Common/TableContainerReactTable";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { ChangeIpStatus } from "../modals/ChangeIpStatus";
import { Link } from "react-router-dom";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { format } from "date-fns";

export const InternationalParticipantsTab = () => {
  const { programData } = useContext(ProgramDetailsContext);
  const { internationalParticipants } = programData;

  const processedInternationalParticipants = internationalParticipants.map(
    (packet) => {
      const data = packet.data.map((participant) => {
        return {
          ...participant,
          action: participant.id,
          programPacketId: packet.programPacketId,
        };
      });

      return {
        ...packet,
        data,
      };
    },
  );

  const [assignIpCanvasVisible, setAssignIpCanvasVisible] = useState(false);
  const [deleteIpModalVisible, setDeleteIpModalVisible] = useState(false);
  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [selectedProgramPacketId, setSelectedProgramPacketId] = useState(null);
  const [selectedParticipantStatus, setSelectedParticipantStatus] =
    useState("");
  const [selectedParticipantType, setSelectedParticipantType] = useState("");
  const [defaultPacketId, setDefaultPacketId] = useState(null);

  const handleDeleteClick = (participantId, programPacketId) => {
    setSelectedParticipantId(participantId);
    setSelectedProgramPacketId(programPacketId);
    setDeleteIpModalVisible(true);
  };

  const handleAssignIpClick = (programPacketId) => {
    setDefaultPacketId(programPacketId);
    setAssignIpCanvasVisible(true);
  };

  const handleChangeStatusClick = (
    participantId,
    programPacketId,
    status,
    type,
  ) => {
    setSelectedParticipantId(participantId);
    setSelectedProgramPacketId(programPacketId);
    setSelectedParticipantStatus(status);
    setSelectedParticipantType(type);
    setChangeStatusModalVisible(true);
  };

  const columns = [
    {
      Header: getString("participant_name"),
      accessor: (d) => (
        <span className="text-decoration-none">
          {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
        </span>
      ),
      Cell: ({ row }) => (
        <Link
          to={`/ip-participants/${row.original.internationalParticipantId}`}
          className="text-decoration-none"
        >
          {`${row.original.firstName || ""} ${row.original.lastName || ""}`.trim() ||
            "-"}
        </Link>
      ),
    },
    {
      Header: getString("email"),
      accessor: "email",
      Cell: ({ value }) => <em>{value || "-"}</em>,
    },
    {
      Header: getString("package_name"),
      accessor: "programPacketName",
      Cell: ({ value }) => <span>{value || "-"}</span>,
    },
    {
      Header: getString("gender"),
      accessor: "gender",
      Cell: ({ value }) => (
        <span className={`gender-column ${value.toLowerCase()}`}>
          <i
            className={`ri-${value === "male" ? "men" : "women"}-line me-1`}
          ></i>
          <FormattedString id={value} />
        </span>
      ),
    },
    {
      Header: getString("ip_type"),
      accessor: "type",
      Cell: ({ value }) => (
        <span className="badge">
          <FormattedString id={value} />
        </span>
      ),
    },
    {
      Header: getString("status"),
      accessor: "applicationStatus",
      Cell: ({ value }) => (
        <span className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}>
          <FormattedString id={value} />
        </span>
      ),
    },
    {
      Header: getString("hotel"),
      accessor: "hotel.name",
      Cell: ({ value }) => <span>{value || "N/A"}</span>,
    },
    {
      Header: getString("program_dates"),
      accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
      Cell: ({ row }) => (
        <div className="text-nowrap">
          {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
            new Date(row.original.programEndDate),
            "yyyy-MM-dd",
          )}`}
        </div>
      ),
    },
    {
      Header: getString("program_status"),
      accessor: "programStatus",
      Cell: ({ value }) => (
        <span className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}>
          <FormattedString id={value} />
        </span>
      ),
    },
    {
      Header: (
        <div className="text-end">
          <FormattedString id="actions" />
        </div>
      ),
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="text-end">
          <ActionButtons
            onDelete={() =>
              handleDeleteClick(
                row.original.internationalParticipantId,
                row.original.programPacketId,
              )
            }
            onChangeStatus={() =>
              handleChangeStatusClick(
                row.original.internationalParticipantId,
                row.original.programPacketId,
                row.original.applicationStatus,
                row.original.type,
              )
            }
            itemId={row.original.id}
          />
        </div>
      ),
      style: { textAlign: "right" },
    },
  ];

  return (
    <TabPane
      tabId="international-participants"
      className="international-participants-tab"
    >
      {processedInternationalParticipants.map((packet) => (
        <Card key={packet.programPacketId}>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">
                {packet.programPacketName}
              </h5>
              <div className="flex-shrink-0">
                <WithPermission permissionName="IP_PARTICIPANT_UPDATE">
                  <Button
                    className="btn btn-danger"
                    onClick={() => handleAssignIpClick(packet.programPacketId)}
                  >
                    <i className="ri-user-add-fill me-1 align-bottom"></i>
                    <FormattedString id="assignIpParticipant" />
                  </Button>
                </WithPermission>
              </div>
            </div>

            <TableContainer
              columns={columns}
              data={packet.data}
              isPagination={true}
              excludeUrlHandling
              isGlobalFilter={true}
              isBordered={false}
              customPageSize={10}
              className="custom-header-css table align-middle table-nowrap"
              tableClassName="table-centered align-middle table-nowrap mb-0"
              theadClassName="text-muted table-light"
              SearchPlaceholder={getString(
                "international_participant_search_for",
              )}
            />
          </CardBody>
        </Card>
      ))}
      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
          defaultPacket={defaultPacketId}
        />
      )}
      {deleteIpModalVisible && (
        <DeleteIpModal
          visible={deleteIpModalVisible}
          setVisible={setDeleteIpModalVisible}
          itemId={selectedParticipantId}
          programPacketId={selectedProgramPacketId}
        />
      )}
      {changeStatusModalVisible && (
        <ChangeIpStatus
          visible={changeStatusModalVisible}
          setVisible={setChangeStatusModalVisible}
          itemId={selectedParticipantId}
          programPacketId={selectedProgramPacketId}
          initialStatus={selectedParticipantStatus}
          initialType={selectedParticipantType}
        />
      )}
    </TabPane>
  );
};
