// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-summary-cell .text-muted {
  margin: 0 2px;
}
.ip-summary-cell .ip-summary-cell__bracket {
  color: black;
  margin: 0 2px;
}
.ip-summary-cell .ip-summary-cell__sum {
  color: #299cdb;
  font-weight: 700;
}
.ip-summary-cell .ip-summary-cell__total {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProgramPackets/cells/IpSummaryCell/IpSummaryCell.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,YAAA;EACA,aAAA;AAAJ;AAEE;EACE,cAAA;EACA,gBAAA;AAAJ;AAEE;EACE,YAAA;AAAJ","sourcesContent":[".ip-summary-cell {\n  .text-muted {\n    margin: 0 2px;\n  }\n  .ip-summary-cell__bracket {\n    color: black;\n    margin: 0 2px;\n  }\n  .ip-summary-cell__sum {\n    color: #299cdb;\n    font-weight: 700;\n  }\n  .ip-summary-cell__total {\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
