import React from "react";
import { getString } from "Components/Common/FormattedString";
import FormNumberField from "Components/Entity/FormNumberField";

const WeekFilter = ({ onChange, filters }) => {
  const handleChange = (e) => {
    const value = e.target.value === "" ? undefined : e.target.value;
    onChange("week", value);
  };

  return (
    <FormNumberField
      label={getString("week")}
      name="week"
      value={filters.week || ""}
      onChange={handleChange}
      tooltip="weekFilterTooltip"
      min={1}
      max={52}
    />
  );
};

export default WeekFilter;
