import React from "react";
import { Alert } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";

const InstallmentAlert = ({ alertVisible }) => {
  return (
    alertVisible && (
      <Alert color="danger">
        <div className="d-flex align-items-center justify-content-center">
          <i
            className="ri-error-warning-line"
            style={{ marginRight: "4px" }}
            role="img"
          ></i>
          <FormattedString id="update_installment_plan_paragraph" />
        </div>
      </Alert>
    )
  );
};

export default InstallmentAlert;
