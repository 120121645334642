import i18next from "i18next";
import strings from "resources/strings";

export const FormattedString = ({ id, defaultValue }) => {
  return i18next.t(id, { defaultValue });
};

export const getString = (id, defaultValue, props = {}) => {
  let translation = i18next.t(id, { defaultValue, ...props });

  Object.keys(props).forEach((key) => {
    const placeholder = `{${key}}`;
    translation = translation.replace(new RegExp(placeholder, "g"), props[key]);
  });

  return translation;
};

export const getStringSync = (id, defaultValue, props = {}) => {
  let translation = strings.en.translation[id] || defaultValue || id;

  Object.keys(props).forEach((key) => {
    const placeholder = `{${key}}`;
    translation = translation.replace(new RegExp(placeholder, "g"), props[key]);
  });

  return translation;
};
