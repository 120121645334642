import React from "react";
import { FormattedString } from "Components/Common/FormattedString";
import { FormSelect } from "Components/Entity/FormSelect";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { handleSelectChange } from "helpers/validation_helper";

const PointOfSellField = ({
  formik,
  index,
  pointOfSellOptions,
  isDisabled,
  canEditInstallment,
  installment,
}) => {
  const pointOfSellError =
    formik.errors.installments?.[index]?.pointOfSell || "";

  return (
    <FormSelect
      label={
        pointOfSellError !== "" ? (
          <AngloTooltip text={pointOfSellError.toString()}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: "red",
              }}
            >
              <i className="ri-information-line"></i>
              <FormattedString id="pointOfSell" />
            </div>
          </AngloTooltip>
        ) : (
          <FormattedString id="pointOfSell" />
        )
      }
      name={`installments[${index}].pointOfSell`}
      value={formik.values.installments[index].pointOfSell}
      defaultValue={pointOfSellOptions.find(
        (r) => r.value === installment.pointOfSell,
      )}
      onChange={(selectedOption) =>
        handleSelectChange(
          formik,
          selectedOption,
          `installments[${index}].pointOfSell`,
        )
      }
      isMulti={false}
      options={pointOfSellOptions}
      error={formik.errors.installments?.[index]?.pointOfSell ? " " : ""}
      isDisabled={isDisabled || !canEditInstallment}
    />
  );
};

export default PointOfSellField;
