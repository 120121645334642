import React from "react";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { getString } from "../../../../Components/Common/FormattedString";

const ApplicationStatistics = ({ applicationsStats }) => {
  const { dates, data } = applicationsStats;

  const orderedData = [
    data.find((series) => series.name === "Paid Applications"),
    data.find((series) => series.name === "Pre-Booking Applications"),
    data.find((series) => series.name === "Abandoned Applications"),
    data.find((series) => series.name === "Canceled Applications"),
  ];

  var options = {
    chart: {
      height: 380,
      type: "area",
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: getChartColorsArray('["#28a745", "#ffc107", "#007bff", "#dc3545"]'),
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: dates,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      <Col className="col-xxl-8 order-xxl-0 order-first">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">
              {getString("application_statistics")}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactApexChart
              dir="ltr"
              options={options}
              series={orderedData}
              type="area"
              height="380"
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ApplicationStatistics;
