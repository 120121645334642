// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html .form-select {
  padding: 0;
  border: none;
}
html .form-select.is-invalid .form-select__control {
  border-color: var(--vz-form-invalid-border-color);
}
html .form-select.is-warning .form-select__control {
  border-color: #d4ae2b;
}
html .form-select .form-select__menu {
  z-index: 2;
}
html .form-select.is-invalid {
  border-color: #dc3545;
}
html .form-select.is-warning {
  border-color: #d4ae2b;
}

.warning-feedback {
  color: #d4ae2b;
  display: flex;
  gap: 2px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/FormSelect.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,YAAA;AACF;AAEI;EACE,iDAAA;AAAN;AAKI;EACE,qBAAA;AAHN;AAOE;EACE,UAAA;AALJ;AAQE;EACE,qBAAA;AANJ;AASE;EACE,qBAAA;AAPJ;;AAWA;EACE,cAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AARF","sourcesContent":["html .form-select {\n  padding: 0;\n  border: none;\n\n  &.is-invalid {\n    .form-select__control {\n      border-color: var(--vz-form-invalid-border-color);\n    }\n  }\n\n  &.is-warning {\n    .form-select__control {\n      border-color: #d4ae2b;\n    }\n  }\n\n  .form-select__menu {\n    z-index: 2;\n  }\n\n  &.is-invalid {\n    border-color: #dc3545;\n  }\n\n  &.is-warning {\n    border-color: #d4ae2b;\n  }\n}\n\n.warning-feedback {\n  color: #d4ae2b;\n  display: flex;\n  gap: 2px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
