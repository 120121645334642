import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, Form, FormGroup } from "reactstrap";
import { getString, getStringSync } from "Components/Common/FormattedString";
import { handleSelectChange } from "helpers/validation_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { updateIpStatus } from "helpers/API/core-service/cs_backend_helper";
import { toast_success } from "helpers/toast_helper";
import { ipGroupedStatusOptions, ipTypeOptions } from "models/ipParticipants";
import { FormSelectGrouped } from "Components/Entity/FormSelectGrouped";
import { FormSelect } from "Components/Entity/FormSelect";
import { parseErrorToFormikErrors } from "helpers/parseErrorsToFormik";

export const ChangeIpStatus = ({
  visible,
  setVisible,
  itemId,
  programPacketId,
  initialStatus,
  initialType,
}) => {
  const { refreshProgramData } = useContext(ProgramDetailsContext);
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      status: initialStatus || "",
      type: initialType || "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required(getStringSync("select_ip_status")),
      type: Yup.string().required(getStringSync("participant_type_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateIpStatus(programPacketId, itemId, values)
        .then(() => {
          toast_success(getString("ip_status_updated"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => parseErrorToFormikErrors(e, validation))
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    if (initialStatus) {
      validation.setFieldValue("status", initialStatus);
    }
    if (initialType) {
      validation.setFieldValue("type", initialType);
    }
  }, [initialStatus, initialType]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("change_ip_status")}
      formId="change-ip-status-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card className="change-ip-status">
        <CardBody className="card-body">
          <Form
            id="change-ip-status-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormGroup>
              <FormSelectGrouped
                name="status"
                id="statusSelect"
                label={getString("select_ip_status")}
                isMulti={false}
                options={ipGroupedStatusOptions}
                value={validation.values.status}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "status")
                }
                error={validation.errors.status}
              />
            </FormGroup>
            <FormGroup>
              <FormSelect
                name="type"
                label={getString("participant_type")}
                defaultValue={ipTypeOptions.find(
                  (o) => o.value === validation.values.type,
                )}
                value={validation.values.type}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "type")
                }
                isMulti={false}
                options={ipTypeOptions}
                error={validation.errors.type}
              />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
