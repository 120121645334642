import React from "react";
import { useSearch } from "./SearchProvider";
import SearchInput from "./components/SearchInput";
import SearchDropdown from "./components/SearchDropdown";

const SearchContent = () => {
  const { formRef } = useSearch();

  return (
    <form className="app-search d-md-block" ref={formRef}>
      <SearchInput />
      <SearchDropdown />
    </form>
  );
};

export default SearchContent;
