import React from "react";
import { getString } from "Components/Common/FormattedString";
import FormSwitch from "Components/Entity/FormSwitch";

const OnlyWaitlistAvailableFilter = ({ onChange, filters }) => {
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    onChange("onlyWaitlistAvailable", isChecked ? true : null);
  };

  return (
    <FormSwitch
      label={getString("only_waitlist_available")}
      name="onlyWaitlistAvailable"
      checked={filters.onlyWaitlistAvailable || false}
      onChange={handleSwitchChange}
      data-testid="switch-filter-onlyWaitlistAvailable"
      noPadding
    />
  );
};

export default OnlyWaitlistAvailableFilter;
