import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../SearchProvider";
import { getIpParticipantListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { Spinner } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";

const IpParticipantsList = () => {
  const {
    searchValue,
    setSearchValue,
    closeDropdown,
    dropdownRef,
    recentSearches,
    setRecentSearches,
  } = useSearch();
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxResults = 5;

  const fetchParticipants = useCallback(
    debounce(() => {
      setLoading(true);
      getIpParticipantListPromise()
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => new Date(a.dateOfBirth) - new Date(b.dateOfBirth),
          );
          setParticipants(sortedResponse);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_participants"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);

  const handleLinkClick = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
    }
    setSearchValue("");
    closeDropdown(dropdownRef.current);
  };

  const filteredParticipants = participants.filter((participant) =>
    `${participant.firstName} ${participant.lastName}`
      .toLowerCase()
      .includes(searchValue.toLowerCase()),
  );

  if (filteredParticipants.length === 0 && !loading) {
    return null;
  }

  return (
    <>
      <div className="dropdown-header mt-2">
        <h6 className="text-overflow text-muted mb-1 text-uppercase">
          <FormattedString id="ip_participants" />
        </h6>
      </div>
      {loading ? (
        <div className="text-center my-3">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {filteredParticipants
            .slice(0, maxResults)
            .map((participant, index) => (
              <Link
                key={index}
                to={`/ip-participants/${participant.id}`}
                className="dropdown-item notify-item py-2"
                onClick={handleLinkClick}
              >
                <div className="d-flex">
                  <i className="ri-user-line me-3 fs-18 text-muted"></i>
                  <div className="flex-grow-1">
                    <h6 className="m-0">{`${participant.firstName} ${participant.lastName}`}</h6>
                    <div className="participants-list__subheader">
                      <span className="fs-11 mb-0 text-muted">
                        +{participant.phonePrefix}{" "}
                        {splitNumbers(participant.phoneNumber)}{" "}
                      </span>
                      <span className="fs-11 mb-0 text-muted">
                        {getString("status")}: {participant.status}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </>
      )}
    </>
  );
};

export default IpParticipantsList;
