import { useState, useRef, useEffect } from "react";
import "./FormPhoneField.scss";
import "react-intl-tel-input/dist/main.css";
import IntlTelInput from "react-intl-tel-input";
import classNames from "classnames";
import { FormFeedback, Label } from "reactstrap";
import parsePhoneNumberFromString from "libphonenumber-js";
import { defaultPreferredCountries } from "./utils/preferredCountries";

const FormPhoneField = ({
  label,
  name,
  value,
  onChange,
  error,
  phonePrefixName,
  phonePrefix,
  onPrefixChange,
  disabled = false,
  setError,
  preferredCountries = defaultPreferredCountries,
}) => {
  const initialPhoneValueRef = useRef(
    parsePhoneNumberFromString(`+${phonePrefix}${value}`),
  );
  const initialPhoneValue = initialPhoneValueRef.current;
  const [phoneValue, setPhoneValue] = useState(
    initialPhoneValue
      ? initialPhoneValue.formatInternational()
      : phonePrefix
        ? `+${phonePrefix}`
        : "",
  );

  const defaultCountry = initialPhoneValue
    ? initialPhoneValue.country
    : undefined;

  useEffect(() => {
    const parsedNumber = parsePhoneNumberFromString(`+${phonePrefix}${value}`);
    if (parsedNumber) {
      setPhoneValue(parsedNumber.formatInternational());
    }
  }, [phonePrefix, value]);

  const handlePhoneChange = (
    isValid,
    value,
    _selectedCountryData,
    fullNumber,
  ) => {
    if (value === phoneValue) {
      return;
    }
    setPhoneValue(value);
    if (isValid) {
      setError(undefined);
      const parsedNumber = parsePhoneNumberFromString(fullNumber);

      const event = {
        target: {
          name: name,
          value: parsedNumber.nationalNumber,
        },
      };

      onChange(event);

      const prefixEvent = {
        target: {
          name: phonePrefixName,
          value: parsedNumber.countryCallingCode,
        },
      };
      onPrefixChange(prefixEvent);
    } else {
      if (value) {
        const errorMessage = "Invalid number";
        setError(errorMessage);
      }
    }
  };

  return (
    <div
      className={classNames([
        "form-phone-field mb-3",
        disabled && "form-phone-field--disabled",
        error && "form-phone-field--error",
      ])}
    >
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
      </Label>
      <IntlTelInput
        containerClassName="intl-tel-input"
        inputClassName={`form-phone-field__input ${error ? "is-invalid" : ""}`}
        preferredCountries={preferredCountries}
        value={phoneValue}
        onPhoneNumberChange={handlePhoneChange}
        disabled={disabled}
        defaultCountry={defaultCountry}
        format={false}
        formatOnInit={false}
        nationalMode={false}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormPhoneField;
