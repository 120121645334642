// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.programs-list__subheader {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 0;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Search/components/ProgramsList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,UAAA;AACF","sourcesContent":[".programs-list__subheader {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  row-gap: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
