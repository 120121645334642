import React from "react";
import "./DetailsBox.scss";
import { SimplestTable } from "../SimplestTable/SimplestTable";
import { FormattedString, getString } from "../FormattedString";
import { renderDetailsItem } from "./methods/renderDetailsItem";
import { filterDetailsData } from "./methods/filterDetailsData";
import { DetailsBoxTabSelect } from "./partials/DetailsBoxTabSelect";

export const DetailsBox = ({ item = {} }) => {
  const preparedData = Object.keys(item).map((accessor) => ({
    key: accessor,
    value: item[accessor],
  }));

  return (
    <div className="details-box">
      <DetailsBoxTabSelect />
      <div className="details-box__content">
        <SimplestTable
          data={filterDetailsData(preparedData)}
          className="details-box__table"
          borderless
          customRows={["installments"]}
          columns={[
            {
              header: "Key",
              render: (item) => (
                <div className="details-box__cell-content">
                  <FormattedString id={getString(item.key)} />
                </div>
              ),
            },
            {
              header: "Value",
              render: (item) =>
                item.key === "installments" ? (
                  <>{renderDetailsItem(item)}</>
                ) : (
                  <div className="details-box__cell-content">
                    {renderDetailsItem(item)}
                  </div>
                ),
            },
          ]}
        />
      </div>
    </div>
  );
};
