import React from "react";
import { Row, Col, Button } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import FormTextField from "Components/Entity/FormTextField";
import FormNumberField from "Components/Entity/FormNumberField";
import { FormSelect } from "Components/Entity/FormSelect";
import {
  handleInputChange,
  handleNumberInputChange2,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormContext } from "../../provider/utils/FormContext";

const InstallmentFormFields = ({
  installment,
  index,
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  isDisabled,
}) => {
  const { maxInstallmentSum } = useFormContext();

  const remainingPrice =
    maxInstallmentSum !== 0
      ? maxInstallmentSum -
        formik.values.installments.reduce((sum, inst, i) => {
          return i !== index ? sum + inst.price : sum;
        }, 0)
      : undefined;

  return (
    <div>
      <Row className="mb-2">
        <Col>
          <h5 className="text-left text-primary blue-header">
            {getString("installment")} {index + 1}
          </h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={2}>
          <FormNumberField
            label={getString("price")}
            name={`installments[${index}].price`}
            value={formik.values.installments[index].price}
            onChange={(e) =>
              handleNumberInputChange2(
                formik,
                e,
                `installments[${index}].price`,
              )
            }
            error={
              remainingPrice !== 0 &&
              remainingPrice !== formik.values.installments[index].price &&
              !isDisabled
            }
            disabled={isDisabled}
            min={0}
            max={remainingPrice === 0 ? undefined : remainingPrice}
          />
        </Col>
        <Col md={2}>
          <FormNumberField
            label={getString("due_days")}
            name={`installments[${index}].dueDays`}
            value={formik.values.installments[index].dueDays}
            onChange={(e) =>
              handleNumberInputChange2(
                formik,
                e,
                `installments[${index}].dueDays`,
              )
            }
            error={formik.errors.installments?.[index]?.dueDays ? " " : ""}
            disabled={isDisabled}
          />
        </Col>
        <Col md={2}>
          <FormTextField
            label={getString("currency")}
            name={`installments[${index}].currency`}
            value={formik.values.installments[index].currency}
            onChange={(e) =>
              handleInputChange(formik, e, `installments[${index}].currency`)
            }
            error={formik.errors.installments?.[index]?.currency ? " " : ""}
            disabled={isDisabled}
          />
        </Col>
        <Col md={2}>
          <FormSelect
            label={getString("type")}
            name={`installments[${index}].type`}
            value={formik.values.installments[index].type}
            defaultValue={installmentTypes.find(
              (r) => r.value === installment.type,
            )}
            onChange={(selectedOption) =>
              handleSelectChange(
                formik,
                selectedOption,
                `installments[${index}].type`,
              )
            }
            isMulti={false}
            options={installmentTypes}
            error={formik.errors.installments?.[index]?.type ? " " : ""}
            isDisabled={isDisabled}
          />
        </Col>
        <Col md={2}>
          <FormSelect
            label={getString("pointOfSell")}
            name={`installments[${index}].pointOfSell`}
            value={formik.values.installments[index].pointOfSell}
            defaultValue={pointOfSellOptions.find(
              (r) => r.value === installment.pointOfSell,
            )}
            onChange={(selectedOption) =>
              handleSelectChange(
                formik,
                selectedOption,
                `installments[${index}].pointOfSell`,
              )
            }
            isMulti={false}
            options={pointOfSellOptions}
            error={formik.errors.installments?.[index]?.pointOfSell ? " " : ""}
            isDisabled={isDisabled}
          />
        </Col>
        {!isDisabled && (
          <Col md={2} className="d-flex align-items-end mb-3">
            <Button
              color="warning"
              onClick={() => removeInstallment(index)}
              disabled={formik.values.installments.length === 1}
              className="w-100"
            >
              {getString("remove")}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InstallmentFormFields;
