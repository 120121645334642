import Navdata from "../LayoutMenuData";

export const handleFocus = (dropdown) => {
  dropdown.classList.add("show");
};

// export const handleKeyUp = (searchInput, dropdown, searchOptions) => {
//   // if (searchInput.value.length > 0) {
//   //   dropdown.classList.add("show");
//   //   searchOptions.classList.remove("d-none");
//   // } else {
//   //   dropdown.classList.remove("show");
//   //   searchOptions.classList.add("d-none");
//   // }
// };

export const handleClose = (dropdown) => {
  // searchInput.value = "";
  dropdown.classList.remove("show");
};

export const searchMenuItems = (searchValue, getString) => {
  const menuItems = Navdata();
  const searchTerm = searchValue.toLowerCase();

  const filterItems = (items) => {
    return items
      .map((item) => {
        if (item.subItems) {
          const filteredSubItems = filterItems(item.subItems);
          if (filteredSubItems.length > 0) {
            return { ...item, subItems: filteredSubItems };
          }
        }
        if (item.childItems) {
          const filteredChildItems = filterItems(item.childItems);
          if (filteredChildItems.length > 0) {
            return { ...item, childItems: filteredChildItems };
          }
        }
        if (
          item.id.toLowerCase().includes(searchTerm) ||
          getString(item.id).toLowerCase().includes(searchTerm)
        ) {
          return item;
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  return filterItems(menuItems);
};
