import React from "react";
import { useSelector } from "react-redux";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { SimplestTable } from "Components/Common/SimplestTable/SimplestTable";
import "./InstallmentsPreview.scss";

export const InstallmentsPreview = ({ installments }) => {
  const { pointOfSellData } = useSelector((rootState) => rootState.PointOfSell);

  if (!installments || installments.length === 0) {
    return (
      <div className="installments-preview empty-state">
        <FormattedString id={getString("noInstallmentsAvailable")} />
      </div>
    );
  }

  const columns = [
    {
      header: getString("installment"),
      render: (item, index) => (
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <i className="ri-calendar-line"></i>{" "}
          <FormattedString id={getString("installment")} />{" "}
          <span style={{ color: "#007bff" }}>#{index + 1}</span>
        </div>
      ),
    },
    {
      header: getString("dueDays"),
      render: (item) => item.dueDays,
    },
    {
      header: getString("currency"),
      render: (item) => item.currency,
    },
    {
      header: getString("type"),
      render: (item) => getString(item.type),
    },
    {
      header: getString("pointOfSell"),
      render: (item) => {
        const pointOfSell = pointOfSellData.find(
          (pos) => pos.id === item.pointOfSellId,
        );
        return `${pointOfSell?.name || getString("notSet")}, ${pointOfSell?.paymentMethod?.name || getString("notSet")}`;
      },
    },
  ];

  return (
    <td className="installments-preview" colSpan={2}>
      <SimplestTable
        columns={columns}
        data={installments}
        className="details-box__table"
      />
    </td>
  );
};

export default InstallmentsPreview;
