import { externalizeDate } from "helpers/utils";

const removeEmptyValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === "object" ? removeEmptyValues(v) : v))
      .filter((v) => v != null && v !== "");
  } else if (obj != null && typeof obj === "object") {
    return Object.entries(obj)
      .map(([k, v]) => [
        k,
        v && typeof v === "object" ? removeEmptyValues(v) : v,
      ])
      .reduce(
        (acc, [k, v]) => (v == null || v === "" ? acc : { ...acc, [k]: v }),
        {},
      );
  }
  return obj;
};

const mapFormikValuesToDTO = (
  values,
  currentStep,
  dontInstallments = false,
) => {
  const dto = {
    program: values.program,
    programMeeting: values.programMeeting,
    participants: values.participants.map((participant) => ({
      gender: participant.gender,
      firstName: participant.firstName,
      lastName: participant.lastName,
      comment: participant.comment,
      email: participant.email,
      phonePrefix: participant.phonePrefix,
      phoneNumber: participant.phoneNumber,
      dateOfBirth: externalizeDate(participant.dateOfBirth),
      programPacket: participant.programPacket,
      upsells: [
        participant.upsells.diet,
        participant.upsells.insurance,
        ...participant.upsells.upsells,
      ].filter(Boolean),
    })),
    payerDetails: {
      firstName: values.payerDetails.firstName,
      lastName: values.payerDetails.lastName,
      address: values.payerDetails.address,
      zipCode: values.payerDetails.zipCode,
      city: values.payerDetails.city,
    },
  };

  if (currentStep === 4 && !dontInstallments) {
    dto.installments = values.installments?.map((installment) => ({
      price: installment.price,
      dueDays: installment.dueDays,
      currency: installment.currency,
      type: installment.type,
      pointOfSell: installment.pointOfSell,
    }));
  }

  return removeEmptyValues(dto);
};

export default mapFormikValuesToDTO;
