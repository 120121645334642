// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-actions__row {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
}
.email-actions__row:hover {
  background-color: #f8f9fa;
}
.email-actions__row.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.email-actions__separator {
  margin: 0;
  border: none;
  border-top: 1px solid #ced4da;
  opacity: 0.8;
}
.email-actions .icon-muted-primary {
  color: #6c757d !important;
}
.email-actions .icon-muted-warning {
  color: #ffc107 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/components/EmailActions/EmailActions.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,aAAA;EACA,sCAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,mBAAA;EACA,YAAA;AADN;AAKE;EACE,SAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AAHJ;AAME;EACE,yBAAA;AAJJ;AAOE;EACE,yBAAA;AALJ","sourcesContent":[".email-actions {\n  &__row {\n    cursor: pointer;\n    padding: 10px;\n    transition: background-color 0.3s ease;\n\n    &:hover {\n      background-color: #f8f9fa;\n    }\n\n    &.disabled {\n      cursor: not-allowed;\n      opacity: 0.6;\n    }\n  }\n\n  &__separator {\n    margin: 0;\n    border: none;\n    border-top: 1px solid #ced4da; // Darker shade for more visibility\n    opacity: 0.8; // Increased opacity for more visibility\n  }\n\n  .icon-muted-primary {\n    color: #6c757d !important; // Muted blue\n  }\n\n  .icon-muted-warning {\n    color: #ffc107 !important; // Muted yellow\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
