import React from "react";
import { Col, Row, Button } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";
import steps, {
  STEP_SELECT_PROGRAM,
  STEP_PARTICIPANTS,
  STEP_UPSELLS,
} from "./provider/utils/stepsConfig";
import { useFormContext } from "./provider/utils/FormContext";

const ApplicationFormContent = () => {
  const { currentStep, handleStepChange, formik } = useFormContext();
  const CurrentStepComponent = steps.find(
    (step) => step.id === currentStep,
  ).component;

  const hasErrors = (stepId) => {
    switch (stepId) {
      case STEP_SELECT_PROGRAM:
        return !!Object.keys(formik.errors.program || {}).length;
      case STEP_PARTICIPANTS:
        return (
          (typeof formik.errors.participants === "string" &&
            formik.errors.participants.length > 0) ||
          (Array.isArray(formik.errors.participants) &&
            formik.errors.participants.some((participantErrors) => {
              const { upsells, ...rest } = participantErrors || {};
              return Object.keys(rest).length > 0;
            }))
        );
      case STEP_UPSELLS:
        return (
          (Array.isArray(formik.errors.participants) &&
            formik.errors.participants.some(
              (participantErrors) =>
                Object.keys(participantErrors?.upsells || {}).length,
            )) ||
          !!formik.errors.upsells ||
          !!formik.errors.programMeeting
        );
      default:
        return false;
    }
  };

  const handleStepClick = (targetStepId) => {
    const stepDifference = targetStepId - currentStep;
    if (stepDifference <= 1) {
      handleStepChange(stepDifference);
    }
  };

  return (
    <Row className="application-form">
      <Col md={3} className="steps-control">
        {steps.map((step) => (
          <Button
            key={step.id}
            color={currentStep === step.id ? "primary" : "secondary"}
            onClick={() => handleStepClick(step.id)}
            block
            className={hasErrors(step.id) ? "bg-danger" : ""}
            disabled={step.id > currentStep + 1}
          >
            <FormattedString id="step" /> {step.id} - {step.name}
          </Button>
        ))}
      </Col>
      <Col md={9} className="step-body">
        <CurrentStepComponent />
      </Col>
    </Row>
  );
};

export default ApplicationFormContent;
