// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-participants thead tr {
  background: var(--vz-topbar-user-bg);
  border-top: solid 1px var(--vz-table-border-color);
  border-bottom: solid 1px var(--vz-table-border-color);
}
.customer-participants thead tr th {
  font-size: 13px;
  color: var(--vz-gray);
  font-weight: 400;
  padding: 1rem !important;
}
.customer-participants__cell-gender-item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 8px;
  width: max-content;
}
.customer-participants__cell-gender-item--male {
  background-color: #007bff;
}
.customer-participants__cell-gender-item--female {
  background-color: #ff69b4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Customers/CustomerDetails/partials/components/CustomerParticipants/CustomerParticipants.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;EACA,kDAAA;EACA,qDAAA;AAAJ;AAEI;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,wBAAA;AAAN;AAKI;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AAHN;AAKM;EACE,yBAAA;AAHR;AAMM;EACE,yBAAA;AAJR","sourcesContent":[".customer-participants {\n  thead tr {\n    background: var(--vz-topbar-user-bg);\n    border-top: solid 1px var(--vz-table-border-color);\n    border-bottom: solid 1px var(--vz-table-border-color);\n\n    th {\n      font-size: 13px;\n      color: var(--vz-gray);\n      font-weight: 400;\n      padding: 1rem !important;\n    }\n  }\n\n  &__cell-gender {\n    &-item {\n      border-radius: 4px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      color: white;\n      padding: 0 8px;\n      width: max-content;\n\n      &--male {\n        background-color: #007bff;\n      }\n\n      &--female {\n        background-color: #ff69b4;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
