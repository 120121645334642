import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getString } from "Components/Common/FormattedString";
import { FormMultiSelect } from "Components/Entity/FormMultiSelect";
import { getHotelListData } from "store/actions";

const HotelFilter = ({ onChange, filters }) => {
  const dispatch = useDispatch();
  const { hotelsData, loading, error } = useSelector((state) => state.Hotels);

  useEffect(() => {
    dispatch(getHotelListData());
  }, [dispatch]);

  const options = hotelsData.map((hotel) => ({
    value: hotel.id,
    label: hotel.name,
  }));

  const handleChange = (selectedValues) => {
    onChange("hotel", selectedValues);
  };

  const hotelArray = Array.isArray(filters.hotel)
    ? filters.hotel
    : Object.values(filters.hotel || {});

  return (
    <FormMultiSelect
      name="hotel"
      isClearable
      label={getString("hotel")}
      values={hotelArray}
      setValues={handleChange}
      options={options}
      isLoading={loading}
      error={error}
      data-testid="multi-select-filter-hotel"
    />
  );
};

export default HotelFilter;
