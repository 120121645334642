import React from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import "./ComparisonContainer.scss";
import { formatDate } from "helpers/utils";

const ComparisonContainer = ({ applicationData, parentProgram, formik }) => {
  const getProgramPacketName = (packetId) => {
    const packet = parentProgram.programPackets.find(
      (packet) => packet.id === packetId,
    );
    return packet ? packet.name : "Unknown Packet";
  };

  const getUpsellName = (upsellId) => {
    const upsell = parentProgram.participantUpsells.find(
      (upsell) => upsell.id === upsellId,
    );
    return upsell ? upsell.name : "Unknown Upsell";
  };

  const getMeetingPointAddress = (meetingId) => {
    const meeting =
      parentProgram.programMeetingsPP.find(
        (meeting) => meeting.id === meetingId,
      ) ||
      parentProgram.programMeetingsNS.find(
        (meeting) => meeting.id === meetingId,
      );
    return meeting ? meeting.meetingPoint.address : "Unknown Meeting Point";
  };

  const isDifferent = (oldValue, newValue) => oldValue !== newValue;

  return (
    <Card className="comparison-card mb-4">
      <CardBody>
        <Row>
          <Col md={5}>
            <CardText className="date-text">
              {formatDate(applicationData.startDate)} -{" "}
              {formatDate(applicationData.endDate)}
            </CardText>
            <CardTitle tag="h5" className="comparison-title old-program-header">
              Old Program
            </CardTitle>
            <CardText
              className={`subheader ${
                isDifferent(applicationData.programName, parentProgram.name)
                  ? "text-different-old"
                  : ""
              }`}
            >
              {applicationData.programName}
            </CardText>
            <ol className="participant-list old-program">
              {applicationData.participants.map((participant, index) => (
                <li key={index} className="participant-details">
                  <h6>
                    {participant.firstName} {participant.lastName}
                  </h6>
                  <ul>
                    <li>
                      <span className="value-key">Package:</span>{" "}
                      <span
                        className={
                          isDifferent(
                            participant.programPacketName,
                            getProgramPacketName(
                              formik.values.participants[index].programPacket,
                            ),
                          )
                            ? "text-different-old"
                            : ""
                        }
                      >
                        {participant.programPacketName}
                      </span>
                    </li>
                    <li>
                      <span className="value-key">Meeting:</span>{" "}
                      <span
                        className={
                          isDifferent(
                            applicationData.meetingPoint?.address,
                            getMeetingPointAddress(
                              formik.values.programMeeting,
                            ),
                          )
                            ? "text-different-old"
                            : ""
                        }
                      >
                        {applicationData.meetingPoint?.address}
                      </span>
                    </li>
                    <li>
                      <span className="value-key">Upsells:</span>
                    </li>
                    <ul>
                      <li>
                        <span className="value-key">Diet:</span>{" "}
                        <span
                          className={
                            isDifferent(
                              participant.dietName,
                              getUpsellName(
                                formik.values.participants[index].upsells.diet,
                              ),
                            )
                              ? "text-different-old"
                              : ""
                          }
                        >
                          {participant.dietName}
                        </span>
                      </li>
                      <li>
                        <span className="value-key">Insurance:</span>{" "}
                        <span
                          className={
                            isDifferent(
                              participant.insuranceName,
                              getUpsellName(
                                formik.values.participants[index].upsells
                                  .insurance,
                              ),
                            )
                              ? "text-different-old"
                              : ""
                          }
                        >
                          {participant.insuranceName}
                        </span>
                      </li>
                      {participant.upsells?.map((u, i) => (
                        <li key={i}>
                          <span
                            className={
                              isDifferent(
                                u.name,
                                getUpsellName(
                                  formik.values.participants[index].upsells
                                    .upsells[i],
                                ),
                              )
                                ? "text-different-old"
                                : ""
                            }
                          >
                            {u.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </ul>
                </li>
              ))}
            </ol>
          </Col>
          <Col md={1} className="separator">
            <div className="vertical-line"></div>
          </Col>
          <Col md={6}>
            <CardText className="date-text">
              {formatDate(parentProgram.startDate)} -{" "}
              {formatDate(parentProgram.endDate)}
            </CardText>
            <CardTitle tag="h5" className="comparison-title text-primary">
              New Program
            </CardTitle>
            <CardText
              className={`subheader ${
                isDifferent(applicationData.programName, parentProgram.name)
                  ? "text-different-new"
                  : ""
              }`}
            >
              {parentProgram.name}
            </CardText>
            <ol className="participant-list new-program">
              {formik.values.participants.map((participant, index) => (
                <li key={index} className="participant-details">
                  <h6>
                    {participant.firstName} {participant.lastName}
                  </h6>
                  <ul>
                    <li>
                      <span className="value-key">Package:</span>{" "}
                      <span
                        className={
                          isDifferent(
                            getProgramPacketName(participant.programPacket),
                            applicationData.participants[index]
                              .programPacketName,
                          )
                            ? "text-different-new"
                            : ""
                        }
                      >
                        {getProgramPacketName(participant.programPacket)}
                      </span>
                    </li>
                    <li>
                      <span className="value-key">Meeting:</span>{" "}
                      <span
                        className={
                          isDifferent(
                            getMeetingPointAddress(
                              formik.values.programMeeting,
                            ),
                            applicationData.meetingPoint?.address,
                          )
                            ? "text-different-new"
                            : ""
                        }
                      >
                        {getMeetingPointAddress(formik.values.programMeeting)}
                      </span>
                    </li>
                    <li>
                      <span className="value-key">Upsells:</span>
                    </li>
                    <ul>
                      <li>
                        <span className="value-key">Diet:</span>{" "}
                        <span
                          className={
                            isDifferent(
                              getUpsellName(participant.upsells.diet),
                              applicationData.participants[index].dietName,
                            )
                              ? "text-different-new"
                              : ""
                          }
                        >
                          {getUpsellName(participant.upsells.diet)}
                        </span>
                      </li>
                      <li>
                        <span className="value-key">Insurance:</span>{" "}
                        <span
                          className={
                            isDifferent(
                              getUpsellName(participant.upsells.insurance),
                              applicationData.participants[index].insuranceName,
                            )
                              ? "text-different-new"
                              : ""
                          }
                        >
                          {getUpsellName(participant.upsells.insurance)}
                        </span>
                      </li>
                      {participant.upsells.upsells.map((upsell, i) => (
                        <li key={i}>
                          <span
                            className={
                              isDifferent(
                                getUpsellName(upsell),
                                applicationData.participants[index].upsells[i]
                                  ?.name,
                              )
                                ? "text-different-new"
                                : ""
                            }
                          >
                            {getUpsellName(upsell)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </ul>
                </li>
              ))}
            </ol>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ComparisonContainer;
