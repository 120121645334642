import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getProgramPacketsListData } from "../../store/programPackets/action";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { format } from "date-fns";
import PresetTable from "Components/Common/PresetTable/PresetTable";
import { Badge } from "reactstrap";
import OnlyWaitlistAvailableFilter from "./filterComponents/OnlyWaitlistAvailableFilter";
import OnlyOpenForApplicationsFilter from "./filterComponents/OnlyOpenForApplicationsFilter";
import ProgramStatusFilter from "./filterComponents/ProgramStatusFilter";
import HotelFilter from "./filterComponents/HotelFilter";
import CreatedAtFilter from "./filterComponents/CreatedAtFilter";
import ProgramStartDateFilter from "./filterComponents/ProgramStartDateFilter";
import ProgramEndDateFilter from "./filterComponents/ProgramEndDateFilter";
import WeekFilter from "./filterComponents/WeekFilter";
import { AddProgramPacketCanvas } from "./AddProgramPacketCanvas";
import { EditProgramPacketCanvas } from "./EditProgramPacketCanvas";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import PackageBadge from "Components/Common/PackageBadge/PackageBadge";
import PpSummaryCell from "./cells/PpSummaryCell/PpSummaryCell";
import IpSummaryCell from "./cells/IpSummaryCell/IpSummaryCell";
import PackageFilter from "./filterComponents/PackageFilter";

const ProgramPackets = () => {
  document.title = getString("program_packets_page_title");

  const dispatch = useDispatch();
  const [programPacketAddVisible, setProgramPacketAddVisible] = useState(false);

  const { programPacketsData, count, currentPage, totalPages, loading, error } =
    useSelector((state) => state.ProgramPackets);

  const toggleProgramPacketAdd = () => {
    setProgramPacketAddVisible(!programPacketAddVisible);
  };

  const [selectedPacket, setSelectedPacket] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const toggleEditModal = (packet) => {
    setSelectedPacket(packet);
    setEditModalVisible((prev) => !prev);
  };

  const presetTableRef = useRef(null);

  const refreshData = () => {
    if (presetTableRef.current) {
      presetTableRef.current.refreshData();
    }
  };

  const hasUpdatePermission = useHasPermission("PROGRAM_PACKET_UPDATE");

  const columns = [
    {
      id: "customId",
      Header: "Custom ID",
      accessor: (row) => `#${row.customId}`,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ fontSize: "13px", color: "#6c757d", fontWeight: 500 }}>
          {value}
        </div>
      ),
    },
    {
      id: "name",
      Header: getString("name"),
      accessor: "name",
      disableSortBy: true,
      Cell: ({ value }) => <div className="font-weight-bold">{value}</div>,
    },
    {
      id: "label",
      Header: getString("label"),
      accessor: "label",
      disableSortBy: true,
      Cell: ({ value }) => <PackageBadge packageLabel={value} />,
    },
    {
      id: "week",
      Header: getString("week"),
      accessor: "week",
      disableSortBy: true,
      Cell: ({ value }) => <div className="text-muted">{value}</div>,
    },
    {
      id: "ppSummary",
      Header: "PP Summary",
      accessor: (row) => row.ppSummary,
      disableSortBy: true,
      Cell: ({ value }) => <PpSummaryCell summary={value} />,
    },
    {
      id: "ipSummary",
      Header: "IP Summary",
      accessor: (row) => row.ipSummary,
      disableSortBy: true,
      Cell: ({ value }) => <IpSummaryCell summary={value} />,
    },
    {
      id: "programStatus",
      Header: getString("program_status"),
      accessor: "programStatus",
      disableSortBy: true,
      Cell: ({ value }) => (
        <Badge>
          <FormattedString id={value} />
        </Badge>
      ),
    },
    {
      id: "programStartDate",
      Header: getString("program_start_date"),
      accessor: (row) => format(new Date(row.programStartDate), "yyyy-MM-dd"),
      disableSortBy: true,
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      id: "programEndDate",
      Header: getString("program_end_date"),
      accessor: (row) => format(new Date(row.programEndDate), "yyyy-MM-dd"),
      disableSortBy: true,
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      id: "hotelName",
      Header: getString("hotel_name"),
      accessor: "hotelName",
      disableSortBy: true,
      Cell: ({ value }) => <div>{value || "-"}</div>,
    },
  ];

  if (hasUpdatePermission) {
    columns.push({
      id: "Actions",
      Header: () => (
        <div style={{ textAlign: "right" }}>
          <FormattedString id="actions" />
        </div>
      ),
      disableSortBy: true,
      accessor: (cellProps) => (
        <ActionButtons
          onEdit={() => toggleEditModal(cellProps)}
          itemId={cellProps.id}
          permissionPrefix="PROGRAM_PACKET"
        />
      ),
    });
  }

  const filterMapping = [
    PackageFilter,
    ProgramStatusFilter,
    HotelFilter,
    CreatedAtFilter,
    ProgramStartDateFilter,
    ProgramEndDateFilter,
    WeekFilter,
    OnlyWaitlistAvailableFilter,
    OnlyOpenForApplicationsFilter,
  ];

  return (
    <>
      <PresetTable
        ref={presetTableRef}
        filterMapping={filterMapping}
        title={getString("program_packets_list")}
        headerButtons={
          <WithPermission permissionName="PROGRAM_PACKET_CREATE">
            <button
              type="button"
              className="btn btn-soft-success"
              onClick={toggleProgramPacketAdd}
            >
              <i className="ri-add-circle-line align-middle me-1"></i>
              <FormattedString id="add_program_packet" />
            </button>
          </WithPermission>
        }
        getDataAction={(...props) =>
          dispatch(getProgramPacketsListData(...props))
        }
        columns={columns}
        data={programPacketsData}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        loading={loading}
        error={error}
        onRetry={() => dispatch(getProgramPacketsListData())}
        tableName="programPackets"
      />
      {programPacketAddVisible && (
        <AddProgramPacketCanvas
          visible={programPacketAddVisible}
          setVisible={setProgramPacketAddVisible}
          refreshData={refreshData}
        />
      )}
      {editModalVisible && (
        <EditProgramPacketCanvas
          programPacketToEdit={selectedPacket}
          visible={editModalVisible}
          setVisible={setEditModalVisible}
          refreshData={refreshData}
        />
      )}
    </>
  );
};

export default ProgramPackets;
