import { getStringSync } from "Components/Common/FormattedString";

import SummaryStep from "../../steps/SummaryStep/SummaryStep";

const steps = [
  { id: 1, name: getStringSync("summary"), component: SummaryStep },
];

export default steps;

export const STEP_SELECT_PROGRAM = 1;
export const STEP_PARTICIPANTS = 2;
export const STEP_UPSELLS = 3;
export const STEP_SUMMARY = 2;
