import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form, Spinner } from "reactstrap";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import FormNumberField from "Components/Entity/FormNumberField";
import { getString } from "Components/Common/FormattedString";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { addProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { getProgramListData } from "store/programs/action";
import { programStatus } from "models/programStatuses";

export const AddProgramPacketCanvas = ({
  visible,
  setVisible,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      programId: "",
      ppCapacity: "",
      ipCapacities: {
        ns: "",
        non_ns_ip: "",
        esl: "",
      },
      programPacketTemplateId: "",
      waitlistAvailable: false,
      openForApplications: false,
    },
    validationSchema: Yup.object({
      programId: Yup.string().required(getString("program_required")),
      ppCapacity: Yup.number()
        .min(1, getString("pp_capacity_required"))
        .required(getString("pp_capacity_required")),
      ipCapacities: Yup.object({
        ns: Yup.number()
          .min(0, getString("ns_capacity_required"))
          .required(getString("ns_capacity_required")),
        non_ns_ip: Yup.number()
          .min(0, getString("non_ns_ip_capacity_required"))
          .required(getString("non_ns_ip_capacity_required")),
        esl: Yup.number()
          .min(0, getString("esl_capacity_required"))
          .required(getString("esl_capacity_required")),
      }),
      programPacketTemplateId: Yup.string().required(
        getString("program_packet_template_required"),
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      addProgramPacket(values.programId, values)
        .then(() => {
          toast_success(getString("program_packet_added"));
          refreshData();
          setVisible(false);
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  const { programPacketTemplatesData } = useSelector(
    (rootState) => rootState.ProgramPacketTemplates,
  );
  const { programsData, loading: programsLoading } = useSelector(
    (rootState) => rootState.Programs,
  );

  const programPacketOptions = programPacketTemplatesData
    .filter((item) => item?.status !== "archived")
    .map((pp) => {
      return { value: pp.id, label: pp.label };
    });

  const programOptions = programsData
    .filter((program) => program.status === programStatus.in_preparation)
    .map((program) => ({
      value: program.id,
      label: program.name,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
    dispatch(getProgramListData());
  }, [dispatch]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("program_packet_add")}
      formId="add-program-packet-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          {programsLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Form
              id="add-program-packet-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <FormSelect
                name="programId"
                label={getString("program")}
                value={validation.values.programId}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "programId")
                }
                isMulti={false}
                options={programOptions}
                error={validation.errors["programId"]}
              />
              <FormSelect
                name="programPacketTemplateId"
                label={getString("programPacketTemplate")}
                value={validation.values.programPacketTemplateId}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "programPacketTemplateId",
                  )
                }
                isMulti={false}
                options={programPacketOptions}
                error={validation.errors["programPacketTemplateId"]}
              />
              <hr />
              <FormNumberField
                label={getString("ppCapacity")}
                name="ppCapacity"
                value={validation.values.ppCapacity}
                onChange={(e) => handleInputChange(validation, e, "ppCapacity")}
                error={validation.errors.ppCapacity}
                touched={validation.touched.ppCapacity}
              />
              <hr />
              <h5>{getString("ipCapacities")}</h5>
              <FormNumberField
                label={getString("nsCapacity")}
                name="ipCapacities.ns"
                value={validation.values.ipCapacities.ns}
                onChange={(e) =>
                  handleInputChange(validation, e, "ipCapacities.ns")
                }
                error={validation.errors.ipCapacities?.ns}
                touched={validation.touched.ipCapacities?.ns}
              />
              <FormNumberField
                label={getString("nonNsIpCapacity")}
                name="ipCapacities.non_ns_ip"
                value={validation.values.ipCapacities.non_ns_ip}
                onChange={(e) =>
                  handleInputChange(validation, e, "ipCapacities.non_ns_ip")
                }
                error={validation.errors.ipCapacities?.non_ns_ip}
                touched={validation.touched.ipCapacities?.non_ns_ip}
              />
              <FormNumberField
                label={getString("eslCapacity")}
                name="ipCapacities.esl"
                value={validation.values.ipCapacities.esl}
                onChange={(e) =>
                  handleInputChange(validation, e, "ipCapacities.esl")
                }
                error={validation.errors.ipCapacities?.esl}
                touched={validation.touched.ipCapacities?.esl}
              />
              <hr />
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="waitlistAvailable"
                  name="waitlistAvailable"
                  checked={validation.values.waitlistAvailable}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "waitlistAvailable",
                      e.target.checked,
                    )
                  }
                />
                <label className="form-check-label" htmlFor="waitlistAvailable">
                  {getString("waitlistAvailable")}
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="openForApplications"
                  name="openForApplications"
                  checked={validation.values.openForApplications}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "openForApplications",
                      e.target.checked,
                    )
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="openForApplications"
                >
                  {getString("openForApplications")}
                </label>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
