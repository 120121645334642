import React, { useContext } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { Tile } from "Components/Common/Tile/Tile";
import { getString } from "Components/Common/FormattedString";
import FormikTextArea from "Components/Entity/FormikTextArea";
import { addEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { IpFormSummary } from "../IpFormSummary/IpFormSummary";
import { IpApplications } from "../IpApplications/IpApplications";
import { IpApplicationDetailsContext } from "../../IpApplicationDetails";
import { IpParticipantDetails } from "../IpParticipantDetails/IpParticipantDetails";

export const IpApplicationOverviewTab = () => {
  const { applicationData, refreshIpApplications } = useContext(
    IpApplicationDetailsContext,
  );

  const handleSubmitNote = (entityId, type, content) => {
    return addEntityNote(entityId, type, content)
      .then(() => {
        toast_success(getString("comment_added"));
        refreshIpApplications(applicationData.internationalParticipant.id);
      })
      .catch((e) => {
        toast_error(e.message);
        throw e;
      });
  };

  const entityNotes = applicationData.entityNotes || [];

  const commentNote = entityNotes.find(
    (note) => note.type === "ip_application",
  ) || { content: "" };

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={3}>
          <IpFormSummary />
        </Col>
        <Col xxl={9}>
          <Tile
            title={getString("international_participant_applications")}
            gap="14px"
          >
            <IpApplications />
          </Tile>
        </Col>
      </Row>
      <Row>
        <Col xxl={3}>
          <Tile title={getString("ip_participant_details")} gap="14px">
            <IpParticipantDetails />
          </Tile>
        </Col>
        <Col xxl={5}>
          <Tile title={getString("comment")} gap="14px">
            <FormikTextArea
              name="commentNote"
              value={commentNote.content}
              handleSubmit={(value) =>
                handleSubmitNote(applicationData.id, "ip_application", value)
              }
            />
          </Tile>
        </Col>
        <Col xxl={4}>
          <Tile title={getString("ip_upsells")} gap="14px">
            {applicationData.internationalParticipantUpsells &&
            applicationData.internationalParticipantUpsells.length > 0 ? (
              <ul>
                {applicationData.internationalParticipantUpsells.map(
                  (upsell) => (
                    <li key={upsell.id}>{upsell.name}</li>
                  ),
                )}
              </ul>
            ) : (
              <div className="text-muted">
                No international participant upsells
              </div>
            )}
          </Tile>
        </Col>
      </Row>
    </TabPane>
  );
};
