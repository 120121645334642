import { getString, getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const UpsellTypeEnum = [
  "diet",
  "insurance",
  "meets",
  "touristic",
  "educational",
  "ski_group_level",
];

export const upsellTypeOptions = [
  { value: "diet", label: getStringSync("diet") },
  { value: "insurance", label: getStringSync("insurance") },
  { value: "meets", label: getStringSync("meets") },
  { value: "touristic", label: getStringSync("touristic") },
  { value: "educational", label: getStringSync("educational") },
  { value: "ski_group_level", label: getStringSync("ski_group_level") },
];

const createUpsellSchema = Yup.object({
  name: Yup.string()
    .required(getStringSync("enter_upsell_name"))
    .min(4)
    .max(255),
  description: Yup.string().max(2056),
  emailDescription: Yup.string().max(8192),
  price: Yup.number()
    .required(getStringSync("enter_upsell_price"))
    .test(
      "is-decimal-or-integer",
      getString("price_must_be_integer_or_proper_float"),
      (value) => /^\d+(\.\d)?$/.test(value),
    ),
  type: Yup.string()
    .required(getStringSync("enter_upsell_type"))
    .oneOf(UpsellTypeEnum, getStringSync("invalid_upsell_type")),
  installmentPosition: Yup.string()
    .required(getStringSync("enter_upsell_installment_position"))
    .oneOf(["first", "last"], getStringSync("invalid_installment_position")),
});

export { createUpsellSchema };
