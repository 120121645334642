import React from "react";
import "./SimplestTable.scss";

import classNames from "classnames";

export const SimplestTable = ({
  columns,
  disableHeader = false,
  borderless = false,
  className,
  customRows = [],
  data,
}) => {
  return (
    <table
      className={classNames([
        "simplest-table table table-hover table-nowrap align-middle mb-0",
        borderless && "table-borderless",
        className,
      ])}
    >
      {disableHeader === false && (
        <thead className="table-light">
          <tr className="text-muted">
            {columns.map((column, index) => (
              <th key={index} scope="col" style={column.style}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {(data || []).map((item, rowIndex) =>
          customRows.includes(item.key) ? (
            <tr key={rowIndex}>
              {columns
                .filter((c) => c.header !== "Key")
                .map((column) => column.render(item))}
            </tr>
          ) : (
            <tr key={rowIndex}>
              {columns.map((column, index) => (
                <td key={index} style={column.style}>
                  {column.render(item, rowIndex)}
                </td>
              ))}
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
};
