import React, { useContext, useState } from "react";
import { Row, Col, Spinner, Modal } from "reactstrap";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { getString } from "Components/Common/FormattedString";
import { toast_success, toast_error } from "helpers/toast_helper";
import { sendApplicationEmail } from "helpers/API/core-service/cs_backend_helper";
import ConfirmEmailSendBody from "Components/Entity/ConfirmEmailSendBody";
import "./EmailActions.scss";

const EmailActions = () => {
  const { applicationData, refreshApplicationData } = useContext(
    ApplicationDetailsContext,
  );
  const [isSending, setIsSending] = useState({
    summary: false,
    installment_paid: false,
    payment_reminder: false,
    updated: false,
  });
  const [emailSent, setEmailSent] = useState({
    summary: false,
    installment_paid: false,
    payment_reminder: false,
    updated: false,
  });
  const [modal, setModal] = useState({ visible: false, emailType: null });

  const toggleModal = (emailType = null) => {
    setModal((prevState) => ({
      visible: !prevState.visible,
      emailType,
    }));
  };

  const sendEmail = async (data, emailType) => {
    setIsSending((prevState) => ({ ...prevState, [emailType]: true }));
    try {
      await sendApplicationEmail(applicationData.id, data);
      toast_success(getString("email_sent_successfully"));
      refreshApplicationData();
      setEmailSent((prevState) => ({ ...prevState, [emailType]: true }));
    } catch (error) {
      console.error(error);
      toast_error(getString("email_send_failed"));
    } finally {
      setIsSending((prevState) => ({ ...prevState, [emailType]: false }));
      toggleModal();
    }
  };

  const handleSendSummaryEmail = () => {
    sendEmail({ emailType: "summary" }, "summary");
  };

  const handleSendInstallmentPaidEmail = () => {
    const firstPaidInstallment = applicationData.applicationInstallments.find(
      (installment) => installment.isPaid,
    );
    if (firstPaidInstallment) {
      sendEmail(
        {
          installmentId: firstPaidInstallment.id,
          emailType: "installment_paid",
        },
        "installment_paid",
      );
    }
  };

  const handleSendPaymentReminderEmail = () => {
    const firstUnpaidInstallment = applicationData.applicationInstallments.find(
      (installment) => !installment.isPaid,
    );
    if (firstUnpaidInstallment) {
      sendEmail(
        {
          installmentId: firstUnpaidInstallment.id,
          installmentReminderType: "five_days_before",
          emailType: "payment_reminder",
        },
        "payment_reminder",
      );
    }
  };

  const handleSendUpdatedEmail = () => {
    sendEmail({ emailType: "updated" }, "updated");
  };

  const hasPaidInstallments = applicationData.applicationInstallments.some(
    (installment) => installment.isPaid,
  );

  const getIconClass = (isSending, isSent, isDisabled) => {
    if (isSending) {
      return "ri-loader-4-line";
    }
    if (isDisabled) {
      return "ri-mail-forbid-line";
    }
    return isSent ? "ri-refresh-line" : "ri-send-plane-line";
  };

  const getEmailAction = (emailType) => {
    switch (emailType) {
      case "summary":
        return handleSendSummaryEmail;
      case "installment_paid":
        return handleSendInstallmentPaidEmail;
      case "payment_reminder":
        return handleSendPaymentReminderEmail;
      case "updated":
        return handleSendUpdatedEmail;
      default:
        return null;
    }
  };

  return (
    <div className="email-actions">
      <div
        className="email-actions__row"
        onClick={() => toggleModal("summary")}
      >
        <Row className="align-items-center">
          <Col>
            <span>
              {emailSent.summary
                ? getString("resend_summary_email")
                : getString("send_summary_email")}
            </span>
          </Col>
          <Col className="text-end">
            {isSending.summary ? (
              <Spinner size="sm" />
            ) : (
              <i
                className={`${getIconClass(isSending.summary, emailSent.summary, false)} ${emailSent.summary ? "icon-muted-warning" : "icon-muted-primary"}`}
                title={getString("send_summary_email")}
              ></i>
            )}
          </Col>
        </Row>
      </div>
      <hr className="email-actions__separator" />
      <div
        className={`email-actions__row ${!hasPaidInstallments ? "disabled" : ""}`}
        onClick={
          hasPaidInstallments ? () => toggleModal("installment_paid") : null
        }
      >
        <Row className="align-items-center">
          <Col>
            <span>
              {emailSent.installment_paid
                ? getString("resend_first_installment_paid_email")
                : getString("send_first_installment_paid_email")}
            </span>
          </Col>
          <Col className="text-end">
            {isSending.installment_paid ? (
              <Spinner size="sm" />
            ) : (
              <i
                className={`${getIconClass(isSending.installment_paid, emailSent.installment_paid, !hasPaidInstallments)} ${emailSent.installment_paid ? "icon-muted-warning" : "icon-muted-primary"}`}
                title={getString("send_first_installment_paid_email")}
              ></i>
            )}
          </Col>
        </Row>
      </div>
      <hr className="email-actions__separator" />
      <div
        className="email-actions__row"
        onClick={() => toggleModal("payment_reminder")}
      >
        <Row className="align-items-center">
          <Col>
            <span>
              {emailSent.payment_reminder
                ? getString("resend_payment_reminder_email")
                : getString("send_payment_reminder_email")}
            </span>
          </Col>
          <Col className="text-end">
            {isSending.payment_reminder ? (
              <Spinner size="sm" />
            ) : (
              <i
                className={`${getIconClass(isSending.payment_reminder, emailSent.payment_reminder, false)} ${emailSent.payment_reminder ? "icon-muted-warning" : "icon-muted-primary"}`}
                title={getString("send_payment_reminder_email")}
              ></i>
            )}
          </Col>
        </Row>
      </div>
      <hr className="email-actions__separator" />
      <div
        className="email-actions__row"
        onClick={() => toggleModal("updated")}
      >
        <Row className="align-items-center">
          <Col>
            <span>
              {emailSent.updated
                ? getString("resend_application_updated_email")
                : getString("send_application_updated_email")}
            </span>
          </Col>
          <Col className="text-end">
            {isSending.updated ? (
              <Spinner size="sm" />
            ) : (
              <i
                className={`${getIconClass(isSending.updated, emailSent.updated, false)} ${emailSent.updated ? "icon-muted-warning" : "icon-muted-primary"}`}
                title={getString("send_application_updated_email")}
              ></i>
            )}
          </Col>
        </Row>
      </div>
      <hr className="email-actions__separator" />

      <Modal
        isOpen={modal.visible}
        toggle={() => toggleModal()}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ConfirmEmailSendBody
          emailType={modal.emailType}
          toggle={toggleModal}
          sendEmailAction={getEmailAction(modal.emailType)}
          loading={isSending[modal.emailType]}
        />
      </Modal>
    </div>
  );
};

export default EmailActions;
