import React from "react";
import { Navigate } from "react-router-dom";

// in sidebar order
import DashboardCrm from "../pages/DashboardCrm";
import Hotels from "../pages/Hotels";
import Users from "../pages/Users";
import MeetingPoints from "../pages/MeetingPoints";
import Coordinators from "../pages/Coordinators";
import Programs from "pages/Programs";
import { ProgramDetails } from "../pages/Programs/ProgramDetails/ProgramDetails";
import Attachments from "pages/Attachments";
import PaymentMethods from "pages/PaymentMethods";
import Upsells from "pages/Upsells";
import ProgramPacketTemplates from "pages/ProgramPacketTemplates";

// error pages
import Error403 from "../pages/AuthenticationInner/Errors/Error403";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import PointsOfSell from "pages/PointsOfSell";
import InstallmentPlans from "pages/InstallmentPlans";
import Applications from "pages/Applications";
import IpParticipants from "pages/IpParticipants";
import Basic404 from "pages/AuthenticationInner/Errors/Basic404";
import Error500 from "pages/AuthenticationInner/Errors/Error500";
import { ApplicationDetails } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import Customers from "pages/Customers";
import { CustomerDetails } from "pages/Customers/CustomerDetails/CustomerDetails";
import { IpParticipantDetails } from "pages/IpParticipants/IpParticipantDetails/IpParticipantDetails";
import IpApplications from "pages/IpApplications";
import Payments from "pages/Payments";
import BillingData from "pages/BillingData";
import Insurance from "pages/Insurance";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <DashboardCrm />,
  },

  // Hotels
  {
    path: "/venues/:tab?/:hotelId?",
    component: <Hotels />,
    permission: "HOTEL_READ",
  },

  // Users
  { path: "/users/:userId?", component: <Users />, permission: "USER_READ" },

  // Meeting Points
  {
    path: "/meeting-points/:tab?/:hotelId?",
    component: <MeetingPoints />,
    permission: "MEETING_POINT_READ",
  },

  // Coordinators
  {
    path: "/coordinators/:tab?/:coordinatorId?",
    component: <Coordinators />,
    permission: "COORDINATOR_READ",
  },

  // Payments
  {
    path: "/payments/:tab?/:paymentId?",
    component: <Payments />,
    permission: "PAYMENT_READ",
  },
  {
    path: "/billing-data",
    component: <BillingData />,
    permission: "PAYMENT_READ",
  },
  {
    path: "/insurance",
    component: <Insurance />,
    permission: "INSURANCE_READ",
  },

  // Programs
  {
    path: "/programs/:tab?",
    component: <Programs />,
    permission: "PROGRAM_READ",
  },
  {
    path: "/program/:id",
    component: <ProgramDetails />,
    permission: "PROGRAM_READ",
  },
  {
    path: "/program/:id/edit",
    component: <ProgramDetails />,
    permission: "PROGRAM_UPDATE",
  },

  // Attachments
  {
    path: "/attachments/:tab?/:attachmentId?",
    component: <Attachments />,
    permission: "ATTACHMENT_READ",
  },

  // Payment Methods
  {
    path: "/payment-methods/:attachmentId?",
    component: <PaymentMethods />,
    permission: "PAYMENT_METHOD_READ",
  },

  // Upsells
  {
    path: "/upsells/:upsellId?",
    component: <Upsells />,
    permission: "UPSELL_READ",
  },

  // Applications
  {
    path: "/pp-applications",
    component: <Applications />,
    permission: "PP_APPLICATION_READ",
  },
  {
    path: "/pp-application/:id/:mode?",
    component: <ApplicationDetails />,
    permission: "PP_APPLICATION_READ",
  },

  // Customers
  {
    path: "/customers",
    component: <Customers />,
    permission: "CUSTOMER_READ",
  },
  {
    path: "/customer/:id/:mode?",
    component: <CustomerDetails />,
    permission: "CUSTOMER_READ",
  },

  // IP Participants
  {
    path: "/ip-participants",
    component: <IpParticipants />,
    permission: "IP_PARTICIPANT_READ",
  },
  {
    path: "/ip-participants/:id/:mode?",
    component: <IpParticipantDetails />,
    permission: "IP_PARTICIPANT_READ",
  },
  {
    path: "/ip-applications",
    component: <IpApplications />,
    permission: "IP_APPLICATION_READ",
  },

  // Points of Sell
  {
    path: "/points-of-sell/:pointOfSellId?",
    component: <PointsOfSell />,
    permission: "POINT_OF_SELL_READ",
  },

  // Program Packets
  {
    path: "/program-packet-templates/:programPacketTemplateId?",
    component: <ProgramPacketTemplates />,
    permission: "PROGRAM_PACKET_TEMPLATE_READ",
  },

  // Installment Plans
  {
    path: "/installment-plans/:installmentPlanId?",
    component: <InstallmentPlans />,
    permission: "INSTALLMENT_PLAN_READ",
  },

  // this route should be at the end of all other routes

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
    permission: "DASHBOARD_READ",
  },
  {
    path: "*",
    component: <Navigate to="/dashboard" />,
    permission: "DASHBOARD_READ",
  },
];

const publicRoutes = [
  // Authentication Page
  {
    path: "/",
    exact: true,
    component: <Login />,
    permission: "DASHBOARD_READ",
  },
  { path: "/internal_server_error", component: <Error500 /> },
  { path: "/access_denied", component: <Error403 /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/not_found", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
