import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { toast_error, toast_success } from "helpers/toast_helper";
import { assignIpToProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { ipTypeOptions } from "models/ipParticipants";
import { getProgramListData } from "store/programs/action";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { convertErrorToFormikErrors } from "helpers/parseErrorsToFormik";
import { MinimalisticButton } from "Components/Common/MinimalisticButton/MinimalisticButton";
import "./AssignIpCanvas.scss";
import { v4 as uuidv4 } from "uuid";
import AssignedProgram from "./components/AssignedProgram/AssignedProgram";

export const AssignToProgramCanvas = ({
  visible,
  setVisible,
  refreshData,
  participant,
}) => {
  const dispatch = useDispatch();
  const { programsData } = useSelector((rootState) => rootState.Programs);

  const [loading, setLoading] = useState(false);
  const [programPackets, setProgramPackets] = useState({});
  const [programMeetings, setProgramMeetings] = useState({});
  const [fetchingPackets, setFetchingPackets] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const fetchProgramPackets = async (programId, index) => {
    setErrors({});
    validation.setErrors({});
    setFetchingPackets((prev) => ({ ...prev, [index]: true }));
    try {
      const program = await getProgram(programId);
      const internationalParticipants = program.internationalParticipants;

      const packets = program.programPackets.map((packet) => {
        const participant = internationalParticipants.find(
          (p) => p.programPacketId === packet.id,
        );
        const totalCapacity =
          participant?.summary.capacityPerType[validation.values.type] || 0;
        const quantity =
          participant?.summary.quantity.find(
            (q) => q.type === validation.values.type,
          )?.count || 0;

        const isDisabled =
          totalCapacity <= quantity ||
          participant?.data.some(
            (p) =>
              p.internationalParticipantId ===
                validation.values.internationalParticipantId &&
              p.type === validation.values.type,
          );

        return {
          value: packet.id,
          label: (
            <span>
              {packet.label} (
              <span className={isDisabled ? "" : "sum"}>{quantity}</span> /{" "}
              <span className={isDisabled ? "" : "capacity"}>
                {totalCapacity}
              </span>
              )
            </span>
          ),
          isDisabled,
        };
      });

      const meetings = program.programMeetingsNS.map((meeting) => ({
        value: meeting.id,
        label: `${meeting.meetingPoint.city} ${meeting.startHour}`,
      }));

      setProgramPackets((prev) => ({
        ...prev,
        [index]: packets,
      }));

      setProgramMeetings((prev) => ({
        ...prev,
        [index]: meetings,
      }));

      if (packets.length === 1 && !packets[0].isDisabled) {
        validation.setFieldValue(
          `assignedPrograms[${index}].programPacketId`,
          packets[0].value,
        );
      }

      if (meetings.length === 1) {
        validation.setFieldValue(
          `assignedPrograms[${index}].programMeetingId`,
          meetings[0].value,
        );
      }
    } catch (error) {
      console.error(error);
      toast_error(getString("error_fetching_program_details"));
    } finally {
      setFetchingPackets((prev) => ({ ...prev, [index]: false }));
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      internationalParticipantId: participant.id,
      type: "",
      assignedPrograms: [
        {
          id: "1",
          programId: "",
          programPacketId: "",
          programMeetingId: "",
        },
      ],
    },
    validationSchema: Yup.object({
      internationalParticipantId: Yup.string().required(
        getString("ip_required"),
      ),
      type: Yup.string().required(getString("participant_type_required")),
      assignedPrograms: Yup.array().of(
        Yup.object().shape({
          programId: Yup.string().required(getString("program_required")),
          programPacketId: Yup.string().required(
            getString("program_packet_required"),
          ),
          programMeetingId: Yup.string().required(
            getString("program_meeting_required"),
          ),
        }),
      ),
    }),
    onSubmit: async (values) => {
      if (values.assignedPrograms.length === 0) {
        toast_error(getString("at_least_one_program_required"));
        return;
      }

      const programIds = values.assignedPrograms.map((ap) => ap.programId);
      const hasDuplicates = programIds.some(
        (programId, index) => programIds.indexOf(programId) !== index,
      );

      if (hasDuplicates) {
        toast_error(getString("duplicate_program_assignment"));
        return;
      }

      const participantTypePairs = values.assignedPrograms.map(
        (ap) =>
          `${ap.programId}-${values.internationalParticipantId}-${values.type}`,
      );
      const hasDuplicateParticipants = participantTypePairs.some(
        (pair, index) => participantTypePairs.indexOf(pair) !== index,
      );

      if (hasDuplicateParticipants) {
        toast_error(getString("duplicate_participant_assignment"));
        return;
      }

      setLoading(true);
      setErrors({});
      setSuccess({});

      const requests = values.assignedPrograms.map((assignedProgram) => {
        const requestData = {
          internationalParticipantId: values.internationalParticipantId,
          programPacketId: assignedProgram.programPacketId,
          type: values.type,
          selectedProgram: assignedProgram.programId,
        };

        return assignIpToProgramPacket(
          assignedProgram.programPacketId,
          requestData,
        )
          .then(() => {
            setSuccess((prev) => ({
              ...prev,
              [assignedProgram.id]: true,
            }));
            toast_success(getString("ip_assigned"));
          })
          .catch((error) => {
            setErrors((prev) => ({
              ...prev,
              [assignedProgram.id]: convertErrorToFormikErrors(error),
            }));
          });
      });

      await Promise.all(requests);

      setLoading(false);

      if (Object.keys(errors).length === 0) {
        setVisible(false);
        refreshData();
      }
    },
  });

  const addProgram = (e) => {
    e.preventDefault();
    validation.setFieldValue("assignedPrograms", [
      ...validation.values.assignedPrograms,
      {
        id: uuidv4(),
        programId: "",
        programPacketId: "",
        programMeetingId: "",
      },
    ]);
  };

  const removeProgram = (index) => {
    const updatedPrograms = validation.values.assignedPrograms.filter(
      (_, i) => i !== index,
    );
    validation.setFieldValue("assignedPrograms", updatedPrograms);
  };

  const handleTypeChange = (selectedOption) => {
    handleSelectChange(validation, selectedOption, "type");
    validation.setFieldValue("assignedPrograms", [
      {
        id: uuidv4(),
        programId: "",
        programPacketId: "",
        programMeetingId: "",
      },
    ]);
    setProgramPackets({});
    setProgramMeetings({});
  };

  const programOptions = programsData
    .filter((program) => program.status !== "archived")
    .map((program) => ({
      value: program.id,
      label: `#${program.customId} ${program.name} ${new Date(program.startDate).toLocaleDateString()} - ${new Date(program.endDate).toLocaleDateString()}`,
    }));

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("ip_assign")}
      formId="assign-ip-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card className="assign-ip-card" data-testid="assign-ip-card">
        <CardBody className="card-body">
          <Form
            id="assign-ip-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="internationalParticipantId"
              label={getString("international_participant")}
              value={participant.id}
              isDisabled={true}
              options={[
                {
                  value: participant.id,
                  label: `${participant.firstName} ${participant.lastName}, ${participant.email}`,
                },
              ]}
              defaultValue={{
                value: participant.id,
                label: `${participant.firstName} ${participant.lastName}, ${participant.email}`,
              }}
              error={validation.errors["internationalParticipantId"]}
              data-testid="international-participant-select"
            />
            <FormSelect
              name="participantType"
              label={getString("participant_type")}
              value={validation.values.type}
              onChange={handleTypeChange}
              isMulti={false}
              options={ipTypeOptions}
              error={validation.errors["type"]}
              data-testid="international-participant-type-select"
            />
            <hr style={{ margin: 0, marginBottom: "8px" }} />

            {validation.values.assignedPrograms.map(
              (assignedProgram, index) => (
                <AssignedProgram
                  key={assignedProgram.id}
                  assignedProgram={assignedProgram}
                  index={index}
                  validation={validation}
                  programOptions={programOptions}
                  programPackets={programPackets}
                  programMeetings={programMeetings}
                  fetchingPackets={fetchingPackets}
                  removeProgram={removeProgram}
                  fetchProgramPackets={fetchProgramPackets}
                  errors={errors[assignedProgram.id]}
                  success={success[assignedProgram.id]}
                  disabled={!validation.values.type}
                />
              ),
            )}

            <MinimalisticButton
              buttonStringId="add_another_program"
              onClick={addProgram}
              color="primary"
              icon="ri-add-line"
              data-testid="add-another-program-button"
              disabled={!validation.values.type}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
