import React from "react";
import { FormattedString } from "Components/Common/FormattedString";
import { FormSelect } from "Components/Entity/FormSelect";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { handleSelectChange } from "helpers/validation_helper";

const TypeField = ({
  formik,
  index,
  installmentTypes,
  isDisabled,
  canEditInstallment,
  installment,
}) => {
  const typeError = formik.errors.installments?.[index]?.type || "";

  return (
    <FormSelect
      label={
        typeError !== "" ? (
          <AngloTooltip text={typeError.toString()}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: "red",
              }}
            >
              <i className="ri-information-line"></i>
              <FormattedString id="type" />
            </div>
          </AngloTooltip>
        ) : (
          <FormattedString id="type" />
        )
      }
      name={`installments[${index}].type`}
      value={formik.values.installments[index].type}
      defaultValue={installmentTypes.find((r) => r.value === installment.type)}
      onChange={(selectedOption) =>
        handleSelectChange(
          formik,
          selectedOption,
          `installments[${index}].type`,
        )
      }
      isMulti={false}
      options={installmentTypes}
      error={formik.errors.installments?.[index]?.type ? " " : ""}
      isDisabled={isDisabled || !canEditInstallment}
    />
  );
};

export default TypeField;
