export const defaultPreferredCountries = [
  "pl",
  "de",
  "fr",
  "es",
  "it",
  "gb",
  "nl",
  "be",
  "se",
  "no",
  "dk",
  "fi",
  "pt",
  "gr",
  "cz",
  "hu",
  "at",
  "ch",
  "ie",
  "ro",
  "bg",
  "hr",
  "si",
  "sk",
  "lt",
  "lv",
  "ee",
  "lu",
  "mt",
  "cy",
  "is",
  "li",
  "mc",
  "ad",
  "sm",
  "va",
  "al",
  "ba",
  "mk",
  "me",
  "rs",
  "md",
  "ua",
  "by",
  "ru",
];
