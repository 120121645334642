import React from "react";
import "./PackageCell.scss";
import PackageBadge from "Components/Common/PackageBadge/PackageBadge";

const PackageCell = ({ packages }) => {
  return (
    <div className="package-cell">
      {packages && packages.length > 0
        ? packages.map((pkg, index) => {
            return <PackageBadge packageLabel={pkg} key={index} />;
          })
        : "-"}
    </div>
  );
};

export default PackageCell;
