import React from "react";
import { Card, CardBody, CardHeader, Col, Progress, Table } from "reactstrap";
import {
  getString,
  FormattedString,
} from "../../../../Components/Common/FormattedString";
import { ParticipantEquation } from "../../../../Components/Common/ParticipantEquation/ParticipantEquation";

const UpcomingPrograms = ({ upcomingPrograms }) => {
  const columns = [
    {
      header: getString("packetLabel"),
      style: { width: "30%" },
      render: (participant) => getString(participant.status),
    },
    {
      header: getString("participants"),
      render: (participant) => (
        <span className={`participants-table__type--${participant.status}`}>
          {participant.count}
        </span>
      ),
    },
    {
      header: getString("progress"),
      style: { width: "25%" },
      render: () => <></>,
    },
  ];

  return (
    <React.Fragment>
      <Col className="col-xxl-4 order-xxl-0 order-first">
        <div className="h-100">
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                <FormattedString id={getString("upcomingPrograms")} />
              </h4>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="participants-table table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index} scope="col" style={column.style}>
                          {column.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {upcomingPrograms.length === 0 ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          className="text-start text-muted"
                        >
                          <FormattedString id={getString("noDataAvailable")} />
                        </td>
                      </tr>
                    ) : (
                      upcomingPrograms.map((program, index) => (
                        <React.Fragment key={`${program.id}-${index}`}>
                          <tr>
                            <td>{program.name}</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              {program.packets && program.packets.length > 0 ? (
                                program.packets.map((packet, index) => (
                                  <React.Fragment key={`${packet.id}-${index}`}>
                                    <Table className="table-nowrap mb-0">
                                      <tbody>
                                        <tr>
                                          <td width="30%">
                                            <div className="d-flex align-items-center gap-2">
                                              <span className="fw-medium">
                                                {packet.label}
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <ParticipantEquation
                                              summary={packet}
                                            />
                                          </td>
                                          <td width="25%">
                                            <Progress
                                              value={packet.occupancyFraction}
                                              color="danger"
                                              className="animated-progress custom-progress progress-label"
                                            >
                                              <div className="label">
                                                {packet.occupancyFraction}%
                                              </div>
                                            </Progress>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </React.Fragment>
                                ))
                              ) : (
                                <div>
                                  <FormattedString
                                    id={getString("noDataAvailable")}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default UpcomingPrograms;
