import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserListData,
  editUser,
  getBusinessUnitListData,
} from "../../store/users/action";
import { useFormik } from "formik";
import {
  handleInputChange,
  handleMultiSelectChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import { Card, CardBody, Form } from "reactstrap";
import { createUserSchema, userRoles } from "../../models/users";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "../../Components/Entity/FormTextField";
import { FormSelect } from "../../Components/Entity/FormSelect";
import { getString } from "Components/Common/FormattedString";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const EditUser = ({ user, toggle, visibility }) => {
  const dispatch = useDispatch();

  const { businessUnitsData, formValidationErrors, loading } = useSelector(
    (state) => state.Users,
  );

  const profileUser = useSelector((state) => state.Profile.user);

  const hasManagerManagementPermission = useHasPermission(
    "ROLE_MANAGER_MANAGEMENT",
  );
  const hasAdminManagementPermission = useHasPermission(
    "ROLE_ADMIN_MANAGEMENT",
  );
  const isSuperAdmin = profileUser.roles.includes("ROLE_SUPER_ADMIN");

  useEffect(() => {
    dispatch(getBusinessUnitListData());
  }, [dispatch]);

  const businessUnitsOptions = businessUnitsData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const filteredUserRole =
    (user?.roles || []).filter((role) => role !== "ROLE_CRM")[0] || null;

  const filteredBusinessUnits = user.businessUnits.map((unit) => {
    return { value: unit.id, label: unit.name };
  });

  const filteredUserRoles = userRoles.filter((role) => {
    if (role.value === "ROLE_SUPER_ADMIN") {
      return isSuperAdmin;
    }
    if (role.value === "ROLE_MANAGER") {
      return hasManagerManagementPermission;
    }
    if (role.value === "ROLE_ADMIN") {
      return hasAdminManagementPermission;
    }
    return (
      role.value !== "ROLE_SUPER_ADMIN" &&
      role.value !== "ROLE_MANAGER" &&
      role.value !== "ROLE_ADMIN"
    );
  });

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      role: filteredUserRole,
      businessUnits: filteredBusinessUnits,
    },
    validationSchema: createUserSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values,
        businessUnits: values.businessUnits.map((b) => b.value),
      };

      dispatch(
        editUser(user.id, newValues, () => {
          dispatch(getUserListData());
          toggle(null, false);
        }),
      );
    },
  });

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={`${getString("users_edit")} ${user?.firstName + " " + user?.lastName || ""}`}
        formId="edit-user"
        loading={loading}
        validationRule={validation.isValid}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-user"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("firstName")}
                name="firstName"
                value={validation.values.firstName}
                onChange={(e) => handleInputChange(validation, e, "firstName")}
                error={validation.errors.firstName}
                touched={validation.touched.firstName}
              />
              <FormTextField
                label={getString("lastName")}
                name="lastName"
                value={validation.values.lastName}
                onChange={(e) => handleInputChange(validation, e, "lastName")}
                error={validation.errors.lastName}
                touched={validation.touched.lastName}
              />
              <FormTextField
                label={getString("email")}
                name="email"
                value={validation.values.email}
                onChange={(e) => handleInputChange(validation, e, "email")}
                error={validation.errors.email}
                touched={validation.touched.email}
              />
              <FormSelect
                name="role"
                label={getString("role")}
                value={validation.values.role}
                defaultValue={filteredUserRoles.find(
                  (r) => r.value === validation.values.role,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "role")
                }
                isMulti={false}
                options={filteredUserRoles}
                error={validation.errors.role}
                touched={validation.touched.role}
              />
              <FormSelect
                name="businessUnits"
                label={getString("business_unit")}
                value={validation.values.businessUnits}
                defaultValue={validation.values.businessUnits}
                onChange={(selectedOption) =>
                  handleMultiSelectChange(
                    validation,
                    selectedOption,
                    "businessUnits",
                  )
                }
                isMulti={true}
                options={businessUnitsOptions}
                error={validation.errors.businessUnits}
                touched={validation.touched.businessUnits}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditUser;
