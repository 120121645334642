import React, { useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { ApplicationsList } from "./ApplicationsList";
import AddApplication from "./AddApplication";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";

const Applications = () => {
  document.title = getString("applications_page_title");
  const [applicationAddVisible, setApplicationAddVisible] = useState(false);
  const toggleApplicationAdd = () => {
    setApplicationAddVisible((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("applications_list")}
                  actionBtnLabel={getString("application")}
                  actionBtn={toggleApplicationAdd}
                  actionBtnPermission={"APPLICATION_CREATE"}
                  headerButtons={
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#EBF6FB",
                        color: "#3FA7D6",
                        border: "none",
                      }}
                    >
                      <FormattedString id="generate_report" />
                    </Button>
                  }
                >
                  <ApplicationsList />
                </EntityCardList>
              </div>
            </Col>
            {applicationAddVisible && (
              <AddApplication
                visibility={applicationAddVisible}
                toggle={toggleApplicationAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Applications;
