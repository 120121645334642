import { parseApplicationErrorToFormikErrors } from "./parseApplicationErrorToFormikErrors";
import mapFormikValuesToDTO from "./mapFormikValuesToDTO";
import { toast_success } from "helpers/toast_helper";
import { moveApplication } from "helpers/API/core-service/cs_backend_helper";
import { isObjectEmpty } from "helpers/utils";
import { getString } from "Components/Common/FormattedString";
import moment from "moment";

export const handleSave = async ({
  currentStep,
  formik,
  applicationData,
  setSubmitting,
  refreshApplicationData,
  setMoveTileOpen,
  validateStep,
  targetProgram,
}) => {
  setSubmitting(true);
  const errors = await validateStep();
  if (isObjectEmpty(errors)) {
    const dto = mapFormikValuesToDTO(
      formik.values,
      currentStep,
      false,
      targetProgram,
    );
    try {
      await moveApplication(applicationData.id, dto);
      toast_success(getString("application_moved_successfully"));
      refreshApplicationData();
      setMoveTileOpen(false);
    } catch (error) {
      parseApplicationErrorToFormikErrors(error.data, formik);
    } finally {
      setSubmitting(false);
      refreshApplicationData();
      setMoveTileOpen(false);
    }
  } else {
    formik.setErrors(errors);
    setSubmitting(false);
  }
};

export const calculateRemainingPrice = (formik, maxInstallmentSum, index) => {
  return maxInstallmentSum !== 0
    ? maxInstallmentSum -
        formik.values.installments.reduce((sum, inst, i) => {
          return index !== undefined && i === index ? sum : sum + inst.price;
        }, 0)
    : undefined;
};

export const calculateMinMaxDate = (startDate, minAge, maxAge) => {
  const minDate = moment(startDate)
    .subtract(maxAge + 1, "years")
    .add(1, "days")
    .format("YYYY-MM-DD");
  const maxDate = moment(startDate)
    .subtract(minAge, "years")
    .format("YYYY-MM-DD");
  return { minDate, maxDate };
};

export const isValidYear = (dateOfBirth) => {
  const year = moment(dateOfBirth).year();
  return year >= 1000 && year <= 9999;
};
