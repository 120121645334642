import React from "react";
import { getString } from "Components/Common/FormattedString";
import { FormMultiSelect } from "Components/Entity/FormMultiSelect";
import { statusOptions } from "models/programStatuses";

const ProgramStatusFilter = ({ onChange, filters }) => {
  const handleChange = (selectedValues) => {
    onChange("programStatus", selectedValues);
  };

  const programArray = Array.isArray(filters.programStatus)
    ? filters.programStatus
    : Object.values(filters.programStatus || {});

  return (
    <FormMultiSelect
      name="programStatus"
      isClearable
      label={getString("program_status")}
      values={programArray}
      setValues={handleChange}
      options={statusOptions}
      data-testid="multi-select-filter-programStatus"
    />
  );
};

export default ProgramStatusFilter;
