import React from "react";
import { Link } from "react-router-dom";
import "./InternationalParticipantShort.scss";
import { splitNumbers } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";

export const InternationalParticipantShort = ({ participant }) => {
  const diet = {
    name: participant?.preferredDiet || <FormattedString id="na" />,
    price: participant?.dietPrice || "",
  };
  const addons = participant?.upsells || [];

  return (
    <div className="international-participant-short">
      <div className="international-participant-short__header">
        <div className="international-participant-short__name">
          <Link
            to={`/ip-participants/${participant.internationalParticipantId}`}
            className="participant-link"
          >
            <i className="ri-user-line participant-icon"></i>
            <span className="international-participant-short__name--content">
              {participant?.firstName} {participant?.lastName}
            </span>
          </Link>
        </div>
      </div>
      <div className="international-participant-short__section">
        <div className="international-participant-short__section__title">
          <FormattedString id="type" />
        </div>
        <div className="international-participant-short__section__items">
          <div className="international-participant-short__section__item">
            <div className="international-participant-short__section__item__name">
              {participant.type}
            </div>
          </div>
        </div>
      </div>
      <div className="international-participant-short__section">
        <div className="international-participant-short__section__title">
          <FormattedString id="packet" />
        </div>
        <div className="international-participant-short__section__items">
          <div className="international-participant-short__section__item">
            <div className="international-participant-short__section__item__name">
              {participant.programPacketName}
            </div>
          </div>
        </div>
      </div>
      <div className="international-participant-short__section">
        <div className="international-participant-short__section__title">
          <FormattedString id="meeting_point" />
        </div>
        <div className="international-participant-short__section__items">
          <div className="international-participant-short__section__item">
            <div className="international-participant-short__section__item__name">
              {participant.meetingPoint?.city || <FormattedString id="na" />}
            </div>
          </div>
        </div>
      </div>
      <div className="international-participant-short__section">
        <div className="international-participant-short__section__title">
          <FormattedString id="hotel" />
        </div>
        <div className="international-participant-short__section__items">
          <div className="international-participant-short__section__item">
            <div className="international-participant-short__section__item__name">
              {participant.hotel?.name || <FormattedString id="na" />}
            </div>
          </div>
        </div>
      </div>
      {diet && (
        <div className="international-participant-short__section">
          <div className="international-participant-short__section__title">
            <FormattedString id="diet" />
          </div>
          <div className="international-participant-short__section__items">
            <div className="international-participant-short__section__item">
              <div className="international-participant-short__section__item__name">
                {diet.name}
              </div>
              <div className="international-participant-short__section__item__price">
                {diet?.price ? `${splitNumbers(diet.price)} zł` : ""}
              </div>
            </div>
          </div>
        </div>
      )}
      {addons.length > 0 && (
        <div className="international-participant-short__section">
          <div className="international-participant-short__section__title">
            <FormattedString id="upsells" />
          </div>
          <div className="international-participant-short__section__items">
            {participant.transportPrice && (
              <div className="international-participant-short__section__item">
                <div className="international-participant-short__section__item__name">
                  <FormattedString id="transport" />
                </div>
                <div className="international-participant-short__section__item__price">
                  {participant?.transportPrice
                    ? `${splitNumbers(participant.transportPrice)} zł`
                    : ""}
                </div>
              </div>
            )}
            {addons.map((addon, index) => (
              <div
                key={index}
                className="international-participant-short__section__item"
              >
                <div className="international-participant-short__section__item__name">
                  {addon.name}
                </div>
                <div className="international-participant-short__section__item__price">
                  {addon?.price ? `${splitNumbers(addon.price)} zł` : ""}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
